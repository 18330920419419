import React from 'react';
import styled from 'styled-components';

import { ProductButton } from './ProductButton';

// Define media query breakpoints
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

const colors = {
  brightPurple: '#6060eb', // Bright purple for highlights
  darkBackground: '#101926', // Background color for the card
  white: '#ffffff', // White color for text
  lightGrey: '#abb2bf', // Light grey for feature list
  darkGrey: '#282c34', // Dark grey for the card content background
};

const PriceContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-weight: bold;
  margin: 1.5rem 0;
`;

// Adjustments for the PricingCard
const PricingCard = styled.div`
  background-color: ${props =>
    props.recommended ? 'rgba(255, 255, 255, 0.05)' : 'transparent'};
  color: ${colors.white};
  border-radius: 20px;
  padding: 2rem;
  margin: 1rem;
  // Use the maxCardWidth prop, default to 300px if not provided
  max-width: ${props => props.maxCardWidth || '300px'} !important;
  width: 100%;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: ${props =>
    props.recommended
      ? `3px solid ${colors.brightPurple}`
      : '1px solid rgba(128, 128, 128, 0.5)'};

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.25);
  }

  // @media ${device.mobileS} {
  //   width: 90%;
  // }

  // @media ${device.tablet} {
  //   width: calc(50% - 2rem);
  // }

  // @media ${device.laptopL} {
  //   width: calc(33.333% - 2rem);
  // }
`;

const Badge = styled.span`
  position: absolute;
  top: -1rem;
  right: -1rem;
  background-color: ${colors.brightPurple};
  color: ${colors.white};
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`;

const PerMonth = styled.span`
  color: ${colors.white};
  font-size: 1rem;
  margin-left: 0.3rem;
  margin-bottom: 0.5rem;
  align-self: flex-end;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
`;

const CardTitle = styled.h3`
  color: ${colors.white};
  font-size: calc(0.7rem + 0.5vw); // Use viewport width to scale the font size
  margin-bottom: 1rem;
  font-weight: 600;

  @media (max-width: 1215px) {
    font-size: calc(
      0.7rem + 0.2vw
    ); // Scale down the font size more slowly below 1215px
  }

  @media (max-width: 1110px) {
    font-size: calc(1.2rem);
  }
`;

const CardPrice = styled.span`
  color: ${colors.white};
  font-size: calc(2rem + 0.5vw); // Scale with viewport width

  @media (max-width: 1215px) {
    font-size: calc(2rem + 0.2vw); // Scale down the font size below 1215px
  }
`;

const CardDescription = styled.p`
  color: ${colors.lightGrey};
  font-size: calc(0.6rem + 0.3vw); // Scale with viewport width

  @media (max-width: 1215px) {
    font-size: calc(0.6rem + 0.25vw); // Smaller increment below 1215px
  }
  @media (max-width: 1110px) {
    font-size: 0.9rem;
  }
`;

const Feature = styled.li`
  color: ${colors.lightGrey};
  margin-bottom: 0.5rem;
  font-size: calc(0.6rem + 0.2vw); // Responsive font size
  &:before {
    content: '✓';
    color: ${colors.brightPurple};
    margin-right: 10px;
    font-size: calc(0.8rem + 0.3vw); // Scale the checkmark size too
  }

  @media (max-width: 1215px) {
    font-size: calc(0.6rem + 0.25vw); // Smaller increment below 1215px
    &:before {
      font-size: calc(0.8rem + 0.25vw);
    }
  }

  @media (max-width: 1110px) {
    font-size: 0.9rem;
  }
`;

const PricingComponent = ({
  title,
  description,
  price,
  features,
  recommended,
  perMonth,
  productIndex,
  buttonTitle,
  maxCardWidth,
}) => {
  return (
    <PricingCard recommended={recommended} maxCardWidth={maxCardWidth}>
      {recommended && <Badge>Most Popular</Badge>}
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
      <PriceContainer>
        <CardPrice>{price}</CardPrice>
        {perMonth ? <PerMonth>/month</PerMonth> : null}
      </PriceContainer>
      <ProductButton productIndex={productIndex} title={buttonTitle} />

      {features && (
        <FeatureList>
          {features.map((feature, index) => (
            <Feature key={index}>{feature}</Feature>
          ))}
        </FeatureList>
      )}
    </PricingCard>
  );
};

export default PricingComponent;
