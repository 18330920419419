import React from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

const CustomSingleValue = props => {
  const key = props.data.label;
  const styleBase = {
    fontWeight: '400',
    fontFamily: 'Inter',
    marginLeft: '5px',
    marginTop: 0,
    paddingTop: 0,
  };

  const gradientStyle = {
    ...styleBase,
    background:
      props.data.label === 'NokémonAI Ultimate'
        ? 'linear-gradient(to right, #4279eb, #934ac8, #d05e6a)'
        : 'linear-gradient(to right, #f1b959, #fca142, #da6196)',
    color: 'transparent',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  return (
    <div style={{ marginTop: '-22px' }} key={key}>
      <span style={{ fontWeight: '400', fontFamily: 'Inter' }}>NokémonAI</span>
      <span style={gradientStyle}>
        {props.data.label.includes('Ultimate')
          ? 'Ultimate'
          : props.data.label.replace('NokémonAI ', '')}
      </span>
    </div>
  );
};

const DefaultSingleValue = props => {
  return (
    <span
      style={{
        padding: 0,
        margin: 0,
        color: 'white',
        fontWeight: '400',
        fontFamily: 'Inter',
        marginTop: '-16px',
      }}
    >
      {props.data.label}
    </span>
  );
};

const customOptionSubtitle = {
  'NokémonAI Ultimate': 'Our latest and greatest model',
  'NokémonAI Classic 2.0': 'Classic model from the past',
  'NokémonAI Classic 1.0': 'Our first model, where it all began',
};

const CustomOption = props => {
  return (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {/* Container for both icons */}
        {props.isSelected ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div>
              {props.data.label}
              <div style={{ fontSize: '12px', color: '#dddddd' }}>
                {customOptionSubtitle[props.data.label]}
              </div>
            </div>

            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ marginRight: '5px', color: '#6061eb', fontSize: '20px' }}
            />
          </div>
        ) : (
          <div>
            {props.data.label}
            <div style={{ fontSize: '12px', color: '#dddddd' }}>
              {customOptionSubtitle[props.data.label]}
            </div>
          </div>
        )}
      </div>
    </components.Option>
  );
};

// Modify Dropdown to accept selectedOption instead of just selectedValue
const Dropdown = ({ options, selectedOption, onChange, width, noBorder }) => {
  // Define the styles for the react-select dynamically
  const customSelectStyles = {
    control: provided => ({
      ...provided,
      width: width || 'auto',
      minWidth: '150px' || '150px',
      backgroundColor: '#181b1a',
      borderRadius: '5px',
      padding: '5px',
      color: '#ffffff',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#bbaaff',
      },
      fontSize: noBorder ? '20px' : '14px',
      border: noBorder ? 'none' : `1px solid ${selectedOption.color}`,
      backgroundColor: noBorder
        ? selectedOption.backgroundColor
        : 'transparent',
      padding: noBorder ? '10px 0px' : 'auto',
      color: 'white',
      fontWeight: 600, // Bolder font for emphasis

      '&:hover': {
        backgroundColor: '#252a2b',
      },

      // backgroundColor: '#1E2225', // Example background
      color: '#E5E8EB', // Example text
      borderRadius: '8px', // Adjust corner rounding
      boxShadow: '0px 2px 6px -2px rgb(0, 0, 0, 0.3)',

      cursor: 'pointer',
      width: width || 'auto',
      minWidth: '150px',
      // backgroundColor: '#22282F', // Slightly darker background
      borderRadius: '10px', // Stronger rounding
      padding: '0px', // Increase internal spacing
      color: '#E0E5ED', // Off-white text
      boxShadow: null, // Softer shadow
      '&:hover': {
        backgroundColor: '#2B323B',
      },
      fontFamily: "'Inter', sans-serif",
      marginBottom: '5px',
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: '#181b1a',
      borderRadius: '5px',
      width: width || 'auto',
      minWidth: '210px' || '150px',
      fontSize: '14px',
      backgroundColor: '#1E2225', // Match control background
      borderRadius: '8px',
      backgroundColor: '#22282F',
      borderRadius: '10px',
      fontFamily: "'Inter', sans-serif",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#' : '#ffffff',
      backgroundColor: state.isSelected ? '' : 'transparent',
      '&:hover': {
        backgroundColor: '#252a2b',
        borderColor: '#6061eb', // Change the border color on hover
      },
      fontSize: '14px',
      color: 'white',
      fontWeight: 500, // Bolder font for emphasis
      cursor: 'pointer',
      boxSizing: 'border-box',
      border: '2px solid transparent', // Add this line to account for the border in the box model
      backgroundColor: state.isSelected ? '#363E49' : '#22282F', // Distinct selected state
      color: '#E0E5ED',
      padding: '14px',
      width: '100%',
      fontWeight: state.isSelected ? 600 : 400, // Vary font weight
      '&:hover': {
        backgroundColor: '#313943',
      },
      fontFamily: "'Inter', sans-serif",
    }),
    singleValue: provided => ({
      ...provided,
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#ffffff',
    }),
    clearIndicator: provided => ({
      ...provided,
      color: '#ffffff',
    }),
  };

  return (
    <Select
      options={options.map(option => ({
        value: option.value,
        label: option.label,
        color: option.color,
      }))}
      value={selectedOption}
      onChange={onChange}
      isSearchable={false}
      components={{
        Option: CustomOption,
        ...{
          SingleValue: noBorder ? CustomSingleValue : DefaultSingleValue,
        },
      }}
      styles={customSelectStyles}
    />
  );
};

export default Dropdown;
