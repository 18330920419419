import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import { useUserContext } from '../../context/UserContext';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCrown } from '@fortawesome/fontawesome-free-solid';
import { recordUserInteraction } from '../../utils/userInteractionsLogger/userInteractionLogger';

// Context for tooltip visibility
const TooltipContext = createContext({
  isVisible: false,
  position: {},
  setVisibility: () => {},
  tooltipRef: null,
});

// Styled-components definitions
const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const TooltipContainer = styled.div`
  background: #2a2a2a; /* Dark background for contrast */
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.25); /* Enhanced shadow for depth */
  padding: 1rem; /* Increased padding for better spacing */
  display: none;
  position: absolute;
  z-index: 1000;
  white-space: nowrap;
  color: #e6e6e6; /* Light text for readability */
  max-width: 320px; /* Ensuring it's mobile-friendly */
  border: 2px solid #7c4dff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 300px;
`;

const Header = styled.div`
  font-size: 1.1rem; /* Increased font size */
  font-weight: 600; /* Bold but not too heavy */
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem; /* More space to the subheader */
  color: #ffffff; /* Brighter color for the header */
  text-align: center;
`;

const SubHeader = styled.div`
  color: #bbbbbb; /* Lighter color for distinction */
  // margin-bottom: 1rem; /* Increased bottom margin for visual separation */
  font-weight: 400; /* Lighter weight for hierarchy */
  text-align: center;
  overflow-wrap: break-word; /* Allow long words to be broken and wrap onto the next line */
  word-wrap: break-word; /* Older, alternate name for overflow-wrap */
  hyphens: auto; /* Automatically add hyphens when breaking words (supported in some browsers) */
  word-break: break-word; /* To prevent overflow and ensure wrapping for long words that do not fit the container width */
  white-space: normal; /* Ensure whitespace is handled normally, allowing wrapping */
  line-height: 1.5; /* Adjust line height for better readability and wrapping */
  width: 100%;
`;

const TimerSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* Space before the button */
  justify-content: center;
`;

const TimerIcon = styled.span`
  font-size: 1rem; /* Enlarged icon for visibility */
  margin-right: 0.75rem; /* More space between icon and timer */
  color: #ffd700; /* Gold color for the coin icon */
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1); /* Slightly larger pulse for emphasis */
    }
    100% {
      transform: scale(1);
    }
  }
`;

const CountdownTimer = styled.div`
  font-weight: 700; /* Increased weight for the timer */
  font-size: 1em; /* Larger font size for focus */
  color: white; /* Using the same gold color for consistency */
`;

const UpgradeButton = styled.button`
  background: #7c4dff; /* Slightly lighter purple for better contrast */
  color: #ffffff; /* Maintained white text for readability */
  border: none; /* Removed border for a cleaner look */
  border-radius: 8px; /* Smoothed radius */
  padding: 0.75rem 1rem; /* More padding for a touch-friendly button */
  font-weight: 600; /* Optimal weight for readability */
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Softened shadow for the button */
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    background: #6200ee; /* Darker shade on hover for feedback */
    transform: translateY(-2px); /* Slight raise for tactile feedback */
  }
`;

const calculateTimeUntilMidnightEST = () => {
  // Get current time in UTC
  const now = new Date();
  const nowUTC = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
  );

  // Convert current UTC time to EST
  const estOffset = -5; // EST is UTC-5
  const nowEST = new Date(nowUTC + estOffset * 60 * 60 * 1000);

  // Calculate the next midnight in EST
  const midnightEST = new Date(nowEST);
  midnightEST.setHours(24, 0, 0, 0); // Set hours to next midnight (24)

  // Get the difference in milliseconds
  return midnightEST - nowEST;
};

export const DailyGenerationsTooltipComponent = ({
  title,
  subTitle,
  buttonText,
  isTimer,
}) => {
  const { isVisible, position, tooltipRef } = useContext(TooltipContext);
  const [timer, setTimer] = useState(calculateTimeUntilMidnightEST());

  const userContext = useUserContext();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(calculateTimeUntilMidnightEST());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = time => {
    let seconds = Math.floor(time / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds %= 60;
    minutes %= 60;
    hours %= 24;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <TooltipContainer
      style={{ ...position, display: isVisible ? 'flex' : 'none' }}
      ref={tooltipRef}
    >
      <Header>{title}</Header>
      <SubHeader style={{ marginBottom: '1rem' }}>{subTitle}</SubHeader>
      {isTimer && (
        <TimerSection>
          <FontAwesomeIcon icon={faClock} />
          <CountdownTimer>&nbsp; {formatTime(timer)}</CountdownTimer>
        </TimerSection>
      )}
      <UpgradeButton
        onClick={() => {
          if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
            recordUserInteraction(
              userContext.userInfo.emailHash,
              'Tooltip Click',
              {
                specificAction: buttonText,
                timestamp: new Date(),
              },
            );
          }
          if (buttonText === 'Add Credits') {
            window.location.href = '/marketplace?mode=credits';
          } else {
            window.location.href = '/marketplace?mode=subscription';
          }
        }}
      >
        <FontAwesomeIcon icon={faCrown} />
        &nbsp; {buttonText}
      </UpgradeButton>
    </TooltipContainer>
  );
};

export const GeneralTooltipComponent = ({ title, subTitle }) => {
  const { isVisible, position, tooltipRef } = useContext(TooltipContext);

  return (
    <TooltipContainer
      style={{ ...position, display: isVisible ? 'flex' : 'none' }}
      ref={tooltipRef}
    >
      {title && <Header>{title}</Header>}
      {subTitle && <SubHeader>{subTitle}</SubHeader>}
    </TooltipContainer>
  );
};

export const TooltipManager = ({ children }) => {
  const [isVisible, setVisibility] = useState(false);
  const [position, setPosition] = useState({});
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);
  let touchTimeout = useRef(null);

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const updatePosition = () => {
    if (tooltipRef.current && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      let newPosition = {
        top: triggerRect.bottom, // Position below the trigger element
        left: triggerRect.left - tooltipRect.width / 2 + triggerRect.width / 2,
        position: 'fixed',
      };

      const sideEdgePadding = 30;
      const horizontalEdgePadding = 10;

      // Check if the tooltip exits the screen on the right
      const rightEdge = newPosition.left + tooltipRect.width;

      if (rightEdge > window.innerWidth - 80) {
        newPosition.left -= rightEdge - window.innerWidth + sideEdgePadding;

        if (window.innerWidth > 768) {
          newPosition.left -= 80;
        }
      }

      // Check if the tooltip exits the screen on the left
      if (newPosition.left < sideEdgePadding) {
        newPosition.left = sideEdgePadding;
      }

      // Check if the tooltip exits the screen on the bottom
      const bottomEdge = newPosition.top + tooltipRect.height;
      if (bottomEdge > window.innerHeight - horizontalEdgePadding) {
        newPosition.top =
          triggerRect.top - tooltipRect.height - horizontalEdgePadding;
      }

      // Check if the tooltip exits the screen on the top
      if (newPosition.top < horizontalEdgePadding) {
        newPosition.top = triggerRect.bottom + horizontalEdgePadding;
      }

      setPosition(newPosition);
    }
  };

  const handleMouseEnter = () => {
    if (!isTouchDevice()) {
      setVisibility(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice()) {
      setVisibility(false);
    }
  };

  const handleTouchStart = () => {
    if (isTouchDevice()) {
      touchTimeout.current = setTimeout(() => {
        setVisibility(true);
      }, 500); // Long press duration
    }
  };

  const handleTouchEnd = () => {
    clearTimeout(touchTimeout.current);
    if (isTouchDevice()) {
      setVisibility(false);
    }
  };
  // Use layout effect to update the position immediately after DOM changes
  useLayoutEffect(() => {
    if (isVisible) {
      window.requestAnimationFrame(updatePosition);
    }
  }, [isVisible, updatePosition]);

  useEffect(() => {
    const handleResize = () => {
      if (isVisible) updatePosition();
    };

    const debouncedResize = debounce(handleResize, 100);
    window.addEventListener('resize', debouncedResize);
    return () => window.removeEventListener('resize', debouncedResize);
  }, [isVisible, updatePosition]);

  // Debounce function to limit the rate at which a function can fire
  const debounce = (func, delay) => {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  };

  return (
    <TooltipContext.Provider
      value={{ isVisible, position, setVisibility, tooltipRef }}
    >
      <TooltipWrapper
        ref={triggerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchEnd} // Dismiss on touch move
      >
        {children}
      </TooltipWrapper>
    </TooltipContext.Provider>
  );
};
