import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import Layout from './PageLayout'; // Assuming you have exported these from a file

// Your existing Container component import
import { Container } from '../GeneratorPage/Container';

// All the styled components and components from previous messages

export default function ReferralContainer(props) {
  return (
    <Container userContext={props.userContext}>
      <Layout
        userInfo={props.userContext.userInfo}
        isLoggedIn={props.userContext.isLoggedIn}
      />
    </Container>
  );
}
