import React from 'react';
import Modal from './Modal';

import './LoginPromptModal.css';
import GoogleLoginComponent from './GoogleLogin';
import NokemonSDMascot from '../nokemon_sdv1_mascot.png';

const nokemonImages = [
  // 'https://nokemon.eloie.tech//api/generation/basic/images/fakemon?fileName=type1=Dark_type2=Electric__k=1024_p=0.99_score=0.17075659334659576_count=9195.png',
  'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Grass_type2=Electric_legendary_k=1024_p=0.99_score=122.08161926269531_count=703.png',
  // 'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Poison_type2=Fairy__k=1024_p=0.99_score=100.67861938476562_count=1215.png',
  // 'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Bug_type2=Fairy_legendary_k=1024_p=0.99_score=0.12370345741510391_count=5031.png',
  // 'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Fairy_type2=Fighting_legendary_k=1024_p=0.99_score=0.10827728360891342_count=10500.png',
  // 'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Ghost_type2=Dragon_legendary_k=1024_p=0.99_score=89.00989532470703_count=1449.png',
  // 'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Fire_type2=Dragon_legendary_k=1024_p=0.99_score=105.81150817871094_count=3458.png',
  // 'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Water_type2=Dragon__k=1024_p=0.99_score=0.8191375136375427_count=8680.png',
  // 'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Bug_type2=Electric__k=1024_p=0.99_score=146.56671142578125_count=1454.png',
];

const ModalGoogleLoginButton = ({ renderProps }) => {
  return (
    <>
      <button
        className="LoginPromptModal-google-login-button"
        onClick={renderProps.onClick}
        disabled={renderProps.disabled}
      >
        <div className="google-logo"></div>
        <p className="google-login-button-text">Continue with Google</p>
      </button>
      <p
        style={{ fontSize: '14px', fontWeight: '100' }}
        className="LoginPromptModal-login-modal-subtitle-center"
      >
        Cookies are used to remember you across sessions
      </p>
    </>
  );
};

const LoginPromptModal = ({ isOpen, setModalSettings }) => {
  return (
    <Modal isOpen={isOpen} setModalSettings={setModalSettings}>
      <div className="login-modal">
        <h2 className="LoginPromptModal-login-modal-title">
          Log in to generate more for free!
          <br />
        </h2>
        <img className="login-modal-logo" src={NokemonSDMascot}></img>
        <GoogleLoginComponent
          render={renderProps => (
            <ModalGoogleLoginButton renderProps={renderProps} />
          )}
        />
        <div className="login-modal-hr"></div>{' '}
        <p className="LoginPromptModal-login-modal-subtitle-center">
          <b>Become a Nokémon Master and Join the Community!</b>
        </p>
      </div>
    </Modal>
  );
};

export default LoginPromptModal;
