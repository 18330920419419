import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const LoaderHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 180px;
  max-height: 180px;
  width: 100%;
  background: #21252b;

  @media (max-width: 768px) {
    height: 150px;
    min-height: 150px;
  }
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;

  @media (max-width: 768px) {
    margin: 6px 0;
  }
  @media (max-width: 480px) {
    margin: 4px 0;
  }
`;

export const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7); }
  70% { box-shadow: 0 0 0 10px rgba(76, 175, 80, 0); }
  100% { box-shadow: 0 0 0 0 rgba(76, 175, 80, 0); }
`;

export const StepText = styled.div`
  font-size: 1em;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.7)};
  transition: opacity 0.3s;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const pulseAnimation = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
`;

export const SkeletonTextLoader = styled.div`
  width: 100%;
  border-radius: 4px;
  padding-top: 5px;

  & > div {
    height: 16px; // Typical text line height
    background: linear-gradient(
      to right,
      #f0f0f0 25%,
      #e0e0e0 50%,
      #f0f0f0 75%
    );
    background-size: 200% 100%;
    animation: ${pulseAnimation} 1.5s infinite linear;
    margin-bottom: 8px; // Spacing between lines
    &:last-child {
      width: 25%; // Shorter width for the second line
      margin-bottom: 0;

      @media (max-height: 900px) {
        display: none;
      }
    }
  }
`;

export const PlaceholderIcon = styled.img`
  min-width: 180px;
  max-width: 180px;
  transition: opacity 0.3s ease;
  @media (max-width: 768px) {
    width: 30%;
    min-width: 150px;
    max-width: 150px;
  }
`;

export const LoadingPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 180px; // Adjust as needed
  width: 180px;
  min-width: 180px;
  font-size: 1.5rem;
  color: #aaa; // Color that matches your design
  background-color: #f0f0f0; // Or any placeholder color
  border-radius: 4px;
  animation: ${pulseAnimation} 2s infinite ease-in-out;

  @media (max-width: 768px) {
    height: 150px; // Adjust as needed
    min-width: 150px;
    width: 150px;
  }
`;

// TimeInfoContainer to wrap the time information
export const TimeInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-height: 900px) {
    padding-bottom: 5px;
  }
`;

// TimeText for displaying the time information
export const TimeText = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: white;
  margin: 5px 0;
  white-space: nowrap; /* This prevents the text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`;

export const StatusSection = styled.div`
  display: flex;
  border-radius: 8px;
  width: 100%;
`;

export const StatusTitle = styled.h3`
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  height: 16px;

  padding: 0;
  margin: 0;
  margin-top: -4px;
  color: white;

  // No text overflow on to next line
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 13px;
    height: 10px;
  }
`;

export const StatusValue = styled.span`
  font-size: 14px;
  color: white;
  font-family: 'Inter', sans-serif;
`;

export const StepIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.color || '#ddd'}; // Default color is #ddd
  margin-right: 10px;
`;

export const ActiveStepIcon = styled(StepIcon)`
  animation: ${pulse} 2s infinite;
`;

// Card-like container for each status
export const StatusCard = styled.div`
  padding: 15px;
  border-radius: 10px;
`;

// Updated StatusContainer to lay out the status cards horizontally
export const UpdatedStatusContainer = styled(StatusContainer)`
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 10px; // Space between cards
`;

// Animation for the active step icon
const activePulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

// Styled component for active step icon with animation
export const AnimatedStepIcon = styled(ActiveStepIcon)`
  animation: ${activePulse} 1s infinite ease-in-out;
`;

// Status text with modern typography and color
export const StatusText = styled.div`
  font-family: 'Inter', sans-serif;
  margin-top: 5px;
  font-size: 14px;
  color: #ddd;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

// Animation for the loading indicator
const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;
export const StatusIndicator = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ status }) => {
    switch (status) {
      case 'Initiating AI: Subsequent generations will be faster.':
        return 'orange';
      case 'Getting Ready To Generate...':
        return 'yellow';
      case 'Generating Your Fakémon...':
      case 'Your Fakémon Is Ready!':
        return 'limegreen';
      default:
        return 'grey'; // Default color for undefined or other status
    }
  }};
  margin-right: 10px;

  // Adjust size for smaller screens if needed
  @media (max-width: 600px) {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
`;

export const QueueStatusIndicator = styled(StatusIndicator)`
  background-color: ${({ position }) => {
    if (position === 1) {
      return 'limegreen'; // Active generation
    } else if (typeof position === 'number' && position !== 0) {
      return 'yellow'; // In queue
    } else {
      return 'grey'; // Calculating or undefined position
    }
  }};
`;

export const StatusAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; // Center children vertically
  width: 100%;
  padding: 10px;
  box-sizing: border-box; // Include padding in the element's total width and height

  @media (max-width: 600px) {
    padding: 5px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  @media (max-height: 900px) {
    padding: 5px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`;

export const StatusTop = styled.div`
  display: flex;
  align-items: center; // Align items vertically in the center
  width: 100%;

  @media (max-width: 600px) {
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  width: 97.5%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'Inter', sans-serif;
`;

export const TimeRemainingText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #4caf50; // Example color for remaining time
  margin: 5px 0;
  text-align: center;
  font-family: 'Inter', sans-serif;
`;

export const TimeElapsedText = styled.p`
  padding: 0 10px;
  font-size: 14px;
  color: #ffc107; // Example color for elapsed time
  text-align: center;
  font-family: 'Inter', sans-serif;
`;

const flip = keyframes`
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(360deg);
  }
`;

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
`;

export const AnimatedIcon = styled(FontAwesomeIcon)`
  animation: ${flip} 3s infinite linear, ${bounce} 1s infinite ease-in-out;
  margin-right: 8px;
`;
