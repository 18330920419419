import React from 'react';
import styled from 'styled-components';
import ReferralLinkComponent from './ReferralLinkComponent';
import HandLogo from '../../nokemon-gift-logo.png';

// Styled components
const InfoContainer = styled.div`
  text-align: center;
  padding: 2em 0;
  width: 80%;
`;

const SubHeading = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: clamp(1rem, 2vw, 1.5rem); // Responsive font size
  font-weight: 500;
  color: #d9e8ff; // Lighter color for a modern look
  margin: 0;
  text-align: center;
  letter-spacing: 1px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const Divider = styled.hr`
  margin: 1.5em 0;
  border: none;
`;

const ReferralInfoComponent = ({ referralCode, isLoggedIn }) => {
  return (
    <InfoContainer>
      <SubHeading>
        Share this link with friends and when they sign up, you both get
        rewards!
      </SubHeading>
      <Divider />
      <ReferralLinkComponent
        referralCode={referralCode}
        isLoggedIn={isLoggedIn}
      />
    </InfoContainer>
  );
};

export default ReferralInfoComponent;
