import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { MdSend } from 'react-icons/md';
import { MdLock, MdLockOpen } from 'react-icons/md';
import TooltipComponent from './TooltipComponent';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

import {
  TooltipManager,
  GeneralTooltipComponent,
} from '../../Tooltips/Tooltips';

import { FaBolt, FaCoins } from 'react-icons/fa';
import { recordUserInteraction } from '../../../utils/userInteractionsLogger/userInteractionLogger';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0 3vh 0; // Use viewport height to ensure vertical responsiveness

  @media (max-height: 810px) {
    padding: 0;
    padding-bottom: 10px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  border-radius: 14px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  margin: 0 auto; // Center the container

  height: 55px !important;

  @media (max-height: 900px) {
    height: 45px !important;
  }
`;
const TextInput = styled.input`
  flex-grow: 1;
  background-color: ${props => (props.disabled ? '#3a3f47' : 'transparent')};
  color: ${props => (props.disabled ? '#aaa' : 'white')};
  border: none;
  padding: 16px;
  padding-right: 50px; // Make room for the button
  padding-left: 50px; // Make room for the lock button
  font-size: 1em; // Use em for scalability
  border-radius: 14px;
  width: calc(
    100% - 100px
  ); // Adjusted width to account for padding and buttons
  box-sizing: border-box;
  box-shadow: 0 0 0 1px #888;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'text')};

  font-family: Inter;

  height: 55px !important;

  @media (max-height: 900px) {
    height: 45px !important;
  }

  &::placeholder {
    color: white;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #6061eb; // Active border color when focused
  }

  resize: none; // Prevent the user from resizing the textarea
  height: 50px; // Set a fixed height
  line-height: 1.5; // Line height for better readability
  max-height: 100px; // Maximum height before scrolling

  // Adjust scroll bar styling for Webkit browsers
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  // Adjust scroll bar styling for Firefox
  scrollbar-width: thin;
  scrollbar-color: #888 #555;

  // Adjust scroll bar styling for IE and Edge
  -ms-overflow-style: scrollbar;

  // Additional styles to ensure text wraps and does not overflow
  // white-space: pre-wrap; // Allows line breaks and wrapping
  // word-break: break-word; // Ensures long words do not overflow
`;

const buttonHoverStyles = css`
  background-color: white;
  color: black;
  transform: translateY(-50%) scale(1.05);
`;
const GenerateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border: none;
  padding: 10px;
  border-radius: 25%;
  cursor: ${props => (props.isLocked ? '' : 'pointer')};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  font-size: 1rem; // Increase icon size
  // Apply hover styles if userInput is not empty
  ${({ userInputNotEmpty }) => userInputNotEmpty && buttonHoverStyles}

  background-color: ${props =>
    props.isLocked ? '#aaa' : '#6061eb'}; // Purple when Credits are used
  color: ${props => (props.isLocked ? 'black' : 'white')};

  &:hover {
    background-color: ${props =>
      props.isLocked ? '#aaa' : '#7080eb'}; // Darker purple on hover
    color: ${props => (props.isLocked ? '#000' : '#fff')};
  }

  cursor: ${props =>
    props.isLocked || !props.isButtonClickable ? 'not-allowed' : 'cursor'};

  transform: translateY(-50%)
    ${props => (props.isAnimating ? 'scale(0.8)' : 'scale(1)')};
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    font-size: 1rem; // Smaller icon size for mobile devices
  }
`;

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  z-index: 1;

  &:hover::after {
    content: 'You need Credits to type and generate!';
    position: absolute;
    background: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    top: 100%;
    white-space: nowrap;
    font-size: 0.8em;
    margin-top: 5px;
  }
`;

// LockButton styled component
const LockButton = styled.button`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  color: white;

  &:focus {
    outline: none;
  }

  // Adjust the icon color and size if needed
  svg {
    font-size: 1.5em;
  }
`;

export async function checkGenerationSession(cookie) {
  // Make a request to your backend to check the generation session
  const response = await fetch(
    '/api/generation/advanced/check-generation-session',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cookie }),
    },
  );

  // Parse the response
  const data = await response.json();

  // Return the result
  return data.hasActiveSession;
}

const LockButtonComponent = ({ isLocked, handleLockClick }) => {
  const tooltipText = 'Tap to unlock and start creating your own Fakemon.';
  const tooltipId = 'lockButtonTooltip'; // Unique ID for the tooltip

  // Handle touch events
  const handleTouch = e => {
    e.preventDefault(); // Prevents the default action of the touch event
    handleLockClick(); // Calls the same function as the onClick
  };

  return (
    <TooltipManager>
      <LockButton
        data-tip
        data-for={tooltipId}
        onClick={handleLockClick}
        onTouchEnd={handleTouch} // Added touch event handler
      >
        {isLocked ? <MdLock /> : <MdLockOpen />}
      </LockButton>
      <GeneralTooltipComponent subTitle={tooltipText} />
    </TooltipManager>
  );
};

const GenerateButtonComponent = ({
  isLocked,
  handleSubmit,
  userInputNotEmpty,
  isButtonClickable,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleButtonClick = () => {
    // Start the animation
    setIsAnimating(true);

    // End the animation after 300ms (same as the transition duration)
    setTimeout(() => {
      setIsAnimating(false);
    }, 300);
  };

  const tooltipText = isLocked
    ? 'You must tap on the padlock to enable the text box!'
    : 'Tap to generate your Fakemon! (x1 Credit)';
  const tooltipId = 'generateButtonTooltip'; // Unique ID for the tooltip

  return (
    <TooltipManager>
      <GenerateButton
        data-tip
        data-for={tooltipId}
        type="button"
        onClick={() => {
          handleSubmit();
          handleButtonClick();
        }}
        isLocked={isLocked}
        userInputNotEmpty={userInputNotEmpty}
        isButtonClickable={isButtonClickable}
        isAnimating={isAnimating}
      >
        <MdSend />
      </GenerateButton>

      <GeneralTooltipComponent subTitle={tooltipText} />
    </TooltipManager>
  );
};

const notifyUser = isLocked => {
  const message = isLocked ? (
    <span>
      <FaCoins
        color="#ffc107"
        fontSize={16}
        style={{
          marginBottom: '-3px',
        }}
      />
      &nbsp;&nbsp;You are now set to 'Create Your Own' Mode.
    </span>
  ) : (
    <span>
      <FaBolt
        color="#fdd835"
        fontSize={16}
        style={{
          marginBottom: '-2px',
        }}
      />
      &nbsp;&nbsp;You are now set to 'Daily Discovery Mode' mode.
    </span>
  );

  toast(message);
};

const TextBox = ({
  userInput,
  setUserInput,
  hoverUserInput,
  onGenerate,
  isLocked,
  setIsLocked,
  isGeneratorRunning,
  isAllowedToGenerate,
  isLoggedIn,
  nokePoints,
  isLoading,
  modelVersion,
  userInfo,
}) => {
  const [isButtonClickable, setIsButtonClickable] = useState(true);

  // Function to toggle the locked state
  const handleLockClick = () => {
    if (isLoggedIn && userInfo.emailHash) {
      recordUserInteraction(userInfo.emailHash, 'Click Lock Button', {
        specificAction: isLocked ? 'Unlock' : 'Lock',
        timestamp: new Date(),
      });
    }
    if (isGeneratorRunning) {
      return;
    }

    if (modelVersion != 'modelv3') {
      const message = (
        <span>
          <FaCoins
            color="#ffc107"
            fontSize={16}
            style={{
              marginBottom: '-3px',
            }}
          />
          &nbsp;&nbsp;'Create Your Own Mode` only works for the latest model
          (Model v3.0)',
        </span>
      );

      toast(message);
      return;
    } else {
      setIsLocked(prev => !prev);
      notifyUser(isLocked);
    }
  };

  const handleChange = event => {
    // if (!isLocked) {
    const value = event.target.value;
    // Allow only alphabet characters, commas, full stops, and forward slashes
    if (/^[a-zA-Z,.\/ ]*$/.test(value) && value.length <= 150) {
      setUserInput(value);
    }
  };

  const handleSubmit = async () => {
    if (isLocked || isLoading) {
      // Check if the form is locked or loading
      return;
    }

    // Check if the user is logged in
    if (!isLoggedIn) {
      toast.error(`You need to be logged in to generate your own Fakémon!`);
      return;
    }

    // Check if the user has sufficient credits
    if (nokePoints <= 0) {
      toast.error(
        `Oops, you're out of Credits! Grab more to continue your journey!`,
      );
      return;
    }

    // Check if the user input is empty
    if (!userInput.trim().length) {
      toast.error('Please enter a description for your Fakémon!');
      return;
    }

    setIsButtonClickable(false);
    setTimeout(() => setIsButtonClickable(true), 10000);

    // Check if the user has an active generation session
    const hasActiveSession = await checkGenerationSession(
      localStorage.getItem('jwt'),
    );

    if (hasActiveSession) {
      toast.error(
        'You already have a generation session in progress. Please wait for it to finish before starting a new one.',
      );
      return;
    }

    // Check if the generator is running
    if (isGeneratorRunning) {
      toast.error(
        'The generator is already running. Please wait for it to finish.',
      );
      return;
    }

    // If all checks pass, call the onGenerate function
    onGenerate(userInput);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Container>
      <InputContainer>
        <LockButtonComponent
          isLocked={isLocked}
          handleLockClick={handleLockClick}
        />
        <TextInput
          type="text"
          placeholder={hoverUserInput}
          value={userInput}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          disabled={isLocked}
          maxLength={1000}
        />
        <GenerateButtonComponent
          handleSubmit={handleSubmit}
          isLocked={isLocked}
          userInputNotEmpty={userInput.length > 0}
          isButtonClickable={isButtonClickable}
        />
      </InputContainer>
    </Container>
  );
};

export default TextBox;
