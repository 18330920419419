import React, { useState, useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import { toast } from 'react-toastify';
import styled, { keyframes } from 'styled-components';

// Styled Components
const LoadingModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  background-color: transparen;
`;

const dualRingSpin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: inline-block;
  width: 64px;
  height: 64px;

  &:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ${dualRingSpin} 1.2s linear infinite;
  }
`;

async function handleLogin(
  googleData,
  nonRegisteredIdentificationToken,
  setLoading,
  redirectUrl,
) {
  setLoading(true); // Start loading

  try {
    const res = await fetch('/api/auth/google', {
      method: 'POST',
      body: JSON.stringify({
        token: googleData.tokenId,
        referralCode: localStorage.getItem('referralCode'),
      }),
      headers: {
        'Content-Type': 'application/json',
        'x-token': nonRegisteredIdentificationToken,
      },
    });

    if (res.status === 201 || res.status === 307) {
      const data = await res.json();

      localStorage.setItem('jwt', data.token);

      if (redirectUrl) {
        window.location.replace(redirectUrl);
      } else {
        window.location.replace('/');
      }

      toast.success('Successful Login. Welcome!');
    }
  } catch (error) {
    toast.error('Error during login');
  } finally {
    setLoading(false); // End loading
  }
}
export default function GoogleLoginComponent(props) {
  const [loading, setLoading] = useState(false);

  const nonRegisteredIdentificationToken = props.userContext.storageId;

  return (
    <div>
      {loading && (
        <LoadingModal>
          <Spinner />
        </LoadingModal>
      )}
      <GoogleLogin
        render={props.render}
        clientId="890789690451-kg5d73elv1l2t6gv1k2tq7it54nn4dlv.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={async googleData => {
          await handleLogin(
            googleData,
            nonRegisteredIdentificationToken,
            setLoading,
            props.redirectUrl,
          );
        }}
        onClick={() => setLoading(true)}
        onFailure={async googleData => {
          if (googleData['type'] === 'error') {
            toast.error('Unsuccessful Login');
          }
          setLoading(false);
        }}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  );
}
