import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

import { useUserContext } from '../../../context/UserContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShareAlt,
  faThumbsUp,
  faThumbsDown,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'; // Outline heart
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons'; // Solid heart

// Import your SVG files as React components
import { toast } from 'react-toastify';
import IntroductionPlaceholder from './IntroductionPlaceholder.js';
import LoaderComponent from './loaders/LoaderComponent.js';
import ChatMessage from './ChatMessage.js';
import { recordUserInteraction } from '../../../utils/userInteractionsLogger/userInteractionLogger.js';

const Container = styled.div`
  display: flex;
  justify-content: center;
  // align-items: space-between;
  // padding: 20px;
  max-width: 1100px;
  width: 90%;
  height: 100%;
  @media (max-width: 768px) {
  }

  @media (max-width: 550px) {
    width: 98%;
    padding: 10px;
  }
`;

const MultipleImageContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  justify-items: center;
  align-items: center;

  // Use the smallest of viewport width or height, but not exceeding 1100px
  width: min(1100px, calc(80vw), calc(80vh - 330px));
  height: min(1100px, calc(80vw), calc(80vh - 330px));

  margin: 0 auto;

  aspect-ratio: 1 / 1; // Maintain a 1:1 aspect ratio

  // @media (max-width: 450px) {
  //   grid-template-columns: 1fr;
  //   width: min(300px, 80vw, 80vh);
  //   height: min(300px, 80vw, 80vh);
  // }

  // // Adjust for a header or footer if necessary
  // // This will ensure that the container's height never exceeds the available vertical space
  // max-height: calc(
  //   100vh - 100px
  // ); // Adjust 100px to the combined height of header/footer
  // max-width: calc(100vh - 100px); // Ensure width doesn't exceed adjusted height
`;

const AnimatedImage = styled.img`
  width: 100%;
  max-height: 32vh; // Set to 50% of the viewport height
  aspect-ratio: 1;
  align-self: center;
  margin: auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  background-color: #f5f5f5;

  &:hover {
    transform: scale(1.05); // Slightly increase the scale on hover
  }
`;

const Shimmer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 32vh; // Set to 50% of the viewport height
  aspect-ratio: 1;
  align-self: center;
  background: linear-gradient(to right, #e0e0e0 8%, #f5f5f5 18%, #e0e0e0 33%);
  animation: ${keyframes`
    from { background-position: -468px 0; }
    to { background-position: 468px 0; }
  `} 1.2s linear infinite;
`;

const ImageActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  margin: auto;
  border-radius: 8px;
  overflow: hidden; // Hide overflow to contain the zoom effect
  width: auto;
  height: auto;
  max-height: 40vh; // Set to 50% of the viewport height

  width: ${({ isMulti }) => (isMulti ? 'auto' : 'auto')};
  height: ${({ isMulti }) => (isMulti ? 'auto' : 'auto')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  display: ${({ display }) => display};

  ${({ isVisible }) =>
    isVisible &&
    css`
      transition: opacity 1s ease-in, visibility 1s ease-in;
      animation-delay: 1.5s;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    `};

  // @media (max-width: 940px) {
  //   width: ${({ isMulti }) => (isMulti ? '220px' : '320px')};
  // }
`;
// Define a theme for button colors
const theme = {
  activeColor: '#4A90E2', // e.g., a vibrant blue for active icons
  inactiveColor: '#adb5bd', // a muted grey for inactive
  likeColor: '#FF4136', // a heart red for likes
};

// Styled components for the button bar
const ButtonBar = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #2c2f33; // a modern dark grey
  padding: 10px 0;
  width: 100%;
  border-top: 1px solid #4b4b4b; // subtle border for depth
  z-index: 100;
`;

const IconButton = styled.button`
  font-size: 20px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%; // circular buttons
  transition: background-color 0.2s, transform 0.2s;
  color: ${({ isActive, activeColor }) =>
    isActive ? activeColor : theme.inactiveColor};
  position: relative; // for pseudo-element positioning

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.1); // subtle hover effect
    transform: scale(1.1); // slight scale on hover for feedback
  }

  &:active {
    transform: scale(0.9); // slight scale in on click
  }

  // Accessibility: Text for screen readers
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;

const pulse = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
`;
const IconActionButton = styled.button`
  background: none;
  border: none;
  color: #adb5bd;
  cursor: pointer;
  padding: 8px;
  margin: 0 8px;
  font-size: 20px;
  transition: transform 0.2s, opacity 0.2s;
  color: ${({ activeColor, isActive }) => (isActive ? activeColor : '#cccccc')};
  &:hover {
    transform: translateY(-2px);
    opacity: 0.8;
  }
  &:active {
    transform: translateY(1px);
  }

  // Change from a solid to outlined icon on active state
  & svg {
    fill: ${({ isActive }) => (isActive ? 'currentColor' : 'none')};
    stroke: ${({ isActive }) => (isActive ? 'none' : 'currentColor')};
  }

  // Add a pulse animation on click
  &:active::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30px;
    height: 30px;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.3) 50%,
      transparent 70%
    );
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    animation: ${pulse} 0.6s forwards;
  }
`;

const ChatContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  // gap: 10%;
  width: 100%;
  // height: 100%;
`;

const ImageActionButtonBar = ({ imageUrl }) => {
  const [liked, setLiked] = useState(false);
  const [voted, setVoted] = useState(null);
  const [likeButtonDisabled, setLikeButtonDisabled] = useState(false);
  const [voteButtonDisabled, setVoteButtonDisabled] = useState(false);
  const [shareButtonDisabled, setShareButtonDisabled] = useState(false);

  const userContext = useUserContext();

  // Regular expression to match the UUID in the imageUrl
  const uuidRegex =
    /uuid=([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
  const match = imageUrl.match(uuidRegex);
  const uuid = match ? match[1] : null;

  // Helper function to extract fileName from imageUrl
  const extractFileNameFromImageUrl = imageUrl => {
    const regex = /fileName=([^&]+)/;
    const match = imageUrl.match(regex);
    return match ? match[1] : null;
  };

  // Reset all states when a new imageUrl is loaded
  useEffect(() => {
    setLiked(false);
    setVoted(null);
    setLikeButtonDisabled(false);
    setVoteButtonDisabled(false);
    setShareButtonDisabled(false);
  }, [imageUrl]);

  const handleShare = () => {
    if (shareButtonDisabled) return;
    setShareButtonDisabled(true);

    const fileNameIndex = imageUrl.indexOf('fileName=');
    if (fileNameIndex === -1) {
      console.error('File name not found in the imageUrl');
      return;
    }

    // Construct the sharing URL using the extracted UUID
    const sharingUrl = `/sharing?nokemon=${uuid}`; // Extract everything after 'fileName='
    const fileName = imageUrl.substring(fileNameIndex + 'fileName='.length);

    navigator.clipboard
      .writeText(window.location.origin + `${sharingUrl}`)
      .then(() => {
        toast('✨ Link copied! Ready to share your Fakémon.');

        if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
          recordUserInteraction(
            userContext.userInfo.emailHash,
            'Click Share Button',
            {
              specificAction: 'Copy Link',
              timestamp: new Date(),
              nokemonUUID: uuid,
            },
          );
        }
      })
      .catch(error => {
        console.error('Error copying link:', error);
      });

    // Silently send the request to increment share count
    fetch(`/api/nokemon/share/fileName`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName }),
    }).catch(error => {
      console.error('Network error:', error);
    });

    setTimeout(() => setShareButtonDisabled(false), 2000);
  };

  const handleLike = async () => {
    if (likeButtonDisabled) return;
    setLikeButtonDisabled(true);

    const fileName = extractFileNameFromImageUrl(imageUrl);
    const token = localStorage.getItem('jwt');

    if (!token) {
      toast.error('You must be logged in to modify favorites.');
      return;
    }

    const apiUrl = liked
      ? '/api/user/favourite/delete'
      : '/api/user/favourite/add';

    // Use font awesome icon for heart
    const successMessage = liked
      ? '💔 Fakémon removed from your favorites.'
      : '❤️ Fakémon added to your favorites.';

    // Send the request to add or remove from favourites
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
        body: JSON.stringify({ fileName }),
      });

      if (response.ok) {
        toast(successMessage);
        if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
          recordUserInteraction(
            userContext.userInfo.emailHash,
            'Click Like Button',
            {
              specificAction: liked
                ? 'Remove From Favorites'
                : 'Add To Favorites',
              timestamp: new Date(),
              nokemonUUID: uuid,
            },
          );
        }
      } else {
        const errorMessage = await response.text();
        toast.error(errorMessage);
        if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
          recordUserInteraction(
            userContext.userInfo.emailHash,
            'Click Like Button',
            {
              specificAction: 'Failed To Add To Favorites',
              timestamp: new Date(),
              nokemonUUID: uuid,
            },
          );
        }
        return;
      }
    } catch (error) {
      console.error('Error while modifying favourites:', error);
      toast.error('An error occurred while modifying the Fakémon favorites.');
    }

    setLiked(!liked);
    setTimeout(() => setLikeButtonDisabled(false), 2000);
  };

  const handleVote = async direction => {
    if (voteButtonDisabled || voted) return; // Disable voting if already voted
    setVoteButtonDisabled(true);

    const fileName = extractFileNameFromImageUrl(imageUrl);

    // Only proceed if the user hasn't voted yet
    if (!voted) {
      fetch('/api/nokemon/vote', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName, voteType: direction }),
      });

      toast(
        `🔺 You have ${
          direction === 'up' ? 'upvoted' : 'downvoted'
        } this Fakémon.`,
      );
      setVoted(direction);

      if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
        recordUserInteraction(
          userContext.userInfo.emailHash,
          'Click Vote Button',
          {
            specificAction: direction === 'up' ? 'Upvote' : 'Downvote',
            timestamp: new Date(),
            nokemonUUID: uuid,
          },
        );
      }
    }
  };
  return (
    <ButtonBar>
      <IconButton
        aria-label="Share image"
        onClick={handleShare}
        activeColor={theme.activeColor}
      >
        <span className="sr-only">Share</span>
        <FontAwesomeIcon icon={faShareAlt} />
      </IconButton>

      <IconButton
        aria-label="Upvote image"
        onClick={() => handleVote('up')}
        isActive={voted === 'up'}
        activeColor={theme.activeColor}
      >
        <span className="sr-only">Upvote</span>
        <FontAwesomeIcon icon={faThumbsUp} />
      </IconButton>

      <IconButton
        aria-label="Downvote image"
        onClick={() => handleVote('down')}
        isActive={voted === 'down'}
        activeColor={theme.activeColor}
      >
        <span className="sr-only">Downvote</span>
        <FontAwesomeIcon icon={faThumbsDown} />
      </IconButton>

      <IconButton
        aria-label={liked ? 'Unlike image' : 'Like image'}
        onClick={handleLike}
        isActive={liked}
        activeColor={theme.likeColor}
      >
        <span className="sr-only">{liked ? 'Unlike' : 'Like'}</span>
        <FontAwesomeIcon icon={liked ? fasHeart : farHeart} />
      </IconButton>
    </ButtonBar>
  );
};

const GeneratedImage = ({ imageUrl, isDisplayed, isMulti }) => {
  const [isVisible, setIsVisible] = useState(false);
  const displayStyle = isDisplayed ? 'flex' : 'none';

  useEffect(() => {
    let timeout = setTimeout(() => setIsVisible(isDisplayed), 0);
    return () => clearTimeout(timeout);
  }, [isDisplayed]);

  return (
    <ImageActionContainer
      display={displayStyle}
      isVisible={isVisible}
      isMulti={isMulti}
    >
      {imageUrl ? (
        <AnimatedImage src={imageUrl} alt="Generated Content" />
      ) : (
        <Shimmer />
      )}
      {!isMulti && <ImageActionButtonBar imageUrl={imageUrl} />}
    </ImageActionContainer>
  );
};

const ChatBody = ({
  imageUrl,
  userContext,
  userPrompt,
  isLoading,
  useCredits,
  beamTaskId,
  beamStatus,
  queuePosition,
  isGenerating,
  generationSessionUUID,
}) => {
  const [isChatMessageLoaded, setIsChatMessageLoaded] = useState(false);

  useEffect(() => setIsChatMessageLoaded(!isLoading), [isLoading]);

  return (
    <ChatContainer>
      <ChatMessage
        imageUrl={userContext.userInfo.picture || ''}
        userPrompt={userPrompt}
        isLoading={isLoading}
        toggleMessageCompletion={setIsChatMessageLoaded}
        isLoggedIn={userContext.isLoggedIn}
      />
      <LoaderComponent
        // isDisplayed={true}
        isDisplayed={isLoading && isChatMessageLoaded}
        useCredits={useCredits}
        beamTaskId={beamTaskId}
        beamStatus={beamStatus}
        queuePosition={queuePosition}
        generationSessionUUID={generationSessionUUID}
      />

      {imageUrl && typeof imageUrl === 'string' && (
        <GeneratedImage
          imageUrl={imageUrl}
          isDisplayed={!isLoading && isChatMessageLoaded}
        />
      )}

      {/*If imageUrl is a list of strings, map them to the GeneratedImage object  */}
      {imageUrl && typeof imageUrl === 'object' && imageUrl.length === 1 && (
        <GeneratedImage
          imageUrl={imageUrl}
          isDisplayed={!isLoading && isChatMessageLoaded}
        />
      )}
      {/* Surround GeneratedImage in multi container */}
      {/* If imageUrl is a list of strings, map them to the GeneratedImage object  */}

      {imageUrl && typeof imageUrl === 'object' && imageUrl.length > 1 && (
        <MultipleImageContainer>
          {imageUrl.map((url, index) => (
            <GeneratedImage
              key={index} // Add a unique key prop here
              imageUrl={url}
              isDisplayed={!isLoading && isChatMessageLoaded}
              isMulti={true}
            />
          ))}
        </MultipleImageContainer>
      )}
    </ChatContainer>
  );
};

const GeneratorBody = ({
  imageUrl,
  userContext,
  isLoading,
  userPrompt,
  useCredits,
  beamTaskId,
  beamStatus,
  queuePosition,
  isGenerating,
  generationSessionUUID,
  modelVersion,
}) => {
  return (
    <Container>
      {!isLoading && !imageUrl && (
        <IntroductionPlaceholder modelVersion={modelVersion} />
      )}
      {(isLoading || imageUrl) && (
        <ChatBody
          imageUrl={imageUrl}
          userContext={userContext}
          userPrompt={userPrompt}
          isLoading={isLoading}
          useCredits={useCredits}
          beamTaskId={beamTaskId}
          beamStatus={beamStatus}
          queuePosition={queuePosition}
          isGenerating={isGenerating}
          generationSessionUUID={generationSessionUUID}
        />
      )}
    </Container>
  );
};

export default GeneratorBody;
