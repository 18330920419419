export async function retrieveUserEmailHashFromCookie(token) {
  try {
    const response = await fetch('/api/user/emailHash/get', {
      method: 'GET',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const emailHash = await response.text(); // Assuming the server responds with plain text
    return emailHash;
  } catch (error) {
    console.error('Error retrieving email hash:', error);
    return null;
  }
}
