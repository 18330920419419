import { useEffect, useState } from 'react';

export function VMAvailability(props) {
  const [availability, setAvailability] = useState(
    props.isLoggedIn
      ? 'Calculating...'
      : 'Please log in to use Advanced Generation',
  );
  const [lastChecked, setLastChecked] = useState(undefined);

  const modelType = props.modelType;

  useEffect(() => {
    if (props.isLoggedIn) {
      let url = '/api/generation/advanced/getResourceAvailability';
      if (modelType) {
        url += `?modelType=${encodeURIComponent(modelType)}`;
      }

      fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(data => {
          if (data['status'] == 'available') {
            setAvailability('Available');
            props.setVMAvailability('available');
          } else {
            setAvailability('Unavailable');
            props.setVMAvailability('unavailable');
          }

          const currentTime = Date.now();
          const lastCheckedMinutes =
            (currentTime - data['creationTime']) / 60000;

          setLastChecked(lastCheckedMinutes.toFixed(0));
        })
        .catch(e => {
          setAvailability('Unavailable');
          props.setVMAvailability('Unavailable');
        });
    }
  }, [modelType]); // include modelType in the dependency array

  return (
    <div
      style={{
        fontSize: '17px',
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '500',
        color: props.color || 'black',
      }}
    >
      <div data-cy="resourceStatus" class="status-icon">
        <i
          style={{
            borderColor:
              availability == 'Calculating...'
                ? 'gray'
                : availability == 'Available'
                ? '#61ef61'
                : 'red',
            backgroundColor:
              availability == 'Calculating...'
                ? 'gray'
                : availability == 'Available'
                ? '#61ef61'
                : 'red',
          }}
        ></i>{' '}
        &nbsp;Resources: {availability} {/* {lastChecked != undefined */}
        {/* ? `(Last Checked: ${lastChecked} mins ago)` */}
        {/* : ' '} */}
      </div>
    </div>
  );
}
