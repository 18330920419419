// useLoaderProgress.js
import { useState, useEffect } from 'react';

/**
 * A custom hook to manage the progress state of a loader.
 * @param {boolean} isVisible - Determines if the loader is visible.
 * @returns {number} The current progress value.
 */
export const useLoaderProgress = isVisible => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;

    if (isVisible) {
      setProgress(0);
      interval = setInterval(() => {
        setProgress(prevProgress => Math.min(prevProgress + 1, 100));
      }, 50);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isVisible]);

  return progress;
};
