import React, { useState, useEffect, useRef, useContext } from 'react';
import { RadioButton } from 'react-radio-buttons';
import { RadioGroup } from 'react-radio-buttons';
import { constants } from 'nokemon-common-utils';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import Select, { NonceProvider } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/fontawesome-free-solid';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { sleep } from 'nokemon-common-utils';
import { Tab, Tabs, TabList } from 'react-tabs';

import { DailyGenerationsButton } from './LandingPageMenu';
import { Tooltip, Overlay, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { AdvancedInput } from './Input/AdvancedInput/AdvancedInput';
import NokemonContext from '../context/NokemonContext';

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RadioButtons = styled.input`
  appearance: none;
  background-color: transparent;
  border: 2px solid black;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  outline: none;
  position: relative;

  &:checked:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &:disabled {
    opacity: 0.6;
  }

  &:disabled:checked:before {
    background: gray;
  }
`;
const Label = styled.label`
  cursor: pointer;
  margin-right: 10px;
`;

const InfoIcon = styled.i`
  font-size: 1em;
  color: purple;
  margin-left: 5px;
  cursor: pointer;
`;

function Example() {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Button ref={target} onClick={() => setShow(!show)}>
        Click me!
      </Button>
      <Overlay target={target.current} show={show} placement="right">
        {props => (
          <Tooltip id="overlay-example" {...props}>
            My Tooltip
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}

const { types, pokemon, colors, pokemonNameToNumber } = constants;

function InputImage(props) {
  if (props.generatedBy == 'pokemon' && props.selected) {
    return (
      // <img
      //   src={
      //     '/api/images/pokemon?fileName=' +
      //     pokemonNameToNumber[props.selected] +
      //     '.png'
      //   }
      //   className="inputImage"
      // ></img>
      <div />
    );
  } else if (props.generatedBy == 'type' && props.selected) {
    return (
      <div className={'icon ' + props.selected.toLowerCase()}>
        <img
          src={
            '/api/generation/basic/images/types/?fileName=' +
            props.selected.toLowerCase() +
            '.svg'
          }
        ></img>
      </div>
    );
  } else {
    return <div></div>;
  }
}

function AlphabeticalSwitch(props) {
  function handleChange(props) {
    if (props.isAlphabetical) {
      props.setIsAlphabetical(false);
    } else {
      props.setIsAlphabetical(true);
    }
  }

  return (
    <div className="switch">
      <p className="inputTitle switchText">Sort:</p>
      <Switch
        onChange={() => handleChange(props)}
        checked={props.isAlphabetical}
        width={36}
        height={18}
      />
    </div>
  );
}

function GenerationFilter(props) {
  const generations = [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
    {
      label: 4,
      value: 4,
    },
    {
      label: 5,
      value: 5,
    },
    {
      label: 6,
      value: 6,
    },
    {
      label: 7,
      value: 7,
    },
  ];
  return (
    <div className="generationFilter">
      <h4 className="inputTitle">Filter by Generation</h4>
      <Select
        className="selector"
        placeholder="Select..."
        options={generations}
        defaultValue={generations}
        onChange={filterValues => {
          props.setGenerationFilters(
            filterValues.map(filterValue => {
              return filterValue.value;
            }),
          );
        }}
        isOptionDisabled={option => option.disabled}
        isMulti
      />
    </div>
  );
}

function OldModelSelectionPanel(props) {
  const [isAlphabetical, setIsAlphabetical] = useState(false);
  const [generationFilters, setGenerationFilters] = useState([
    1, 2, 3, 4, 5, 6, 7,
  ]);
  const [options, setOptions] = useState(types);
  const [style, setStyle] = useState('3d model');

  useEffect(() => {
    let optionArray =
      props.generatedBy == 'type'
        ? [...types]
        : props.generatedBy == 'pokemon'
        ? [...pokemon]
        : props.generatedBy == 'color'
        ? [...colors]
        : [];

    if (isAlphabetical) {
      optionArray = [...optionArray].sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    }

    setOptions(optionArray);
  }, [props.generatedBy, isAlphabetical, generationFilters]);

  return (
    <div>
      {props.generatedBy == 'random' ? (
        <>
          <h4 className="inputTitle">Image Style:</h4>
          <Select
            className="selector"
            isOptionDisabled={option => option.disabled}
            options={[
              { value: '3d model', label: '3D Style' },
              {
                value: 'sugimori',
                label: '2D Style (new!)',
                disabled: false,
              },
            ]}
            onChange={obj => {
              setStyle(obj['value']);
              props.setStyle(obj['value']);
            }}
            // defaultValue={{ label: 'Sugimori (new)', value: 'sugimori' }}
            defaultValue={{ label: '3D Style', value: '3d model' }}
          />
          <h4 className="inputTitle">Number Of Generations:</h4>

          <Select
            className="selector"
            defaultValue={{
              label: 1,
              value: 1,
            }}
            isOptionDisabled={option => option.disabled}
            options={[
              {
                label: 1,
                value: 1,
              },
              {
                label:
                  props.subscriptionTier >= 1
                    ? '5'
                    : '5 - Novice Subscription Tier Required',
                value: 5,
                disabled: props.subscriptionTier < 1 ? true : false,
              },
              {
                label:
                  props.subscriptionTier >= 2
                    ? '10'
                    : '10 - Elite Subscription Tier Required',
                value: 10,
                disabled: props.subscriptionTier < 2 ? true : false,
              },
              {
                label:
                  props.subscriptionTier >= 3
                    ? '20'
                    : '20 - Master Subscription Tier Required',
                value: 20,
                disabled: props.subscriptionTier < 3 ? true : false,
              },
            ]}
            onChange={obj => {
              props.setBatchNum(obj['value']);
            }}
          />
          {/* <h1
            className="questionMark"
            style={{
              position: 'relative',
              textAlign: 'center',
              fontSize: '120px',
              marginTop: '150px',
            }}
          >
            ?
          </h1> */}
        </>
      ) : (
        <>
          <h4 className="inputTitle">Image Style:</h4>
          <Select
            key={`selector_imageStyle_${props.generatedBy}`}
            className="selector"
            options={
              props.generatedBy == 'type'
                ? [
                    { value: '3d model', label: '3D Style' },
                    { value: 'sugimori', label: '2D Style (new!)' },
                  ]
                : [{ value: '3d model', label: '3D Style' }]
            }
            onChange={obj => {
              setStyle(obj['value']);
              props.setStyle(obj['value']);
            }}
            // defaultValue={{ label: 'Sugimori (new)', value: 'sugimori' }}
            isOptionDisabled={option => option.disabled}
            defaultValue={{ label: '3D Style', value: '3d model' }}
          />
          <h4 className="inputTitle">{props.generatedBy}:</h4>
          <Select
            key={`selector_generatedBy_${props.generatedBy}`}
            id={props.generatedBy}
            className="selector"
            options={options}
            onChange={obj => {
              props.setSelected(obj['value']);
              props.propsSetSelected(obj['value']);
            }}
            isOptionDisabled={option => option.disabled}
          />
          <h4 className="inputTitle">Number Of Generations:</h4>

          <Select
            key={`selector_batch_${props.generatedBy}`}
            className="selector"
            defaultValue={{
              label: 1,
              value: 1,
            }}
            isOptionDisabled={option => option.disabled}
            options={[
              {
                label: 1,
                value: 1,
              },
              {
                label: '5 - Novice Subscription Tier Required',
                value: 5,
                disabled: props.subscriptionTier < 1 ? true : false,
              },
              {
                label: '10 - Elite Subscription Tier Required',
                value: 10,
                disabled: props.subscriptionTier < 2 ? true : false,
              },
              {
                label: '20 - Master Subscription Tier Required',
                value: 20,
                disabled: props.subscriptionTier < 3 ? true : false,
              },
            ]}
            onChange={obj => {
              props.setBatchNum(obj['value']);
            }}
          />
          <br />
        </>
      )}
    </div>
  );
}

function NewModelSelectionPanel(props) {
  const [isAlphabetical, setIsAlphabetical] = useState(false);
  const [generationFilters, setGenerationFilters] = useState([
    1, 2, 3, 4, 5, 6, 7,
  ]);
  const [options, setOptions] = useState(types);

  useEffect(() => {
    let optionArray = [...types];

    setOptions(optionArray);
  }, [props.generatedBy]);

  return (
    <div>
      {props.generatedBy == 'random' ? (
        <>
          <h4 className="inputTitle">Number Of Generations:</h4>

          <Select
            className="selector"
            defaultValue={{
              label: 1,
              value: 1,
            }}
            isOptionDisabled={option => option.disabled}
            options={[
              {
                label: 1,
                value: 1,
              },
              {
                label:
                  props.subscriptionTier >= 1
                    ? '5'
                    : '5 - Novice Subscription Tier Required',
                value: 5,
                disabled: props.subscriptionTier < 1 ? true : false,
              },
              {
                label:
                  props.subscriptionTier >= 2
                    ? '10'
                    : '10 - Elite Subscription Tier Required',
                value: 10,
                disabled: props.subscriptionTier < 2 ? true : false,
              },
              {
                label:
                  props.subscriptionTier >= 3
                    ? '20'
                    : '20 - Master Subscription Tier Required',
                value: 20,
                disabled: props.subscriptionTier < 3 ? true : false,
              },
            ]}
            onChange={obj => {
              props.setBatchNum(obj['value']);
            }}
          />
          {/* <h1
            className="questionMark"
            style={{
              position: 'relative',
              textAlign: 'center',
              fontSize: '120px',
              marginTop: '150px',
            }}
          >
            ?
          </h1> */}
        </>
      ) : (
        <>
          <h4 className="inputTitle">{props.generatedBy}:</h4>
          <Select
            key={`selector_generatedBy_${props.generatedBy}`}
            id={props.generatedBy}
            className="selector"
            options={options}
            onChange={obj => {
              props.setSelected(obj['value']);
              props.propsSetSelected(obj['value']);
            }}
            isOptionDisabled={option => option.disabled}
          />
          <h4 className="inputTitle">Number Of Generations:</h4>

          <Select
            className="selector"
            defaultValue={{
              label: 1,
              value: 1,
            }}
            isOptionDisabled={option => option.disabled}
            options={[
              {
                label: 1,
                value: 1,
              },
              {
                label:
                  props.subscriptionTier >= 1
                    ? '5'
                    : '5 - Novice Subscription Tier Required',
                value: 5,
                disabled: props.subscriptionTier < 1 ? true : false,
              },
              {
                label:
                  props.subscriptionTier >= 2
                    ? '10'
                    : '10 - Elite Subscription Tier Required',
                value: 10,
                disabled: props.subscriptionTier < 2 ? true : false,
              },
              {
                label:
                  props.subscriptionTier >= 3
                    ? '20'
                    : '20 - Master Subscription Tier Required',
                value: 20,
                disabled: props.subscriptionTier < 3 ? true : false,
              },
            ]}
            onChange={obj => {
              props.setBatchNum(obj['value']);
            }}
          />
          <br />
        </>
      )}
    </div>
  );
}

function Input(props) {
  const [overlay, setOverlay] = useState(false);
  const [tabSelection, setTabSelection] = useState('Basic');

  // const { nokemonData } = useContext(NokemonContext);

  // useEffect(() => {
  //   if (nokemonData) {
  //     setTabSelection('Advanced');
  //     props.setIsPremium(true);
  //   }
  // }, [nokemonData]);

  return (
    <div>
      <Tabs
        style={{
          height: '50px',
          width: '350px',
          fontSize: '15px',
          marginLeft: '10px',
          borderRadius: '0px',
        }}
        defaultIndex={props.nokemonData ? 1 : 0}
        onSelect={(index, lastIndex) => {
          // Reset batchNum on tab click
          props.setBatchNum(1);
          props.setFileName(undefined);

          if (index == 0) {
            setTabSelection('Basic');
            props.setIsPremium(false);
            props.setResetSeed(true);
          } else if (index == 1) {
            setTabSelection('Advanced');
            props.setIsPremium(true);
            props.setModelType('sd_v1');
          }
        }}
      >
        <TabList
          style={{
            height: '50px',
            padding: '0px',
            borderRadius: '0px',
          }}
        >
          <Tab
            data-cy="basicGenerationTab"
            style={{
              height: '50px',
              padding: '0px',
              borderRadius: '0px',
            }}
          >
            Basic
          </Tab>
          <Tab
            data-cy="advancedGenerationTab"
            style={{
              height: '50px',
              padding: '0px',
              borderRadius: '0px',
            }}
          >
            Advanced
            {/* <span
              style={{
                position: 'absolute',
                left: '80%',
                top: '0%',
                color: 'red',
                textShadow: '0px 0px 10px red, 0px 0px 10px red',
                fontWeight: '400',
              }}
            >
              new
            </span> */}
          </Tab>
        </TabList>
      </Tabs>
      <div
        style={{
          position: 'relative',
        }}
        className="box"
        id={tabSelection == 'Basic' ? 'inputBox' : 'inputBoxAdvanced'}
      >
        {overlay ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 20,
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
            }}
          >
            <h1
              className="animate-flicker"
              style={{
                position: 'relative',
                color: 'rgb(229, 231, 235)',
                padding: 'auto',
              }}
            >
              Fakemon Below! &nbsp;
              <FontAwesomeIcon icon={faAnglesDown} />
            </h1>
          </div>
        ) : null}

        {tabSelection == 'Basic' ? (
          <BasicInput
            isLoggedIn={props.isLoggedIn}
            userInfo={props.userInfo}
            setUserInfo={props.setUserInfo}
            sessionId={props.sessionId}
            setIsPremium={props.setIsPremium}
            modelType={props.modelType}
            setModelType={props.setModelType}
            setSelected={props.setSelected}
            setGeneratedBy={props.setGeneratedBy}
            setRandom={props.setRandom}
            setBatchNum={props.setBatchNum}
            setStyle={props.setStyle}
            random={props.random}
            overlay={overlay}
            setOverlay={setOverlay}
            setModalVisible={props.setModalVisible}
            setModalType={props.setModalType}
            generationButtonClicked={props.generationButtonClicked}
            setGenerationButtonClicked={props.setGenerationButtonClicked}
            fileName={props.fileName}
            batchNum={props.batchNum}
          />
        ) : (
          <AdvancedInput
            userInfo={props.userInfo}
            modelType={props.modelType}
            setOptionalText={props.setOptionalText}
            setObjectText={props.setObjectText}
            setModelType={props.setModelType}
            setUserInfo={props.setUserInfo}
            sessionId={props.sessionId}
            setIsPremium={props.setIsPremium}
            setSelected={props.setSelected}
            setGeneratedBy={props.setGeneratedBy}
            setRandom={props.setRandom}
            type1={props.type1}
            setType1={props.setType1}
            type2={props.type2}
            setType2={props.setType2}
            setLegendary={props.setLegendary}
            setPool={props.setPool}
            setBody={props.setBody}
            setColor={props.setColor}
            setStyle={props.setStyle}
            setGlobalBatchNum={props.setBatchNum}
            overlay={overlay}
            setFileName={props.setFileName}
            setOverlay={setOverlay}
            isLoggedIn={props.isLoggedIn}
            setModalSettings={props.setModalSettings}
            setGenerationButtonClicked={props.setGenerationButtonClicked}
            seed={props.seed}
            setSeed={props.setSeed}
            resetSeed={props.resetSeed}
            setResetSeed={props.setResetSeed}
          />
        )}
      </div>
    </div>
  );
}

// function AdvancedNewModelOptions(props) {
//   const [generatedBy, setGeneratedBy] = useState('type');
//   const [type1, setType1] = useState(undefined);
//   const [type2, setType2] = useState('None');

//   return (
//     <>
//       <div
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//         }}
//       >
//         <h4 className="inputTitle">Generate By:</h4>
//         <RadioGroup
//           className="radioGroup"
//           value={generatedBy}
//           onChange={value => {
//             // Reset batchNum on radio button click
//             props.setBatchNum(1);
//             props.setGlobalBatchNum(1);
//             props.setFileName(undefined);

//             setGeneratedBy(value);
//           }}
//           horizontal
//         >
//           <RadioButton
//             value="type"
//             data-cy="typeRadioButtonAdvanced"
//             pointColor="#191a1a"
//             rootColor="#191a1a"
//           >
//             Type
//           </RadioButton>

//           <RadioButton
//             value="batch"
//             data-cy="batchRadioButtonAdvanced"
//             pointColor="#191a1a"
//             rootColor="#191a1a"
//           >
//             Batch
//             <p
//               style={{
//                 position: 'absolute',
//                 margin: '0',
//                 padding: '0',
//                 marginTop: '-42px',
//                 marginLeft: '-14px',
//                 fontSize: '16px',
//                 color: 'red',
//                 textShadow: '1px 1px 10px red, 1px 1px 10px red',
//               }}
//             >
//               new
//             </p>
//           </RadioButton>
//         </RadioGroup>

//         <div>
//           <div
//             style={{
//               display: 'flex',
//               width: '100%',
//               justifyContent: 'space-between',
//               flexDirection: 'column',
//             }}
//           >
//             <h4 className="inputTitle">Number Of Generations:</h4>

//             <Select
//               className="selector"
//               defaultValue={{
//                 label: 1,
//                 value: 1,
//               }}
//               isOptionDisabled={option => option.disabled}
//               options={[
//                 {
//                   label: 1,
//                   value: 1,
//                 },
//                 {
//                   label:
//                     props.subscriptionTier >= 1
//                       ? '5'
//                       : '5 - Novice Subscription Tier Required',
//                   value: 5,
//                   disabled: props.subscriptionTier < 1 ? true : false,
//                 },
//                 {
//                   label:
//                     props.subscriptionTier >= 2
//                       ? '10'
//                       : '10 - Elite Subscription Tier Required',
//                   value: 10,
//                   disabled: props.subscriptionTier < 2 ? true : false,
//                 },
//                 {
//                   label:
//                     props.subscriptionTier >= 3
//                       ? '20'
//                       : '20 - Master Subscription Tier Required',
//                   value: 20,
//                   disabled: props.subscriptionTier < 3 ? true : false,
//                 },
//               ]}
//               onChange={obj => {
//                 props.setBatchNum(obj['value']);
//                 props.setGlobalBatchNum(obj['value']);
//               }}
//             />
//           </div>
//         </div>

//         <div>
//           <div
//             style={{
//               display: 'flex',
//               width: '100%',
//               justifyContent: 'space-between',
//             }}
//           >
//             <div
//               style={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 width: '48%',
//               }}
//             >
//               <h4 className="inputTitle">Type 1:</h4>

//               <Select
//                 className="selector"
//                 options={types.filter(type => type.value != type2)}
//                 onChange={obj => {
//                   props.setSelected(obj['value']);
//                   props.setType1(obj['value']);
//                   props.setLegendary('false');
//                 }}
//                 isOptionDisabled={option => option.disabled}
//               />
//             </div>
//             <div
//               style={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 width: '48%',
//               }}
//             >
//               <h4 className="inputTitle">Type 2:</h4>

//               <Select
//                 className="selector"
//                 options={[{ value: 'None', label: 'None' }, ...types].filter(
//                   type => type.value != type1,
//                 )}
//                 defaultValue={{ value: 'None', label: 'None' }}
//                 onChange={obj => {
//                   props.setSelected(obj['value']);
//                   props.setType2(obj['value']);
//                 }}
//                 isOptionDisabled={option => option.disabled}
//               />
//             </div>
//           </div>

//           <div
//             style={{
//               display: 'flex',
//               width: '100%',
//               justifyContent: 'space-between',
//             }}
//           >
//             <div
//               style={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 width: '100%',
//               }}
//             >
//               <div
//                 style={{
//                   display: 'flex',
//                   alignItems: 'center',
//                 }}
//               >
//                 <h4
//                   className="inputTitle"
//                   style={{
//                     display: 'flex',
//                   }}
//                 >
//                   Keywords
//                 </h4>
//                 <InfoIcon
//                   data-tip={
//                     'Enter up to 6 keywords to influence image generation. For example, "cute" enhances cuteness, while "dog" introduces elements of a dog.'
//                   }
//                   className="material-icons"
//                 >
//                   info_outline
//                 </InfoIcon>
//                 <ReactTooltip
//                   place="right"
//                   type="dark"
//                   effect="solid"
//                   style={{
//                     fontFamily: 'Open Sans !important',
//                     textTransform: 'none !important',
//                   }}
//                 />
//                 :
//               </div>

//               <KeywordInputBar
//                 onKeywordsChange={keywords => {
//                   // keywords is an array of keywords, concatenate them into text.
//                   props.setOptionalText(keywords.join(', '));
//                 }}
//               />
//             </div>

//             {/* <div
//                 style={{
//                   width: '48%',
//                 }}
//               >
//                 <h4 className="inputTitle">Legendary:</h4>
//                 <Select
//                   className="selector"
//                   options={[
//                     { value: 'True', label: 'True' },
//                     { value: 'False', label: 'False' },
//                   ]}
//                   onChange={obj => {
//                     setSelected(obj['value']);
//                     setLegendary(obj['value']);
//                   }}
//                   isOptionDisabled={option => option.disabled}
//                 />
//               </div> */}
//           </div>
//           <div
//             style={{
//               display: 'flex',
//               width: '100%',
//               justifyContent: 'space-between',
//               flexDirection: 'column',
//             }}
//           >
//             <h4
//               className="inputTitle"
//               style={{
//                 display: 'flex',
//               }}
//             >
//               Modify Previous Generation?
//             </h4>
//             <RadioContainer>
//               <RadioButtons
//                 type="radio"
//                 id="modifyYes"
//                 name="modify"
//                 value="yes"
//                 disabled={props.seed === undefined}
//                 onChange={() => props.setResetSeed(false)}
//                 checked={props.resetSeed === false}
//               />
//               <Label htmlFor="modifyYes">Yes</Label>
//               <RadioButtons
//                 type="radio"
//                 id="modifyNo"
//                 name="modify"
//                 value="no"
//                 disabled={props.seed === undefined}
//                 checked={props.resetSeed === true}
//                 onChange={() => props.setResetSeed(true)}
//               />
//               <Label htmlFor="modifyNo">No</Label>
//             </RadioContainer>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

function SugimoriCount(props) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  useEffect(() => {}, [props.currencyValueFree]);

  // useEffect(() => {
  //   fetch('/api/user/currencyValueFree/get', {
  //     headers: {
  //       Authorization: localStorage.getItem('jwt'),
  //     },
  //   }).then(async res => {
  //     if (res.status != 200) {
  //       setGenerationsLeft(props.user);
  //       return;
  //     }

  //     const data = await res.json();
  //     const currencyValueFree = data.currencyValueFree;

  //     setGenerationsLeft(currencyValueFree);

  //     if (props.generationButtonClicked == true && currencyValueFree <= 0) {
  //       props.setModalType('sugimoriModal');
  //       props.setModalVisible(true);
  //     }
  //   });
  // }, [props.random]);

  return (
    <>
      <div
        style={{
          position: 'absolute',
          width: '90px',
          height: '20px',
          left: 0,
          bottom: 0,
          right: '20px',
          top: 0,
          marginLeft: 'auto',
        }}
      >
        <div
          style={{
            fontSize: '17px',
            fontFamily: 'Archivo',
            fontWeight: '500',
            backgroundColor: 'rgb(245, 158, 11)',
            textAlign: 'center',
            color: props.color || 'white',
            padding: '5px',
            borderRadius: '0.25rem',
            marginTop: '20px',
          }}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          ref={target}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={Icons.faQuestionCircle}
              style={{
                color: 'white',
              }}
            />
            <h4
              data-cy="currencyFreeValueLeft"
              style={{
                margin: 0,
              }}
            >
              &nbsp;{' '}
              {props.currencyValueFree != undefined
                ? props.currencyValueFree
                : '...'}
            </h4>
          </div>
        </div>
      </div>

      <Overlay target={target.current} show={show} placement="left">
        {props => (
          <Tooltip {...props}>
            <div
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                padding: '10px',
                borderRadius: '0.25rem',
                marginRight: '10px',
              }}
            >
              {' '}
              Number of Daily Basic Generation Left
            </div>
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}

// Component for modelType 'sdv1'
function BasicOldModelOptions(props) {
  // Your code for modelType 'sdv1' goes here
  return (
    <>
      <h4 className="inputTitle">Generate By:</h4>
      <RadioGroup
        className="radioGroup"
        value={props.generatedBy}
        onChange={value => {
          props.setGeneratedBy(value);
          props.setSelected(undefined);
          props.propsSetGeneratedBy(value);
          props.propsSetSelected(undefined);
          props.setBatchNum(1);
          props.setStyle('3d model');
        }}
        horizontal
      >
        <RadioButton
          value="type"
          data-cy="typeRadioButtonBasic"
          pointColor="#191a1a"
          rootColor="#191a1a"
        >
          Type
        </RadioButton>

        {props.style == '3D model' ? (
          <RadioButton
            value="color"
            data-cy="colorRadioButtonBasic"
            pointColor="#191a1a"
            rootColor="#191a1a"
            style={{
              position: 'relative',
              width: '100%',
            }}
          >
            Color&nbsp;&nbsp;&nbsp;
          </RadioButton>
        ) : (
          <div />
        )}
        <RadioButton
          value="random"
          data-cy="randomRadioButtonBasic"
          pointColor="#191a1a"
          rootColor="#191a1a"
        >
          Random
        </RadioButton>
      </RadioGroup>
      <OldModelSelectionPanel
        generatedBy={props.generatedBy}
        setSelected={props.setSelected}
        propsSetSelected={props.propsSetSelected}
        setBatchNum={props.setBatchNum}
        setStyle={props.setStyle}
        subscriptionTier={props.userInfo['subscriptionTier']}
      />
      {/* <InputImage
        className="inputImage"
        generatedBy={props.generatedBy}
        selected={props.selected}
      /> */}
    </>
  );
}

// Component for another modelType
function BasicNewModelOptions(props) {
  // Your code for another modelType goes here
  // Your code for modelType 'sdv1' goes here
  return (
    <>
      <h4 className="inputTitle">Generate By:</h4>
      <RadioGroup
        className="radioGroup"
        value={props.generatedBy}
        onChange={value => {
          props.setGeneratedBy(value);
          props.propsSetGeneratedBy(value);
          props.setSelected(undefined);
          props.propsSetSelected(undefined);
          props.setBatchNum(1);
        }}
        horizontal
      >
        <RadioButton
          value="random"
          data-cy="randomRadioButtonBasic"
          pointColor="#191a1a"
          rootColor="#191a1a"
        >
          Random
        </RadioButton>
        <RadioButton
          value="type"
          data-cy="typeRadioButtonBasic"
          pointColor="#191a1a"
          rootColor="#191a1a"
          disabled={props.userInfo['isSubscribed'] ? false : true}
        >
          {props.userInfo['isSubscribed']
            ? 'Type'
            : 'Type (subscription required)'}
        </RadioButton>
      </RadioGroup>
      <NewModelSelectionPanel
        generatedBy={props.generatedBy}
        setSelected={props.setSelected}
        propsSetSelected={props.propsSetSelected}
        setBatchNum={props.setBatchNum}
        setStyle={props.setStyle}
        subscriptionTier={props.userInfo['subscriptionTier']}
      />
      {/* <InputImage
        className="inputImage"
        generatedBy={props.generatedBy}
        selected={props.selected}
      /> */}
    </>
  );
}
function BasicInput(props) {
  const [selected, setSelected] = useState(undefined);
  const [style, setStyle] = useState('3D model');
  const [modelType, setModelType] = useState('sd_v1');
  const [generatedBy, setGeneratedBy] = useState(
    modelType === 'sd_v1' ? 'random' : 'type',
  );

  useEffect(() => {}, [modelType]);

  useEffect(() => {}, [props.userInfo]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: 0,
        }}
      >
        {props.isLoggedIn ? (
          <div
            style={{
              top: '10px',
              position: 'absolute',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              left: '0',
            }}
          >
            <DailyGenerationsButton
              value={props.userInfo['currencyValueFree']}
              defaultShow={true}
            />
          </div>
        ) : (
          // <SugimoriCount
          //   currencyValueFree={currencyValueFree}
          //   isLoggedIn={props.isLoggedIn}
          //   style={style}
          //   setModalVisible={props.setModalVisible}
          //   setModalType={props.setModalType}
          //   generationButtonClicked={props.generationButtonClicked}
          //   random={props.random}
          //   userInfo={props.userInfo}
          //   fileName={props.fileName}
          // />
          <div />
        )}

        {!props.isLoggedIn && props.modelType == 'sd_v1' ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <span class="circle-question-mark">!</span>
            Sign in to access the full pool of Fakémon
          </div>
        ) : (
          <></>
        )}
        <h4 className="inputTitle">Model Type:</h4>
        <Select
          className="selector"
          options={[
            {
              value: 'sd_v1',
              label: 'Model v2.0 (latest)',
            },
            { value: 'dalle', label: 'Model v1.0' },
          ]}
          onChange={obj => {
            setModelType(obj['value']);
            props.setModelType(obj['value']);
            setSelected(undefined);
            setGeneratedBy('random');
          }}
          isOptionDisabled={option => option.disabled}
          // defaultValue={{ label: 'Sugimori (new)', value: 'sugimori' }}
          defaultValue={{ label: 'Model v2.0 (latest)', value: 'sd_v1' }}
        />

        {modelType === 'sd_v1' && (
          <BasicNewModelOptions
            selected={selected}
            generatedBy={generatedBy}
            setGeneratedBy={setGeneratedBy}
            propsSetGeneratedBy={props.setGeneratedBy}
            propsSetSelected={props.setSelected}
            setSelected={setSelected}
            setBatchNum={props.setBatchNum}
            userInfo={props.userInfo}
          />
        )}
        {modelType === 'dalle' && (
          <BasicOldModelOptions
            selected={selected}
            generatedBy={generatedBy}
            setGeneratedBy={setGeneratedBy}
            setSelected={setSelected}
            propsSetGeneratedBy={props.setGeneratedBy}
            propsSetSelected={props.setSelected}
            setStyle={props.setStyle}
            setBatchNum={props.setBatchNum}
            style={style}
            userInfo={props.userInfo}
          />
        )}
      </div>

      <button
        type="button"
        id="submitButton"
        className="inputButton"
        data-cy="basicGenerationButton"
        onClick={async () => {
          props.setGenerationButtonClicked(true);
          props.setIsPremium(false);

          if (selected) {
            props.setGeneratedBy(generatedBy);
            props.setSelected(selected);
            props.setRandom(Math.random());

            document.getElementById('submitButton').disabled = true;

            if (props.isLoggedIn) {
              const resNumFreeNokemonGenerated = await fetch(
                '/api/user/numFreeNokemonGenerated/get',
                {
                  headers: {
                    Authorization: localStorage.getItem('jwt'),
                  },
                },
              );

              const resHasPurchased = await fetch(
                '/api/user/hasPurchased/get',
                {
                  headers: {
                    Authorization: localStorage.getItem('jwt'),
                  },
                },
              );

              if (
                resNumFreeNokemonGenerated.status == 200 &&
                resHasPurchased.status == 200
              ) {
                const numFreeNokemonGeneratedData =
                  await resNumFreeNokemonGenerated.json();

                const numFreeNokemonGenerated =
                  numFreeNokemonGeneratedData['numFreeNokemonGenerated'];

                const hasPurchasedData = await resHasPurchased.json();
                const hasPurchased = hasPurchasedData['hasPurchased'];

                // Heavy user of website > 100 free generations
                if (numFreeNokemonGenerated >= 100 && !hasPurchased) {
                  if (Math.random() < 0.1) {
                    // If new session to last, pop up
                    if (props.sessionId != undefined) {
                      const res = await fetch(
                        `/api/user/checkDiscountPopupSession?sessionId=${props.sessionId}`,
                        {
                          headers: {
                            Authorization: localStorage.getItem('jwt'),
                          },
                        },
                      );

                      if (res.status == 200) {
                        const data = await res.json();

                        if (data['displayPopup']) {
                          props.setModalVisible(true);
                          props.setModalType('discountModal');

                          fetch(`/api/user/discountModalCount/update`, {
                            headers: {
                              Authorization: localStorage.getItem('jwt'),
                            },
                          });
                        }
                      }
                    }
                  }
                }
              }
            }

            props.setOverlay(true);
            sleep(3500).then(() => {
              props.setOverlay(false);
            });
          } else {
            if (generatedBy == 'random') {
              props.setGeneratedBy(generatedBy);
              props.setSelected(generatedBy);
              props.setRandom(Math.random());
              document.getElementById('submitButton').disabled = true;

              props.setOverlay(true);
              sleep(3500).then(() => {
                props.setOverlay(false);
              });
            } else {
              toast(
                `Please select a ${
                  generatedBy.charAt(0).toUpperCase() +
                  generatedBy.slice(1).toLowerCase()
                }`,
              );
            }
          }
        }}
      >
        Generate
      </button>
    </div>
  );
}

export default Input;
