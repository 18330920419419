import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import NokemonImageGuidedGIF from '../Nokemon SD Ad v2.gif';
import BasicGenerationDemoGIF from '../BasicGenerationDemo.gif';
import NokemonBatchImage from '../nokemon-batch-ad-v3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/fontawesome-free-solid';

// Styled-components allows us to create styled divs for layout
const BlogContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
  color: #333;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const OrderedList = styled.ol`
  margin-left: 1.5em;
  margin-bottom: 1em;
`;

const UnorderedList = styled.ul`
  margin-left: 1.5em;
  margin-bottom: 1em;
`;

const ListItem = styled.li`
  font-size: 1em;
  color: #666;
  margin-bottom: 0.5em;
`;

const Title = styled.h1`
  font-size: 2.5em;
  color: #333;
  margin-bottom: 0.5em;
`;

const Subtitle = styled.h2`
  font-size: 1.5em;
  color: #666;
  margin-bottom: 1em;
`;

const SubSubTitle = styled.h4`
  font-size: 1em;
  color: #666;
  margin-bottom: 0.8em;
`;

const Section = styled.div`
  margin-bottom: 2em;

  h3 {
    font-size: 1.3em;
    color: #444;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 1em;
    color: #666;
  }
`;

const ImageContainer = styled.div`
  text-align: center;
  margin-top: 20px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const ReducedImageContainer = styled.div`
  text-align: center;
  margin-top: 20px;

  img {
    max-width: 80%;
    height: auto;
  }
`;

const IncludesListContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const IncludesListElementContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 1em;
  color: #666;
`;

const IconContainer = styled.div`
  color: ${props => (props.unavailability ? 'red' : 'darkblue')};
  font-size: 18px;
  width: 30px;
`;

const IncludesListTextContainer = styled.div`
  margin-top: 0;
  margin-bottom: 0;
`;

const IncludesListTitle = styled.h4`
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
`;

const IncludesListDescription = styled.h5`
  margin-top: -20px;
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
`;

const TableOfContentsContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.8;
  color: #333;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const TableOfContentsTitle = styled.h3`
  font-size: 1.5em;
  color: #444;
  margin-bottom: 1em;
  text-transform: uppercase;
`;

const TableOfContentsList = styled.ol`
  list-style-type: none;
  counter-reset: section;
  padding: 0;
  margin-left: 0;
`;

const TableOfContentsItem = styled.li`
  counter-increment: section;
  margin-bottom: 1em;
  padding-left: 1em;
  text-indent: -1em;

  a {
    color: #666;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #333;
    }
  }

  &::before {
    content: counter(section) '. ';
    font-weight: bold;
    color: #666;
  }
`;

const TableOfContentsSubList = styled.ol`
  list-style-type: none;
  counter-reset: subsection;
  padding: 0;
  margin-left: 0;
`;

const TableOfContentsSubItem = styled.li`
  margin-bottom: 0.5em;
  padding-left: 2em;
  text-indent: 0.2em;

  a {
    color: #666;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #333;
    }
  }

  &::before {
    font-weight: bold;
    color: #666;
  }
`;
// Styled Component
// Keyframes for the gradient animation
const GradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Styled Components
const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1a202c;
  color: white;
  height: 200px;
  width: 100%;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
`;

const BannerText = styled.h1`
  font-size: 2.5em;
  text-align: center;
  z-index: 2;
  font-family: 'Arial', sans-serif;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const BannerOverlay = styled.div`
  position: absolute;
  background: linear-gradient(45deg, #6c63ff, #3a7bd5, #ff5f6d);
  background-size: 200% 200%;
  animation: ${GradientAnimation} 5s linear infinite;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: 1;
  transform: rotate(30deg);
  opacity: 0.5;
`;

// React Component
export function BannerComponent(props) {
  return (
    <BannerContainer>
      <BannerOverlay />
      <BannerText>{props.text}</BannerText>
    </BannerContainer>
  );
}
const includesListOptions = [
  {
    title: 'Starting up the computer that hosts the AI.',
    description:
      'When a Fakemon is generated for the first time, the computer that contains the AI needs to turn on. Therefore, you may find that it takes longer than usual for your first generation to happen. After the first generation, each subsequent generation should only take ~10 seconds!',
  },
  {
    title: 'Waiting in a queue.',
    description:
      'The AI needs to run on a specific computer to work. Unfortunately, it can only handle one request at a time. Therefore, if multiple people are using the service at once, you may find yourself having to wait in a queue. The estimated time for generation will be displayed at all times, so you will know how long you will approximately have to wait until your Fakemon is generated.',
  },
  {
    title: 'Generate a Fakemon!',
    description:
      "Once you have made it to the front of the queue, it is now your time to Generate! The Fakemon will automatically be generated and within ~10 seconds you will have your result! If you navigate away from the page at any point, not to worry! Once generation has completed, the Fakemon will automatically be saved in the 'Premium' tab in your storage.",
  },
  {
    title: 'Server unavailability',
    description:
      "Due to the type of computer that the AI needs to run on, these services are sometimes unavailable, and therefore you won't be able to generate a Fakemon. In the Advanced Tab, the resource availability will be displayed, notifying whether a computer is available to perform generation or not. This is a problem due to Cloud GPU demands, and hopefully, in the future, this problem will be solved.",
  },
];

function IncludesListElement(props) {
  const { title, description, unavailability } = props;

  return (
    <IncludesListElementContainer>
      <IconContainer unavailability={unavailability}>
        <FontAwesomeIcon
          icon={unavailability ? Icons.faExclamation : Icons.faRobot}
        />
      </IconContainer>
      <IncludesListTextContainer>
        <IncludesListTitle>{title}</IncludesListTitle>
        <IncludesListDescription>{description}</IncludesListDescription>
      </IncludesListTextContainer>
    </IncludesListElementContainer>
  );
}

function IncludesList(props) {
  const { options } = props;

  return (
    <IncludesListContainer>
      {options.map((option, index) => (
        <IncludesListElement
          key={option.title}
          title={`${index + 1}. ${option.title}`}
          description={option.description}
          unavailability={option.unavailability}
        />
      ))}
    </IncludesListContainer>
  );
}

const FAQPage = () => {
  const tableOfContentsRef = useRef(null);

  const handleTableOfContentsClick = (event, id) => {
    event.preventDefault();
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div style={{ backgroundColor: '#f5f9ff' }}>
      <BannerComponent text="Master Your Fakemon Journey: Information Guide!" />
      <TableOfContentsContainer>
        <TableOfContentsTitle>Table of Contents</TableOfContentsTitle>
        <TableOfContentsList ref={tableOfContentsRef}>
          <TableOfContentsItem>
            <a
              href="#section-introducing-text-guided-generation"
              onClick={e =>
                handleTableOfContentsClick(
                  e,
                  'section-introducing-text-guided-generation',
                )
              }
            >
              Introducing Text-Guided Generation
            </a>
          </TableOfContentsItem>
          <TableOfContentsSubItem>
            <a
              href="#section-how-to-use-text-guided-generation"
              onClick={e =>
                handleTableOfContentsClick(
                  e,
                  'section-how-to-use-text-guided-generation',
                )
              }
            >
              How to Use Text-Guided Generation?
            </a>
          </TableOfContentsSubItem>
          <TableOfContentsItem>
            <a
              href="#section-basic-generation"
              onClick={e =>
                handleTableOfContentsClick(e, 'section-basic-generation')
              }
            >
              Basic Generation
            </a>
          </TableOfContentsItem>
          <TableOfContentsSubItem>
            <a
              href="#section-basic-what-is-basic-generation"
              onClick={e =>
                handleTableOfContentsClick(
                  e,
                  'section-basic-what-is-basic-generation',
                )
              }
            >
              What is Basic Generation?
            </a>
          </TableOfContentsSubItem>
          <TableOfContentsSubItem>
            <a
              href="#section-basic-how-does-basic-generation-work"
              onClick={e =>
                handleTableOfContentsClick(
                  e,
                  'section-basic-how-does-basic-generation-work',
                )
              }
            >
              How Does Basic Generation Work?
            </a>
          </TableOfContentsSubItem>
          <TableOfContentsItem>
            <a
              href="#section-advanced-generation"
              onClick={e =>
                handleTableOfContentsClick(e, 'section-advanced-generation')
              }
            >
              Advanced Generation
            </a>
          </TableOfContentsItem>
          <TableOfContentsSubItem>
            <a
              href="#section-advanced-generation-what-is-advanced-generation"
              onClick={e =>
                handleTableOfContentsClick(
                  e,
                  'section-advanced-generation-what-is-advanced-generation',
                )
              }
            >
              What Is Advanced Generation?
            </a>
          </TableOfContentsSubItem>
          <TableOfContentsSubItem>
            <a
              href="#section-advanced-generation-what-are-nokepoints"
              onClick={e =>
                handleTableOfContentsClick(
                  e,
                  'section-advanced-generation-what-are-nokepoints',
                )
              }
            >
              What Are Credits?
            </a>
          </TableOfContentsSubItem>

          <TableOfContentsSubItem>
            <a
              href="#section-what-is-batch-generation"
              onClick={e =>
                handleTableOfContentsClick(
                  e,
                  'section-what-is-batch-generation',
                )
              }
            >
              What Is Batch Generation?
            </a>
          </TableOfContentsSubItem>
          <TableOfContentsSubItem>
            <a
              href="#section-generation-process"
              onClick={e =>
                handleTableOfContentsClick(e, 'section-generation-process')
              }
            >
              What Does The Generation Process Look Like?
            </a>
          </TableOfContentsSubItem>
          <TableOfContentsItem>
            <a
              href="#section-subscription-tiers"
              onClick={e =>
                handleTableOfContentsClick(e, 'section-subscription-tiers')
              }
            >
              Subscription Tiers
            </a>
          </TableOfContentsItem>
        </TableOfContentsList>
      </TableOfContentsContainer>
      {/* <BlogContainer id="master-your-fakemon-journey">
        <Title>Master Your Fakemon Journey: A Comprehensive Guide</Title>
        <Subtitle>Unleash Your Creativity and Craft Unique Fakemon</Subtitle>

        <Section id="section-introduction">
          <Subtitle>Welcome to the Master Your Fakemon Journey Guide</Subtitle>
          <p>
            Embark on an exciting journey of Fakemon creation. This guide will
            walk you through the process, from understanding the basics to
            mastering the advanced features of our platform. Ready to dive in?
            Let's get started!
          </p>
        </Section>
      </BlogContainer>

      <BlogContainer id="basic-generation">
        <Title>Basic Generation: Your Gateway to Fakemon Creation</Title>

        <Section id="section-what-is-basic-generation">
          <Subtitle>What is Basic Generation?</Subtitle>
          <p>
            Basic Generation is a simple and fast way to bring a Fakemon to
            life. With just a few clicks, you can generate a Fakemon from our
            extensive cache of pre-generated images. This feature is perfect for
            beginners or those who want to explore a wide variety of Fakemon
            designs without any specific preferences.
          </p>
        </Section>

        <Section id="section-what-can-be-done-with-basic-generation">
          <Subtitle>What Can Be Done with Basic Generation</Subtitle>
          <p>
            Generate Random Fakemon: With Basic Generation, you can generate
            random Fakemon with just a few clicks. This feature is perfect for
            those who want to explore a wide variety of Fakemon designs without
            any specific preferences.
          </p>
          <p>
            Generate by Type (Subscription Only): With a subscription, you can
            generate Fakemon by type. This feature allows you to create Fakemon
            that align with your preferred type, such as fire, water, grass, and
            more.
          </p>
          <p>
            Generate in Batch (Subscription Only): With a subscription, you can
            generate multiple Fakemon at once. This feature is perfect for those
            who want to quickly create a variety of unique Fakemon for their
            team.
          </p>
        </Section>

        <Section id="section-how-to-use-basic-generation">
          <Subtitle>How to Use Basic Generation</Subtitle>
          <p>
            Using Basic Generation is as easy as 1-2-3: Open the Fakemon
            Generator, navigate to the Basic tab, choose the Fakemon's style and
            type, and click the "Generate" button. Voila! Your Fakemon is ready.
          </p>
        </Section>
      </BlogContainer> */}
      {/* <BlogContainer id="master-your-fakemon-journey">
        <Title>Master Your Fakemon Journey: A Comprehensive Guide</Title>
        <Subtitle>Unleash Your Creativity and Craft Unique Fakemon</Subtitle>

        <Section id="section-introduction">
          <Subtitle>Welcome to the Master Your Fakemon Journey Guide</Subtitle>
          <p>
            Embark on an exciting journey of Fakemon creation. This guide will
            walk you through the process, from understanding the basics to
            mastering the advanced features of our platform. Ready to dive in?
            Let's get started!
          </p>
        </Section>
      </BlogContainer>

      <BlogContainer id="basic-generation">
        <Title>Basic Generation: Your Gateway to Fakemon Creation</Title>

        <Section id="section-what-is-basic-generation">
          <Subtitle>What is Basic Generation?</Subtitle>
          <p>
            Basic Generation is a simple and fast way to bring a Fakemon to
            life. With just a few clicks, you can generate a Fakemon from our
            extensive cache of pre-generated images. This feature is perfect for
            beginners or those who want to explore a wide variety of Fakemon
            designs without any specific preferences.
          </p>
        </Section>

        <Section id="section-benefits-of-subscription">
          <Subtitle>The Benefits of Subscription</Subtitle>
          <p>
            But that's just the beginning. With a subscription, you can unlock
            even more potential. Subscribers gain access to exclusive features
            and perks, such as increased daily generation limits, batch
            generation capabilities, and expanded storage capacity. Plus, higher
            tiers come with Credits, which unlock advanced generation options
            and further customization possibilities.
          </p>
        </Section>

        <Section id="section-how-to-use-basic-generation">
          <Subtitle>How to Use Basic Generation</Subtitle>
          <p>
            Using Basic Generation is as easy as 1-2-3: Open the Fakemon
            Generator, navigate to the Basic tab, choose the Fakemon's style and
            type, and click the "Generate" button. Voila! Your Fakemon is ready.
          </p>
        </Section>
      </BlogContainer>

      <BlogContainer id="advanced-generation">
        <Title>Advanced Generation: Elevate Your Fakemon Creation</Title>

        <Section id="section-what-is-advanced-generation">
          <Subtitle>What is Advanced Generation?</Subtitle>
          <p>
            Ready to take your Fakemon creation to the next level? Advanced
            Generation offers more control and customization. With Credits,
            you can unlock advanced generation options and ensure your Fakemon
            are truly unique. This feature is perfect for those who have a
            specific vision for their Fakemon and want to guide the generation
            process.
          </p>
        </Section>

        <Section id="section-what-are-nokepoints">
          <Subtitle>What are Credits?</Subtitle>
          <p>
            Credits are a virtual currency that enhances your Fakemon
            creation experience. They unlock advanced generation options and
            further customization possibilities. With Credits, you can guide
            the AI to create Fakemon that align with your specific vision. Ready
            to try Advanced Generation? Get Credits{' '}
            <a href="nokepoints_link">here</a>!
          </p>
        </Section>

        <Section id="section-what-can-be-done-with-advanced-generation">
          <Subtitle>What canbe done with Advanced Generation?</Subtitle>
          <p>
            Choose Dual Types: Want a Fakemon that's both fiery and electric?
            With Advanced Generation, you can create Fakemon with two elemental
            types for more diverse abilities and characteristics. This feature
            allows you to explore the unique combinations of different types and
            create Fakemon that are truly unique.
          </p>
          <p>
            Keyword Guidance: Have a specific vision for your Fakemon? Use
            specific keywords to guide the image generation process and create
            Fakemon that align with your vision. This feature allows you to
            influence the AI's generation process and create Fakemon that truly
            reflect your imagination.
          </p>
          <p>
            Batch Generation: Need a whole team of unique Fakemon? Generate
            multiple Fakemon simultaneously, ranging from 5 to 100 per batch.
            This feature is perfect for those who want to quickly create a
            variety of unique Fakemon for their team.
          </p>
        </Section>
      </BlogContainer>

      <BlogContainer id="subscription-tiers">
        <Title>Subscription Tiers: Enhance Your Experience</Title>

        <Section id="section-what-are-subscription-tiers">
          <Subtitle>What are Subscription Tiers?</Subtitle>
          <p>
            We offer a range of subscription tiers designed to enhance your
            Fakemon creation experience. By subscribing, you gain access to
            additional features and perks, allowing you to take full advantage
            of our platform's capabilities.
          </p>
        </Section>

        <Section id="section-benefits-of-subscription-tiers">
          <Subtitle>The Benefits of Subscription Tiers</Subtitle>
          <p>
            Subscribing to a tier provides you with increased daily generation
            limits, batch generation capabilities, and storage capacity
            increases. These perks allow you to create more Fakemon every day,
            save time by generating multiple Fakemon at once, and store a larger
            number of Fakemon in your personal collection.
          </p>
          <p>
            Additionally, Credits provided with higher tiers unlock more
            advanced generation options and further customization possibilities.
            This means you can create Fakemon that are truly unique and align
            with your specific vision.
          </p>
          <p>
            By subscribing to one of the tiers, you not only unlock exclusive
            benefits but also support the continuous development and improvement
            of our platform. Your subscription helps us maintain and upgrade our
            infrastructure, expand our feature set, and ensure a seamless user
            experience. It is thanks to the support of our subscribers that we
            can continue to provide an innovative and high-quality platform for
            Fakemon creation.
          </p>
        </Section>
      </BlogContainer>
      <BlogContainer id="master-your-fakemon-journey">
        <Title>Master Your Fakemon Journey: A Comprehensive Guide</Title>
        <Subtitle>Unleash Your Creativity and Craft Unique Fakemon</Subtitle>

        <Section id="section-introduction">
          <Subtitle>Welcome to the Master Your Fakemon Journey Guide</Subtitle>
          <p>
            Embark on an exciting journey of Fakemon creation. This guide will
            walk you through the process, from understanding the basics to
            mastering the advanced features of our platform. Ready to dive in?
            Let's get started!
          </p>
        </Section>
      </BlogContainer>

      <BlogContainer id="basic-generation">
        <Title>Basic Generation: Your Gateway to Fakemon Creation</Title>

        <Section id="section-what-is-basic-generation">
          <Subtitle>What is Basic Generation?</Subtitle>
          <p>
            Basic Generation is a simple and fast way to bring a Fakemon to
            life. With just a few clicks, you can generate a Fakemon from our
            extensive cache of pre-generated images. This feature is perfect for
            beginners or those who want to explore a wide variety of Fakemon
            designs without any specific preferences.
          </p>
        </Section>

        <Section id="section-what-can-be-done-with-basic-generation">
          <Subtitle>What Can Be Done with Basic Generation</Subtitle>
          <p>
            Generate Random Fakemon: With Basic Generation, you can generate
            random Fakemon with just a few clicks. This feature is perfect for
            those who want to explore a wide variety of Fakemon designs without
            any specific preferences.
          </p>
          <p>
            Generate by Type (Subscription Only): With a subscription, you can
            generate Fakemon by type. This feature allows you to create Fakemon
            that align with your preferred type, such as fire, water, grass, and
            more.
          </p>
          <p>
            Generate in Batch (Subscription Only): With a subscription, you can
            generate multiple Fakemon at once. This feature is perfect for those
            who want to quickly create a variety of unique Fakemon for their
            team.
          </p>
        </Section>

        <Section id="section-how-to-use-basic-generation">
          <Subtitle>How to Use Basic Generation</Subtitle>
          <p>
            Using Basic Generation is as easy as 1-2-3: Open the Fakemon
            Generator, navigate to the Basic tab, choose the Fakemon's style and
            type, and click the "Generate" button. Voila! Your Fakemon is ready.
          </p>
        </Section>
      </BlogContainer>

      <BlogContainer id="advanced-generation">
        <Title>Advanced Generation: Elevate Your Fakemon Creation</Title>

        <Section id="section-what-is-advanced-generation">
          <Subtitle>What is Advanced Generation?</Subtitle>
          <p>
            Ready to take your Fakemon creation to the next level? Advanced
            Generation offers more control and customization. With Credits,
            you can unlock advanced generation options and ensure your Fakemon
            are truly unique. This feature is perfect for those who have a
            specific vision for their Fakemon and want to guide the generation
            process.
          </p>
        </Section>

        <Section id="section-what-are-nokepoints">
          <Subtitle>What are Credits?</Subtitle>
          <p>
            Credits are a virtual currency that enhances your Fakemon
            creation experience. With Credits, you can unlock advanced
            generation options and ensure your Fakemon are truly unique.
          </p>
        </Section>

        <Section id="section-what-can-be-done-with-advanced-generation">
          <Subtitle>What Can Be Done with Advanced Generation</Subtitle>
          <p>
            Dual Types: With Advanced Generation, you can create Fakemon with
            two elemental types for more diverse abilities and characteristics.
            This feature allows you to explore the unique combinations of
            different types and create Fakemon that are truly unique.
          </p>
          <p>
            Keyword Guidance: Have a specific vision for your Fakemon? Use
            specific keywords to guide the image generation process and create
            Fakemon that align with your vision. This feature allows you to
            influence the AI's generation process and create Fakemon that truly
            reflect your imagination.
          </p>
          <p>
            Batch Generation: Need a whole team of unique Fakemon? Generate
            multiple Fakemon simultaneously, ranging from 5 to 100 per batch.
            This feature is perfect for those who want to quickly create a
            variety of unique Fakemon for their team.
          </p>
        </Section>

        <Section id="section-how-to-use-advanced-generation">
          <Subtitle>How to Use Advanced Generation</Subtitle>
          <p>
            Using Advanced Generation is simple: Navigate to the Advanced tab in
            the Fakemon Generator, choose your Fakemon's types and keywords, and
            use your Credits to access advanced generation options. In just a
            few clicks, you can start creating unique Fakemon that truly reflect
            your imagination.
          </p>
        </Section>
      </BlogContainer>

      <BlogContainer id="subscription-tiers">
        <Title>Subscription Tiers: Enhance Your Experience</Title>

        <Section id="section-what-are-subscription-tiers">
          <Subtitle>What are Subscription Tiers?</Subtitle>
          <p>
            We offer a range of subscription tiers designed to enhance your
            Fakemon creation experience. By subscribing, you gain access to
            additional features and perks, allowing you to take full advantage
            of our platform's capabilities.
          </p>
        </Section>

        <Section id="section-benefits-of-subscription-tiers">
          <Subtitle>The Benefits of Subscription Tiers</Subtitle>
          <p>
            Subscribing to a tier provides you with increased daily generation
            limits, batch generation capabilities, and storage capacity
            increases. These perks allow you to create more Fakemon every day,
            save time by generating multiple Fakemon at once, and store a larger
            number of Fakemon in your personal collection.
          </p>
          <p>
            Additionally, Credits provided with higher tiers unlock more
            advanced generation options and further customization possibilities.
            This means you can create Fakemon that are truly unique and align
            with your specific vision.
          </p>
          <p>
            By subscribing to one of the tiers, you not only unlock exclusive
            benefits but also support the continuous development and improvement
            of our platform. Your subscription helps us maintain and upgrade our
            infrastructure, expand our feature set, and ensure a seamless user
            experience. It is thanks to the support of our subscribers that we
            can continue to provide an innovative and high-quality platform for
            Fakemon creation.
          </p>
        </Section>
      </BlogContainer> */}

      <BlogContainer id="section-introducing-text-guided-generation">
        <Title>Discover the Magic Text-Guided Generation</Title>
        <Subtitle>Shape Your Fakemon with the Power of Words</Subtitle>
        <ImageContainer>
          <img
            src={NokemonImageGuidedGIF}
            alt="Nokemon Guided Generation Demo"
          />
        </ImageContainer>
        <Section id="section-introducing-text-guided-generation">
          <Subtitle>Experience the Future of Fakemon Creation</Subtitle>
          <p>
            We're thrilled to introduce our revolutionary Text-Guided Generation
            feature! This is your chance to take the reins and craft Fakemon in
            ways you've never imagined. Whether you're dreaming of a fiery
            dragon-like creature or a cute, grassy critter, your words can bring
            them to life. This exciting feature is exclusive to Advanced
            Generation, offering you even more reasons to stack up on Credits.
            Start your journey towards becoming a master Fakemon creator today!
          </p>
        </Section>

        <Section id="section-how-to-use-text-guided-generation">
          <Subtitle>Unlock the Power of Text-Guided Generation</Subtitle>
          <p>
            Eager to get started? Here's your guide to manifesting your Fakemon
            dreams with Text-Guided Generation:
          </p>
          <OrderedList>
            <ListItem>Head over to the Advanced Tab in the generator.</ListItem>
            <ListItem>
              Choose the types that align with your envisioned Fakemon (Fire,
              Water, Electric, etc.).
            </ListItem>
            <ListItem>
              Insert a few keywords that capture your idea. For instance, "cute,
              dog-like, fiery" might conjure a charming Fakemon, ablaze with
              fiery charm.
            </ListItem>
            <ListItem>
              Press the "Generate" button and witness your ideas come to life!
            </ListItem>
          </OrderedList>
          <p>
            But that's not all! With Text-Guided Generation, you can fine-tune
            your creation until it perfectly matches your vision:
          </p>
          <UnorderedList>
            <ListItem>
              Wish to tweak your latest creation? Just select "Yes" under
              "Modify Previous Generation".
            </ListItem>
            <ListItem>
              Tweak the type or add/remove keywords to steer your design. It's
              an iterative process that helps you nail your Fakemon's look.
            </ListItem>
            <ListItem>
              Not happy with the result? Choose "No" under "Modify Previous
              Generation" to generate a fresh image.
            </ListItem>
            <ListItem>
              Remember, if you select "Modify Previous Generation" and hit "Yes"
              without making changes, you'll receive the same image.
            </ListItem>
          </UnorderedList>
          <p>
            Ready to elevate your Fakemon crafting skills? Leap into the world
            of Text-Guided Generation! Just remember, this exclusive feature
            requires Credits, our platform's currency used for accessing
            advanced features. Unleash your creative prowess and{' '}
            <a href="/marketplace?productType=Credits&mode=4">
              grab your Credits
            </a>{' '}
            now to start crafting your dream Fakemon! Need more information
            about Credits? Check out our{' '}
            <a href="#section-what-are-nokepoints">Credits info section</a>.
          </p>
        </Section>
      </BlogContainer>
      {/* <BlogContainer id="mastering-text-guided-generation">
        <Title>
          Mastering Text-Guided Image Generation for Custom Fakemon Design
        </Title>
        <Subtitle>
          Maximize Your Fakemon Creation with the Right Keywords
        </Subtitle>

        <Section id="section-understanding-seeds-keywords">
          <Subtitle>Understanding Seeds and Keywords</Subtitle>
          <p>
            To create an image, the AI system uses something we call a 'seed'.
            Imagine the seed as a unique instruction manual that the AI follows
            to generate a particular image. If you were to give the AI the same
            seed again later, it would follow the same instruction manual and
            create the same image.
          </p>
          <p>
            Now, combining this seed with a 'keyword' can influence the image
            the AI generates. The keyword is like an additional instruction
            you're giving to the AI to slightly change the image. So, even if
            the AI is using the same seed (instruction manual), adding a keyword
            will make it generate the same base image but with a few tweaks
            according to your keyword. This ensures that the image retains its
            overall style and representation, but is slightly influenced by your
            keyword, giving you control over its final appearance.
          </p>
          <p>
            This feature becomes a powerful tool in designing custom Fakemon. By
            iteratively adjusting keywords, you can tailor the design to your
            preference and even create evolutionary lines for your Fakemon.
          </p>
        </Section>

        <Section id="section-tailoring-fakemon-iteratively">
          <Subtitle>Tailoring a Fakemon Iteratively</Subtitle>
          <p>Creating a unique Fakemon requires an iterative process:</p>
          <OrderedList>
            <ListItem>
              Generate an Initial Image: Begin by selecting your seed and
              keywords. For example, you could use the keyword "lizard" and the
              seed "001".
            </ListItem>
            <ListItem>
              Review and Refine: Critically examine the generated image.
              Identify what aspects you like and what you want to change.
              Suppose you love the overall shape but wish it had longer claws.
              For the next iteration, you could add the keyword "long claws".
            </ListItem>
            <ListItem>
              Iterate: Continue this process, refining your keywords at each
              step to better align with your envisioned design.
            </ListItem>
          </OrderedList>
        </Section>

        <Section id="section-creating-evolution-lines">
          <Subtitle>Creating Evolution Lines for a Fakemon</Subtitle>
          <p>Creating an evolutionary line is a fun and creative exercise:</p>
          <OrderedList>
            <ListItem>
              Base Form: Start with a simple design. This can be the first
              iteration from the process above.
            </ListItem>
            <ListItem>
              First Evolution: For the first evolution, make a few noticeable
              changes that suggest growth or progression. Perhaps your "lizard"
              gains wings, turning into a "winged lizard". Use the same seed as
              the base form and add the keyword "wings".
            </ListItem>
            <ListItem>
              Final Evolution: For the final evolution, introduce significant
              changes that reflect a mature version of your Fakemon. Perhaps
              your "winged lizard" evolves into a "dragon", indicating a massive
              transformation.
            </ListItem>
          </OrderedList>
        </Section>
        <Section id="section-best-keywords">
          <Subtitle>Finding the Best Keywords for Each Type</Subtitle>
          <p>
            Choosing the right keywords is crucial in guiding the AI to create a
            perfect image. However, it's important to note how specific keywords
            might be interpreted by the AI. For instance, the word "warm" can be
            interpreted as a color, texture, or even an emotion. So, specificity
            is vital.
          </p>
          <p>
            Here are some keywords you can use to guide the AI for each type:
          </p>
          <UnorderedList>
            <ListItem>Normal: Simple, Familiar, Versatile, Neutral...</ListItem>
            <ListItem>Fire: Warm, Bright, Fierce, Explosive...</ListItem>
          </UnorderedList>
        </Section>

        <Section id="section-tips-and-tricks">
          <Subtitle>Tips and Tricks</Subtitle>
          <p>
            Here are a few tips to get the most out of the text-guided image
            generation tool:
          </p>
          <UnorderedList>
            <ListItem>
              Double Up: Using the same keyword multiple times can emphasize a
              feature.
            </ListItem>
            <ListItem>
              Keyword Combinations: Experiment with contrasting keywords for
              unique results.
            </ListItem>
            <ListItem>
              Avoid Overcrowding: Too many keywords can confuse the AI. Stick to
              a few strong, descriptive keywords per iteration.
            </ListItem>
          </UnorderedList>
          <p>
            Remember, creating the perfect Fakemon requires patience,
            creativity, and a bit of fun!
          </p>
        </Section>
      </BlogContainer> */}

      <BlogContainer id="section-basic-generation">
        <Title>Basic Generation</Title>

        <Section id="section-basic-what-is-basic-generation">
          <Subtitle>What is Basic Generation?</Subtitle>
          <p>
            Basic Generation is your gateway to the world of Fakemon creation.
            It's simple, fast, and designed for anyone to use. With just a few
            clicks, you can bring a Fakemon to life, drawn from our extensive
            cache of over 100,000 pre-generated images. And the best part? We're
            continuously adding to this cache, ensuring you always have fresh
            and exciting designs to explore.
          </p>
          <p>
            But that's just the beginning. With a subscription, you can unlock
            even more potential. Subscribers gain access to exclusive features
            and perks, such as increased daily generation limits, batch
            generation capabilities, and expanded storage capacity. Plus, higher
            tiers come with Credits, which unlock advanced generation options
            and further customization possibilities.
          </p>
          <p>
            Whether you're a Novice Trainer, an Elite Trainer, or a Master
            Trainer, there's a subscription tier tailored to your needs. Not
            only do these subscriptions enhance your Fakemon creation
            experience, but they also support the continuous development and
            improvement of the NokemonAI platform. Your subscription helps us
            maintain and upgrade our infrastructure, expand our feature set, and
            ensure a seamless user experience.
          </p>
          <p>
            So why wait? Start your Basic Generation journey today, and consider
            subscribing{' '}
            <a href="/marketplace?productType=Subscription&mode=3">here</a> to
            unlock the full potential of your Fakemon creation experience!
          </p>
        </Section>
        <Section id="section-basic-how-does-basic-generation-work">
          <Subtitle>How Does Basic Generation Work?</Subtitle>
          <p>
            To generate a Fakemon using Basic Generation, follow these steps:
          </p>
          <OrderedList>
            <ListItem>
              Open the Fakemon Generator and navigate to the Basic tab.
            </ListItem>
            <ListItem>
              Choose the Fakemon's style (2D or 3D) and select a single type
              (fire, water, grass, etc.) from the available options.
            </ListItem>
            <ListItem>
              Click the "Generate" button to create a Fakemon based on your
              chosen attributes.
            </ListItem>
          </OrderedList>{' '}
          <ImageContainer>
            <img
              src={BasicGenerationDemoGIF}
              alt="Basic GenerationGeneration Demo"
            />
          </ImageContainer>
        </Section>
      </BlogContainer>

      <BlogContainer id="section-advanced-generation">
        <Section id="section-advanced-generation">
          <Title>Advanced Generation</Title>

          <Subtitle id="section-advanced-generation-what-is-advanced-generation">
            What is Advanced Generation?
          </Subtitle>

          <p>
            Advanced Generation offers more control and customization. With
            Credits, you can unlock advanced generation options and ensure your
            Fakemon are truly unique. This feature is perfect for those who have
            a specific vision for their Fakemon and want to guide the generation
            process. Advanced Generation offers the following features:
          </p>

          <UnorderedList>
            <ListItem>
              <strong>Dual Types:</strong> Craft Fakemon with two elemental
              types, bringing more diversity to abilities and characteristics.
            </ListItem>
            <ListItem>
              <strong>Keyword Guidance:</strong> Use specific keywords to guide
              the image generation process, aligning the creation closer to your
              vision.
            </ListItem>
            <ListItem>
              <strong>Guaranteed Uniqueness:</strong> Each Fakemon created with
              Credits is guaranteed to be unique, offering one-of-a-kind
              creations.
            </ListItem>
          </UnorderedList>

          <Subtitle id="section-advanced-generation-what-are-nokepoints">
            What are Credits?
          </Subtitle>

          <p>
            Credits are a virtual currency that you can use to access Advanced
            Generation and create unique Fakemon. To use Credits, navigate to
            the Advanced tab in the Fakemon Generator. Here, you can spend your
            Credits to unlock Advanced Generation options and start crafting
            unique Fakemon.
          </p>

          <p>
            Ready to unlock your creativity with Advanced Generation? Get your
            Credits{' '}
            <a href="/marketplace?productType=Credits&mode=3#marketplaceTabs">
              here
            </a>
            &nbsp; and start crafting Fakemon that truly reflect your
            imagination.
          </p>
        </Section>

        <Section id="section-what-is-batch-generation">
          <Subtitle>What Is Batch Generation?</Subtitle>
          <ReducedImageContainer>
            <img src={NokemonBatchImage} alt="Nokemon Batch Generation Demo" />
          </ReducedImageContainer>
          <p>
            Batch Generation is a feature that amplifies your Fakemon creation
            experience. With this function, you can generate from 5 to 100
            Fakemon simultaneously, providing you with an array of diverse and
            unique ideas swiftly and efficiently. It's designed to spark
            creativity and offer you a broad range of design possibilities in
            just around 30 seconds!
          </p>
          <Section>
            <SubSubTitle>How to Use Batch Generation?</SubSubTitle>
            <OrderedList>
              <ListItem>
                Navigate to the Advanced Tab in the Fakemon Generator.
              </ListItem>
              <ListItem>Switch the 'Generate by' option to 'Batch'.</ListItem>
              <ListItem>
                Select the number of Fakemon you wish to generate.
              </ListItem>
              <ListItem>
                Click the 'Generate' button. It takes around 30 seconds to
                generate your batch, no matter how many Fakemon you're
                generating.
              </ListItem>
            </OrderedList>
          </Section>
          <Section>
            <SubSubTitle>Benefits of Batch Generation</SubSubTitle>
            <OrderedList>
              <ListItem>
                Large Volume: Generate up to 100 Fakemon per batch for a wide
                range of design options.
              </ListItem>
              <ListItem>
                Quick Generation: It only takes approximately 30 seconds to
                create your batch, making it a fast way to generate multiple
                Fakemon.
              </ListItem>
              <ListItem>
                Random Results: Every Fakemon created through Batch Generation
                is randomly generated, resulting in a diverse array of unique
                designs.
              </ListItem>
              <ListItem>
                Spark Creativity: Whether you're feeling stuck or looking to
                explore various design ideas, Batch Generation is your gateway
                to an influx of unexpected and inspiring Fakemon designs.
              </ListItem>
            </OrderedList>
          </Section>
          <Section>
            <SubSubTitle>Limitations of Batch Generation</SubSubTitle>
            <p>
              While Batch Generation offers the convenience of creating multiple
              Fakemon simultaneously, it's important to note that this feature
              is designed to generate random Fakemon. As a result, Batch
              Generation provides less customization compared to the single
              Fakemon generation option. Nonetheless, it is an invaluable tool
              when seeking to generate a multitude of Fakemon in a short span of
              time.
            </p>
          </Section>
        </Section>

        <Section id="section-generation-process">
          <Subtitle>What Does The Generation Process Look Like?</Subtitle>
          <IncludesList options={includesListOptions} />
          <p>
            Ready to start creating? Get your Credits today and experience the
            benefits of Advanced Generation!
          </p>
        </Section>
      </BlogContainer>

      <BlogContainer id="section-subscription-tiers">
        <Title>Subscription Tiers</Title>
        <Subtitle>Support the Project, Enhance Your Experience</Subtitle>
        <Section id="section-subscription-tiers">
          <Subtitle>Subscription Tiers</Subtitle>
          <p>
            We offer a range of subscription tiers designed to enhance your
            Fakemon creation experience and provide you with exclusive benefits.
            By subscribing, you gain access to additional features and perks,
            allowing you to take full advantage of our platform's capabilities.
            Choose the tier that best suits your needs and elevate your Fakemon
            journey to new heights.
          </p>
        </Section>
        <Section>
          <SubSubTitle>Novice Trainer ($2/month)</SubSubTitle>
          <ul>
            <ListItem>30 basic generations per day</ListItem>
            <ListItem>Batch generation of 5 Fakemon</ListItem>
            <ListItem>Storage capacity increase by 50</ListItem>
          </ul>
        </Section>
        <Section>
          <SubSubTitle>Elite Trainer ($5/month)</SubSubTitle>
          <ul>
            <ListItem>100 basic generations per day</ListItem>
            <ListItem>Batch generation of 10 Fakemon</ListItem>
            <ListItem>20 Credits per month</ListItem>
            <ListItem>Storage capacity increase by 200</ListItem>
          </ul>
        </Section>
        <Section>
          <SubSubTitle>Master Trainer ($10/month)</SubSubTitle>
          <ul>
            <ListItem>200 basic generations per day</ListItem>
            <ListItem>Batch generation of 20 Fakemon</ListItem>
            <ListItem>50 Credits per month</ListItem>
            <ListItem>Storage capacity increase by 500</ListItem>
          </ul>
        </Section>
        <Section>
          <p>
            Subscribing to a tier provides you with increased daily generation
            limits, allowing you to create more Fakemon every day. The ability
            to perform batch generation saves you time by generating multiple
            Fakemon in one go. With storage capacity increases, you can save and
            organize a larger number of Fakemon in your personal collection.
            Additionally, Credits provided with higher tiers unlock more
            advanced generation options and further customization possibilities.
          </p>
        </Section>
        <Section>
          <p>
            By subscribing to one of the tiers, you not only unlock exclusive
            benefits but also support the continuous development and improvement
            of our platform. Your subscription helps us maintain and upgrade our
            infrastructure, expand our feature set, and ensure a seamless user
            experience. It is thanks to the support of our subscribers that we
            can continue to provide an innovative and high-quality platform for
            Fakemon creation.
          </p>
        </Section>
      </BlogContainer>
    </div>
  );
};

export default FAQPage;
