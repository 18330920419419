import React, { useState, useEffect, useRef } from 'react';
import { enableFeedback, FeedbackButtons } from './FeedbackButtons';
import { AddButton, ShareButton } from './StorageButtons';
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/fontawesome-free-solid';
import ProgressBar from '@ramonak/react-progress-bar';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import {
  sleep,
  generateTextFromFileName,
  constants,
} from 'nokemon-common-utils';
import { toast } from 'react-toastify';

import { v4 as uuidv4 } from 'uuid';
import { PremiumOutput } from './Output/AdvancedOutput/PremiumOutput';

const { fakemonToName, legendaryPokemon } = constants;

const TYPE_OPTIONS = [
  'none',
  'normal',
  'fire',
  'water',
  'grass',
  'electric',
  'ice',
  'fighting',
  'poison',
  'ground',
  'flying',
  'psychic',
  'bug',
  'rock',
  'ghost',
  'dark',
  'dragon',
  'steel',
  'fairy',
];

function Nokemon(props) {
  return (
    <div className="nokemonGroup">
      <NokemonName fileName={props.fileName} />
      <NokemonLabels fileName={props.fileName} isPremium={props.isPremium} />
      <NokemonImage fileName={props.fileName} />
    </div>
  );
}

function NokemonName(props) {
  return props.fileName in fakemonToName ? (
    <p className="nokemonName">{fakemonToName[props.fileName]}</p>
  ) : (
    <span className="nokemonName" />
  );
}

function NokemonImage(props) {
  if (props.fileName) {
    return (
      <img
        src={'/api/generation/basic/images/fakemon?fileName=' + props.fileName}
        className="nokemonImage"
      />
    );
  } else {
    return <div />;
  }
}

function NokemonLabels(props) {
  let isLegendary;

  if (props.fileName) {
    const types = generateTextFromFileName(props.fileName);

    if (props.fileName.includes('model=sd_v1')) {
      if (props.fileName.includes('legendary=0')) {
        isLegendary = false;
      } else if (props.fileName.includes('legendary=1')) {
        isLegendary = true;
      }
    } else {
      isLegendary =
        Array.from(legendaryPokemon).includes(props.fileName) ||
        (props.fileName.includes('legendary') && props.isPremium);
    }

    return (
      <div className="nokemon">
        <div className="nokemonTypes">
          {isLegendary ? (
            <div id="legendary" className={'typeBar legendary'}>
              legendary
            </div>
          ) : (
            <div></div>
          )}
          <div
            data-cy="typeLabel"
            id="type1"
            className={'typeBar ' + types['type1']}
          >
            {types['type1']}
          </div>
          {types['type2'] && types['type1'] != types['type2'] ? (
            <div
              data-cy="typeLabel"
              id="type2"
              className={'typeBar ' + types['type2']}
            >
              {types['type2']}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

function BasicOutput(props) {
  if (props.loadingState) {
    return (
      <div
        id="outputBox"
        className="box"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="loader" />
      </div>
    );
  } else if (props.fileName == undefined) {
    return <div id="outputBox" className="box" />;
  } else {
    return (
      <div id="outputBox" className="box">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <ShareButton fileName={props.fileName} />
          <AddButton
            fileName={props.fileName}
            isAddButtonClicked={props.isAddButtonClicked}
            setIsAddButtonClicked={props.setIsAddButtonClicked}
            isAddButtonDisplayed={true}
            isAddButtonDisabled={props.isAddButtonDisabled}
            setIsAddButtonDisabled={props.setIsAddButtonDisabled}
          />
        </div>
        <div className="outputNokemon">
          <Nokemon fileName={props.fileName} isPremium={props.isPremium} />
          <FeedbackButtons outputData={props.outputData} />
        </div>
      </div>
    );
  }
}

function AdvancedOutput(props) {
  const [estimatedTotalTime, setEstimatedTotalTime] = useState(undefined);

  useEffect(() => {
    setEstimatedTotalTime(0);
  }, [props.random]);

  if (props.loadingState) {
    return (
      <div id="outputBox" className="box advancedBox">
        <ElapsedTime estimatedTotalTime={estimatedTotalTime} />
        <EstimateTime
          random={props.random}
          generationSessionId={props.generationSessionId}
          queuePosition={props.queuePosition}
          vmStatus={props.vmStatus}
          startTime={props.startTime}
          serverStatus={props.serverStatus}
          cacheAvailability={props.cacheAvailability}
          estimatedTotalTime={estimatedTotalTime}
          setEstimatedTotalTime={setEstimatedTotalTime}
          taskStatus={props.taskStatus}
        />
        {props.vmStatus === 'RUNNING' ? (
          ''
        ) : (
          <p>Subsequent generations will take ~10 seconds.</p>
        )}
        <GenerationStatusList statusInfo={props.statusInfo} />
        {/* <GenerationStatus
          vmStatus={props.vmStatus}
          serverStatus={props.serverStatus}
          queuePosition={props.queuePosition}
          cacheAvailability={props.cacheAvailability}
          random={props.random}
          loadingState={props.loadingState}
        /> */}
      </div>
    );
  } else if (props.fileName == undefined) {
    if (props.nokemonData) {
      return (
        <div id="outputBox" className="box">
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <ShareButton fileName={props.fileName} />
            <AddButton
              fileName={props.fileName}
              isAddButtonClicked={props.isAddButtonClicked}
              setIsAddButtonClicked={props.setIsAddButtonClicked}
              isAddButtonDisplayed={true}
              isAddButtonDisabled={props.isAddButtonDisabled}
              setIsAddButtonDisabled={props.setIsAddButtonDisabled}
            />
          </div>
          <div className="outputNokemon">
            <Nokemon
              fileName={props.nokemonData.file_name}
              isPremium={props.isPremium}
            />
            {/* <FeedbackButtons outputData={props.outputData} /> */}
          </div>
        </div>
      );
    }
    return <div id="outputBox" className="box" />;
  } else {
    return (
      <div id="outputBox" className="box">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <ShareButton fileName={props.fileName} />
          <AddButton
            fileName={props.fileName}
            isAddButtonClicked={props.isAddButtonClicked}
            setIsAddButtonClicked={props.setIsAddButtonClicked}
            isAddButtonDisplayed={true}
            isAddButtonDisabled={props.isAddButtonDisabled}
            setIsAddButtonDisabled={props.setIsAddButtonDisabled}
          />
        </div>
        <div className="outputNokemon">
          <Nokemon fileName={props.fileName} isPremium={props.isPremium} />
          {/* <FeedbackButtons outputData={props.outputData} /> */}
        </div>
      </div>
    );
  }
}

// function AdvancedOutput(props) {
//   const [estimatedTotalTime, setEstimatedTotalTime] = useState(undefined);
//   useEffect(() => {
//     setEstimatedTotalTime(0);
//   }, [props.random]);

//   if (props.loadingState) {
//     return (
//       <div id="outputBox" className="box advancedBox">
//         <ElapsedTime estimatedTotalTime={estimatedTotalTime} />
//         <EstimateTime
//           random={props.random}
//           generationSessionId={props.generationSessionId}
//           queuePosition={props.queuePosition}
//           vmStatus={props.vmStatus}
//           startTime={props.startTime}
//           serverStatus={props.serverStatus}
//           cacheAvailability={props.cacheAvailability}
//           estimatedTotalTime={estimatedTotalTime}
//           setEstimatedTotalTime={setEstimatedTotalTime}
//         />
//         {props.vmStatus === 'RUNNING' ? (
//           ''
//         ) : (
//           <p>Subsequent generations will takes ~10 seconds.</p>
//         )}
//         <GenerationStatusList statusInfo={props.statusInfo} />
//         {/* <GenerationStatus
//           vmStatus={props.vmStatus}
//           serverStatus={props.serverStatus}
//           queuePosition={props.queuePosition}
//           cacheAvailability={props.cacheAvailability}
//           random={props.random}
//           loadingState={props.loadingState}
//         /> */}
//       </div>
//     );
//   } else if (props.fileName == undefined) {
//     if (props.nokemonData) {
//       return (
//         <div id="outputBox" className="box">
//           <div
//             style={{
//               display: 'flex',
//               width: '100%',
//               justifyContent: 'space-between',
//             }}
//           >
//             <ShareButton fileName={props.fileName} />
//             <AddButton
//               fileName={props.fileName}
//               isAddButtonClicked={props.isAddButtonClicked}
//               setIsAddButtonClicked={props.setIsAddButtonClicked}
//               isAddButtonDisplayed={true}
//               isAddButtonDisabled={props.isAddButtonDisabled}
//               setIsAddButtonDisabled={props.setIsAddButtonDisabled}
//             />
//           </div>
//           <div className="outputNokemon">
//             <Nokemon
//               fileName={props.nokemonData.file_name}
//               isPremium={props.isPremium}
//             />
//             {/* <FeedbackButtons outputData={props.outputData} /> */}
//           </div>
//         </div>
//       );
//     }
//     return <div id="outputBox" className="box" />;
//   } else {
//     return (
//       <div id="outputBox" className="box">
//         <div
//           style={{
//             display: 'flex',
//             width: '100%',
//             justifyContent: 'space-between',
//           }}
//         >
//           <ShareButton fileName={props.fileName} />
//           <AddButton
//             fileName={props.fileName}
//             isAddButtonClicked={props.isAddButtonClicked}
//             setIsAddButtonClicked={props.setIsAddButtonClicked}
//             isAddButtonDisplayed={true}
//             isAddButtonDisabled={props.isAddButtonDisabled}
//             setIsAddButtonDisabled={props.setIsAddButtonDisabled}
//           />
//         </div>
//         <div className="outputNokemon">
//           <Nokemon fileName={props.fileName} isPremium={props.isPremium} />
//           {/* <FeedbackButtons outputData={props.outputData} /> */}
//         </div>
//       </div>
//     );
//   }
// }

function BasicBatchOutput(props) {
  if (props.loadingState) {
    return (
      <div
        id="outputBox"
        className="box"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="loader" />
      </div>
    );
  } else if (props.fileName == undefined) {
    return <div id="outputBox" className="box" />;
  } else {
    return (
      <div id="outputBox" className="box" style={{}}>
        {props.fileName != undefined ? (
          <Carousel
            style={{
              padding: 0,
              margin: 0,
            }}
            width={'100%'}
            centerMode={false}
            useKeyboardArrows={true}
            statusFormatter={(current, total) => `${current} / ${total}`}
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  data-cy="outputNokemonNextButton"
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    zIndex: 2,
                    width: 50,
                    height: 50,
                    cursor: 'pointer',
                    right: 15,
                    background: 'rgba(0, 0, 0, 0.5)',
                    border: 'none',
                    borderRadius: '50%',
                    color: '#fff',
                    fontSize: 30,
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                </button>
              )
            }
            renderArrowPrev={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    zIndex: 2,
                    width: 50,
                    height: 50,
                    cursor: 'pointer',
                    left: 15,
                    background: 'rgba(0, 0, 0, 0.5)',
                    border: 'none',
                    borderRadius: '50%',
                    color: '#fff',
                    fontSize: 30,
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                </button>
              )
            }
          >
            {props.fileName.map(fileName => {
              return (
                <div
                  id="outputBox"
                  className="box"
                  style={{
                    margin: 0,
                    marginTop: '30px',
                    padding: 0,
                    boxShadow: 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ShareButton fileName={fileName} />
                    <AddButton
                      fileName={fileName}
                      isAddButtonClicked={props.isAddButtonClicked}
                      setIsAddButtonClicked={props.setIsAddButtonClicked}
                      isAddButtonDisplayed={true}
                      isAddButtonDisabled={props.isAddButtonDisabled}
                      setIsAddButtonDisabled={props.setIsAddButtonDisabled}
                    />
                  </div>
                  <div className="outputNokemon">
                    <Nokemon fileName={fileName} isPremium={props.isPremium} />
                  </div>
                </div>
              );
            })}
          </Carousel>
        ) : (
          <div />
        )}
      </div>
    );
  }
}
function AdvancedBatchOutput(props) {
  const [estimatedTotalTime, setEstimatedTotalTime] = useState(undefined);

  useEffect(() => {
    setEstimatedTotalTime(0);
  }, [props.random]);

  if (props.loadingState) {
    return (
      <div id="outputBox" className="box advancedBox">
        <ElapsedTime estimatedTotalTime={estimatedTotalTime} />
        <EstimateTime
          random={props.random}
          generationSessionId={props.generationSessionId}
          queuePosition={props.queuePosition}
          vmStatus={'RUNNING'}
          startTime={props.startTime}
          serverStatus={true}
          cacheAvailability={false}
          estimatedTotalTime={estimatedTotalTime}
          setEstimatedTotalTime={setEstimatedTotalTime}
        />
        <GenerationStatusList statusInfo={props.statusInfo} />
        {/* <GenerationStatus
          vmStatus={props.vmStatus}
          serverStatus={props.serverStatus}
          queuePosition={props.queuePosition}
          cacheAvailability={props.cacheAvailability}
          random={props.random}
          loadingState={props.loadingState}
        /> */}
      </div>
    );
  } else if (props.fileName == undefined) {
    return <div id="outputBox" className="box" />;
  } else {
    return (
      <div id="outputBox" className="box">
        {props.fileName != undefined ? (
          <Carousel
            style={{
              padding: 0,
              margin: 0,
            }}
            width={'100%'}
            centerMode={false}
            useKeyboardArrows={true}
            statusFormatter={(current, total) => `${current} / ${total}`}
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  data-cy="outputNokemonNextButton"
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    zIndex: 2,
                    width: 50,
                    height: 50,
                    cursor: 'pointer',
                    right: 15,
                    background: 'rgba(0, 0, 0, 0.5)',
                    border: 'none',
                    borderRadius: '50%',
                    color: '#fff',
                    fontSize: 30,
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                </button>
              )
            }
            renderArrowPrev={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    zIndex: 2,
                    width: 50,
                    height: 50,
                    cursor: 'pointer',
                    left: 15,
                    background: 'rgba(0, 0, 0, 0.5)',
                    border: 'none',
                    borderRadius: '50%',
                    color: '#fff',
                    fontSize: 30,
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                </button>
              )
            }
          >
            {props.fileName.map(fileName => {
              return (
                <div
                  id="outputBox"
                  className="box"
                  style={{
                    margin: 0,
                    marginTop: '30px',
                    padding: 0,
                    boxShadow: 'none',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ShareButton fileName={fileName} />
                    <AddButton
                      fileName={fileName}
                      isAddButtonClicked={props.isAddButtonClicked}
                      setIsAddButtonClicked={props.setIsAddButtonClicked}
                      isAddButtonDisplayed={true}
                      isAddButtonDisabled={props.isAddButtonDisabled}
                      setIsAddButtonDisabled={props.setIsAddButtonDisabled}
                    />
                  </div>
                  <div className="outputNokemon">
                    <Nokemon fileName={fileName} isPremium={props.isPremium} />
                  </div>
                </div>
              );
            })}
          </Carousel>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

async function fetchQueuePosition(generateSessionUUID) {
  const data = await fetch(
    `/api/generation/advanced/getSessionIndex?generationSessionUUID=${generateSessionUUID}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  ).then(res => res.json());
  // const stop = performance.now();
  // const inSeconds = (stop - start) / 1000;
  // const rounded = Number(inSeconds).toFixed(3);
  return 1 + data['index'];
}

async function fetchTaskStatus(beamTaskId) {
  if (!beamTaskId) {
    return;
  }
  // Define the URL to fetch the task status from.
  const url = `/api/beam/status/${beamTaskId}`;

  try {
    // Perform the fetch operation.
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });

    // Check if the response was successful.
    if (!response.ok) {
      throw new Error(`Server responded with status code: ${response.status}`);
    }

    // Parse the JSON response.
    const data = await response.json();

    // Check if the expected 'status' field is present.
    if (!data || typeof data['status'] !== 'string') {
      throw new Error(
        'The "status" field is missing or invalid in the response.',
      );
    }

    // Return the status.
    return data['status'];
  } catch (error) {
    // Handle any errors that occurred during the fetch operation.
    console.error(`Error fetching task status for ID ${beamTaskId}:`, error);
    throw error; // Re-throw the error to handle it where the function is called.
  }
}

async function fetchVmStatus(modelType) {
  let url = '/api/generation/advanced/ping/vm';
  url += `?modelType=${encodeURIComponent(modelType)}`;

  const data = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
  }).then(res => res.json());

  return data;
}

async function fetchServerStatus(modelType) {
  let url = '/api/generation/advanced/ping/http/server';
  url += `?modelType=${encodeURIComponent(modelType)}`;

  const data = await fetch(url).then(res => res.json());

  return data;
}

// function generationStatusInformation(
//   vmStatus,
//   serverStatus,
//   cacheAvailability,
//   queuePosition,
// ) {
//   const statusIcons = {
//     'calculating processies': {
//       message: 'Calculating processies...',
//       status: 'running',
//     },
//     'starting up computer': {
//       message: 'Starting up computer...',
//       status: 'waiting',
//     },
//     'loading ai model': {
//       message: 'Loading AI model...',
//       status: 'waiting',
//     },
//     'waiting in queue': {
//       message: 'Waiting in queue...',
//       status: 'waiting',
//     },
//     'generating nokemon': {
//       message: 'Generating Nokémon...',
//       status: 'waiting',
//     },
//   };

//   if (cacheAvailability == undefined) {
//     return statusIcons;
//   } else if (cacheAvailability == true) {
//     statusIcons['calculating processes']['status'] = 'completed';
//     statusIcons['starting up computer']['status'] = 'completed';
//     statusIcons['loading ai model']['status'] = 'completed';
//     statusIcons['waiting in queue']['status'] = 'completed';
//     statusIcons['generating nokemon']['status'] = 'running';

//     return statusIcons;
//   }

//   // Cache unavailable, generate by means of model
//   if (vmStatus == undefined) {
//     statusIcons['calculating processies']['status'] = 'running';
//     return statusIcons;
//   } else if (
//     vmStatus == 'STAGING' ||
//     vmStatus == 'PROVISIONING' ||
//     vmStatus == 'TERMINATED' ||
//     vmStatus == 'STOPPING'
//   ) {
//     statusIcons['calculating processies']['status'] = 'done';
//     statusIcons['starting up computer']['status'] = 'running';
//     return statusIcons;
//   }

//   // VM Status MUST be available from this point
//   if (serverStatus == undefined) {
//     statusIcons['calculating processies']['status'] = 'done';
//     statusIcons['starting up computer']['status'] = 'done';

//     return statusIcons;
//   } else if (serverStatus == false) {
//     statusIcons['calculating processies']['status'] = 'done';
//     statusIcons['starting up computer']['status'] = 'done';
//     statusIcons['loading ai model']['status'] = 'running';

//     return statusIcons;
//   }

//   // Server Status MUST be available from this point
//   if (queuePosition == undefined) {
//     statusIcons['calculating processies']['status'] = 'done';
//     statusIcons['starting up computer']['status'] = 'done';
//     statusIcons['loading ai model']['status'] = 'done';

//     return statusIcons;
//   } else if (queuePosition > 1) {
//     statusIcons['calculating processies']['status'] = 'done';
//     statusIcons['starting up computer']['status'] = 'done';
//     statusIcons['loading ai model']['status'] = 'done';
//     statusIcons['waiting in queue']['status'] = 'running';

//     return statusIcons;
//   }
//   statusIcons['calculating processies']['status'] = 'done';
//   statusIcons['starting up computer']['status'] = 'done';
//   statusIcons['loading ai model']['status'] = 'done';
//   statusIcons['waiting in queue']['status'] = 'done';
//   statusIcons['generating nokemon']['status'] = 'running';

//   return statusIcons;
// }
function generationStatusInformation(
  taskStatus,
  cacheAvailability,
  queuePosition,
) {
  const statusIcons = {
    'calculating processies': {
      message: 'Calculating processies...',
      status: 'running',
    },
    'starting up computer': {
      message: 'Starting up computer...',
      status: 'waiting',
    },
    'loading ai model': {
      message: 'Loading AI model...',
      status: 'waiting',
    },
    'waiting in queue': {
      message: 'Waiting in queue...',
      status: 'waiting',
    },
    'generating nokemon': {
      message: 'Generating Nokémon...',
      status: 'waiting',
    },
  };

  if (cacheAvailability == undefined) {
    return statusIcons;
  } else if (cacheAvailability == true) {
    statusIcons['calculating processes']['status'] = 'completed';
    statusIcons['starting up computer']['status'] = 'completed';
    statusIcons['loading ai model']['status'] = 'completed';
    statusIcons['waiting in queue']['status'] = 'completed';
    statusIcons['generating nokemon']['status'] = 'running';

    return statusIcons;
  }

  // Cache unavailable, generate by means of model
  if (taskStatus == undefined) {
    statusIcons['calculating processies']['status'] = 'running';
    return statusIcons;
  } else if (taskStatus == 'PENDING') {
    statusIcons['calculating processies']['status'] = 'done';
    statusIcons['starting up computer']['status'] = 'running';
    statusIcons['loading ai model']['status'] = 'running';
    return statusIcons;
  }

  // Server Status MUST be available from this point
  if (queuePosition == undefined) {
    statusIcons['calculating processies']['status'] = 'done';
    statusIcons['starting up computer']['status'] = 'done';
    statusIcons['loading ai model']['status'] = 'done';

    return statusIcons;
  } else if (queuePosition > 1) {
    statusIcons['calculating processies']['status'] = 'done';
    statusIcons['starting up computer']['status'] = 'done';
    statusIcons['loading ai model']['status'] = 'done';
    statusIcons['waiting in queue']['status'] = 'running';

    return statusIcons;
  }
  statusIcons['calculating processies']['status'] = 'done';
  statusIcons['starting up computer']['status'] = 'done';
  statusIcons['loading ai model']['status'] = 'done';
  statusIcons['waiting in queue']['status'] = 'done';
  statusIcons['generating nokemon']['status'] = 'running';

  return statusIcons;
}

const defaultStatusInfo = {
  'calculating processies': {
    message: 'Calculating processies...',
    status: 'running',
  },
  'starting up computer': {
    message: 'Starting up computer...',
    status: 'waiting',
  },
  'loading ai model': {
    message: 'Loading AI model...',
    status: 'waiting',
  },
  'waiting in queue': {
    message: 'Waiting in queue...',
    status: 'waiting',
  },
  'generating nokemon': {
    message: 'Generating Nokémon...',
    status: 'waiting',
  },
};

const defaultStatusInfoBatch = {
  'calculating processies': {
    message: 'Calculating processies...',
    status: 'done',
  },
  'starting up computer': {
    message: 'Starting up computer...',
    status: 'done',
  },
  'loading ai model': {
    message: 'Loading AI model...',
    status: 'done',
  },
  'waiting in queue': {
    message: 'Waiting in queue...',
    status: 'done',
  },
  'generating nokemon': {
    message: 'Generating Nokémon...',
    status: 'waiting',
  },
};

// export function DefaultOutput(props) {
//   const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

//   const [queuePosition, setQueuePosition] = useState(undefined);
//   const [vmStatus, setVmStatus] = useState(undefined);
//   const [startTime, setStartTime] = useState(undefined);
//   const [serverStatus, setServerStatus] = useState(undefined);
//   const [cacheAvailability, setCacheAvailability] = useState(undefined);
//   const [statusInfo, setStatusInfo] = useState(defaultStatusInfo);
//   const [fetchDataBool, setFetchDataBool] = useState(true);

//   useEffect(() => {
//     if (!props.loadingState) {
//       setQueuePosition(undefined);
//       setVmStatus(undefined);
//       setStartTime(undefined);
//       setServerStatus(undefined);
//       setCacheAvailability(undefined);

//       setStatusInfo(defaultStatusInfo);
//       return;
//     }

//     if (!props.isLoggedIn) {
//       return;
//     }

//     if (!props.isPremium) {
//       return;
//     }

//     if (props.generatedBy == 'batch') {
//       setStatusInfo(defaultStatusInfoBatch);
//       return;
//     }

//     if (props.generatedBy == 'random') {
//       setCacheAvailability(false);
//     }

//     if (
//       props.type1 == undefined ||
//       props.type2 == undefined ||
//       props.legendary == undefined ||
//       props.style == undefined
//     ) {
//       return;
//     }

//     fetch(
//       `/api/generation/advanced/checkgenerateByCache?type1=${props.type1}&type2=${props.type2}&legendary=${props.legendary}&style=${props.style}`,
//       {
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         },
//         method: 'GET',
//       },
//     )
//       .then(res => res.json())
//       .then(data => {
//         setCacheAvailability(data['canGenerateCache']);
//       })
//       .catch(e => {
//         toast.error(
//           'An error has occurred, please refresh or try again later.',
//         );
//         setCacheAvailability('error');
//         props.setLoadingState(false);
//       });
//   }, [props.random, props.loadingState]);

//   useInterval(() => {
//     if (!fetchDataBool) {
//       return;
//     }

//     if (!props.loadingState) {
//       setQueuePosition(undefined);
//       setVmStatus(undefined);
//       setStartTime(undefined);
//       setServerStatus(undefined);
//       return;
//     }

//     if (!props.isLoggedIn) {
//       return;
//     }

//     if (!props.isPremium) {
//       return;
//     }

//     async function fetchQueueData(generateSessionUUID) {
//       const queuePosition = await fetchQueuePosition(generateSessionUUID);
//       setQueuePosition(queuePosition);
//     }

//     if (props.generatedBy === 'batch') {
//       setStatusInfo(defaultStatusInfoBatch);
//       fetchQueueData(props.generateSessionUUID);

//       return;
//     }

//     // TODO: The issue for random generation might be something to do with this function.
//     async function fetchData(modelType, generateSessionUUID) {
//       setFetchDataBool(false);

//       const queuePosition = await fetchQueuePosition(generateSessionUUID);
//       const vmStatusData = await fetchVmStatus(modelType);
//       const serverStatusData = await fetchServerStatus(modelType);

//       setQueuePosition(queuePosition);
//       setVmStatus(vmStatusData['status']);
//       setStartTime(vmStatusData['startTime']);
//       setServerStatus(serverStatusData['status']);

//       setFetchDataBool(true);
//     }

//     fetchData(props.modelType, props.generateSessionUUID);

//     const statusInformation = generationStatusInformation(
//       vmStatus,
//       serverStatus,
//       cacheAvailability,
//       queuePosition,
//     );

//     if (queuePosition > 0) {
//       statusInformation['waiting in queue'][
//         'message'
//       ] = `Waiting in queue... (${queuePosition} in queue)`;
//     }

//     setStatusInfo(statusInformation);
//   }, 5000);

//   if (props.isPremium) {
//     if (props.batchNum !== 1) {
//       return (
//         <AdvancedBatchOutput
//           fileName={props.fileName}
//           loadingState={props.loadingState}
//           random={props.random}
//           statusInfo={statusInfo}
//           vmStatus={vmStatus}
//           serverStatus={serverStatus}
//           queuePosition={queuePosition}
//           cacheAvailability={cacheAvailability}
//           startTime={startTime}
//           batchNum={props.batchNum}
//           isPremium={true}
//           setIsAddButtonDisabled={setIsAddButtonDisabled}
//         />
//       );
//     } else {
//       return (
//         <AdvancedOutput
//           fileName={props.fileName}
//           loadingState={props.loadingState}
//           random={props.random}
//           statusInfo={statusInfo}
//           vmStatus={vmStatus}
//           serverStatus={serverStatus}
//           queuePosition={queuePosition}
//           cacheAvailability={cacheAvailability}
//           startTime={startTime}
//           batchNum={props.batchNum}
//           isPremium={true}
//           nokemonData={props.nokemonData}
//           setIsAddButtonDisabled={setIsAddButtonDisabled}
//         />
//       );
//     }
//   } else {
//     if (props.batchNum != 1) {
//       return (
//         <BasicBatchOutput
//           fileName={props.fileName}
//           loadingState={props.loadingState}
//           setIsAddButtonClicked={props.setIsAddButtonClicked}
//           isAddButtonDisabled={isAddButtonDisabled}
//           setIsAddButtonDisabled={setIsAddButtonDisabled}
//           isPremium={false}
//           outputData={props.outputData}
//         />
//       );
//     }
//     return (
//       <BasicOutput
//         fileName={props.fileName}
//         loadingState={props.loadingState}
//         setIsAddButtonClicked={props.setIsAddButtonClicked}
//         isAddButtonDisabled={isAddButtonDisabled}
//         setIsAddButtonDisabled={setIsAddButtonDisabled}
//         isPremium={false}
//         outputData={props.outputData}
//       />
//     );
//   }

//   // return (
//   //   <div id="outputBox" className="box">
//   //     {!props.loadingState ? (
//   //       !(props.fileName == undefined) ? (
//   //         <Carousel
//   //           style={{
//   //             padding: 0,
//   //             margin: 0,
//   //           }}
//   //           width={'100%'}
//   //           centerMode={false}
//   //           useKeyboardArrows={true}
//   //           statusFormatter={(current, total) =>
//   //             `Nokemon: ${current} / ${total}`
//   //           }
//   //           renderArrowNext={(onClickHandler, hasNext, label) =>
//   //             hasNext && (
//   //               <button
//   //                 type="button"
//   //                 onClick={onClickHandler}
//   //                 title={label}
//   //                 style={{
//   //                   position: 'absolute',
//   //                   zIndex: 2,
//   //                   top: 'calc(50% - 100px)',
//   //                   width: 30,
//   //                   height: 200,
//   //                   cursor: 'pointer',
//   //                   right: 15,
//   //                   background: 'none',
//   //                   border: 'none',
//   //                   margin: 0,
//   //                   padding: 0,
//   //                   fontSize: 50,
//   //                 }}
//   //               >
//   //                 <FontAwesomeIcon icon="fa-solid fa-angle-right" />
//   //               </button>
//   //             )
//   //           }
//   //           renderArrowPrev={(onClickHandler, hasNext, label) =>
//   //             hasNext && (
//   //               <button
//   //                 type="button"
//   //                 onClick={onClickHandler}
//   //                 title={label}
//   //                 style={{
//   //                   position: 'absolute',
//   //                   zIndex: 2,
//   //                   top: 'calc(50% - 100px)',
//   //                   width: 30,
//   //                   height: 200,
//   //                   cursor: 'pointer',
//   //                   left: 15,
//   //                   background: 'none',
//   //                   border: 'none',
//   //                   margin: 0,
//   //                   padding: 0,
//   //                   fontSize: 50,
//   //                 }}
//   //               >
//   //                 <FontAwesomeIcon icon="fa-solid fa-angle-left" />
//   //               </button>
//   //             )
//   //           }
//   //           renderIndicator={(onClickHandler, isSelected, index, label) => {
//   //             return (
//   //               <li
//   //                 style={{
//   //                   background: 'rgba(249, 250, 251, var(--tw-bg-opacity))',
//   //                   width: 8,
//   //                   height: 8,
//   //                   display: 'none',
//   //                   margin: '0 8px',
//   //                 }}
//   //                 onClick={onClickHandler}
//   //                 onKeyDown={onClickHandler}
//   //                 value={index}
//   //                 key={index}
//   //                 role="button"
//   //                 tabIndex={0}
//   //                 title={`${label} ${index + 1}`}
//   //                 aria-label={`${label} ${index + 1}`}
//   //               />
//   //             );
//   //           }}
//   //         >
//   //           {props.fileName.map(fileName => {
//   //             return (
//   //               <div id="outputBox" className="box">
//   //                 <AddButton
//   //                   fileName={props.fileName}
//   //                   isAddButtonClicked={props.isAddButtonClicked}
//   //                   setIsAddButtonClicked={props.setIsAddButtonClicked}
//   //                   isAddButtonDisplayed={true}
//   //                   isAddButtonDisabled={isAddButtonDisabled}
//   //                   setIsAddButtonDisabled={setIsAddButtonDisabled}
//   //                 />
//   //                 <ShareButton fileName={props.fileName} />
//   //                 <div className="outputNokemon">
//   //                   <Nokemon fileName={props.fileName} isPremium={props.isPremium} />
//   //                   <FeedbackButtons outputData={props.outputData} />
//   //                 </div>
//   //               </div>
//   //             );
//   //           })}
//   //         </Carousel>
//   //       ) : (
//   //         <div />
//   //       )
//   //     ) : (
//   //       <>
//   //         {props.isPremium ? (
//   //           <>
//   //             <GenerationStatus
//   //               vmStatus={vmStatus}
//   //               serverStatus={serverStatus}
//   //               queuePosition={queuePosition}
//   //               cacheAvailability={cacheAvailability}
//   //               random={props.random}
//   //               loadingState={props.loadingState}
//   //             />
//   //             <EstimateTime
//   //               random={props.random}
//   //               generationSessionId={props.generationSessionId}
//   //               queuePosition={queuePosition}
//   //               vmStatus={vmStatus}
//   //               startTime={startTime}
//   //               serverStatus={serverStatus}
//   //               cacheAvailability={cacheAvailability}
//   //             />
//   //             <ElapsedTime />
//   //           </>
//   //         ) : (
//   //           <div />
//   //         )}
//   //         <div className="loader"></div>
//   //       </>
//   //     )}
//   //   </div>
//   // );
// }
export function DefaultOutput(props) {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const [queuePosition, setQueuePosition] = useState(undefined);
  const [taskStatus, setTaskStatus] = useState(undefined);
  const [startTime, setStartTime] = useState(undefined);
  const [cacheAvailability, setCacheAvailability] = useState(undefined);
  const [statusInfo, setStatusInfo] = useState(defaultStatusInfo);
  const [fetchDataBool, setFetchDataBool] = useState(true);

  useEffect(() => {
    if (!props.loadingState) {
      setQueuePosition(undefined);
      setTaskStatus(undefined);
      setStartTime(undefined);
      setCacheAvailability(undefined);

      setStatusInfo(defaultStatusInfo);
      return;
    }

    if (!props.isLoggedIn) {
      return;
    }

    if (!props.isPremium) {
      return;
    }

    if (props.generatedBy == 'batch') {
      setStatusInfo(defaultStatusInfoBatch);
      return;
    }

    if (props.generatedBy == 'random') {
      setCacheAvailability(false);
    }

    if (
      props.type1 == undefined ||
      props.type2 == undefined ||
      props.legendary == undefined ||
      props.style == undefined
    ) {
      return;
    }

    fetch(
      `/api/generation/advanced/checkgenerateByCache?type1=${props.type1}&type2=${props.type2}&legendary=${props.legendary}&style=${props.style}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      },
    )
      .then(res => res.json())
      .then(data => {
        setCacheAvailability(data['canGenerateCache']);
      })
      .catch(e => {
        toast.error(
          'An error has occurred, please refresh or try again later.',
        );
        setCacheAvailability('error');
        props.setLoadingState(false);
      });
  }, [props.random, props.loadingState]);

  useInterval(() => {
    if (!fetchDataBool) {
      return;
    }

    if (!props.loadingState) {
      setQueuePosition(undefined);
      setTaskStatus(undefined);
      setStartTime(undefined);
      return;
    }

    if (!props.isLoggedIn) {
      return;
    }

    if (!props.isPremium) {
      return;
    }

    async function fetchQueueData(generateSessionUUID) {
      const queuePosition = await fetchQueuePosition(generateSessionUUID);
      setQueuePosition(queuePosition);
    }

    if (props.generatedBy === 'batch') {
      setStatusInfo(defaultStatusInfoBatch);
      fetchQueueData(props.generateSessionUUID);

      return;
    }

    // TODO: The issue for random generation might be something to do with this function.
    async function fetchData(modelType, generateSessionUUID) {
      setFetchDataBool(false);

      const queuePosition = await fetchQueuePosition(generateSessionUUID);
      const taskStatus = await fetchTaskStatus(props.beamTaskId);

      setQueuePosition(queuePosition);
      setTaskStatus(taskStatus);
      // TODO: what???
      setStartTime(0);

      setFetchDataBool(true);
    }

    fetchData(props.modelType, props.generateSessionUUID);

    const statusInformation = generationStatusInformation(
      taskStatus,
      cacheAvailability,
      queuePosition,
    );

    if (queuePosition > 0) {
      statusInformation['waiting in queue'][
        'message'
      ] = `Waiting in queue... (${queuePosition} in queue)`;
    }

    setStatusInfo(statusInformation);
  }, 2000);

  if (props.isPremium) {
    if (props.batchNum !== 1) {
      return (
        <AdvancedBatchOutput
          fileName={props.fileName}
          loadingState={props.loadingState}
          random={props.random}
          statusInfo={statusInfo}
          queuePosition={queuePosition}
          cacheAvailability={cacheAvailability}
          startTime={startTime}
          batchNum={props.batchNum}
          isPremium={true}
          setIsAddButtonDisabled={setIsAddButtonDisabled}
        />
        // <AdvancedBatchOutput
        //   fileName={props.fileName}
        //   loadingState={props.loadingState}
        //   random={props.random}
        //   statusInfo={statusInfo}
        //   vmStatus={vmStatus}
        //   serverStatus={serverStatus}
        //   queuePosition={queuePosition}
        //   cacheAvailability={cacheAvailability}
        //   startTime={startTime}
        //   batchNum={props.batchNum}
        //   isPremium={true}
        //   setIsAddButtonDisabled={setIsAddButtonDisabled}
        // />
      );
    } else {
      return (
        <AdvancedOutput
          fileName={props.fileName}
          loadingState={props.loadingState}
          random={props.random}
          statusInfo={statusInfo}
          queuePosition={queuePosition}
          cacheAvailability={cacheAvailability}
          startTime={startTime}
          batchNum={props.batchNum}
          isPremium={true}
          nokemonData={props.nokemonData}
          setIsAddButtonDisabled={setIsAddButtonDisabled}
          taskStatus={taskStatus}
        />
        // <AdvancedOutput
        //   fileName={props.fileName}
        //   loadingState={props.loadingState}
        //   random={props.random}
        //   statusInfo={statusInfo}
        //   vmStatus={vmStatus}
        //   serverStatus={serverStatus}
        //   queuePosition={queuePosition}
        //   cacheAvailability={cacheAvailability}
        //   startTime={startTime}
        //   batchNum={props.batchNum}
        //   isPremium={true}
        //   nokemonData={props.nokemonData}
        //   setIsAddButtonDisabled={setIsAddButtonDisabled}
        // />
      );
    }
  } else {
    if (props.batchNum != 1) {
      return (
        <BasicBatchOutput
          fileName={props.fileName}
          loadingState={props.loadingState}
          setIsAddButtonClicked={props.setIsAddButtonClicked}
          isAddButtonDisabled={isAddButtonDisabled}
          setIsAddButtonDisabled={setIsAddButtonDisabled}
          isPremium={false}
          outputData={props.outputData}
        />
      );
    }
    return (
      <BasicOutput
        fileName={props.fileName}
        loadingState={props.loadingState}
        setIsAddButtonClicked={props.setIsAddButtonClicked}
        isAddButtonDisabled={isAddButtonDisabled}
        setIsAddButtonDisabled={setIsAddButtonDisabled}
        isPremium={false}
        outputData={props.outputData}
      />
    );
  }

  // return (
  //   <div id="outputBox" className="box">
  //     {!props.loadingState ? (
  //       !(props.fileName == undefined) ? (
  //         <Carousel
  //           style={{
  //             padding: 0,
  //             margin: 0,
  //           }}
  //           width={'100%'}
  //           centerMode={false}
  //           useKeyboardArrows={true}
  //           statusFormatter={(current, total) =>
  //             `Nokemon: ${current} / ${total}`
  //           }
  //           renderArrowNext={(onClickHandler, hasNext, label) =>
  //             hasNext && (
  //               <button
  //                 type="button"
  //                 onClick={onClickHandler}
  //                 title={label}
  //                 style={{
  //                   position: 'absolute',
  //                   zIndex: 2,
  //                   top: 'calc(50% - 100px)',
  //                   width: 30,
  //                   height: 200,
  //                   cursor: 'pointer',
  //                   right: 15,
  //                   background: 'none',
  //                   border: 'none',
  //                   margin: 0,
  //                   padding: 0,
  //                   fontSize: 50,
  //                 }}
  //               >
  //                 <FontAwesomeIcon icon="fa-solid fa-angle-right" />
  //               </button>
  //             )
  //           }
  //           renderArrowPrev={(onClickHandler, hasNext, label) =>
  //             hasNext && (
  //               <button
  //                 type="button"
  //                 onClick={onClickHandler}
  //                 title={label}
  //                 style={{
  //                   position: 'absolute',
  //                   zIndex: 2,
  //                   top: 'calc(50% - 100px)',
  //                   width: 30,
  //                   height: 200,
  //                   cursor: 'pointer',
  //                   left: 15,
  //                   background: 'none',
  //                   border: 'none',
  //                   margin: 0,
  //                   padding: 0,
  //                   fontSize: 50,
  //                 }}
  //               >
  //                 <FontAwesomeIcon icon="fa-solid fa-angle-left" />
  //               </button>
  //             )
  //           }
  //           renderIndicator={(onClickHandler, isSelected, index, label) => {
  //             return (
  //               <li
  //                 style={{
  //                   background: 'rgba(249, 250, 251, var(--tw-bg-opacity))',
  //                   width: 8,
  //                   height: 8,
  //                   display: 'none',
  //                   margin: '0 8px',
  //                 }}
  //                 onClick={onClickHandler}
  //                 onKeyDown={onClickHandler}
  //                 value={index}
  //                 key={index}
  //                 role="button"
  //                 tabIndex={0}
  //                 title={`${label} ${index + 1}`}
  //                 aria-label={`${label} ${index + 1}`}
  //               />
  //             );
  //           }}
  //         >
  //           {props.fileName.map(fileName => {
  //             return (
  //               <div id="outputBox" className="box">
  //                 <AddButton
  //                   fileName={props.fileName}
  //                   isAddButtonClicked={props.isAddButtonClicked}
  //                   setIsAddButtonClicked={props.setIsAddButtonClicked}
  //                   isAddButtonDisplayed={true}
  //                   isAddButtonDisabled={isAddButtonDisabled}
  //                   setIsAddButtonDisabled={setIsAddButtonDisabled}
  //                 />
  //                 <ShareButton fileName={props.fileName} />
  //                 <div className="outputNokemon">
  //                   <Nokemon fileName={props.fileName} isPremium={props.isPremium} />
  //                   <FeedbackButtons outputData={props.outputData} />
  //                 </div>
  //               </div>
  //             );
  //           })}
  //         </Carousel>
  //       ) : (
  //         <div />
  //       )
  //     ) : (
  //       <>
  //         {props.isPremium ? (
  //           <>
  //             <GenerationStatus
  //               vmStatus={vmStatus}
  //               serverStatus={serverStatus}
  //               queuePosition={queuePosition}
  //               cacheAvailability={cacheAvailability}
  //               random={props.random}
  //               loadingState={props.loadingState}
  //             />
  //             <EstimateTime
  //               random={props.random}
  //               generationSessionId={props.generationSessionId}
  //               queuePosition={queuePosition}
  //               vmStatus={vmStatus}
  //               startTime={startTime}
  //               serverStatus={serverStatus}
  //               cacheAvailability={cacheAvailability}
  //             />
  //             <ElapsedTime />
  //           </>
  //         ) : (
  //           <div />
  //         )}
  //         <div className="loader"></div>
  //       </>
  //     )}
  //   </div>
  // );
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function ElapsedTime(props) {
  let [startTime, _] = useState({ time: new Date() });
  let [currentTime, setCurrentTime] = useState({ time: new Date() });
  let [denominator, setDenominator] = useState(0);

  const timeElapsed = ((currentTime.time - startTime.time) / 1000).toFixed(1);

  useEffect(() => {
    if (props.estimatedTotalTime == 0) {
      denominator = timeElapsed;
      setDenominator(timeElapsed);
    }

    setDenominator(props.estimatedTotalTime + parseFloat(timeElapsed));
  }, [props.estimatedTotalTime]);

  useInterval(() => {
    setCurrentTime({ time: new Date() });
  }, 100);

  return (
    <div>
      <ProgressBar
        height="30px"
        labelAlignment="left"
        maxCompleted={denominator}
        completed={timeElapsed}
        customLabel={`Time Elapsed: ${timeElapsed}s / ${
          denominator == 0 ? timeElapsed : denominator
        }s`}
        bgColor="purple"
        customLabelStyles={{
          whiteSpace: 'nowrap',
          width: '400px',
          paddingLeft: '20px',
        }}
      />
    </div>
  );
}

// function EstimateTime(props) {
//   const [estimatedTimeMessage, setEstimatedTimeMessage] = useState(undefined);

//   if (estimatedTimeMessage != undefined) {
//     const estimatedTotalTime = getEstimatedTime(
//       props.vmStatus,
//       props.serverStatus,
//       props.cacheAvailability,
//       props.queuePosition,
//     );

//     if (estimatedTimeMessage != 'Calculating...') {
//       props.setEstimatedTotalTime(parseInt(estimatedTotalTime));
//     }
//   }

//   useEffect(() => {
//     if (
//       props.vmStatus == 'error' ||
//       props.queuePosition == 'error' ||
//       props.serverStatus == 'error' ||
//       props.cacheAvailability == 'error'
//     ) {
//       setEstimatedTimeMessage('error');
//       return;
//     }

//     const timeMessage = getEstimatedTimeMessage(
//       props.vmStatus,
//       props.serverStatus,
//       props.cacheAvailability,
//       props.queuePosition,
//     );

//     setEstimatedTimeMessage(timeMessage);
//   }, [
//     props.random,
//     props.queuePosition,
//     props.vmStatus,
//     props.startTime,
//     props.serverStatus,
//   ]);

//   return <div></div>;

//   // return estimatedTimeMessage == 'error' ? (
//   //   <p data-cy="advancedGenerationEstimatedTimeText">
//   //     An error has occurred, please try again later.
//   //   </p>
//   // ) : (
//   //   <p data-cy="advancedGenerationEstimatedTimeText">
//   //     Estimated time remaining: {estimatedTimeMessage}
//   //   </p>
//   // );
// }

function EstimateTime(props) {
  const [estimatedTimeMessage, setEstimatedTimeMessage] = useState(undefined);

  if (estimatedTimeMessage != undefined) {
    const estimatedTotalTime = getEstimatedTime(
      props.taskStatus,
      props.cacheAvailability,
      props.queuePosition,
    );

    if (estimatedTimeMessage != 'Calculating...') {
      props.setEstimatedTotalTime(parseInt(estimatedTotalTime));
    }
  }

  useEffect(() => {
    if (props.taskStatus == 'error' || props.cacheAvailability == 'error') {
      setEstimatedTimeMessage('error');
      return;
    }

    const timeMessage = getEstimatedTimeMessage(
      props.taskStatus,
      props.cacheAvailability,
      props.queuePosition,
    );

    setEstimatedTimeMessage(timeMessage);
  }, [props.random, props.queuePosition, props.startTime, props.taskStatus]);

  return <div></div>;

  // return estimatedTimeMessage == 'error' ? (
  //   <p data-cy="advancedGenerationEstimatedTimeText">
  //     An error has occurred, please try again later.
  //   </p>
  // ) : (
  //   <p data-cy="advancedGenerationEstimatedTimeText">
  //     Estimated time remaining: {estimatedTimeMessage}
  //   </p>
  // );
}
function getStatusMessage(
  vmStatus,
  serverStatus,
  cacheAvailability,
  queuePosition,
) {
  if (cacheAvailability == undefined) {
    return 'Retrieving Status...';
  } else if (cacheAvailability == true) {
    return 'Generating Nokémon!';
  }

  // Cache unavailable, generate by means of model
  if (vmStatus == undefined) {
    return 'Retrieving Status...';
  } else if (
    vmStatus == 'STAGING' ||
    vmStatus == 'PROVISIONING' ||
    vmStatus == 'TERMINATED' ||
    vmStatus == 'STOPPING'
  ) {
    return 'Starting up computer... (subsequent generations should take around 30 seconds)';
  }

  // VM Status MUST be available from this point
  if (serverStatus == undefined) {
    return 'Retrieving Status...';
  } else if (serverStatus == false) {
    return 'Loading the AI model... (subsequent generations will take 30 seconds)';
  }

  // Server Status MUST be available from this point

  if (queuePosition == undefined) {
    return 'Retrieving Status...';
  } else if (queuePosition > 1) {
    return 'Waiting in queue...';
  }

  // All checks passed, generation started!
  return 'Generating Nokémon!';
}

// function getEstimatedTime(
//   vmStatus,
//   serverStatus,
//   cacheAvailability,
//   queuePosition,
// ) {
//   const estimatedGenerationTime = 33.0;
//   const estimatedTimeToStopComputer = 60.0;
//   const estimatedTimeToStartComputer = 50.0;
//   const estimatedTimeToLoadModel = 90;

//   if (cacheAvailability == undefined) {
//     return 'Calculating...';
//   } else if (cacheAvailability == true) {
//     return (estimatedGenerationTime * queuePosition).toFixed(1);
//   }

//   // Cache unavailable, generate by means of model
//   if (vmStatus == undefined) {
//     return 'Calculating...';
//   } else if (
//     vmStatus == 'STAGING' ||
//     vmStatus == 'PROVISIONING' ||
//     vmStatus == 'TERMINATED'
//   ) {
//     return (
//       estimatedTimeToStartComputer +
//       estimatedTimeToLoadModel +
//       estimatedGenerationTime * queuePosition
//     ).toFixed(1);
//   } else if (vmStatus == 'STOPPING') {
//     return (
//       estimatedTimeToStopComputer +
//       estimatedTimeToStartComputer +
//       estimatedTimeToLoadModel +
//       estimatedGenerationTime * queuePosition
//     ).toFixed(1);
//   }

//   // VM Status MUST be available from this point
//   if (serverStatus == undefined) {
//     return 'Calculating...';
//   } else if (serverStatus == false) {
//     return estimatedTimeToLoadModel + estimatedGenerationTime * queuePosition;
//   }

//   // Server Status MUST be available from this point
//   if (queuePosition == undefined) {
//     return 'Calculating...';
//   } else if (queuePosition > 0) {
//     return (estimatedGenerationTime * queuePosition).toFixed(1);
//   }

//   // All checks passed, generation started!
//   return (estimatedGenerationTime * queuePosition).toFixed(1);
// }
function getEstimatedTime(taskStatus, cacheAvailability, queuePosition) {
  const estimatedGenerationTime = 10;
  const estimatedColdStartTime = 120;

  if (cacheAvailability == undefined) {
    return 'Calculating...';
  } else if (cacheAvailability == true) {
    return (estimatedGenerationTime * queuePosition).toFixed(1);
  }

  if (queuePosition > 1) {
    return estimatedGenerationTime * queuePosition;
  }

  // Cache unavailable, generate by means of model
  if (taskStatus == undefined) {
    return 'Calculating...';
  } else if (taskStatus == 'PENDING') {
    if (queuePosition == 1) {
      return (
        estimatedColdStartTime +
        estimatedGenerationTime * queuePosition
      ).toFixed(1);
    } else {
      return (estimatedGenerationTime * queuePosition).toFixed(1);
    }
  } else if (taskStatus == 'RUNNING') {
    return estimatedGenerationTime * queuePosition;
  }
  return estimatedGenerationTime;
}
function getEstimatedTimeMessage(taskStatus, cacheAvailability, queuePosition) {
  const estimatedGenerationTime = 10;
  const estimatedColdStartTime = 120;

  if (cacheAvailability == undefined) {
    return 'Calculating...';
  } else if (cacheAvailability == true) {
    return `${(estimatedGenerationTime * queuePosition).toFixed(
      1,
    )}s (${queuePosition} in queue)`;
  }

  // Cache unavailable, generate by means of model
  if (taskStatus == undefined) {
    return 'Calculating...';
  } else if (taskStatus == 'PENDING') {
    if (queuePosition == 1) {
      return `${(
        estimatedColdStartTime +
        estimatedGenerationTime * queuePosition
      ).toFixed(1)}s (${queuePosition} in queue)`;
    } else {
      return `${(estimatedGenerationTime * queuePosition).toFixed(
        1,
      )}s (${queuePosition} in queue)`;
    }
  } else if (taskStatus == 'RUNNING') {
    return `${(estimatedGenerationTime * queuePosition).toFixed(
      1,
    )}s (${queuePosition} in queue)`;
  }

  // Server Status MUST be available from this point
  if (queuePosition == undefined) {
    return 'Calculating...';
  } else if (queuePosition > 0) {
    return `${(estimatedGenerationTime * queuePosition).toFixed(
      1,
    )}s (${queuePosition} in queue)`;
  }

  // All checks passed, generation started!
  return `${(estimatedGenerationTime * queuePosition).toFixed(
    1,
  )}s (${queuePosition} in queue)`;
}
// function getEstimatedTimeMessage(
//   vmStatus,
//   serverStatus,
//   cacheAvailability,
//   queuePosition,
// ) {
//   const estimatedGenerationTime = 33.0;
//   const estimatedTimeToStopComputer = 60.0;
//   const estimatedTimeToStartComputer = 50.0;
//   const estimatedTimeToLoadModel = 90;

//   if (cacheAvailability == undefined) {
//     return 'Calculating...';
//   } else if (cacheAvailability == true) {
//     return `${(estimatedGenerationTime * queuePosition).toFixed(
//       1,
//     )}s (${queuePosition} in queue)`;
//   }

//   // Cache unavailable, generate by means of model
//   if (vmStatus == undefined) {
//     return 'Calculating...';
//   } else if (
//     vmStatus == 'STAGING' ||
//     vmStatus == 'PROVISIONING' ||
//     vmStatus == 'TERMINATED'
//   ) {
//     return `${(
//       estimatedTimeToStartComputer +
//       estimatedTimeToLoadModel +
//       estimatedGenerationTime * queuePosition
//     ).toFixed(1)}s (${queuePosition} in queue)`;
//   } else if (vmStatus == 'STOPPING') {
//     return `${(
//       estimatedTimeToStopComputer +
//       estimatedTimeToStartComputer +
//       estimatedTimeToLoadModel +
//       estimatedGenerationTime * queuePosition
//     ).toFixed(1)}s (${queuePosition} in queue)`;
//   }

//   // VM Status MUST be available from this point
//   if (serverStatus == undefined) {
//     return 'Calculating...';
//   } else if (serverStatus == false) {
//     return `${(
//       estimatedTimeToLoadModel +
//       estimatedGenerationTime * queuePosition
//     ).toFixed(1)}s (${queuePosition} in queue)`;
//   }

//   // Server Status MUST be available from this point
//   if (queuePosition == undefined) {
//     return 'Calculating...';
//   } else if (queuePosition > 0) {
//     return `${(estimatedGenerationTime * queuePosition).toFixed(
//       1,
//     )}s (${queuePosition} in queue)`;
//   }

//   // All checks passed, generation started!
//   return `${(estimatedGenerationTime * queuePosition).toFixed(
//     1,
//   )}s (${queuePosition} in queue)`;
// }

function GenerationStatusItem(props) {
  function getIconStyle(icon) {
    if (icon == 'fa-circle-notch') {
      return {
        color: 'lightblue',
      };
    } else if (icon == 'fa-circle') {
      return {
        color: 'green',
      };
    } else if (icon == 'fa-circle-xmark') {
      return {
        color: 'red',
      };
    }
  }

  return (
    <div
      className="generationStatusItem"
      data-cy="generationStatusItem"
      style={{
        display: 'flex',
        textAlign: 'left',
        width: '100%',
        color: 'black',
        display: 'flex',
        gap: '15px',
        margin: 'auto',
      }}
    >
      <FontAwesomeIcon
        className={props.icon == 'fa-circle' ? '' : 'generationIconLoader'}
        icon={`fa-solid ${props.icon}`}
        style={{
          ...getIconStyle(props.icon),
          fontSize: '18px',
          marginTop: '0px',
        }}
      />
      <p
        style={{
          color: 'black',
          marginTop: '-2px',
          height: '25px',
          fontFamily: "'Open Sans', sans-serif",
        }}
      >
        {props.text}
      </p>
    </div>
  );
}

function getGenerationStatusIcons(
  vmStatus,
  serverStatus,
  cacheAvailability,
  queuePosition,
) {
  const statusIcons = {
    'starting up computer': 'fa-circle-notch',
    'loading ai model': 'fa-circle-notch',
    'waiting in queue': 'fa-circle-notch',
    'generating nokemon': 'fa-circle-notch',
  };

  if (cacheAvailability == undefined) {
    return statusIcons;
  } else if (cacheAvailability == true) {
    statusIcons['starting up computer'] = 'fa-circle';
    statusIcons['loading ai model'] = 'fa-circle';
    statusIcons['waiting in queue'] = 'fa-circle';
    statusIcons['generating nokemon'] = 'fa-circle-notch';

    return statusIcons;
  }

  // Cache unavailable, generate by means of model
  if (vmStatus == undefined) {
    return statusIcons;
  } else if (
    vmStatus == 'STAGING' ||
    vmStatus == 'PROVISIONING' ||
    vmStatus == 'TERMINATED' ||
    vmStatus == 'STOPPING'
  ) {
    return statusIcons;
  }

  // VM Status MUST be available from this point
  if (serverStatus == undefined) {
    statusIcons['starting up computer'] = 'fa-circle';
    statusIcons['loading ai model'] = 'fa-circle-notch';
    statusIcons['waiting in queue'] = 'fa-circle-notch';
    statusIcons['generating nokemon'] = 'fa-circle-notch';

    return statusIcons;
  } else if (serverStatus == false) {
    statusIcons['starting up computer'] = 'fa-circle';
    statusIcons['loading ai model'] = 'fa-circle-notch';
    statusIcons['waiting in queue'] = 'fa-circle-notch';
    statusIcons['generating nokemon'] = 'fa-circle-notch';

    return statusIcons;
  }

  // Server Status MUST be available from this point

  if (queuePosition == undefined) {
    statusIcons['starting up computer'] = 'fa-circle';
    statusIcons['loading ai model'] = 'fa-circle';
    statusIcons['waiting in queue'] = 'fa-circle-notch';
    statusIcons['generating nokemon'] = 'fa-circle-notch';

    return statusIcons;
  } else if (queuePosition > 1) {
    statusIcons['starting up computer'] = 'fa-circle';
    statusIcons['loading ai model'] = 'fa-circle';
    statusIcons['waiting in queue'] = 'fa-circle-notch';
    statusIcons['generating nokemon'] = 'fa-circle-notch';

    return statusIcons;
  }

  statusIcons['starting up computer'] = 'fa-circle';
  statusIcons['loading ai model'] = 'fa-circle';
  statusIcons['waiting in queue'] = 'fa-circle';
  statusIcons['generating nokemon'] = 'fa-circle-notch';

  return statusIcons;

  // All checks passed, generation started!
}

function GenerationStatusList(props) {
  const statusToIcon = {
    waiting: 'fa-square',
    running: 'fa-circle-notch',
    done: 'fa-circle',
  };

  return (
    <div
      className="generationStatusList"
      data-cy="generationStatusList"
      style={{
        marginTop: '20px',
      }}
    >
      {Object.keys(props.statusInfo).map(key => (
        <GenerationStatusItem
          text={props.statusInfo[key]['message']}
          icon={statusToIcon[props.statusInfo[key]['status']]}
        />
      ))}
    </div>
  );
}

function GenerationStatus(props) {
  let [message, setMessage] = useState('Retrieving Status...');

  useEffect(() => {
    if (
      props.vmStatus == 'error' ||
      props.queuePosition == 'error' ||
      props.serverStatus == 'error'
    ) {
      setMessage('An error has occurred, please refresh or try again later.');
      return;
    }

    const statusMessage = getStatusMessage(
      props.vmStatus,
      props.serverStatus,
      props.cacheAvailability,
      props.queuePosition,
    );

    setMessage(statusMessage);
  }, [props.random, props.serverStatus, props.queuePosition, props.vmStatus]);

  return <p data-cy="advancedGenerationStatusText">Status: {message}</p>;
}

function updateNokemonHistory(fileName) {
  let nokemonHistory = JSON.parse(
    localStorage.getItem('nokemonHistory') || '[]',
  );
  if (Array.isArray(fileName))
    fileName.forEach(
      f => !nokemonHistory.includes(f) && nokemonHistory.unshift(f),
    );
  else !nokemonHistory.includes(fileName) && nokemonHistory.unshift(fileName);
  nokemonHistory = nokemonHistory.slice(0, 500);
  localStorage.setItem('nokemonHistory', JSON.stringify(nokemonHistory));
}

function get_random(list) {
  return list[Math.floor(Math.random() * list.length)];
}

// PremiumOutput.js
// function PremiumOutput(props) {
//   const [loadingState, setLoadingState] = useState(false);
//   const [outputData, setOutputData] = useState(undefined);
//   const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
//   const [generationSessionId, setGenerationSessionId] = useState(undefined);

//   const fetchData = async (endpoint, body) => {
//     const response = await fetch(endpoint, {
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//       method: 'POST',
//       body: JSON.stringify(body),
//     });

//     return response;
//   };

//   const handleResponse = async (response, outputBox) => {
//     if (response.status != 200) {
//       const text = await response.text();
//       toast.error(text);

//       setLoadingState(false);
//       outputBox.style.opacity = 1.0;
//       const submitButton = document.getElementById('submitButton');

//       if (submitButton != null) {
//         submitButton.disabled = false;
//       }
//       setIsAddButtonClicked(false);
//       enableFeedback();
//       return null;
//     }

//     props.setUserInfo({
//       ...props.userInfo,
//       currencyValue: Math.max(props.userInfo['currencyValue'] - 1, 0),
//     });
//   };

//   const updateUIAfterGeneration = fileName => {
//     setLoadingState(false);
//     props.setFileName(fileName);

//     setOutputData({
//       selected: props.selected,
//       generatedBy: props.generatedBy,
//       fileName: fileName,
//       session: props.session_id,
//       uuid: localStorage.getItem('storageId'),
//     });

//     updateNokemonHistory(fileName);

//     const outputBox = document.getElementById('outputBox');
//     outputBox.style.opacity = 1.0;

//     const submitButton = document.getElementById('submitButton');

//     if (submitButton != null) {
//       submitButton.disabled = false;
//     }
//     setIsAddButtonClicked(false);
//     enableFeedback();
//   };

//   const checkGenerationStatus = async () => {
//     if (loadingState && generationSessionId) {
//       const response = await fetch(
//         `/api/generation/advanced/getSessionEntity?generationSessionUUID=${generationSessionId}`,
//       );
//       const entity = await response.json();
//       const status = entity['status'];
//       const fileName = entity['fileName'];

//       if (status.includes('complete')) {
//         updateUIAfterGeneration(fileName);
//       }
//     }
//   };

//   useInterval(checkGenerationStatus, 3000);
//   useEffect(async () => {
//     let type1;
//     let type2;
//     let legendary;
//     let style;
//     let endpoint;
//     let body;

//     if (!props.generationButtonClicked) {
//       return;
//     }
//     const outputBox = document.getElementById('outputBox');

//     if (outputBox) {
//       outputBox.style.opacity = 0.5;
//     }

//     setLoadingState(true);

//     if (props.batchNum == undefined || props.batchNum == 1) {
//       if (props.generatedBy == 'type') {
//         type1 = props.type1;

//         if (props.type2 == 'None') {
//           type2 = type1;
//         } else {
//           type2 = props.type2;
//         }
//         legendary = props.legendary.toLowerCase();
//       } else if (props.generatedBy == 'random') {
//         type1 = get_random(TYPE_OPTIONS.filter(type => type != 'none'));
//         type2 = get_random(TYPE_OPTIONS.filter(type => type != type1));
//         legendary = get_random(['true', 'false']);

//         props.setType1(type1);
//         props.setType2(type2);
//         props.setLegendary(legendary);
//       }

//       style = props.style;

//       // Create a generation session UUID
//       const generateSessionUUID = uuidv4();
//       setGenerationSessionId(generateSessionUUID);

//       // THE PROBLEM IS HERE (TODO)
//       if (props.modelType == 'sd_v1') {
//         endpoint = '/api/generation/advanced/generate/sd_v1';

//         // Convert legendary since 0 and 1 for sd_v1

//         legendary = legendary == 'true' ? 1 : 0;

//         let optionalText;
//         let objectText;

//         if (props.optionalText == '') {
//           optionalText = '';
//         } else {
//           optionalText = props.optionalText;
//         }

//         if (props.objectText == '') {
//           objectText = '';
//         } else {
//           objectText = props.objectText;
//         }

//         let seed;
//         if (props.resetSeed) {
//           seed = Math.floor(Math.random() * Math.pow(2, 31) - 1);
//           props.setSeed(seed);
//         } else {
//           seed = props.seed;
//         }

//         body = {
//           model: props.modelType,
//           type1: type1,
//           type2: type2,
//           legendary: legendary,
//           optional_text: optionalText,
//           object_kind: objectText,
//           seed: seed,
//           cookie: localStorage.getItem('jwt'),
//           generationSessionUUID: generateSessionUUID,
//         };
//       } else {
//         endpoint = '/api/generation/advanced/generate/';
//         body = {
//           style: style,
//           type1: type1,
//           type2: type2,
//           legendary: legendary,
//           body: props.body,
//           color: props.color,
//           share: 'false',
//           cookie: localStorage.getItem('jwt'),
//           generationSessionUUID: generateSessionUUID,
//         };
//       }
//     } else {
//       if (props.modelType == 'sd_v1') {
//         const generateSessionUUID = uuidv4();
//         setGenerationSessionId(generateSessionUUID);

//         endpoint = '/api/generation/advanced/generate/sd_v1/batch';
//         body = {
//           model: props.modelType,
//           batchNum: props.batchNum,
//           cookie: localStorage.getItem('jwt'),
//           generationSessionUUID: generateSessionUUID,
//         };
//       } else {
//         // Batch
//         style = props.style;

//         // Create a generation session UUID
//         const generateSessionUUID = uuidv4();
//         setGenerationSessionId(generateSessionUUID);

//         endpoint = '/api/generation/advanced/generate/batch';
//         body = {
//           style: style,
//           cookie: localStorage.getItem('jwt'),
//           generationSessionUUID: generateSessionUUID,
//           batchNum: props.batchNum,
//         };
//       }
//     }

//     const response = await fetchData(endpoint, body);
//     handleResponse(response, outputBox);
//     props.setGenerationButtonClicked(false);
//   }, [props.random]);

//   if (props.generatedBy) {
//     return (
//       <DefaultOutput
//         modelType={props.modelType}
//         isLoggedIn={props.isLoggedIn}
//         fileName={props.fileName}
//         outputData={outputData}
//         loadingState={loadingState}
//         setLoadingState={setLoadingState}
//         isAddButtonClicked={isAddButtonClicked}
//         setFileName={props.setFileName}
//         setOutputData={setOutputData}
//         setIsAddButtonClicked={setIsAddButtonClicked}
//         isPremium={props.isPremium}
//         generateSessionUUID={generationSessionId}
//         random={props.random}
//         type1={props.type1}
//         type2={props.type2}
//         legendary={props.legendary}
//         style={props.style}
//         generatedBy={props.generatedBy}
//         batchNum={props.batchNum}
//         nokemonData={props.nokemonData}
//       />
//     );
//   } else {
//     return <div className="box"></div>; // <div className="box"></div>;
//   }
// }

// LoggedInOutput.js
function LoggedInOutput(props) {
  const [loadingState, setLoadingState] = useState(false);
  const [outputData, setOutputData] = useState(undefined);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);

  const fetchData = async (endpoint, body) => {
    const response = await fetch(endpoint, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body),
    });

    return response;
  };

  const handleResponse = async (response, outputBox) => {
    if (response.status != 200) {
      const text = await response.text();
      if (response.status != 555) {
        if (text.includes('You have exceeded')) {
          let modalSettings;
          if (props.userInfo['isSubscribed']) {
            modalSettings = {
              modalType: 'subscriptionDailyGenerationsUsed',
            };
          } else {
            modalSettings = {
              modalType: 'subscription',
            };
          }
          props.setModalSettings(modalSettings);
        }
        toast.error(text);
      }

      setLoadingState(false);
      await sleep(1000);

      outputBox.style.opacity = 1.0;
      const submitButton = document.getElementById('submitButton');

      if (submitButton != null) {
        submitButton.disabled = false;
      }
      setIsAddButtonClicked(false);
      enableFeedback();
      return null;
    }

    const data = await response.json();

    props.setFileName(data.fileName);
    setOutputData({
      selected: props.selected,
      generatedBy: props.generatedBy,
      fileName: data.fileName,
      similarity: data.similarity,
      session: props.session_id,
      uuid: localStorage.getItem('storageId'),
    });

    updateNokemonHistory(data.fileName);
    await sleep(1000 + 1000 * Math.random());

    outputBox.style.opacity = 1.0;
    const submitButton = document.getElementById('submitButton');

    if (submitButton != null) {
      submitButton.disabled = false;
    }

    setLoadingState(false);
    setIsAddButtonClicked(false);

    enableFeedback();
    props.setUserInfo({
      ...props.userInfo,
      currencyValueFree: Math.max(
        props.userInfo['currencyValueFree'] - parseInt(props.batchNum),
        0,
      ),
    });
  };

  useEffect(() => {
    async function fetchDataAndHandleResponse() {
      if (!props.generationButtonClicked) {
        return;
      }
      const outputBox = document.getElementById('outputBox');

      if (outputBox) {
        outputBox.style.opacity = 0.5;
      }

      setLoadingState(true);

      let endpoint = '';
      let body = {};

      if (props.generatedBy === 'type') {
        endpoint = '/api/generation/basic/new/loggedIn/fakemon/type';
        body = {
          modelType: props.modelType,
          type1: props.selected,
          style: props.style,
          nokemonHistory: localStorage.getItem('nokemonHistory'),
          cookie: localStorage.getItem('jwt'),
          batchNum: props.batchNum,
        };
      } else if (props.generatedBy === 'color') {
        endpoint = '/api/generation/basic/new/loggedIn/fakemon/color';
        body = {
          modelType: props.modelType,
          color: props.selected,
          nokemonHistory: localStorage.getItem('nokemonHistory'),
          cookie: localStorage.getItem('jwt'),
          batchNum: props.batchNum,
        };
      } else if (props.generatedBy === 'random') {
        endpoint = '/api/generation/basic/new/loggedIn/fakemon/random';
        body = {
          modelType: props.modelType,
          type1: props.selected,
          style: props.style,
          nokemonHistory: localStorage.getItem('nokemonHistory'),
          cookie: localStorage.getItem('jwt'),
          batchNum: props.batchNum,
        };
      }

      try {
        const response = await fetchData(endpoint, body);
        handleResponse(response, outputBox);
      } catch (error) {
        // Handle or log the error
        console.error('Error fetching data: ', error);
      } finally {
        props.setGenerationButtonClicked(false);
      }
    }

    fetchDataAndHandleResponse();
  }, [props.random]);

  if (props.generatedBy) {
    return (
      <DefaultOutput
        isLoggedIn={props.isLoggedIn}
        fileName={props.fileName}
        outputData={outputData}
        loadingState={loadingState}
        setLoadingState={setLoadingState}
        isAddButtonClicked={isAddButtonClicked}
        setFileName={props.setFileName}
        setOutputData={setOutputData}
        setIsAddButtonClicked={setIsAddButtonClicked}
        isPremium={props.isPremium}
        random={props.random}
        type1={props.type1}
        type2={props.type2}
        legendary={props.legendary}
        style={props.style}
        generatedBy={props.generatedBy}
        batchNum={props.batchNum}
        beamTaskId={props.beamTaskId}
      />
    );
  } else {
    return <div className="box"></div>; // <div className="box"></div>;
  }
}
// LoggedOutOutput.js
function LoggedOutOutput(props) {
  const [loadingState, setLoadingState] = useState(false);
  const [outputData, setOutputData] = useState(undefined);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);

  const fetchData = async (endpoint, body) => {
    const response = await fetch(endpoint, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-token': props.uniqueIdentifier,
      },
      method: 'POST',
      body: JSON.stringify(body),
    });

    return response;
  };

  const handleResponse = async (
    response,
    outputBox,
    IOContext,
    userContext,
  ) => {
    if (response.status !== 200) {
      const text = await response.text();

      if (response.status === 429) {
        props.setModalSettings({ modalType: 'loginPrompt' });
        toast.error(
          'Daily generation limit reached. Please log in to generate more!',
        );
      } else if (response.status !== 555) {
        toast.error(text);
      }

      setLoadingState(false);
      await sleep(1000);

      outputBox.style.opacity = 1.0;
      const submitButton = document.getElementById('submitButton');

      if (submitButton !== null) {
        submitButton.disabled = false;
      }
      setIsAddButtonClicked(false);
      enableFeedback();
      return null;
    }

    const data = await response.json();
    props.setFileName(data.fileName);
    setOutputData({
      selected: props.selected,
      generatedBy: props.generatedBy,
      fileName: data.fileName,
      similarity: data.similarity,
      session: props.session_id,
      uuid: localStorage.getItem('storageId'),
    });

    updateNokemonHistory(data.fileName);
    await sleep(1000 + 1000 * Math.random());

    outputBox.style.opacity = 1.0;
    const submitButton = document.getElementById('submitButton');

    if (submitButton !== null) {
      submitButton.disabled = false;
    }

    setLoadingState(false);
    setIsAddButtonClicked(false);

    enableFeedback();
  };

  useEffect(() => {
    async function fetchDataAndHandleResponse() {
      if (!props.generationButtonClicked) {
        return;
      }
      const outputBox = document.getElementById('outputBox');

      if (outputBox) {
        outputBox.style.opacity = 0.5;
      }

      setLoadingState(true);

      let endpoint = '';
      let body = {};

      if (props.generatedBy === 'type') {
        endpoint = '/api/generation/basic/fakemon/type';
        body = {
          model: props.modelType,
          type1: props.selected,
          style: props.style,
          nokemonHistory: localStorage.getItem('nokemonHistory'),
          cookie: localStorage.getItem('jwt'),
          sessionId: localStorage.getItem('storageId'),
          referralCode: localStorage.getItem('referralCode'),
        };
      } else if (props.generatedBy === 'random') {
        endpoint = '/api/generation/basic/fakemon/random';
        body = {
          modelType: props.modelType,
          nokemonHistory: localStorage.getItem('nokemonHistory'),
          cookie: localStorage.getItem('jwt'),
          style: props.style,
          referralCode: localStorage.getItem('referralCode'),
        };
      } else if (props.generatedBy === 'color') {
        endpoint = '/api/generation/basic/fakemon/color';
        body = {
          model: props.modelType,
          style: props.style,
          color: props.selected,
          nokemonHistory: localStorage.getItem('nokemonHistory'),
          cookie: localStorage.getItem('jwt'),
          referralCode: localStorage.getItem('referralCode'),
        };
      }

      try {
        const response = await fetchData(endpoint, body);
        handleResponse(response, outputBox);
      } catch (error) {
        // Handle or log the error
        console.error('Error fetching data: ', error);
      } finally {
        props.setGenerationButtonClicked(false);
      }
    }

    fetchDataAndHandleResponse();
  }, [props.random]);

  if (props.generatedBy) {
    return (
      <DefaultOutput
        isLoggedIn={props.isLoggedIn}
        fileName={props.fileName}
        outputData={outputData}
        loadingState={loadingState}
        setLoadingState={setLoadingState}
        isAddButtonClicked={isAddButtonClicked}
        setFileName={props.setFileName}
        setOutputData={setOutputData}
        setIsAddButtonClicked={setIsAddButtonClicked}
        isPremium={props.isPremium}
        random={props.random}
        type1={props.type1}
        type2={props.type2}
        legendary={props.legendary}
        style={props.style}
        generatedBy={props.generatedBy}
        batchNum={props.batchNum}
      />
    );
  } else {
    return <div className="box"></div>; // <div className="box"></div>;
  }
}

function Output(props) {
  if (props.isPremium) {
    return <PremiumOutput {...props} />;
  } else if (props.isLoggedIn) {
    return <LoggedInOutput {...props} />;
  } else {
    return <LoggedOutOutput {...props} />;
  }
}

// function Output(props) {
//   const [loadingState, setLoadingState] = useState(false);
//   // const [fileName, setFileName] = useState(undefined);
//   const [similarity, setSimilarity] = useState(undefined);
//   const [outputData, setOutputData] = useState(undefined);
//   const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
//   const [generationSessionId, setGenerationSessionId] = useState(undefined);

//   useInterval(async () => {
//     if (props.isPremium && loadingState && generationSessionId) {
//       fetch(
//         `/api/generation/advanced/getSessionEntity?generationSessionUUID=${generationSessionId}`,
//       )
//         .then(res => res.json())
//         .then(entity => {
//           const status = entity['status'];
//           const fileName = entity['fileName'];

//           if (status.includes('complete')) {
//             setLoadingState(false);
//             props.setFileName(fileName);

//             setOutputData({
//               selected: props.selected,
//               generatedBy: props.generatedBy,
//               fileName: fileName,
//               session: props.session_id,
//               uuid: localStorage.getItem('storageId'),
//             });

//             updateNokemonHistory(fileName);

//             const outputBox = document.getElementById('outputBox');
//             outputBox.style.opacity = 1.0;

//             const submitButton = document.getElementById('submitButton');

//             if (submitButton != null) {
//               submitButton.disabled = false;
//             }
//             setIsAddButtonClicked(false);
//             enableFeedback();
//           }
//         })
//         .catch(e => {});
//     }
//   }, 3000);

//   useEffect(async () => {
//     const outputBox = document.getElementById('outputBox');

//     if (outputBox) {
//       outputBox.style.opacity = 0.5;
//     }

//     setLoadingState(true);

//     if (props.isPremium) {
//       const fetchData = async (endpoint, body) => {
//         const response = await fetch(endpoint, {
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//           },
//           method: 'POST',
//           body: JSON.stringify(body),
//         });

//         return response;
//       };

//       const handleResponse = async (response, outputBox) => {
//         if (response.status != 200) {
//           const text = await response.text();
//           toast.error(text);

//           setLoadingState(false);
//           outputBox.style.opacity = 1.0;
//           const submitButton = document.getElementById('submitButton');

//           if (submitButton != null) {
//             submitButton.disabled = false;
//           }
//           setIsAddButtonClicked(false);
//           enableFeedback();
//           return null;
//         }

//         props.setUserInfo({
//           ...props.userInfo,
//           currencyValue: Math.max(props.userInfo['currencyValue'] - 1, 0),
//         });
//       };
//       let type1;
//       let type2;
//       let legendary;
//       let style;
//       let endpoint;
//       let body;

//       if (props.batchNum == undefined || props.batchNum == 1) {
//         if (props.generatedBy == 'type') {
//           type1 = props.type1;
//           type2 = props.type2;
//           legendary = props.legendary;
//         } else if (props.generatedBy == 'random') {
//           type1 = get_random(TYPE_OPTIONS.filter(type => type != 'none'));
//           type2 = get_random(TYPE_OPTIONS.filter(type => type != type1));
//           legendary = get_random(['true', 'false']);

//           props.setType1(type1);
//           props.setType2(type2);
//           props.setLegendary(legendary);
//         }

//         style = props.style;

//         // Create a generation session UUID
//         const generateSessionUUID = uuidv4();
//         setGenerationSessionId(generateSessionUUID);

//         endpoint = '/api/generation/advanced/generate/';
//         body = {
//           style: style,
//           type1: type1,
//           type2: type2,
//           legendary: legendary,
//           body: props.body,
//           color: props.color,
//           share: 'false',
//           cookie: localStorage.getItem('jwt'),
//           generationSessionUUID: generateSessionUUID,
//         };
//       } else {
//         // Batch
//         style = props.style;

//         // Create a generation session UUID
//         const generateSessionUUID = uuidv4();
//         setGenerationSessionId(generateSessionUUID);

//         endpoint = '/api/generation/advanced/generate/batch';
//         body = {
//           style: style,
//           cookie: localStorage.getItem('jwt'),
//           generationSessionUUID: generateSessionUUID,
//           batchNum: props.batchNum,
//         };
//       }

//       const response = await fetchData(endpoint, body);
//       handleResponse(response);
//     } else {
//       // Deal with logged in basic generation
//       if (props.isLoggedIn) {
//         const fetchData = async (endpoint, body) => {
//           const response = await fetch(endpoint, {
//             headers: {
//               Accept: 'application/json',
//               'Content-Type': 'application/json',
//             },
//             method: 'POST',
//             body: JSON.stringify(body),
//           });

//           return response;
//         };

//         const handleResponse = async (response, outputBox) => {
//           if (response.status != 200) {
//             const text = await response.text();
//             if (response.status != 555) {
//               toast.error(text);
//             }

//             setLoadingState(false);
//             await sleep(1000);

//             outputBox.style.opacity = 1.0;
//             const submitButton = document.getElementById('submitButton');

//             if (submitButton != null) {
//               submitButton.disabled = false;
//             }
//             setIsAddButtonClicked(false);
//             enableFeedback();
//             return null;
//           }

//           const data = await response.json();

//           props.setFileName(data.fileName);
//           setOutputData({
//             selected: props.selected,
//             generatedBy: props.generatedBy,
//             fileName: data.fileName,
//             similarity: data.similarity,
//             session: props.session_id,
//             uuid: localStorage.getItem('storageId'),
//           });

//           updateNokemonHistory(data.fileName);
//           await sleep(1000 + 1000 * Math.random());

//           outputBox.style.opacity = 1.0;
//           const submitButton = document.getElementById('submitButton');

//           if (submitButton != null) {
//             submitButton.disabled = false;
//           }

//           setLoadingState(false);
//           setIsAddButtonClicked(false);

//           enableFeedback();
//           props.setUserInfo({
//             ...props.userInfo,
//             currencyValueFree: Math.max(
//               props.userInfo['currencyValueFree'] - parseInt(props.batchNum),
//               0,
//             ),
//           });
//         };
//         let endpoint = '';
//         let body = {};

//         if (props.generatedBy == 'type') {
//           endpoint = '/api/generation/basic/loggedIn/fakemon/type';
//           body = {
//             modelType: props.modelType,
//             type1: props.selected,
//             style: props.style,
//             nokemonHistory: localStorage.getItem('nokemonHistory'),
//             cookie: localStorage.getItem('jwt'),
//             batchNum: props.batchNum,
//           };
//         } else if (props.generatedBy == 'color') {
//           endpoint = '/api/generation/basic/loggedIn/fakemon/color';
//           body = {
//             modelType: props.modelType,
//             color: props.selected,
//             nokemonHistory: localStorage.getItem('nokemonHistory'),
//             cookie: localStorage.getItem('jwt'),
//             batchNum: props.batchNum,
//           };
//         } else if (props.generatedBy == 'random') {
//           endpoint = '/api/generation/basic/loggedIn/fakemon/random';
//           body = {
//             modelType: props.modelType,
//             type1: props.selected,
//             style: props.style,
//             nokemonHistory: localStorage.getItem('nokemonHistory'),
//             cookie: localStorage.getItem('jwt'),
//             batchNum: props.batchNum,
//           };
//         }

//         const response = await fetchData(endpoint, body);
//         handleResponse(response, outputBox);
//       } else {
//         const fetchData = async (endpoint, body) => {
//           const response = await fetch(endpoint, {
//             headers: {
//               Accept: 'application/json',
//               'Content-Type': 'application/json',
//               'x-token': props.uniqueIdentifier,
//             },
//             method: 'POST',
//             body: JSON.stringify(body),
//           });

//           return response;
//         };

//         const handleResponse = async (response, outputBox) => {
//           if (response.status != 200) {
//             const text = await response.text();

//             if (response.status == 429) {
//               props.setModalSettings({ modalType: 'loginPrompt' });
//               toast.error(
//                 'Daily generation limit reached. Please log in to generate more!',
//               );
//             } else if (response.status != 555) {
//               toast.error(text);
//             }

//             setLoadingState(false);
//             await sleep(1000);

//             outputBox.style.opacity = 1.0;
//             const submitButton = document.getElementById('submitButton');

//             if (submitButton != null) {
//               submitButton.disabled = false;
//             }
//             setIsAddButtonClicked(false);
//             enableFeedback();
//             return null;
//           }

//           const data = await response.json();

//           props.setFileName(data.fileName);
//           setOutputData({
//             selected: props.selected,
//             generatedBy: props.generatedBy,
//             fileName: data.fileName,
//             similarity: data.similarity,
//             session: props.session_id,
//             uuid: localStorage.getItem('storageId'),
//           });

//           updateNokemonHistory(data.fileName);
//           await sleep(1000 + 1000 * Math.random());

//           outputBox.style.opacity = 1.0;
//           const submitButton = document.getElementById('submitButton');

//           if (submitButton != null) {
//             submitButton.disabled = false;
//           }

//           setLoadingState(false);
//           setIsAddButtonClicked(false);

//           enableFeedback();
//         };

//         let endpoint = '';
//         let body = {};

//         if (props.generatedBy == 'type') {
//           endpoint = '/api/generation/basic/fakemon/type';
//           body = {
//             type1: props.selected,
//             style: props.style,
//             nokemonHistory: localStorage.getItem('nokemonHistory'),
//             cookie: localStorage.getItem('jwt'),
//             sessionId: localStorage.getItem('storageId'),
//           };
//         } else if (props.generatedBy == 'pokemon') {
//           endpoint = '/api/fakemon/pokemon';
//           body = {
//             pokemonName: props.selected,
//             nokemonHistory: localStorage.getItem('nokemonHistory'),
//             style: props.style,
//             cookie: localStorage.getItem('jwt'),
//           };
//         } else if (props.generatedBy == 'random') {
//           endpoint = '/api/generation/basic/fakemon/random';
//           body = {
//             nokemonHistory: localStorage.getItem('nokemonHistory'),
//             cookie: localStorage.getItem('jwt'),
//             style: props.style,
//           };
//         } else if (props.generatedBy == 'color') {
//           endpoint = '/api/generation/basic/fakemon/color';
//           body = {
//             style: props.style,
//             color: props.selected,
//             nokemonHistory: localStorage.getItem('nokemonHistory'),
//             cookie: localStorage.getItem('jwt'),
//           };
//         }

//         const response = await fetchData(endpoint, body);
//         handleResponse(response, outputBox);
//       }
//     }
//   }, [props.random]);

//   if (props.generatedBy) {
//     return (
//       <DefaultOutput
//         isLoggedIn={props.isLoggedIn}
//         fileName={props.fileName}
//         outputData={outputData}
//         similarity={similarity}
//         loadingState={loadingState}
//         setLoadingState={setLoadingState}
//         isAddButtonClicked={isAddButtonClicked}
//         setFileName={props.setFileName}
//         setOutputData={setOutputData}
//         setSimilarity={setSimilarity}
//         setIsAddButtonClicked={setIsAddButtonClicked}
//         isPremium={props.isPremium}
//         generateSessionUUID={generationSessionId}
//         random={props.random}
//         type1={props.type1}
//         type2={props.type2}
//         legendary={props.legendary}
//         style={props.style}
//         generatedBy={props.generatedBy}
//         batchNum={props.batchNum}
//       />
//     );
//   } else {
//     return <div className="box"></div>; // <div className="box"></div>;
//   }
// }

export { Output };
