import { v4 as uuidv4 } from 'uuid';

export async function setSessionId() {
  const token = localStorage.getItem('jwt');
  const session = uuidv4();

  await fetch(`/api/user/sessionId/set?sessionId=${session}`, {
    headers: {
      Authorization: token,
    },
  });

  return session;
}

// getToken.js
export function getToken() {
  const token = localStorage.getItem('jwt');
  return token && token !== 'null' ? token : null;
}

// fetchUserInfo.js
export async function fetchUserInfo(token) {
  const response = await fetch('/api/user/userInfo/get', {
    headers: { Authorization: token },
  });

  if (!response.ok) {
    throw new Error(`Error fetching user info: ${response.statusText}`);
  }

  return response.json();
}

// processUserInfo.js
export function processUserInfo(entity) {
  const infoFields = [
    'name',
    'picture',
    'proStatus',
    'subscriptionTier',
    'currencyValue',
    'currencyCompetitionValue',
    'currencyValueFree',
    'currencyValueFreeRetro',
    'numFavourites',
    'numPremiumNokemon',
    'isSubscribed',
    'endSubscriptionTime',
    'referralCode',
    'referralRewards',
    'numSessions',
    'hasPromoCodePopup',
    'emailHash',
  ];

  const userInfo = {};

  infoFields.forEach(field => {
    userInfo[field] = field in entity ? entity[field] : 0;
  });

  // Additional sources of 'currency' need to be appended.
  userInfo['currencyValue'] += userInfo['currencyCompetitionValue'];

  if ('referralRewards' in userInfo && userInfo['referralRewards']) {
    if ('dailyGenerations' in userInfo['referralRewards']) {
      userInfo['currencyValueFree'] +=
        userInfo['referralRewards']['dailyGenerations'];
    }

    if ('nokepoints' in userInfo['referralRewards']) {
      userInfo['currencyValue'] += userInfo['referralRewards']['nokepoints'];
    }
  }

  return userInfo;
}

// getUserInfo.js
export async function getUserInfo() {
  try {
    const token = getToken();
    if (!token) return {};

    const data = await fetchUserInfo(token);
    return processUserInfo(data.entity);
  } catch (error) {
    console.error(error.message);
    return {};
  }
}

// export async function getUserInfo() {
//   const token = localStorage.getItem('jwt');

//   if (token == 'null') {
//     return 0;
//   }

//   const res = await fetch('/api/user/userInfo/get', {
//     headers: { Authorization: token },
//   });

//   if (res.status != 200) {
//     return {};
//   }

//   const body = await res.json();
//   const infoFields = [
//     'name',
//     'picture',
//     'proStatus',
//     'subscriptionTier',
//     'currencyValue',
//     'currencyCompetitionValue',
//     'currencyValueFree',
//     'numFavourites',
//     'numPremiumNokemon',
//     'isSubscribed',
//     'endSubscriptionTime',
//     'referralCode',
//   ];

//   const userInfo = {};

//   for (const info of infoFields) {
//     if (info in body['entity']) {
//       userInfo[info] = body['entity'][info];
//     } else {
//       userInfo[info] = 0;
//     }
//   }

//   userInfo['currencyValue'] =
//     userInfo['currencyValue'] + userInfo['currencyCompetitionValue'];

//   return userInfo;
// }

export async function getSubscriptionTier() {
  const token = localStorage.getItem('jwt');

  if (token == 'null') {
    return 0;
  }

  const res = await fetch('/api/user/subscriptionTier/get', {
    headers: { Authorization: token },
  });

  if (res.status != 200) {
    return 0;
  }

  const body = await res.json();

  if ('subscriptionTier' in body) {
    return parseInt(body['subscriptionTier']);
  }

  return 0;
}

export async function getUserProStatus() {
  const token = localStorage.getItem('jwt');

  if (token == 'null') {
    return 0;
  }

  const res = await fetch('/api/user/proStatus/get', {
    headers: { Authorization: token },
  });

  if (res.status != 200) {
    return 0;
  }

  const body = await res.json();

  if ('proStatus' in body) {
    return body['proStatus'];
  }

  return 0;
}

export async function getUserName() {
  const token = localStorage.getItem('jwt');

  if (token == 'null') {
    return;
  }

  const res = await fetch('/api/user/name/get', {
    headers: { Authorization: token },
  });

  if (res.status != 200) {
    return;
  }

  const body = await res.json();

  if ('name' in body) {
    return body['name'];
  }

  return;
}

export async function checkLogin() {
  const token = localStorage.getItem('jwt');

  if (token == null) {
    return false;
  }

  const response = await fetch('/check-token-valid', {
    headers: {
      Authorization: token,
    },
  });

  if (response.status != 200) {
    return false;
  } else {
    return true;
  }
}
