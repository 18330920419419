import React, { useState, useRef, useEffect } from 'react';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import {
  FaImage,
  FaHistory,
  FaInfoCircle,
  FaUserCircle,
  FaDollarSign,
  FaTwitter,
  FaSignOutAlt,
  FaBars,
  FaCrown,
  FaGithub,
  FaGem,
  FaRegCreditCard,
  FaSignInAlt,
  FaUserPlus,
  FaGoogle,
  FaWrench,
  FaStore,
} from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import 'react-pro-sidebar/dist/css/styles.css';
import Logo from '../../../nokemon-logo.png';
import GoogleLogoIcon from '../../../google-logo.png';
import { GoogleLoginIcon } from '../../GoogleLogin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import LoginModal from '../../LoginModal/LoginModal';
import { recordUserInteraction } from '../../../utils/userInteractionsLogger/userInteractionLogger';

const GlobalStyle = createGlobalStyle`
  body {
    transition: padding-left 0.25s;
    font-family: 'Poppins', sans-serif;
    @media only screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
`;

const MenuHeader = styled.p`
  color: #adb5bd;
  margin-top: 20px;
  padding-left: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
`;

const StyledProSidebar = styled(ProSidebar)`
  background: #15171c;
  color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: ${props => (props.collapsed ? '80px' : '250px')};
  transition: all 0.25s ease;
  z-index: 9999;

  @media only screen and (max-width: 768px) {
    width: ${props => (props.collapsed ? '0' : '250px')};
    margin-left: ${props => (props.collapsed ? '-100%' : '0')};
  }

  .pro-sidebar-inner {
    background: linear-gradient(180deg, #121212 0%, #121212 100%);
  }

  .pro-menu-item.active {
    background-color: #4d7cfe;
  }

  .pro-menu-item.active .pro-icon-wrapper {
    color: white;
  }
`;

const StyledToggleButton = styled(FaBars)`
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5em;
  position: fixed;
  top: 19px;
  left: ${props => (props.collapsed ? '15px' : '235px')};
  transition: left 0.25s ease;
  cursor: pointer;
  z-index: 9999;
  display: none;

  &:hover {
    color: #6495ed;
  }

  @media only screen and (max-width: 768px) {
    position: absolute;
    display: inline;
    margin-top: 6px;
  }
`;

const StyledMenu = styled(Menu)`
  border-top: 1px solid #2c2d33;
`;

const StyledMenuItem = styled(MenuItem)`
  width: 2000px !important;
  &:hover {
    background: #1e2025;
    color: white;
  }
  transition: background-color 0.25s, color 0.25s;
`;

const CollapsibleMenuHeader = styled(MenuHeader)`
  width: 2000px !important;
  display: ${props => (props.collapsed ? 'none' : 'block')};
`;

const CollapsibleText = styled.span`
  display: ${props => (props.collapsed ? 'none' : 'inline')};
  margin-left: 10px;
`;

const StyledSubMenu = styled(SubMenu)`
  padding: 0;
  margin: 0;

  width: 275px !important;

  &:hover {
    background: #1e2025;
  }

  .pro-inner-list-item {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;

    // Reset padding and margin for ul
    ul {
      padding-left: 0;
      margin: 0;
    }

    // Reset padding and margin for li
    li {
      padding: 0;
      margin: 0;
    }

    .pro-inner-item {
      padding: 0;
      margin: 0;
      &:hover {
        background: #1e2025;
      }
    }
  }
`;
// Updated FooterTextLink styling
const FooterTextLink = styled(NavLink)`
  color: #a0a0c2; // A neutral color for the text that stands out against the dark background
  font-size: 0.85em; // Slightly smaller than the main button to keep the hierarchy
  font-weight: 500; // Medium weight for better readability
  text-decoration: none; // No underlines to keep it clean
  margin: 0 10px; // Horizontal spacing
  padding: 10px 0; // Vertical padding for a larger clickable area
  transition: color 0.25s ease; // Smooth transition for hover effect
  white-space: nowrap; // Prevents the text from wrapping

  &:hover,
  &:focus {
    color: #ffffff; // Bright color for hover/focus to indicate interactivity
    text-decoration: underline; // Underline on hover for a clear indication
  }
`;
const SidebarFooterLinksContainer = styled.div`
  display: flex;
  justify-content: center; // Center the links horizontally
  align-items: center;
  padding: 10px 20px; // Consistent padding with the rest of the sidebar
  gap: 20px; // Space between the terms and privacy policy links

  // Full width for when sidebar is expanded
  width: 100%; // Ensures container takes full width of expanded sidebar

  // If the sidebar is collapsed, maintain the layout without resizing
  .pro-sidebar.collapsed & {
    justify-content: flex-start;
    padding-left: 75px; // Adjust this value to match the width of the collapsed sidebar's content area
  }

  @media (max-width: 768px) {
    padding: 10px; // Adjust padding for
    `;

const FooterLink = styled(Link)`
  color: #c0c0c0; // A lighter grey color for the text for better readability
  display: flex;
  align-items: center;
  justify-content: center; // Center content for both states
  padding: 12px 20px; // Consistent padding regardless of the state
  font-size: 0.8em; // Font size is consistent in both states
  font-weight: bold;
  text-decoration: none;
  background: #25262b; // A slightly lighter background to create a "button" effect
  border-radius: 5px; // Consistent border-radius for both states
  margin: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); // Subtle shadow for depth
  cursor: pointer; // Change cursor to indicate the link is clickable
  width: auto; // The width is auto for both states
  overflow: hidden; // Prevent textfrom overflowing when collapsed

  &:hover {
    color: #ffffff; // White color on hover for emphasis
    background: #303136; // A hover background color for feedback
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3); // Slightly more pronounced shadow on hover
  }

  // Adjust the icon size and margin for both states
  & > svg {
    font-size: 1em; // Consistent icon size
    margin-right: 8px; // Consistent spacing next to the icon
  }
`;

const FooterIcon = styled(FaWrench)`
  margin-right: 8px; // Space between the icon and the text
  font-size: 1.2em; // Slightly larger icon size for visibility
`;

const SidebarFooterStyle = styled(SidebarFooter)`
  border-top: 1px solid #2c2d33;
  background: #1e2025;
  transition: background-color 0.25s, color 0.25s;

  .view-code {
    color: #4d7cfe;
    text-align: center;
    padding: 20px;
    display: block;
    transition: color 0.25s;
    &:hover {
      color: #6495ed;
    }
  }

  background: #1e2025; // Adjusted to match your UI's footer color

  padding: 0; // Remove padding to allow full-width footer link
  background: transparent; // Make background transparent to fit the rest of the sidebar
  width: 100%;
  white-space: nowrap; // Prevents the text from wrapping

  display: ${({ collapsed }) =>
    collapsed ? 'none' : 'flex'}; // Hide the footer content when collapsed
  justify-content: center;
  align-items: center;
  background: #1e2025; // Adjusted to match your UI's footer color
  border-top: 1px solid #2c2d33;
  flex-direction: column;
`;

const NewTag = styled.span`
  display: inline-block;
  background-color: #9c27b0; /* Green background for the new tag */
  color: white; /* White text color */
  padding: 2px 8px; /* Padding inside the tag */
  border-radius: 10px; /* Rounded corners for the tag */
  font-size: 0.75em; /* Smaller font size */
  margin-left: 10px; /* Space between the tag and the menu item text */
  font-weight: bold; /* Bold font weight */
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3); /* Optional: shadow for depth */
`;

const OldGeneratorLink = styled.div`
  color: #adb5bd; // Adjust color to fit your theme
  padding: 20px;
  text-align: center;
  font-size: 0.9em;
  cursor: pointer;
  &:hover {
    color: #4d7cfe; // Highlight color on hover
  }
`;

const Sidebar = ({ userContext, handleUserLogout }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalCenterVisible, setModalCenterVisible] = useState(true);

  const sidebarRef = useRef(null); // Ref for the sidebar

  // Handler for outside click
  const handleOutsideClick = event => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setCollapsed(true);
    }
  };

  const handleLoginClick = () => {
    setModalVisible(true);
    setModalCenterVisible(true);
  };

  const handleLoading = () => {
    setModalCenterVisible(false);
  };

  const handleCloseModal = () => {
    setModalCenterVisible(false);
    setModalVisible(false);
  };
  const handleManageSubscription = async () => {
    toast(
      <div>
        <FontAwesomeIcon icon={faSpinner} spin /> &nbsp;Redirecting to
        Subscription Manangement...
      </div>,
    );
    const res = await fetch('/api/stripe/portalSession', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        cookie: localStorage.getItem('jwt'),
      }),
    });

    if (res.status !== 303) {
      toast.error('Failed to redirect, try again later!');
      return;
    }

    const body = await res.json();
    window.location.href = body.portalSessionUrl;
  };

  // Effect to add/remove outside click listener
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleLinkClick = actionDescription => {
    if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
      recordUserInteraction(
        userContext.userInfo.emailHash,
        'Click Sidebar Link',
        {
          specificAction: actionDescription,
          timestamp: Date.now(),
        },
      );
    }
  };

  const renderLoggedInMenu = () => (
    <StyledMenu iconShape="square">
      <CollapsibleMenuHeader collapsed={collapsed}>
        Creation & Learning
      </CollapsibleMenuHeader>
      <StyledMenuItem
        icon={<FaImage />}
        onClick={() => handleLinkClick('Create Fakemon')}
      >
        <Link to="/">
          <CollapsibleText collapsed={collapsed}>
            Create Fakemon
          </CollapsibleText>
        </Link>
      </StyledMenuItem>
      <StyledMenuItem
        icon={<FaHistory />}
        onClick={() => handleLinkClick('Gallery')}
      >
        <Link to="/storage">
          <CollapsibleText collapsed={collapsed}>Gallery</CollapsibleText>
        </Link>
      </StyledMenuItem>
      <StyledMenuItem
        icon={<FaInfoCircle />}
        onClick={() => handleLinkClick('Learn More')}
      >
        <Link to="/information">
          <CollapsibleText collapsed={collapsed}>Learn More</CollapsibleText>
        </Link>
      </StyledMenuItem>

      <CollapsibleMenuHeader collapsed={collapsed}>
        Community & Account
      </CollapsibleMenuHeader>
      <StyledSubMenu
        title={
          <CollapsibleText collapsed={collapsed}>
            Profile & Settings
          </CollapsibleText>
        }
        icon={<FaUserCircle />}
        onClick={() => handleLinkClick('Profile & Settings')}
      >
        <MenuItem
          icon={<FaRegCreditCard />}
          onClick={() =>
            handleLinkClick(
              userContext.userInfo && userContext.userInfo.subscriptionTier > 0
                ? 'Manage Subscription'
                : 'Want to subscribe?',
            )
          }
        >
          {userContext.userInfo && userContext.userInfo.subscriptionTier > 0 ? (
            <div onClick={handleManageSubscription}>Manage Subscription</div>
          ) : (
            <Link to="/marketplace">Want to subscribe?</Link>
          )}
        </MenuItem>
      </StyledSubMenu>

      <StyledMenuItem
        icon={<FaStore />}
        onClick={() => handleLinkClick('Marketplace')}
      >
        <Link to="/marketplace">
          <CollapsibleText collapsed={collapsed}>Marketplace</CollapsibleText>
        </Link>
      </StyledMenuItem>

      <StyledMenuItem
        icon={<FaCrown />}
        onClick={() => handleLinkClick('Rewards')}
      >
        <Link to="/referral">
          <CollapsibleText collapsed={collapsed}>
            Rewards <NewTag>New</NewTag>
          </CollapsibleText>
        </Link>
      </StyledMenuItem>

      <StyledMenuItem
        icon={<FaTwitter />}
        onClick={() => handleLinkClick('Twitter')}
      >
        <a
          href="https://twitter.com/NokemonAI"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CollapsibleText collapsed={collapsed}>Twitter</CollapsibleText>
        </a>
      </StyledMenuItem>

      <StyledMenuItem
        icon={<FaSignOutAlt />}
        onClick={() => {
          handleLinkClick('Sign Out');
          handleUserLogout();
        }}
      >
        <Link to="/logout">
          <CollapsibleText collapsed={collapsed}>Sign Out</CollapsibleText>
        </Link>
      </StyledMenuItem>
    </StyledMenu>
  );

  // Function to render the menu items for logged-out users
  const renderLoggedOutMenu = () => (
    <StyledMenu iconShape="square">
      <StyledMenuItem
        icon={<img src={GoogleLogoIcon} style={{ width: '20px' }} />}
        onClick={handleLoginClick}
      >
        <CollapsibleText collapsed={collapsed}>
          Log in with Google
        </CollapsibleText>
      </StyledMenuItem>
      <CollapsibleMenuHeader collapsed={collapsed}>
        Creation & Learning
      </CollapsibleMenuHeader>
      <StyledMenuItem icon={<FaImage />}>
        <Link to="/">
          <CollapsibleText collapsed={collapsed}>
            Create Fakemon
          </CollapsibleText>
        </Link>
      </StyledMenuItem>
      <StyledMenuItem icon={<FaHistory />}>
        <Link to="/storage">
          <CollapsibleText collapsed={collapsed}>Gallery</CollapsibleText>
        </Link>
      </StyledMenuItem>
      <StyledMenuItem icon={<FaInfoCircle />}>
        <Link to="/information">
          <CollapsibleText collapsed={collapsed}>Learn More</CollapsibleText>
        </Link>
      </StyledMenuItem>
      <CollapsibleMenuHeader collapsed={collapsed}>
        Community
      </CollapsibleMenuHeader>
      <StyledMenuItem icon={<FaStore />}>
        <Link to="/marketplace">
          <CollapsibleText collapsed={collapsed}>Marketplace</CollapsibleText>
        </Link>
      </StyledMenuItem>
      <StyledMenuItem icon={<FaTwitter />}>
        <a href="https://twitter.com/NokemonAI" target="_blank">
          <CollapsibleText collapsed={collapsed}>Twitter</CollapsibleText>
        </a>
      </StyledMenuItem>
    </StyledMenu>
  );

  return (
    <>
      <GlobalStyle isSidebarCollapsed={collapsed} />
      <StyledToggleButton
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
      />
      <StyledProSidebar
        ref={sidebarRef} // Attach the ref to the sidebar
        collapsed={collapsed}
        onMouseEnter={() => (collapsed ? setCollapsed(!collapsed) : null)}
        onMouseLeave={() => (!collapsed ? setCollapsed(!collapsed) : null)}
      >
        <SidebarHeader
          style={{
            fontSize: '1.5em',
            fontWeight: 'bold',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '60px',
            width: '100%',
          }}
        >
          <img style={{ width: '40px' }} src={Logo} alt="Logo" />
        </SidebarHeader>

        <SidebarContent>
          {userContext && userContext.isLoggedIn
            ? renderLoggedInMenu()
            : renderLoggedOutMenu()}
        </SidebarContent>

        <SidebarFooterStyle collapsed={collapsed}>
          {/* <FooterLink to="/classic-generator" collapsed={collapsed}>
            <FooterIcon />
            {collapsed ? '' : 'Switch to Classic Generator'}
          </FooterLink> */}

          <SidebarFooterLinksContainer collapsed={collapsed}>
            <FooterTextLink
              to="/terms-of-service"
              collapsed={collapsed}
              target="_blank"
            >
              Terms of Service
            </FooterTextLink>
            <FooterTextLink
              to="/privacy-policy"
              collapsed={collapsed}
              target="_blank"
            >
              Privacy Policy
            </FooterTextLink>
          </SidebarFooterLinksContainer>
        </SidebarFooterStyle>
      </StyledProSidebar>

      {isModalVisible && (
        <LoginModal
          isVisible={isModalVisible}
          isModalCenterVisible={isModalCenterVisible}
          onLoginSuccess={handleLoading}
          onLoginFailure={handleCloseModal}
          userContext={userContext}
        />
      )}
    </>
  );
};

export default Sidebar;
