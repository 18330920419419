import React from 'react';

// Import necessary dependencies
import styled from 'styled-components';

// Import necessary components
import SubscriptionSection from '../../MarketplacePage/subcomponents/SubscriptionSection';
import NokepointsSection from '../../MarketplacePage/subcomponents/NokepointsSection';

import { recordUserInteraction } from '../../../utils/userInteractionsLogger/userInteractionLogger';
import { useUserContext } from '../../../context/UserContext';

// import { Container } from '../GeneratorPage/Container';
// import GeneratorTopBar from '../GeneratorPage/subcomponents/GeneratorTopBar';

// Styled container for the segmented control
const SegmentedControlContainer = styled.div`
  background: #20232a;
  border-radius: 20px;
  display: inline-flex;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2); // Adds depth
  overflow: hidden; // Ensures child elements adhere to the container's border radius

  @media (max-width: 930px) {
    margin-top: 50px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Segment = styled.button`
  background: ${({ isActive }) => (isActive ? '#6060eb' : '#2c2f36')};
  color: ${({ isActive }) => (isActive ? '#ffffff' : '#8a8d90')};
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  border: none;
  transition: background-color 0.3s ease, font-weight 0.3s ease;
  cursor: pointer;
  width: 200px;

  &:hover {
    background: ${({ isActive }) => (isActive ? '#6060eb' : '#33363d')};
    color: #ffffff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(96, 96, 235, 0.5); // Focus ring for accessibility
  }

  @media (max-width: 400px) {
    width: 50%;
    padding: 10px 10px;
  }
`;

// Main container for the entire page, setting the background color and full-screen layout

// Container for the content of the marketplace, allowing for inner spacing and layout control
const ContentWrapper = styled.div`
  width: 100%; // Full width of the container
  max-width: 1800px; // Maximum width for large screens
  margin: 0 auto; // Center the content wrapper
  padding: 2rem; // Padding around the content for spacing from the edges
  box-sizing: border-box; // Ensure padding is included in width calculation
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// Styled component for section titles
const SectionTitle = styled.h2`
  color: white; // A color that stands out against the dark background
  text-align: center; // Center-align the title
  padding: 1rem 0; // Space above and below the title
`;

// Styled component for section descriptions
const SectionDescription = styled.p`
  color: #ccc; // Light grey for less emphasis than white
  text-align: center; // Center-align the description
  max-width: 800px; // Max width for readability
  margin: 0 auto 2rem; // Center in container with margin at the bottom
`;

const SubscriptionContainer = () => {
  const [activeSegment, setActiveSegment] = React.useState('subscriptions');

  const userContext = useUserContext();

  return (
    <ContentWrapper>
      <SegmentedControlContainer>
        <Segment
          isActive={activeSegment === 'subscriptions'}
          onClick={() => {
            setActiveSegment('subscriptions');

            if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
              recordUserInteraction(
                userContext.userInfo.emailHash,
                'Marketplace Segment Change',
                {
                  timestamp: new Date(),
                  specificAction: 'Subscriptions',
                },
              );
            }
          }}
        >
          Subscriptions
        </Segment>
        <Segment
          isActive={activeSegment === 'nokepoints'}
          onClick={() => {
            setActiveSegment('nokepoints');
            if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
              recordUserInteraction(
                userContext.userInfo.emailHash,
                'Marketplace Segment Change',
                {
                  timestamp: new Date(),
                  specificAction: 'Credits',
                },
              );
            }
          }}
        >
          Generation Credits
        </Segment>
      </SegmentedControlContainer>

      {activeSegment === 'subscriptions' ? (
        <>
          <SectionTitle>Choose Your Subscription Plan</SectionTitle>
          <SectionDescription>
            Select the subscription tier that best fits your needs and enjoy
            increased daily generations.
          </SectionDescription>
          <SubscriptionSection maxCardWidth="350px" />
        </>
      ) : (
        <>
          <SectionTitle>Buy Generation Credits</SectionTitle>
          <SectionDescription>
            Purchase Generation Credits to unlock 'Create Your Own' mode, giving
            you full control over your Fakémon designs.
          </SectionDescription>
          <NokepointsSection maxCardWidth="350px" />
        </>
      )}
      {/* Additional content can be added here */}
    </ContentWrapper>
  );
};

export default SubscriptionContainer;
