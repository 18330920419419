export function enableFeedback() {
  const goodButton = document.getElementById('goodButton');
  if (goodButton != null) {
    goodButton.disabled = false;
    goodButton.style.backgroundColor = '#007FFF';
  }

  const badButton = document.getElementById('badButton');
  if (badButton != null) {
    badButton.disabled = false;
    badButton.style.backgroundColor = '#ff8000';
  }
}

function disableFeedback() {
  const goodButton = document.getElementById('goodButton');

  if (goodButton != null) {
    goodButton.disabled = true;
    goodButton.style.backgroundColor = 'gray';
  }

  const badButton = document.getElementById('badButton');

  if (badButton != null) {
    badButton.disabled = true;
    badButton.style.backgroundColor = 'gray';
  }
}

function FeedbackPopper(props) {
  return (
    <div
      style={{
        position: 'absolute',
        width: '200px',
        fontFamily: "'Open Sans', sans-serif",
        backgroundColor: '#88b7d5',
        marginTop: '-100px',
        marginLeft: '-200px',

        opacity: 0.8,
        padding: '20px',
      }}
    >
      <h4
        style={{
          margin: 0,
        }}
      >
        Make sure to give feedback to help improve the AI!
      </h4>
    </div>
  );
}

export function FeedbackButtons(props) {
  if (props.pokemonState != '' || props.typeState != '') {
    return (
      <div className="feedbackPanel">
        <div>
          {/* <FeedbackPopper /> */}
          <p>Feedback</p>
          <div className="feedbackButtons">
            <button
              type="button"
              id="goodButton"
              className="goodButton"
              onClick={() => {
                sendFeedback('good', props.outputData);
                disableFeedback();
              }}
            >
              <i className="fa fa-thumbs-up"></i>
            </button>
            <button
              type="button"
              id="badButton"
              className="badButton"
              onClick={() => {
                sendFeedback('bad', props.outputData);
                disableFeedback();
              }}
            >
              <i className="fa fa-thumbs-down"></i>
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export function sendFeedback(rating, data) {
  let body = {
    feedback: rating,
    ...data,
  };

  fetch('/api/nokemon/feedback/', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  });
}
