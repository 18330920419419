import React from 'react';
import './Modal.css';

const Modal = ({
  children,
  isOpen,
  setModalSettings,
  modalContainerClassName,
}) => {
  const handleCloseModal = event => {
    if (event.target.className === 'modal-overlay') {
      setModalSettings({});
    }
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className={'modal ' + modalContainerClassName}>
            <div className="modal-border"></div>
            {children}
            <button
              className="modal-close-button"
              onClick={() => setModalSettings({})}
            ></button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
