import { constants, generateTextFromFileName } from 'nokemon-common-utils';

export function Nokemon(props) {
  return (
    <div className="nokemonGroup">
      <NokemonName fileName={props.fileName} color="white" />
      <NokemonLabels fileName={props.fileName} />
      <NokemonImage fileName={props.fileName} />
    </div>
  );
}

export function NokemonName(props) {
  return props.fileName in constants.fakemonToName ? (
    <p className="nokemonName" style={{ color: props.color }}>
      {constants.fakemonToName[props.fileName]}
    </p>
  ) : (
    <div></div>
  );
}

export function NokemonImage(props) {
  if (props.fileName) {
    return (
      <img
        src={'/api/generation/basic/images/fakemon?fileName=' + props.fileName}
        className="nokemonImage"
      />
    );
  } else {
    return <div></div>;
  }
}

export function NokemonLabels(props) {
  if (props.fileName) {
    const types = generateTextFromFileName(props.fileName);
    const isLegendary =
      Array.from(constants.legendaryPokemon).includes(props.fileName) ||
      props.fileName.includes('legendary_premium');

    const isShiny = Array.from(constants.shinyPokemon).includes(props.fileName);

    return (
      <div className="nokemonTypes">
        {isShiny ? (
          <div id="shiny" className={'typeBar shiny'}>
            shiny
          </div>
        ) : (
          <div></div>
        )}
        {isLegendary ? (
          <div id="legendary" className={'typeBar legendary'}>
            legendary
          </div>
        ) : (
          <div></div>
        )}
        <div id="type1" className={'typeBar ' + types['type1']}>
          {types['type1']}
        </div>
        {types['type2'] && types['type1'] != types['type2'] ? (
          <div id="type2" className={'typeBar ' + types['type2']}>
            {types['type2']}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}
