import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import styled from 'styled-components';
import Bronze from '../../tier-1-reward.png';
import Silver from '../../tier-2-reward.png';
import Gold from '../../tier-3-reward.png';
import Platinum from '../../nokemon-gift-logo.png';

const ProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: clamp(
    20px,
    10vw,
    75px
  ); // Adjusts margin based on viewport width
  padding-top: clamp(
    50px,
    10vh,
    100px
  ); // Adjusts padding based on viewport height
  padding-bottom: clamp(
    10px,
    5vh,
    20px
  ); // Adjusts padding based on viewport height
  transition: width 2s ease;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: clamp(150px, 25vw, 200px); // Adjusts width based on viewport width
`;

const StepLabel = styled.span`
  color: white;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: clamp(8px, 2.1vw, 12px); // Responsive font size
`;

const StepImage = styled.img`
  width: clamp(50px, 10vw, 85px); // Adjusts image size based on viewport width
  filter: grayscale(${props => (props.accomplished ? 0 : 100)}%);
  opacity: ${props => (props.accomplished ? 1 : 0.75)};
`;

const StepReward = styled.span`
  color: white;
  padding-top: 10px;
  font-weight: bold;
  font-size: clamp(6px, 2vw, 12px); // Responsive font size
`;

function ReferralTracker({ userInfo }) {
  const referralPoints = userInfo['referralRewards']['referralPoints'];
  let progressPercent = calculateProgressPercent(referralPoints);

  return (
    <ProgressBarContainer>
      <ProgressBar
        percent={progressPercent}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
        width={'80%'}
      >
        {getStepData().map(stepData => (
          <Step
            transition="scale"
            key={stepData.referrals}
            transitionDuration={500}
          >
            {({ accomplished }) => (
              <StepContainer>
                <StepLabel>{stepData.referrals}</StepLabel>
                <StepImage
                  accomplished={accomplished}
                  size={stepData.size}
                  src={stepData.imgSrc}
                  style={{
                    width: stepData.size,
                  }}
                />
                <StepReward>{stepData.reward}</StepReward>
              </StepContainer>
            )}
          </Step>
        ))}
      </ProgressBar>
    </ProgressBarContainer>
  );
}

function calculateProgressPercent(referralPoints) {
  if (!referralPoints) {
    return 0;
  } else if (referralPoints <= 3) {
    return (referralPoints / 3) * 25;
  } else if (referralPoints <= 8) {
    const slope = (50 - 25) / (8 - 3);
    return 25 + (referralPoints - 3) * slope;
  } else if (referralPoints <= 15) {
    const slope = (75 - 50) / (15 - 8);
    return 50 + (referralPoints - 8) * slope;
  } else if (referralPoints <= 18) {
    const slope = (100 - 75) / (18 - 15);
    return 75 + (referralPoints - 15) * slope;
  } else {
    return 100;
  }
}

function getStepData() {
  return [
    {},
    {
      referrals: '3 Referrals',
      reward: '+10 Credits',
      imgSrc: Bronze,
      size: 'clamp(20px, 5vw, 50px); // Adjusts image size based on viewport width',
    },
    {
      referrals: '8 Referrals',
      reward: '+20 Credits',
      imgSrc: Silver,
      size: 'clamp(20px, 8vw, 50px); // Adjusts image size based on viewport width',
    },
    {
      referrals: '15 Referrals',
      reward: '+30 Credits',
      imgSrc: Gold,
      size: 'clamp(20px, 8vw, 50px); // Adjusts image size based on viewport width',
    },
    {
      referrals: 'Every 3 Referrals',
      reward: '+5 Credits',
      imgSrc: Platinum,
      size: 'clamp(53px, 11vw, 85px)',
    },
  ];
}

export default ReferralTracker;
