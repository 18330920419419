import { useState, useEffect } from 'react';

export const useProgress = (elapsedTime, remainingTime, beamStatus) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let intervalId;
    const updateInterval = 50; // Update progress every 50 milliseconds

    const getTargetProgress = () => {
      if (beamStatus === 'COMPLETE') {
        return 100;
      }

      if (remainingTime === 0) {
        return 100;
      }
      if (!remainingTime) {
        return elapsedTime;
      }

      return (elapsedTime / (elapsedTime + remainingTime)) * 100;
    };

    const smoothUpdate = () => {
      const targetProgress = getTargetProgress();
      const difference = targetProgress - progress;

      // Gradually update progress
      if (Math.abs(difference) > 0.5) {
        setProgress(progress => progress + difference / 10);
      } else {
        setProgress(targetProgress);
        clearInterval(intervalId); // Stop updating when the target is nearly reached
      }
    };

    intervalId = setInterval(smoothUpdate, updateInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [elapsedTime, remainingTime, beamStatus, progress]);

  return progress;
};
