function generateTextFromFileName(file_name) {
  // Example: type1=Bug_type2=Dark__k=1024_p=0.99_score=95.43968200683594_count=1709.png
  const type1Regex = /type1=(.*?)_/g;
  const type2Regex = /type2=(.*?)_/g;

  const type1 = type1Regex.exec(file_name)[1];
  const type2 = type2Regex.exec(file_name)[1];

  return {
    type1: type1.toLowerCase(),
    type2: type2.toLowerCase(),
  };
}

function choose(choices) {
  const index = Math.floor(Math.random() * choices.length);
  return choices[index];
}

function chooseBatch(choices, batchNum) {
  const choicesCopy = [...choices];
  let shuffled = choicesCopy
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  return shuffled.slice(0, batchNum);
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class DefaultDict {
  constructor(defaultInit) {
    return new Proxy(
      {},
      {
        get: (target, name) =>
          name in target
            ? target[name]
            : (target[name] =
                typeof defaultInit === 'function'
                  ? new defaultInit().valueOf()
                  : defaultInit),
      },
    );
  }
}

export * as constants from './constants.mjs';
export { DefaultDict, sleep, choose, generateTextFromFileName, chooseBatch };
