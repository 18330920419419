import LoginModal from './LoginModal';
import LoginPromptModal from './LoginPromptModal';
import LoginMarketplaceModal from './LoginMarketplaceModal';
import SubscriptionModal from './SubscriptionModal';
import SubscriptionDailyGenerationsUsedModal from './SubscriptionDailyGenerationsUsedModal';
import NokepointsModal from './NokepointsModal';
// import NokepointsImageGuidedModal from './NokepointsImageGuidedModal';
import NokepointsBatchGenerationModal from './NokepointsBatchGenerationModal';
import AnnouncementModal from './AnnouncementModal.js';

const GenericModal = ({ modalSettings, setModalSettings }) => {
  let content;

  if (modalSettings == undefined) {
    return <></>;
  }

  if (Object.keys(modalSettings).length === 0) {
    return <></>;
  }
  const { modalType } = modalSettings;

  if (modalType == 'loginPrompt') {
    content = (
      <LoginPromptModal isOpen={true} setModalSettings={setModalSettings} />
    );
  } else if (modalType == 'login') {
    content = <LoginModal isOpen={true} setModalSettings={setModalSettings} />;
  } else if (modalType == 'loginMarketplace') {
    content = (
      <LoginMarketplaceModal
        isOpen={true}
        setModalSettings={setModalSettings}
      />
    );
  } else if (modalType == 'subscription') {
    content = (
      <SubscriptionModal isOpen={true} setModalSettings={setModalSettings} />
    );
  } else if (modalType == 'subscriptionDailyGenerationsUsed') {
    content = (
      <SubscriptionDailyGenerationsUsedModal
        isOpen={true}
        setModalSettings={setModalSettings}
      />
    );
  } else if (modalType == 'nokepointsPrompt') {
    content = (
      <NokepointsModal isOpen={true} setModalSettings={setModalSettings} />
    );
  } else if (modalType == 'nokepointsImageGuided') {
    content = (
      <AnnouncementModal isOpen={true} setModalSettings={setModalSettings} />
      // <NokepointsImageGuidedModal
      //   isOpen={true}
      //   setModalSettings={setModalSettings}
      // />
    );
  } else if (modalType == 'nokepointsBatchGeneration') {
    content = (
      <NokepointsBatchGenerationModal
        isOpen={true}
        setModalSettings={setModalSettings}
      />
    );
  }

  return content;
};

export default GenericModal;
