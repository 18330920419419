// useRandomLoaderMessage.js
import { useState, useEffect } from 'react';

const loaderMessages = [
  "AI is currently negotiating with the pixels to shape your Fakemon's DNA...",
  'Mixing up a genetic cocktail for your Fakemon in our AI blender...',
  "AI's cooking up a storm in the genome kitchen—your Fakemon's brewing!",
  'Our AI is playing matchmaker with genetic blocks to create your Fakemon...',
  "Choreographing a DNA dance sequence for your Fakemon's grand entrance...",
  'The AI is now threading the double helix of your Fakemon—needle and thread at the ready!',
  "AI's on a coffee break but don't worry, it's still dreaming up your Fakemon's genes...",
  'Beep bop! AI bot is knitting the genetic sweater for your Fakemon, one stitch at a time.',
  "Our AI's got its lab coat on, splicing genes with a pinch of fun for your Fakemon!",
  "Who knew AI could juggle? It's tossing around genes to get your Fakemon just right!",
  'Crafting the genetic blueprint of your Fakemon with AI',
];

export const useRandomLoaderMessage = isVisible => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * loaderMessages.length);
    setMessage(loaderMessages[randomIndex]);
  }, [isVisible]);

  return message;
};
