import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Step 1
import styled, { keyframes } from 'styled-components';
import { FaBolt, FaCoins } from 'react-icons/fa';
import SignInButton from './LoginPrompt';
import TooltipComponent from './TooltipComponent';
import {
  TooltipManager,
  DailyGenerationsTooltipComponent,
} from '../../Tooltips/Tooltips.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  NoviceTrainerBadge,
  EliteTrainerBadge,
  MasterTrainerBadge,
} from '../../SVGComponents';

import Dropdown from './Dropdown.js';
import { recordUserInteraction } from '../../../utils/userInteractionsLogger/userInteractionLogger';
import { useUserContext } from '../../../context/UserContext';

// Add a keyframe animation for the numbers
const countAnimation = keyframes`
  from {
    transform: scale(1.15);
  }
  to {
    transform: scale(1);
  }
`;

const TopBarContainer = styled.div`
  position: absolute;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  // background-color: #181a1b;
  color: white;
  font-family: 'Inter', sans-serif;
  backdrop-filter: blur(2px);
  width: 100%;
  box-sizing: border-box;
  position: relative; // Added for absolute positioning of child elements
  z-index: 5555;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-end; // Align to start on small screens
    // padding: 1.2rem;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 1rem; // Space between items
  align-items: center;
`;

const InfoDisplay = styled.div`
  position: relative; // For tooltip positioning
  height: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  font-weight: 500;
  color: #ffffff;
  opacity: 0.9; // Reduced opacity for a subtler look

  // Icon styling
  svg {
    margin-right: 0.5rem;
    color: #c758da; // Primary color
    transition: all 0.3s ease-in-out;
  }

  // Animated number styling
  span {
    color: white;
    font-weight: 500; // Bolder font for emphasis
    animation: ${props => props.animate && countAnimation} 0.3s ease;
  }

  .label {
    display: inline; // Ensure label is displayed by default

    @media (max-width: 950px) {
      display: none; // Hide the label text on smaller screens
    }
  }

  span {
    // Ensure that numbers are always displayed
    display: inline;
  }

  @media (max-width: 768px) {
    font-size: 0.85rem; // Smaller font size for responsiveness
  }

  &:hover svg {
    color: #9c27b0; // Darker shade on hover
    transform: scale(1.1);
  }

  &:hover span {
    color: #e0e0e0; // Slightly lighter color on hover
  }

  // Conditional styling based on props
  background-color: ${props =>
    props.highlight ? 'rgba(197, 88, 218, 0.1)' : 'transparent'};
  border-radius: 8px;
  padding: 10px;
  transition: all 0.3s ease-in-out;

  // Conditional glow effect based on props
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: ${props => (props.highlight ? '0 0 15px 0 #6061eb' : 'none')};

  &:hover {
    box-shadow: 0 0 15px 0 rgba(253, 216, 53, 0.75); // Enhance glow effect on hover
  }

  // Applying a different glow for Credits
  ${props =>
    props.highlightCredits &&
    `
    box-shadow: 0 0 15px 0 rgba(197, 88, 218, 0.5);
    &:hover {
      box-shadow: 0 0 15px 0 rgba(197, 88, 218, 0.75); // Enhance glow effect on hover
    }
  `}

  @media (max-width: 900px) {
    padding: 8px;
    height: 15px;
  }
`;

// Badge container to resize
const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 2px;
  margin-bottom: 3px;

  @media (max-width: 950px) {
    margin-right: -6px;
  }
`;

// Function to return the correct InfoDisplay based on the subscriptionTier
function getSubscriptionInfoDisplay(subscriptionTier) {
  // Return the correct InfoDisplay depending on the subscriptionTier
  // 1 = Novice, 2 = Elite, 3 = Master
  // If the subscriptionTier is not 1, 2, or 3, return an empty string
  // Include the corresponding badge
  switch (subscriptionTier) {
    case 0:
      return <div>Hi</div>;
    case 1:
      return (
        <InfoDisplay>
          <BadgeContainer>
            <NoviceTrainerBadge />
          </BadgeContainer>
          <span className="label">Novice Trainer</span>
        </InfoDisplay>
      );
    case 2:
      return (
        <InfoDisplay>
          <BadgeContainer>
            <EliteTrainerBadge />
          </BadgeContainer>
          <span className="label">Elite Trainer</span>
        </InfoDisplay>
      );
    case 3:
      return (
        <InfoDisplay>
          <BadgeContainer>
            <MasterTrainerBadge />
          </BadgeContainer>
          <span className="label">Master Trainer</span>
        </InfoDisplay>
      );
    default:
      return <></>;
  }
}

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: #ffffff;
  opacity: 0.9;
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
    margin-left: 20px;
  }
`;

// GeneratorTopBar component

const InfoDisplayComponent = ({
  dailyGenerationsLeft,
  nokePoints,
  subscriptionTier,
  useCredits,
}) => {
  // Define tooltip texts
  const dailyGenerationsTooltip =
    'Number of generations you can generate today. Resets at midnight EST.';
  const creditsTooltip =
    'Number of credits you have left. Used for premium generations.';

  const userContext = useUserContext();

  return (
    <>
      {/* Subscription and credit displays */}
      {subscriptionTier !== getSubscriptionInfoDisplay(subscriptionTier)}
      <TooltipManager>
        <InfoDisplay
          highlight={!useCredits}
          data-tip
          data-for="dailyGenerationsTooltip"
          onClick={() => {
            if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
              recordUserInteraction(
                userContext.userInfo.emailHash,
                'Click Toolbar',
                {
                  specificAction: 'Click Daily Generations',
                  timestamp: Date.now(),
                },
              );
            }
            window.location.href = '/marketplace';
          }}
        >
          <FaBolt color="#fdd835" />
          <span className="label">Daily Generations:&nbsp;</span>
          {typeof dailyGenerationsLeft === 'number' ||
          dailyGenerationsLeft === 'Unlimited' ? (
            dailyGenerationsLeft
          ) : (
            <>
              &nbsp;
              <FontAwesomeIcon icon={faSpinner} spin />
            </>
          )}
        </InfoDisplay>
        <DailyGenerationsTooltipComponent
          title="Daily Generations"
          subTitle="Generations are reset every 24 hours."
          buttonText="Upgrade Plan"
          isTimer={true}
        />
      </TooltipManager>

      <TooltipManager>
        <InfoDisplay
          highlight={useCredits}
          onClick={() => {
            if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
              recordUserInteraction(
                userContext.userInfo.emailHash,
                'Click Toolbar',
                {
                  specificAction: 'Click Credits',
                  timestamp: Date.now(),
                },
              );
            }
            window.location.href = '/marketplace';
          }}
          data-tip
          data-for="creditsTooltip"
        >
          <FaCoins color="#ffc107" />
          <span className="label">Credits:&nbsp;</span>
          {typeof nokePoints === 'number' ? (
            nokePoints
          ) : (
            <>
              &nbsp;
              <FontAwesomeIcon icon={faSpinner} spin />
            </>
          )}
        </InfoDisplay>
        <DailyGenerationsTooltipComponent
          title="Credits"
          subTitle="Generation Credits are used for 'Create Your Own' mode."
          buttonText="Add Credits"
          isTimer={false}
        />
      </TooltipManager>
    </>
  );
};

const handleModelChange = event => {
  if (event.target.value === 'classic') {
    window.location.href = 'URL_FOR_CLASSIC_GENERATOR'; // Replace with the actual URL
  }
};

const ModelSwitcherDropdown = ({
  onModelVersionChange,
  noBorder,
  modelVersion,
}) => {
  const userContext = useUserContext();
  const modelOptions = [
    { value: 'modelv3', label: 'NokémonAI Ultimate', color: '#9677ff' },
    { value: 'modelv2', label: 'NokémonAI Classic 2.0', color: '#9677ff' },
    { value: 'modelv1', label: 'NokémonAI Classic 1.0', color: '#9677ff' },
  ];

  const [selectedOption, setSelectedOption] = useState(
    modelOptions.find(option => option.value === modelVersion) ||
      modelOptions[0],
  );

  const handleModelVersionChange = selectedOption => {
    if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
      recordUserInteraction(
        userContext.userInfo.emailHash,
        'Change Model Version',
        {
          specificAction: selectedOption.value,
          timestamp: new Date(),
        },
      );
    }
    setSelectedOption(selectedOption);
    onModelVersionChange(selectedOption.value);
  };

  useEffect(() => {
    setSelectedOption(
      modelOptions.find(option => option.value === modelVersion) ||
        modelOptions[0],
    );
  }, modelVersion);

  return (
    <Dropdown
      options={modelOptions}
      selectedOption={selectedOption}
      onChange={handleModelVersionChange}
      noBorder={noBorder}
    />
  );
};

const GeneratorTopBar = ({
  dailyGenerationsLeft,
  nokePoints,
  subscriptionTier,
  isLoggedIn,
  onLoginClick,
  useCredits,
  handleModelVersionChange,
  modelVersion,
  userContext,
}) => {
  // State to track if the viewport is desktop size
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 550);
  const location = useLocation(); // Step 2

  // Effect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 550); // Update based on your breakpoint
    };

    // Add resize event listener when component mounts
    window.addEventListener('resize', handleResize);

    // Cleanup event listener when component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <TopBarContainer>
      {location.pathname === '/' && ( // Step 3
        <ModelSwitcherDropdown
          modelVersion={modelVersion}
          onModelVersionChange={handleModelVersionChange}
          noBorder={true}
          style={{ order: isDesktop ? 1 : undefined }} // Change applies here
        />
      )}
      <InfoContainer>
        {isLoggedIn ? (
          <InfoDisplayComponent
            dailyGenerationsLeft={dailyGenerationsLeft}
            nokePoints={nokePoints}
            subscriptionTier={subscriptionTier}
            useCredits={useCredits}
          />
        ) : (
          <SignInButton userContext={userContext} />
        )}
      </InfoContainer>
    </TopBarContainer>
  );
};

export default GeneratorTopBar;
