// ReactWizardX's Modern Text and Interactive Component
import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import FakemonGrassExample from '../../../fakemon-example-grass-1.png';
import FakemonIceExample from '../../../fakemon-example-ice-1.png';
import FakemonFlyingExample from '../../../fakemon-example-flying-electric-1.png';
import FakemonFairyExample from '../../../fakemon-example-fairy-1.png';
import NokemonLogo from '../../../nokemon-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/fontawesome-free-solid';

import OwlExample1 from '../../../OwlExample1.png';
import OwlExample2 from '../../../OwlExample2.png';
import OwlExample3 from '../../../OwlExample3.png';
import OwlExample4 from '../../../OwlExample4.png';
import HummingbirdExample1 from '../../../HummingbirdExample1.png';
import HummingbirdExample2 from '../../../HummingbirdExample2.png';
import HummingbirdExample3 from '../../../HummingbirdExample3.png';
import HummingbirdExample4 from '../../../HummingbirdExample4.png';
import SnailExample1 from '../../../SnailExample1.png';
import SnailExample2 from '../../../SnailExample2.png';
import SnailExample3 from '../../../SnailExample3.png';
import SnailExample4 from '../../../SnailExample4.png';

const responsiveFontSizes = ({ small, medium, large }) => {
  return css`
    @media (max-width: 500px) {
      font-size: ${small};
    }

    @media (min-width: 501px) and (max-width: 768px) {
      font-size: ${medium};
    }

    @media (min-width: 769px) {
      font-size: ${large};
    }
  `;
};
// 🌐 ReactWizardX's Styled Components for Feature Layout 🌐
const FeatureContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #191a1a;
  backdrop-filter: blur(10px); // Blur effect for glassmorphism
  -webkit-backdrop-filter: blur(10px); // For Safari browser
  // border: 1px solid rgba(255, 255, 255, 0.25); // Border for light glass effect
  color: white;
  border-radius: 12px;
  margin: auto; // Center the container on the page
  width: 90%;
  padding-bottom: 10rem;

  @media (max-width: 1200px) {
    padding: 0 1rem 10rem 1rem; // Further reduce padding on small screens
  }

  @media (max-width: 768px) {
    width: 100%;
    min-width: 100px;
    padding-top: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 6rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem; // Space below the content containers

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const InteractiveContainer = styled.div`
  border-radius: 6px;
  background-color: ${({ smallMode }) => (smallMode ? 'none' : '#282c30aa')};
  padding: ${({ smallMode }) => (smallMode ? '0rem' : '2rem')};
  border: ${({ smallMode }) => (smallMode ? 'none' : '1px solid #444c56aa')};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Consistent shadow with FeatureContainer
  color: white;
  position: relative; // Ensure this container is positioned to establish a stacking context
  z-index: 1; // Lower z-index than UserDisplay
  display: flex;
  flex-direction: column;
  align-items: center; // Center-align the content
  justify-content: flex-start;
  margin: auto; // Center the container on the page
  height: ${({ smallMode }) => (smallMode ? '400px' : '500px')};
  width: 600px;
  min-width: 600px;

  @media (max-width: 768px) {
    width: 90%;
    min-width: 100px;
    height: ${({ smallMode }) => (smallMode ? '320px' : '500px')};
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;
const SuggestionTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // Add any additional styling you need for the container
`;

const TypeText = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 700; /* Increased font weight for better emphasis */
  font-size: 1.1em; /* Slightly larger font size */
  color: white;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  text-align: left;
`;

const DescriptionText = styled.span`
  text-align: left;
  font-family: 'Inter', sans-serif;
  color: #c2c2c2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.lineClamp};

  overflow: hidden;
  text-overflow: ellipsis;
`;

const SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const PlaceholderImage = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: ${props => props.top}px;
  margin-left: ${props => props.left}px;
  position: absolute;
  z-index: ${props => props.zIndex};
  background-color: #1e2124;
  border-radius: 12px;
  padding: 1rem;
  border: 2px solid #2e3139;
`;

const PlaceholderStack = () => {
  const placeholders = [1, 2, 3]; // Array representing three placeholders
  const baseWidth = 200; // Base width for the largest placeholder
  const baseHeight = 200; // Base height for the largest placeholder

  return (
    <div>
      {placeholders.map((placeholder, index) => (
        <PlaceholderImage
          key={index}
          width={baseWidth - index * 20} // Each placeholder is 20px smaller than the previous
          height={baseHeight - index * 20} // Each placeholder is 20px smaller than the previous
          zIndex={placeholders.length + index} // Higher zIndex for larger placeholders
          top={0 + index * 20}
          left={0 + index * 20}
        />
      ))}
    </div>
  );
};

// Dummy data for suggestions and images, replace with real data as needed
const dummySuggestions = [
  {
    id: 1,
    text: 'A flying / psychic type rabbit-owl hybrid with white feathery fur and large eyes.',
    type: 'Owl',
    imgUrls: [OwlExample1, OwlExample2, OwlExample3, OwlExample4],
  },
  {
    id: 2,
    text: 'A cybernetic hummingbird with metallic feathers and a ruby red body.',
    type: 'Hummingbird',
    imgUrls: [
      HummingbirdExample1,
      HummingbirdExample2,
      HummingbirdExample3,
      HummingbirdExample4,
    ],
  },
  {
    id: 3,
    text: 'A water type sea serpent and snail hybrid with a blue body.',
    type: 'Snail',
    imgUrls: [SnailExample1, SnailExample2, SnailExample3, SnailExample4],
  },
];

// Define a new BoxContainer styled component
const BoxContainer = styled.div`
  border: 1px solid #444c56aa; // Set the border color to grey as per your other components
  border-radius: 4px; // Add a border-radius if you want rounded corners
  padding: 1rem; // Add some padding around the MessageInput
  width: 100%; // Ensure it takes the full width of its parent container
  background-color: #1e2124; // Set the background color to match your theme
  display: flex; // Use flex layout to center the MessageInput
  justify-content: center; // Center the MessageInput horizontally
  align-items: center; // Center the MessageInput vertically
  position: absolute;
  width: 500px;
  top: 350px;
`;

const MessageInputBox = styled(BoxContainer)`
  // Reuse the BoxContainer styles and apply any specific overrides here
  width: 500px;
  top: ${({ smallMode }) => (smallMode ? '355px' : '470px')};
  border: 1px solid #6464ec;
  padding: 8px;

  @media (max-width: 768px) {
    margin-left: 0;
    top: ${({ smallMode }) => (smallMode ? '270px' : '470px')};
  }

  @media (max-width: 530px) {
    width: 100%;
  }
`;

const MessageInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #6464ec;
  background-color: #1e2124;
  color: #c2c2c2;
  font-size: 16px;
  border: 0;
  // Truncate
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Inter', sans-serif;

  &:focus {
    outline: none;
  }

  ${responsiveFontSizes({
    small: '0.8rem',
    medium: '0.85rem',
    large: '0.9rem',
  })}
`;

const SendButton = styled.button`
  background-color: ${props =>
    !props.isButtonClicked ? '#6464ec' : '#6464ec66'};
  border: 0;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 6px;

  cursor: not-allowed;
`;

const UserDisplay = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  background-color: #1e2124;
  padding: 10px;
  border: 1px solid #6464ec;
  border-radius: 6px;
  top: -20px;
  margin-left: -300px;
  width: 400px;
  z-index: 3;
  height: 60px;

  @media (max-width: 768px) {
    margin-left: 0;
  }

  @media (max-width: 530px) {
    width: 100%;
  }
`;

const UserDisplayLogo = styled.img`
  width: 25px;
  height: 25px;
`;

const UserDisplayText = styled.p`
  padding: 0;
  margin: 0;
  color: #c2c2c2;
  font-family: 'Inter', sans-serif;
  height: 60px;
  ${responsiveFontSizes({
    small: '0.8rem',
    medium: '0.85rem',
    large: '0.9rem',
  })}
`;

const textShimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const SkeletonTextPlaceholder = styled.div`
  width: 100%;
  height: 15px;
  background-color: #2e2e2e;
  background-image: linear-gradient(
    to right,
    #2e2e2e 0%,
    #3a3a3a 20%,
    #2e2e2e 40%,
    #2e2e2e 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: block;
  margin-bottom: 8px;
  animation: ${textShimmer} 1.2s infinite linear;

  &:last-child {
    width: 80%; // Second line shorter to mimic natural text
  }
`;

// Define a new styled component for the grid items
const GridItem = styled.div`
  position: relative;
  border: 1px solid #444c56aa; // Border color to match the theme
  border-radius: 8px; // Rounded corners
  overflow: hidden; // Hide overflow to maintain the rounded corner effect on images
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Shadow for depth
  display: flex; // Use flexbox to center the image
  align-items: center; // Center the image vertically
  justify-content: center; // Center the image horizontally
  background-color: #1e2124; // Background color to match the theme
  width: ${({ smallMode }) => (smallMode ? '140px' : '150px')};
  height: ${({ smallMode }) => (smallMode ? '140px' : '150px')};
  @media (max-width: 768px) {
    width: ${({ smallMode }) => (smallMode ? '100px' : '100%')};
    height: ${({ smallMode }) => (smallMode ? '100px' : 'auto')};
    max-height: ${({ smallMode }) => (smallMode ? '100px' : '150px')};
    max-width: ${({ smallMode }) => (smallMode ? '100px' : '150px')};
  }
  min-width: 100px;
  min-height: 100px;
`;

// Define the ImageGrid styled component as a 2x2 grid
const ImageGrid = styled.div`
  position: absolute;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr); // 2 columns
  grid-gap: 20px; // Space between grid items
  margin-top: ${({ smallMode }) => (smallMode ? '0px' : '50px')};
  background-color: #1e2124;
  padding: 10px;
  border: 1px solid #444c56aa;
  border-radius: 6px;

  @media (max-width: 350px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
`;

const GridImage = styled.img`
  max-width: 100%;
  max-height: 150px;
  display: ${props => (props.isLoading ? 'none' : 'block')};

  @media (max-width: 420px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    max-width: auto;
    max-height: auto;
  }
`;

const imageShimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const SkeletonImagePlaceholder = styled.div`
  width: 150px;
  height: 150px;
  background-color: #1e2124;
  background-image: linear-gradient(
    to right,
    #1e2124 0%,
    #2b2f31 50%,
    #1e2124 100%
  );
  background-repeat: no-repeat;
  // background-size: 800px 104px; // The width of the gradient can be adjusted for the desired effect
  animation: ${imageShimmer} 2s linear infinite;
  animation-delay: 0.5s; // Adding a delay can make the effect smoother

  @media (max-width: 420px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
`;

// Custom hook for observing element visibility
const useVisibility = ref => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const [entry] = entries;
      setIsVisible(entry.isIntersecting);
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return isVisible;
};

// Custom hook for tab visibility
const useTabVisibility = () => {
  const [isTabVisible, setIsTabVisible] = useState(!document.hidden);

  useEffect(() => {
    const handleVisibilityChange = () => setIsTabVisible(!document.hidden);

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isTabVisible;
};

const ImageGridComponent = ({
  currentSuggestion,
  imageLoadingStatus,
  smallMode,
}) => {
  return (
    <ImageGrid smallMode={smallMode}>
      {currentSuggestion.imgUrls.map((imgUrl, index) => (
        <GridItem
          key={`${currentSuggestion.id}-${index}`}
          smallMode={smallMode}
        >
          {imageLoadingStatus[`${currentSuggestion.id}-${index}`] ? (
            <SkeletonImagePlaceholder />
          ) : (
            <GridImage
              src={imgUrl}
              alt={`Image ${index + 1} for ${currentSuggestion.text}`}
              smallMode={smallMode}
            />
          )}
        </GridItem>
      ))}
    </ImageGrid>
  );
};

const MessageInputBoxComponent = ({
  typedText,
  isButtonClicked,
  onSendClick,
  smallMode,
}) => {
  return (
    <MessageInputBox smallMode={smallMode}>
      <MessageInput value={typedText} readOnly />
      <SendButton isButtonClicked={isButtonClicked} onClick={onSendClick}>
        <FontAwesomeIcon icon={faPaperPlane} />
      </SendButton>
    </MessageInputBox>
  );
};

const UserDisplayComponent = ({ displayedText, showSkeletonText }) => {
  return (
    <UserDisplay>
      <UserDisplayLogo src={NokemonLogo} alt="Nokemon" />
      {showSkeletonText ? (
        <div
          style={{
            zIndex: 100000,
            width: '100%',
          }}
        >
          <SkeletonTextPlaceholder />
          <SkeletonTextPlaceholder />
        </div>
      ) : (
        <UserDisplayText>{displayedText}</UserDisplayText>
      )}
    </UserDisplay>
  );
};

export const InteractiveComponent = ({ smallMode }) => {
  const [currentSuggestion, setCurrentSuggestion] = useState(
    dummySuggestions[0],
  );
  const [typedText, setTypedText] = useState('');
  const [displayedText, setDisplayedText] = useState('');
  const [showSkeletonText, setShowSkeletonText] = useState(true);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoadingStatus, setImageLoadingStatus] = useState(
    dummySuggestions.reduce((status, suggestion) => {
      suggestion.imgUrls.forEach((_, index) => {
        status[`${suggestion.id}-${index}`] = true;
      });
      return status;
    }, {}),
  );

  const componentRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const loadingTimeoutRef = useRef(null);
  // const isVisible = useVisibility(componentRef); // Assuming useVisibility is a custom hook
  // const isTabVisible = useTabVisibility(); // Assuming useTabVisibility is a custom hook

  // Function to simulate typing into the message input box
  const simulateTypingIntoMessageBox = (text, onComplete) => {
    let index = 0;
    setTypedText(''); // Explicitly reset typedText for each new suggestion

    const typeCharacter = () => {
      if (index < text.length) {
        setTypedText(prevTypedText => prevTypedText + text.charAt(index));
        index++;
        typingTimeoutRef.current = setTimeout(typeCharacter, 20);
      } else if (onComplete) {
        if (index === text.length) {
          simulateButtonClick();
          if (onComplete) {
            onComplete();
          }
        }
      }
    };

    // Delay the start of typing to ensure state reset is effective
    setTimeout(() => typeCharacter(), 0);
  };

  // Function to simulate typing into the user display
  const simulateTypingIntoUserDisplay = text => {
    let index = 0;
    setDisplayedText('');
    setShowSkeletonText(false);

    const typeCharacter = () => {
      if (index < text.length) {
        setDisplayedText(displayedText => displayedText + text.charAt(index));
        index++;
        setTimeout(typeCharacter, 20); // Typing speed
      } else {
        // Typing finished, start loading images
        setIsLoading(false);
        loadImagesRandomly();
        setTimeout(resetAnimation, 4000);
      }
    };

    typeCharacter();
  };

  const simulateButtonClick = () => {
    setIsButtonClicked(true); // Simulate button being clicked
    setTimeout(() => {
      setIsButtonClicked(false); // Reset button click state after a short delay
    }, 200); // Duration of simulated click
  };

  // Function to load images in random order and at random intervals
  const loadImagesRandomly = () => {
    currentSuggestion.imgUrls.forEach((_, imageIndex) => {
      const delay = Math.random() * 1000 + 500;
      loadingTimeoutRef.current = setTimeout(() => {
        setImageLoadingStatus(prev => ({
          ...prev,
          [`${currentSuggestion.id}-${imageIndex}`]: false,
        }));
      }, delay);
    });
  };

  // Function to reset the component's state for starting the animation over
  const resetAnimation = () => {
    setCurrentSuggestion(
      dummySuggestions[
        (dummySuggestions.indexOf(currentSuggestion) + 1) %
          dummySuggestions.length
      ],
    );
    setTypedText('');
    setDisplayedText('');
    setIsLoading(true);
    setShowSkeletonText(true);
    // Reset imageLoadingStatus for each image
    setImageLoadingStatus(
      dummySuggestions.reduce((status, suggestion) => {
        suggestion.imgUrls.forEach((_, index) => {
          status[`${suggestion.id}-${index}`] = true;
        });
        return status;
      }, {}),
    );
  };

  // Clear all timeouts
  const clearAllTimeouts = () => {
    clearTimeout(typingTimeoutRef.current);
    clearTimeout(loadingTimeoutRef.current);
  };

  // useEffect to handle visibility changes
  useEffect(() => {
    // if (isVisible && isTabVisible) {
    simulateTypingIntoMessageBox(currentSuggestion.text, () => {
      if (!smallMode) {
        simulateTypingIntoUserDisplay(currentSuggestion.text);
      } else {
        setIsLoading(false);
        loadImagesRandomly();
        setTimeout(resetAnimation, 4000);
      }
    });
    // }
    // else {
    //   clearAllTimeouts();
    //   resetAnimation();
    // }
  }, [currentSuggestion]);

  // Cleanup timeouts when component unmounts
  useEffect(() => {
    return () => {
      clearAllTimeouts();
    };
  }, []);

  return (
    <InteractiveContainer smallMode={smallMode}>
      {!smallMode && (
        <UserDisplayComponent
          displayedText={displayedText}
          showSkeletonText={showSkeletonText}
        />
      )}
      <ImageGridComponent
        currentSuggestion={currentSuggestion}
        imageLoadingStatus={imageLoadingStatus}
        smallMode={smallMode}
      />
      <MessageInputBoxComponent
        typedText={typedText}
        isButtonClicked={isButtonClicked}
        onSendClick={() => setIsButtonClicked(true)}
        smallMode={smallMode}
      />
    </InteractiveContainer>
  );
};
const TextContainer = styled.div`
  background: #191a1add; // Adjust color to match the image
  color: white; // Text color
  padding: 0rem 2rem; // Adjust padding to match the image
  margin: 0rem 0; // Space around the container
  width: 50%;

  @media (max-width: 1200px) {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem; // Adjust font-size to match the image
  color: white; // Title color
  margin-bottom: 1rem; // Space below the title
  margin-top: 0;
  padding-top: 0;
  font-family: Inter;
  ${responsiveFontSizes({ small: '2rem', medium: '2.25rem', large: '2.5rem' })}
`;

const Subtitle = styled.h2`
  display: inline-block; // Make the tag a block-level element that flows with the text
  background-color: transparent; // Adjust background color to match the tag color in the image
  border: 2px solid #6061eb; // Adjust the color to match the line on the image
  color: white; // Text color for contrast
  padding: 0.2rem 0.7rem; // Vertical and horizontal padding
  border-radius: 6px; // Fully rounded corners for pill shape
  font-size: 0.8rem; // Adjust font-size to match the image
  margin-bottom: 1rem; // Space below the subtitle
  font-weight: bold; // Bold font weight
  ${responsiveFontSizes({
    small: '0.7rem',
    medium: '0.75rem',
    large: '0.8rem',
  })}
`;

const BulletPoints = styled.ul`
  list-style: none; // Remove default list styling
  padding: 0; // Remove default padding
  margin: 0; // Remove default margin
  border-left: 5px solid #6061eb; // Adjust the color to match the line on the image
`;

const BulletPoint = styled.div`
  background-color: #2a2d3444; // A dark background that fits the theme
  padding: 1rem; // Spacing inside the bullet points
  margin-bottom: 0.5rem; // Space between bullet points
  color: white; // Text color for readability
  transition: background-color 0.3s ease; // Smooth transition for hover effect

  &:hover {
    background-color: #373b44; // Slightly lighter background on hover
  }
`;

const BulletPointTitle = styled.h3`
  font-size: 1.1rem; // Larger size for titles
  color: #ffffff; // Bright color for the title
  margin: 0 0 0.3rem 0; // Spacing between title and description
  font-weight: 600; // Semi-bold for distinction
  ${responsiveFontSizes({ small: '1rem', medium: '1.05rem', large: '1.1rem' })}
`;

const BulletPointDescription = styled.p`
  font-size: 0.9rem; // Smaller than the title
  color: #bbbbbb; // A lighter shade for descriptions
  margin: 0; // No additional spacing needed
  ${responsiveFontSizes({
    small: '0.8rem',
    medium: '0.85rem',
    large: '0.9rem',
  })}
`;

// Component structure
const DiscoveryTextContainer = () => (
  <TextContainer>
    <Subtitle>Full Control & Creativity</Subtitle>
    <Title>Create Your Own Mode</Title>
    <BulletPoints>
      <BulletPoint>
        <BulletPointTitle>Design Your Own Fakémon</BulletPointTitle>
        <BulletPointDescription>
          Describe the unique characteristics and features you envision for your
          custom Fakémon.
        </BulletPointDescription>
      </BulletPoint>
      <BulletPoint>
        <BulletPointTitle>Multiple Variations, One Generation</BulletPointTitle>
        <BulletPointDescription>
          Input your concept and generate four distinct variations of your
          Fakémon.
        </BulletPointDescription>
      </BulletPoint>
      <BulletPoint>
        <BulletPointTitle>Completely Unique Fakemon</BulletPointTitle>
        <BulletPointDescription>
          Every Fakémon you create is a novel creation, fashioned uniquely
          through your interaction with our AI model.
        </BulletPointDescription>
      </BulletPoint>

      <BulletPoint>
        <BulletPointTitle>Refine and Iterate</BulletPointTitle>
        <BulletPointDescription>
          Continuously adjust and perfect your Fakémon's description to capture
          the essence of your ideal creature.
        </BulletPointDescription>
      </BulletPoint>
    </BulletPoints>
  </TextContainer>
);

// The FeatureComponent with left-aligned text and right-aligned interactive placeholder
const CreateYourOwnSection = () => {
  return (
    <FeatureContainer>
      <ContentContainer>
        <DiscoveryTextContainer />
        <InteractiveComponent smallMode={false} />
      </ContentContainer>
    </FeatureContainer>
  );
};

export default CreateYourOwnSection;
