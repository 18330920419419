import { AdvancedNewModelOptions } from './AdvancedNewModelOptions';
import { AdvancedOldModelOptions } from './AdvancedOldModelOptions';

// Define a reusable component for the model options interface
function ModelOptionsInterface({
  modelType,
  setGeneratedBy,
  batchNum,
  setBatchNum,
  availability,
  ...props
}) {
  if (modelType === 'sd_v1') {
    return (
      <AdvancedNewModelOptions
        batchNum={batchNum}
        setGeneratedBy={setGeneratedBy}
        setBatchNum={setBatchNum}
        globalBatchNum={props.globalBatchNum}
        setGlobalBatchNum={props.setGlobalBatchNum}
        availability={availability}
        {...props}
      />
    );
  } else {
    return <AdvancedOldModelOptions availability={availability} {...props} />;
  }
}

export default ModelOptionsInterface;
