import styled from 'styled-components';

// Define your styled component
const LearnMoreButtonStyled = styled.button`
  white-space: nowrap;
  height: 40px;
  font-size: 16px;
  padding: 10px 20px;
  background-color: purple;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid gold;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  display: flex;
  align-items: center;
`;

// Then, in your component, you can use this new styled component like so:
function LearnMoreButton() {
  return (
    <LearnMoreButtonStyled
      onClick={() => {
        window.location.href = '/information#section-advanced-generation';
      }}
    >
      Learn More
    </LearnMoreButtonStyled>
  );
}

export default LearnMoreButton;
