// useEstimatedWaitTime.js
import { useState, useEffect } from 'react';

const COLD_START_TIME = 180; // 3 minutes
const AVERAGE_GENERATOR_TIME = 25; // 15 seconds

const isValidDate = dateString => {
  return dateString && !isNaN(new Date(dateString).getTime());
};

// Helper function to round the time to the nearest second
const roundToNearestSecond = timeInSeconds => {
  return Math.round(timeInSeconds);
};

const useEstimatedWaitTime = (
  lastComplete,
  lastPending,
  beamStatus,
  queuePosition,
) => {
  const [waitTime, setWaitTime] = useState(null);

  useEffect(() => {
    const calculateWaitTime = (
      lastComplete,
      lastPending,
      beamStatus,
      queuePosition,
    ) => {
      // Check if date values are valid. If not, return 'calculating'
      if (!isValidDate(lastComplete) && !isValidDate(lastPending)) {
        // Case 1: No Data Available (Calculating Phase)
        return null;
      }

      if (beamStatus === 'RUNNING') {
        return roundToNearestSecond(AVERAGE_GENERATOR_TIME);
      } else if (beamStatus === 'COMPLETE') {
        return roundToNearestSecond(0);
      }

      const now = new Date();
      const lastCompleteTime = isValidDate(lastComplete)
        ? new Date(lastComplete)
        : now;
      const lastPendingTime = isValidDate(lastPending)
        ? new Date(lastPending)
        : now;

      const timeSinceLastComplete = (now - lastCompleteTime) / 1000; // Time in seconds
      const timeSinceLastPending = (now - lastPendingTime) / 1000; // Time in seconds

      // Determine if the generator is in a cold start state
      const isColdStart = timeSinceLastComplete > COLD_START_TIME;
      const isTaskPendingOverColdStartTime =
        timeSinceLastPending > COLD_START_TIME;

      // Calculate remaining cold start time, if any
      let remainingColdStartTime = COLD_START_TIME;

      if (isColdStart && timeSinceLastPending < COLD_START_TIME) {
        remainingColdStartTime = COLD_START_TIME - timeSinceLastPending;
      }

      let waitTime;

      if (typeof queuePosition !== 'number') {
        // Case 2: Data Available, No Queue Position
        waitTime =
          isColdStart && !isTaskPendingOverColdStartTime
            ? remainingColdStartTime
            : COLD_START_TIME;
      } else if (queuePosition === 1) {
        // Case 3: User First in Queue
        if (isColdStart && !isTaskPendingOverColdStartTime) {
          waitTime = remainingColdStartTime; // Full cold start time
        } else if (isColdStart && isTaskPendingOverColdStartTime) {
          waitTime = COLD_START_TIME; // Remaining cold start time
        } else {
          if (beamStatus === 'PENDING' || beamStatus === null) {
            waitTime = AVERAGE_GENERATOR_TIME; // Regular generator time
          } else {
            waitTime = AVERAGE_GENERATOR_TIME;
          }
        }
      } else {
        // Case 4: User in Queue (Position > 1)
        const queueWaitTime = queuePosition * AVERAGE_GENERATOR_TIME;
        if (isColdStart && !isTaskPendingOverColdStartTime) {
          waitTime = remainingColdStartTime + queueWaitTime; // Full cold start time plus queue wait
        } else if (isColdStart && isTaskPendingOverColdStartTime) {
          waitTime = COLD_START_TIME + queueWaitTime; // Remaining cold start time plus queue wait
        } else {
          waitTime = queueWaitTime; // Just the queue wait time
        }
      }

      // Round the calculated wait time to the nearest second
      if (waitTime === 0) {
        return null;
      }
      return roundToNearestSecond(waitTime);
    };

    setWaitTime(
      calculateWaitTime(lastComplete, lastPending, beamStatus, queuePosition),
    );
  }, [lastComplete, lastPending, beamStatus, queuePosition]);

  return waitTime;
};

export default useEstimatedWaitTime;
