import React from 'react';
import Modal from './Modal';

import './SubscriptionModal.css';
import GoogleLoginComponent from './GoogleLogin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/fontawesome-free-solid';
import SubscriptionLogo from '../Subscription Logo.png';
import NokemonSDMascot from '../nokemon_sdv1_mascot.png';

const options = [
  {
    title: 'Generate Unique Fakemon',
    description: 'One-Of-A-Kind',
  },
  {
    title: 'Gain More Control',
    description: 'Specify dual-types and legendary',
  },
  {
    title: 'Generate Multiple Fakemon At Once',
    description: 'Generate up to 250 unique Fakemon at once',
  },
];

const nokemonImages = [
  'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Grass_type2=Electric_legendary_k=1024_p=0.99_score=122.08161926269531_count=703.png',
];

function IncludesListElement(props) {
  return (
    <div className="SubscriptionModal-IncludesListElement" style={{}}>
      <div
        style={{
          height: '25px',
        }}
      >
        <FontAwesomeIcon
          icon={Icons.faCheck}
          style={{ color: 'darkblue', fontSize: '20px', marginTop: '0px' }}
        />
      </div>

      <div>
        <h4
          style={{
            marginTop: '0px',
            height: '20px',
            fontFamily: "'Open Sans', sans-serif",
          }}
        >
          {props.title}
        </h4>
        <h5
          style={{
            height: '25px',
            marginTop: '-20px',
            marginBottom: '0px',
            color: '#adadad',
            fontFamily: "'Open Sans', sans-serif",
          }}
        >
          {props.description}
        </h5>
      </div>
    </div>
  );
}

export function IncludesList(props) {
  return (
    <div
      data-cy="shopDisplayCardBenefits"
      className="SubscriptionModal-IncludesList"
    >
      {props.options.map(option => {
        return (
          <IncludesListElement
            title={option.title}
            description={option.description}
          />
        );
      })}
    </div>
  );
}

const ModalGoogleLoginButton = ({ renderProps }) => {
  return (
    <button
      className="google-login-button"
      onClick={renderProps.onClick}
      disabled={renderProps.disabled}
    >
      <img
        className="google-logo"
        href="https://www.freepnglogos.com/images/google-logo-9808.html"
      ></img>
      <p className="google-login-button-text">Continue with Google</p>
    </button>
  );
};

const SubscriptionButton = ({ text, icon }) => {
  return (
    <button
      className="google-subscription-button"
      onClick={() =>
        (window.location.href =
          '/marketplace?productType=Credits&mode=0#marketplaceTabs')
      }
    >
      <p className="google-subscription-button-text">{text}</p>
    </button>
  );
};

const SubscriptionModal = ({ isOpen, setModalSettings }) => {
  return (
    <Modal isOpen={isOpen} setModalSettings={setModalSettings}>
      <div className="subscription-modal">
        <h2 className="subscription-modal-title">Advanced Generation</h2>

        <img className="subscription-modal-logo" src={NokemonSDMascot}></img>
        <p className="subscription-modal-subtitle-center">
          <b>Get Credits Now!</b>
        </p>
        {/* <h2
          data-cy="shopDisplayCardAmount"
          className={`ShopDisplay-amount nokepoints`}
          style={{ margin: 0, paddingBottom: '20px' }}
        >
          <p className="dollar-symbol">$</p>
          <p className="dollar-amount">{1}</p>
          <p className="cent-amount">.{99}</p>
          <p className="per-month"> / month</p>
        </h2> */}
        <SubscriptionButton text={'Learn More'} />
        <div className="subscription-modal-hr"></div>

        <IncludesList options={options} />
        {/* <SubscriptionButton
          text={'Novice Subscription'}
          icon={<FontAwesomeIcon icon="fa-solid fa-robot" />}
        />
        <br />
        <SubscriptionButton
          text={'Trainer Subscription'}
          icon={<FontAwesomeIcon icon="fa-solid fa-robot" />}
        />
        <br />
        <SubscriptionButton
          text={'Master Subscription'}
          icon={<FontAwesomeIcon icon="fa-solid fa-robot" />}
        /> */}
      </div>
    </Modal>
  );
};
const SubscriptionModal1 = ({ isOpen, setIsOpen }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="subscription-modal">
        <h2 className="subscription-modal-title">Consider Subscribing!</h2>

        <img
          className="subscription-modal-logo"
          src={
            'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Grass_type2=Electric_legendary_k=1024_p=0.99_score=122.08161926269531_count=703.png'
          }
        ></img>
        <p className="subscription-modal-subtitle-center">
          <b>You have reached your daily limit!</b>
        </p>
        {/* <h2
          data-cy="shopDisplayCardAmount"
          className={`ShopDisplay-amount nokepoints`}
          style={{ margin: 0, paddingBottom: '20px' }}
        >
          <p className="dollar-symbol">$</p>
          <p className="dollar-amount">{1}</p>
          <p className="cent-amount">.{99}</p>
          <p className="per-month"> / month</p>
        </h2> */}
        <SubscriptionButton text={'Learn More!'} />
        <div className="subscription-modal-hr"></div>

        <IncludesList options={options} />
        {/* <SubscriptionButton
          text={'Novice Subscription'}
          icon={<FontAwesomeIcon icon="fa-solid fa-robot" />}
        />
        <br />
        <SubscriptionButton
          text={'Trainer Subscription'}
          icon={<FontAwesomeIcon icon="fa-solid fa-robot" />}
        />
        <br />
        <SubscriptionButton
          text={'Master Subscription'}
          icon={<FontAwesomeIcon icon="fa-solid fa-robot" />}
        /> */}
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
