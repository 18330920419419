// useRandomTip.js
import { useState, useEffect } from 'react';

import CyberAlpaca from '../../../../../../loader_images/cyberpunk_alpaca.png';
import CyberDog from '../../../../../../loader_images/cyberpunk_dog.png';
import SteamFox from '../../../../../../loader_images/steampunk_fox.png';
import SteamMonkey from '../../../../../../loader_images/steampunk_monkey.png';
import BushBaby from '../../../../../../loader_images/bush_baby.png';
import BabyKomodo from '../../../../../../loader_images/baby_komodo.png';

const tips = [
  {
    text: "Try using 'Cyberpunk' style, check out this alpaca!",
    imageSrc: CyberAlpaca,
  },
  {
    text: "Try using 'Steampunk' style, check out this fox!",
    imageSrc: SteamFox,
  },
  {
    text: "Try using 'Cyberpunk' style, check out this dog!",
    imageSrc: CyberDog,
  },
  {
    text: "Try using 'Steampunk' style, check out this monkey!",
    imageSrc: SteamMonkey,
  },
  {
    text: "Try using the keyword 'Baby' to generate a Fakémon with cuter features! Check out this bush baby!",
    imageSrc: BushBaby,
  },
  {
    text: "Try using the keyword 'Baby' to generate a Fakemon with cuter features! Check out this baby komodo dragon!",
    imageSrc: BabyKomodo,
  },
];

/**
 * A custom hook to get a random tip.
 * @param {boolean} isVisible - Determines if the component is visible.
 * @returns {Object|null} A random tip object or null.
 */
export const useRandomTip = isVisible => {
  const [randomTip, setRandomTip] = useState(null);

  useEffect(() => {
    if (isVisible && tips.length > 0) {
      const randomIndex = Math.floor(Math.random() * tips.length);
      setRandomTip(tips[randomIndex]);
    } else {
      setRandomTip(null);
    }
  }, [isVisible]);

  return randomTip;
};

export default useRandomTip;
