// LabeledSelect.js
import React from 'react';
import styled from 'styled-components';
import { RadioButton, RadioGroup } from 'react-radio-buttons';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const InfoIcon = styled.i`
  font-size: 1em;
  color: purple;
  margin-left: 5px;
  cursor: pointer;
`;

const Title = styled.h4`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  text-transform: uppercase;
  color: #191a1a;
`;

// Define your styled components
const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// Define a styled component for the link
const StyledLink = styled.a`
  color: purple;
  text-decoration: none;
  margin-left: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

// Define a styled component for the radio button content
const RadioButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

function LabeledRadioGroup({ title, options, value, onChange }) {
  return (
    <RadioGroupContainer>
      <Title className="inputTitle">{title}:</Title>
      <RadioGroup
        className="radioGroup"
        value={value}
        onChange={onChange}
        horizontal
      >
        {options.map(option => (
          <RadioButton
            value={option.value}
            data-cy={option.dataCy}
            pointColor="#191a1a"
            rootColor="#191a1a"
            disabled={option.isDisabled}
          >
            <RadioButtonContent>
              <span>{option.label}</span>
              {option.value === 'batch' && (
                <>
                  <InfoIcon
                    data-tip={
                      'Batch generation allows you to generate multiple Fakemon at once.'
                    }
                    className="material-icons"
                  >
                    info_outline
                  </InfoIcon>
                  <ReactTooltip
                    place="right"
                    type="dark"
                    effect="solid"
                    style={{
                      fontFamily: 'Open Sans !important',
                      textTransform: 'none !important',
                    }}
                  />
                  {/* <StyledLink href="/information?mode=1#section-what-is-batch-generation">
                    Learn more
                  </StyledLink> */}
                </>
              )}
            </RadioButtonContent>
          </RadioButton>
        ))}
      </RadioGroup>
    </RadioGroupContainer>
  );
}

export default LabeledRadioGroup;
