import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/fontawesome-free-solid';
import { ProSidebar } from 'react-pro-sidebar';
import './ReceiptPage.css';
import SmallCoinsImage from '../Small Coins.png';
import MediumCoinsImage from '../Medium Coins.png';
import BigCoinsImage from '../Big Coins.png';

import {
  NoviceTrainerBadge,
  EliteTrainerBadge,
  MasterTrainerBadge,
} from './SVGComponents';

import { SVGBadgeComponent } from './ShopDisplay';

const productInformation = {
  0: {
    title: 'Credits x20',
    price: '$5.00',
    options: [
      {
        title: 'Credits x20',
        description: 'Generate 20 Premium Nokémon',
      },
      {
        title: 'Batch Generation',
        description: 'Generate up to 200 Premium Nokémon at a time!',
      },
    ],
  },
  1: {
    title: 'Credits x50',
    price: '$10.00',
    options: [
      {
        title: 'Credits x50',
        description: 'Generate 50 Premium Nokémon',
      },
      {
        title: 'Batch Generation',
        description: 'Generate up to 200 Premium Nokémon at a time!',
      },
    ],
  },
  2: {
    title: 'Credits x120',
    price: '$20.00',
    options: [
      {
        title: 'Credits x120',
        description: 'Generate 120 Premium Nokémon',
      },
      {
        title: 'Batch Generation',
        description: 'Generate up to 200 Premium Nokémon at a time!',
      },
    ],
  },
  3: {
    title: 'Novice Trainer',
    price: '$2.00 / month',
    options: [
      {
        title: 'Basic Generation x30',
        description: 'Increase the daily basic generation to x30',
      },
      {
        title: 'Batch Generation x5',
        description: 'Generate up to 5 Nokémon at a time!',
      },
      {
        title: 'Storage x50',
        description: 'Increase your History and Favourites Storage by 50',
      },
      {
        title: 'No Ads!',
        description:
          'Since you are directly supporting the website, you can generate Nokémon ad free!',
      },
    ],
  },
  4: {
    title: 'Elite Trainer',
    price: '$5.00 / month',
    options: [
      {
        title: 'Basic Generation x100',
        description: 'Increase the daily basic generation to x100',
      },
      {
        title: 'Batch Generation x10',
        description: 'Generate up to 10 Nokémon at a time!',
      },
      {
        title: 'Credits x20',
        description: 'Generate 20 Premium Nokémon per month',
      },
      {
        title: 'Storage x200  ',
        description: 'Increase your History and Favourites Storage by 200',
      },
      {
        title: 'No Ads!',
        description:
          'Since you are directly supporting the website, you can generate Nokémon ad free!',
      },
    ],
  },
  5: {
    title: 'Master Trainer',
    price: '$10.00 / month',
    options: [
      {
        title: 'Basic Generation x200',
        description: 'Increase the daily basic generation to x200',
      },
      {
        title: 'Batch Generation x20',
        description: 'Generate up to 20 Nokémon at a time!',
      },
      {
        title: 'Credits x50',
        description: 'Generate x50 Premium Nokémon per month',
      },
      {
        title: 'Storage x500',
        description: 'Increase your History and Favourites Storage by 500',
      },
      {
        title: 'No Ads!',
        description:
          'Since you are directly supporting the website, you can generate Nokémon ad free!',
      },
    ],
  },
};

function IncludesListElement(props) {
  return (
    <div
      className="receiptIncludesListElement"
      style={{
        textAlign: 'left',
        color: 'black',
        display: 'flex',
        gap: '15px',
        minHeight: '55px',
        maxHeight: '55px',
      }}
    >
      <div
        style={{
          height: '25px',
        }}
      >
        <FontAwesomeIcon
          icon={Icons.faCheck}
          style={{ color: 'darkblue', fontSize: '18px', marginTop: '0px' }}
        />
      </div>

      <div style={{}}>
        <h5
          style={{
            marginTop: '0px',
            height: '25px',
            fontFamily: "'Open Sans', sans-serif",
          }}
        >
          {props.title}
        </h5>
        <h5
          style={{
            height: '25px',
            marginTop: '-30px',
            marginBottom: '0px',
            color: '#adadad',
            fontFamily: "'Open Sans', sans-serif",
          }}
        >
          {props.description}
        </h5>
      </div>
    </div>
  );
}

export function IncludesList(props) {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {props.options.map(option => {
        return (
          <IncludesListElement
            title={option.title}
            description={option.description}
          />
        );
      })}
    </div>
  );
}
function ReceiptInformationLine(props) {
  return (
    <div
      className="receiptInformationLine"
      data-cy={props.dataCy}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: '30px',
        paddingRight: '30px',
        color: 'black',
      }}
    >
      <h2
        style={{
          width: '50%',
          textAlign: 'left',
          fontFamily: "'Open Sans', sans-serif",
        }}
      >
        {props.leftText}:{' '}
      </h2>
      <h2
        style={{
          width: '100%',
          textAlign: 'left',
          fontFamily: "'Open Sans', sans-serif",
        }}
      >
        {props.rightText}
      </h2>
    </div>
  );
}

function ReceiptInformation(props) {
  // const purchaseTimestamp = new Date(
  //   Date.parse(props.productIndex['purchaseTimestamp']),
  // );

  return (
    <div className="ReceiptInformation-container" style={{}}>
      <hr style={{ width: '100%' }} />
      <ReceiptInformationLine
        dataCy="receiptEmailText"
        leftText={'Email'}
        rightText={props.userEmail}
      />
      <ReceiptInformationLine
        dataCy="receiptProductText"
        leftText={'Product'}
        rightText={productInformation[props.index]['title']}
      />
      <ReceiptInformationLine
        dataCy="receiptPriceText"
        leftText={'Price'}
        rightText={productInformation[props.index]['price']}
      />
      {productInformation[props.index]['title'].includes('Trainer') ? (
        <>
          <hr style={{ width: '100%' }} />
          <ReceiptInformationLine
            dataCy="receiptPriceText"
            leftText={'Renewal'}
            rightText={props.renewalDate}
          />
          <ReceiptInformationLine
            dataCy="receiptPriceText"
            leftText={'Cancellation'}
            rightText={'Any time - See Manage Subscription'}
          />{' '}
        </>
      ) : (
        <></>
      )}
      <hr style={{ width: '100%' }} />
      <ReceiptInformationLine
        dataCy="receiptBenefitsText"
        leftText={'Benefits'}
        rightText={
          <IncludesList options={productInformation[props.index]['options']} />
        }
      />
      <p
        style={{
          color: 'black',
          fontFamily: "'Open Sans', sans-serif",
          fontSize: '14px',
        }}
      >
        It may take a minute for your account to be updated
      </p>
    </div>
  );
}

function ReceiptTopBorder(props) {
  return (
    <div
      style={{
        width: '100%',
        minHeight: '30px',
        backgroundColor: '#5e18eb',
        borderRadius: '10px 10px 0 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {props.isReceiptLoaded ? (
        <a className="ReceiptHeader-back-button-container" href="/">
          <FontAwesomeIcon
            icon={Icons.faHome}
            style={{ color: 'white', fontSize: '18px', marginTop: '0px' }}
          />
          <span>Return to AI Generator</span>
        </a>
      ) : (
        <></>
      )}
      {/* <FontAwesomeIcon
        icon={Icons.faCheckCircle}
        style={{
          color: 'lightgreen',
          fontSize: '20px',
        }}
      /> */}
    </div>
  );
}

const ReceiptHeader = ({ productIndex }) => {
  const productTitle = productInformation[productIndex]['title'];
  let SVGComponent;

  if (productTitle == 'Novice Trainer') {
    SVGComponent = <NoviceTrainerBadge />;
  } else if (productTitle == 'Elite Trainer') {
    SVGComponent = <EliteTrainerBadge />;
  } else if (productTitle == 'Master Trainer') {
    SVGComponent = <MasterTrainerBadge />;
  } else if (productTitle == 'Credits x20') {
    SVGComponent = (
      <img
        src={SmallCoinsImage}
        style={{
          width: '200px',
          marginTop: '-30px',
        }}
      />
    );
  } else if (productTitle == 'Credits x50') {
    SVGComponent = (
      <img
        src={MediumCoinsImage}
        style={{
          width: '200px',
          marginTop: '-0px',
          marginRight: '20px',
        }}
      />
    );
  } else if (productTitle == 'Credits x120') {
    SVGComponent = (
      <img
        src={BigCoinsImage}
        style={{
          width: '200px',
          marginTop: '-0px',
        }}
      />
    );
  }

  const startText = productTitle.includes('Trainer')
    ? 'You just upgraded to'
    : 'You just purchased';

  return (
    <div className="ReceiptHeader-container">
      <h1>Congratulations!</h1>
      <div className="ReceiptHeader-SVGComponent-container">{SVGComponent}</div>
      <h1>
        {startText} {productInformation[productIndex]['title']}!
      </h1>
    </div>
  );
};

function ReceiptBoundary(props) {
  return (
    <div
      className="receiptBorder"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgb(240, 245, 253)',
        borderRadius: '10px 10px',
        textAlign: 'center',
        maxHeight: '95vh',
        overflow: 'scroll',
        paddingBottom: '20px',
      }}
    >
      <ReceiptTopBorder isReceiptLoaded={props.productIndex != -1} />
      {props.productIndex != -1 ? (
        <>
          <ReceiptHeader productIndex={props.productIndex} />
          <ReceiptInformation
            index={props.productIndex}
            userEmail={props.userEmail}
            renewalDate={props.renewalDate}
          />
        </>
      ) : (
        <>
          <h1
            style={{
              color: 'black',
              padding: '30px',
              fontSize: '30px',
            }}
          >
            Please wait for transaction details... (this might take a few
            seconds)
          </h1>
          <div className="loader" style={{ marginBottom: '40px' }} />
        </>
      )}
    </div>
  );
}

export default function ReceiptPage(props) {
  const [productIndex, setProductIndex] = useState(-1);
  const [userEmail, setUserEmail] = useState(undefined);
  const [renewalDate, setRenewalDate] = useState(undefined);

  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const url = window.location.href;
      const purchaseId = /purchaseId=(.*)/g.exec(url)[1];

      const res = await fetch('/api/stripe/purchase', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          purchaseId: purchaseId,
        }),
      });

      if (res.status != 200) {
        return;
      }

      const body = await res.json();

      setProductIndex(body['productIndex']);
      setUserEmail(body['email']);

      const oneMonthFromNow = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const day = oneMonthFromNow.getDate();
      const ordinal =
        day % 10 == 1 && day % 100 != 11
          ? 'st'
          : day % 10 == 2 && day % 100 != 12
          ? 'nd'
          : day % 10 == 3 && day % 100 != 13
          ? 'rd'
          : 'th';
      const month = monthNames[oneMonthFromNow.getMonth()];
      const year = oneMonthFromNow.getFullYear();

      setRenewalDate(`${day}${ordinal} ${month} ${year}`);
      // Log sessionId in Datastore
    };

    intervalRef.current = setInterval(fetchData, 5000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '25px',
      }}
    >
      <ReceiptBoundary
        productIndex={productIndex}
        userEmail={userEmail}
        renewalDate={renewalDate}
      />
    </div>
  );
}
