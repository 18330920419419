import React, { useState, useEffect, useContext } from 'react';
import { constants } from 'nokemon-common-utils';
import ReactTooltip from 'react-tooltip';

import { KeywordInputBar } from './KeywordInputBar';
import styled from 'styled-components';
import LabeledSelect from '../../Common/LabeledSelect';
import LabeledRadioGroup from '../../Common/LabeledRadioGroup';

import NokemonContext from '../../../context/NokemonContext';

const { types } = constants;

// Define your options outside of your component
const generationOptions = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RadioButtons = styled.input`
  appearance: none;
  background-color: transparent;
  border: 2px solid black;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  outline: none;
  position: relative;

  &:checked:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &:disabled {
    opacity: 0.6;
  }

  &:disabled:checked:before {
    background: gray;
  }
`;
const Label = styled.label`
  cursor: pointer;
  margin-right: 10px;
`;

const InfoIcon = styled.i`
  font-size: 1em;
  color: purple;
  margin-left: 5px;
  cursor: pointer;
`;

const Title = styled.h4`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  text-transform: uppercase;
  color: #191a1a;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
// Define your styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FlexRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModifyGenerationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const FullWidthContainer = styled.div`
  width: 100%;
`;

// Define a reusable component for object input
function ObjectInput({ onObjectChange, defaultValue }) {
  return (
    <FlexContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Title className="inputTitle">Object</Title>
        <InfoIcon
          data-tip={
            'Enter an object to influence image generation. For example, "fox", "bee", or "dog".'
          }
          className="material-icons"
        >
          info_outline
        </InfoIcon>
        <ReactTooltip
          place="right"
          type="dark"
          effect="solid"
          style={{
            fontFamily: 'Open Sans !important',
            textTransform: 'none !important',
          }}
        />
      </div>
      <KeywordInputBar
        onKeywordsChange={onObjectChange}
        maxKeywords={1}
        maxKeywordLength={12}
        defaultValue={defaultValue}
      />
    </FlexContainer>
  );
}

// Define a reusable component for keyword input
function KeywordInput({ onKeywordsChange, defaultValue }) {
  return (
    <FlexContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Title className="inputTitle">
          Modifier Keywords (Press Space After Each Word)
        </Title>
        <InfoIcon
          data-tip={
            'Enter up to 6 keywords to influence image generation. For example, "cute" enhances cuteness, while "red" introduces elements of red in the image.'
          }
          className="material-icons"
        >
          info_outline
        </InfoIcon>
        <ReactTooltip
          place="right"
          type="dark"
          effect="solid"
          style={{
            fontFamily: 'Open Sans !important',
            textTransform: 'none !important',
          }}
        />
        :
      </div>

      <KeywordInputBar
        onKeywordsChange={onKeywordsChange}
        defaultValue={defaultValue}
      />
    </FlexContainer>
  );
}

// Define a reusable component for modify generation
function ModifyGeneration({ seed, resetSeed, setResetSeed }) {
  return (
    <ModifyGenerationContainer>
      <Title className="inputTitle">Modify Previous Generation?</Title>
      <RadioContainer>
        <RadioButtons
          type="radio"
          id="modifyYes"
          name="modify"
          value="yes"
          disabled={seed === undefined}
          onChange={() => setResetSeed(false)}
          checked={resetSeed === false}
        />
        <Label htmlFor="modifyYes">Yes</Label>
        <RadioButtons
          type="radio"
          id="modifyNo"
          name="modify"
          value="no"
          disabled={seed === undefined}
          checked={resetSeed === true}
          onChange={() => setResetSeed(true)}
        />
        <Label htmlFor="modifyNo">No</Label>
      </RadioContainer>
    </ModifyGenerationContainer>
  );
}

// Then, in your main component, you can use these new components like so:
export function AdvancedNewModelOptions(props) {
  const [generatedBy, setGeneratedBy] = useState('type');
  const [type1, setType1] = useState(undefined);
  const [type2, setType2] = useState(undefined);
  const [objectText, setObjectText] = useState('');
  const [optionalText, setOptionalText] = useState('');

  // const { nokemonData } = useContext(NokemonContext);

  // useEffect(() => {
  //   if (nokemonData) {
  //     props.setBatchNum(1);

  //     props.setType1(nokemonData.type1);
  //     setType1(nokemonData.type1);
  //     props.setType2(nokemonData.type2);
  //     setType2(nokemonData.type2);

  //     setObjectText(nokemonData.object_kind);
  //     setOptionalText(nokemonData.optional_text);
  //   }
  // }, [nokemonData]);

  const handleGenerationChange = obj => {
    props.setBatchNum(obj['value']);
    props.setGlobalBatchNum(obj['value']);
  };

  const handleType1Change = obj => {
    props.setSelected(obj['value']);
    setType1(obj['value']);
    props.setType1(obj['value']);
    props.setLegendary('false');
  };

  const handleType2Change = obj => {
    props.setSelected(obj['value']);
    props.setType2(obj['value']);
    setType2(obj['value']);
  };

  const handleGeneratedByChange = value => {
    // Reset batchNum on radio button click
    if (value === 'batch') {
      props.setGlobalBatchNum(5);
      props.setBatchNum(5);
    } else {
      props.setGlobalBatchNum(1);
      props.setBatchNum(1);
    }
    props.setFileName(undefined);

    setGeneratedBy(value);
    props.setGeneratedBy(value);
  };

  return (
    <Container>
      <LabeledRadioGroup
        title="Generate By"
        options={[
          { label: 'Type', value: 'type', dataCy: 'typeRadioButtonAdvanced' },
          {
            label: 'Batch (under maintenance)',
            value: 'batch',
            dataCy: 'batchRadioButtonAdvanced',
            isDisabled: true,
          },
        ]}
        value={generatedBy}
        onChange={handleGeneratedByChange}
      />

      {generatedBy === 'type' && (
        <FlexRowContainer>
          <LabeledSelect
            title="Type 1"
            options={types.filter(type => type.value !== type2)}
            onChange={handleType1Change}
            value={types.find(type => type.value === props.type1)}
            defaultValue={{
              value: type1 ? type1 : undefined,
              label: type1 ? type1 : undefined,
            }}
          />

          <LabeledSelect
            title="Type 2"
            options={[{ value: 'None', label: 'None' }, ...types].filter(
              type => type.value !== type1,
            )}
            value={types.find(type => type.value === props.type2)}
            onChange={handleType2Change}
          />
        </FlexRowContainer>
      )}

      {generatedBy === 'type' && (
        <FullWidthContainer>
          {/* <ObjectInput
            onObjectChange={object => {
              props.setObjectText(object.join(', '));
            }}
            defaultValue={objectText}
          /> */}
          <KeywordInput
            onKeywordsChange={keywords => {
              // keywords is an array of keywords, concatenate them into text.
              props.setOptionalText(keywords.join(', '));
            }}
            defaultValue={optionalText}
          />
          <ModifyGeneration
            seed={props.seed}
            resetSeed={props.resetSeed}
            setResetSeed={props.setResetSeed}
          />
        </FullWidthContainer>
      )}

      {generatedBy === 'batch' && (
        <LabeledSelect
          title="Number Of Generations"
          options={generationOptions}
          onChange={handleGenerationChange}
          defaultValue={{ value: 5, label: 5 }}
        />
      )}
    </Container>
  );
}
