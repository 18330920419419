import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 30% !important;
  left: 50% !important;
  transform: translate(-50%, -25%);
  display: ${props => (props.isVisible ? 'flex' : 'none')}
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 550px;
  min-width: 300px;
  width: 600px;
  background: #21252b;
  border-radius: 5px;
  max-height: 5vh; // Set to 50% of the viewport height
  

  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 300px;
    margin-top: 20px;
    height: auto;
  }

  @media (max-height: 950px) {
    align-items: center;
    justify-content: center;
    position: relative;
    top: 10% !important;
    left: 0% !important;
    transform: translate(0, 0);
  }
`;

export const ImagePlaceholder = styled.div`
  min-height: 180px;
  max-height: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #21252b;
  @media (max-width: 768px) {
    height: 150px;
    min-height: 150px;
  }
`;

export const PlaceholderIcon = styled.img`
  min-width: 180px;
  max-width: 180px;
  transition: opacity 0.3s ease;
  @media (max-width: 768px) {
    width: 30%;
    min-width: 150px;
    max-width: 150px;
  }
`;

export const LoaderText = styled.div`
  text-align: center;
  min-width: 180px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 0.8em;
  color: white;
  width: 100%;
  padding: 10px 20px;
  @media (max-width: 768px) {
    min-width: 150px;
    font-size: clamp(0.7em, 2.5vw, 0.8em);
    padding: 10px;
  }
  @media (max-width: 500px) {
    min-width: 150px;
    font-size: clamp(0.6em, 2.5vw, 0.8em);
  }
`;

export const TipSection = styled.div`
  width: calc(100% - 40px);
  padding: 20px;
  background: #1c1f24;
  color: #fff;
  font-family: 'Inter', sans-serif;

  @media (max-height: 900px) {
    padding-bottom: 0px;
    padding-top: 10px;
  }

  @media (max-height: 715px) {
    // display: none;
  }
`;

export const TipTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  margin-bottom: 5px;

  /* Example of a media query for screen widths 600px or less */
  @media (max-width: 768px) {
    font-size: 0.8em; /* Adjust font size for smaller screens if needed */
  }
`;

export const TipContent = styled.div`
  font-size: 0.9em;
  font-family: 'Inter', sans-serif;

  /* Example of a media query for screen widths 600px or less */
  @media (max-width: 768px) {
    font-size: 0.7em; /* Adjust font size for smaller screens if needed */
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #1c1f24;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const ProgressIndicator = styled.div`
  background-color: #e0e0e0;
  height: 10px;
  border-radius: 5px;
  position: relative;
`;

export const ProgressBar = styled.div.attrs(props => ({
  style: {
    width: `${props.progress}%`,
  },
}))`
  background-color: #6061eb;
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
  box-shadow: none;
  position: relative;
  overflow: hidden;
`;
