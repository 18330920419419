import React, { useState, useEffect } from 'react';
import Input from './Input.js';
import { Output } from './Output.js';
import AdSense from 'react-adsense';

import { usePopper } from 'react-popper';

function SubscriptionIO(props) {
  const [modelType, setModelType] = useState('sd_v1');
  const [generatedBy, setGeneratedBy] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const [random, setRandom] = useState(undefined);
  const [seed, setSeed] = useState(undefined);
  const [resetSeed, setResetSeed] = useState(true);

  const [style, setStyle] = useState('3d model');

  const [isPremium, setIsPremium] = useState(false);
  const [type1, setType1] = useState(undefined);
  const [type2, setType2] = useState(undefined);
  const [legendary, setLegendary] = useState(undefined);
  const [body, setBody] = useState(undefined);
  const [color, setColor] = useState(undefined);
  const [pool, setPool] = useState(undefined);

  const [optionalText, setOptionalText] = useState(undefined);
  const [objectText, setObjectText] = useState(undefined);

  const [batchNum, setBatchNum] = useState(1);
  const [fileName, setFileName] = useState(undefined);

  // useEffect(() => {
  //   if (props.nokemonData) {
  //     setSeed(props.nokemonData['seed']);
  //     setGeneratedBy('type');
  //     setResetSeed(false);
  //   }
  // }, [props.nokemonData]);

  return (
    <div className="io">
      <div className="subscription-io-container">
        <Input
          isLoggedIn={props.isLoggedIn}
          userInfo={props.userInfo}
          setUserInfo={props.setUserInfo}
          sessionId={props.sessionId}
          modelType={modelType}
          setModelType={setModelType}
          setOptionalText={setOptionalText}
          setObjectText={setObjectText}
          setSelected={setSelected}
          setGeneratedBy={setGeneratedBy}
          setStyle={setStyle}
          setRandom={setRandom}
          type1={type1}
          setType1={setType1}
          type2={type2}
          setType2={setType2}
          setBody={setBody}
          setColor={setColor}
          setPool={setPool}
          legendary={legendary}
          setLegendary={setLegendary}
          setIsPremium={setIsPremium}
          setBatchNum={setBatchNum}
          random={random}
          setModalVisible={props.setModalVisible}
          setModalType={props.setModalType}
          generationButtonClicked={props.generationButtonClicked}
          setGenerationButtonClicked={props.setGenerationButtonClicked}
          currencyValueFree={props.currencyValueFree}
          setCurrencyValueFree={props.setCurrencyValueFree}
          setFileName={setFileName}
          fileName={fileName}
          batchNum={batchNum}
          setModalSettings={props.setModalSettings}
          seed={seed}
          setSeed={setSeed}
          resetSeed={resetSeed}
          setResetSeed={setResetSeed}
        />
        <Output
          uniqueIdentifier={props.uniqueIdentifier}
          isLoggedIn={props.isLoggedIn}
          modelType={modelType}
          optionalText={optionalText}
          objectText={objectText}
          selected={selected}
          generatedBy={generatedBy}
          style={style}
          random={random}
          type1={type1}
          type2={type2}
          body={body}
          color={color}
          legendary={legendary}
          setType1={setType1}
          setType2={setType2}
          setLegendary={setLegendary}
          pool={pool}
          batchNum={batchNum}
          sessionId={props.sessionId}
          isPremium={isPremium}
          fileName={fileName}
          setFileName={setFileName}
          setModalSettings={props.setModalSettings}
          userInfo={props.userInfo}
          setUserInfo={props.setUserInfo}
          generationButtonClicked={props.generationButtonClicked}
          setGenerationButtonClicked={props.setGenerationButtonClicked}
          seed={seed}
          setSeed={setSeed}
          resetSeed={resetSeed}
          setResetSeed={setResetSeed}
          // nokemonData={props.nokemonData}
        />
      </div>
    </div>
  );
}

function IO(props) {
  const [modelType, setModelType] = useState('sd_v1');
  const [generatedBy, setGeneratedBy] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const [random, setRandom] = useState(undefined);
  const [seed, setSeed] = useState(undefined);
  const [resetSeed, setResetSeed] = useState(true);

  const [style, setStyle] = useState('3d model');

  const [isPremium, setIsPremium] = useState(false);
  const [type1, setType1] = useState(undefined);
  const [type2, setType2] = useState(undefined);
  const [legendary, setLegendary] = useState(undefined);
  const [body, setBody] = useState(undefined);
  const [color, setColor] = useState(undefined);
  const [pool, setPool] = useState(undefined);

  const [optionalText, setOptionalText] = useState(undefined);
  const [objectText, setObjectText] = useState(undefined);

  const [batchNum, setBatchNum] = useState(1);
  const [fileName, setFileName] = useState(undefined);

  return (
    <div className="io">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Input
          isLoggedIn={props.isLoggedIn}
          userInfo={props.userInfo}
          setUserInfo={props.setUserInfo}
          sessionId={props.sessionId}
          modelType={modelType}
          setModelType={setModelType}
          setOptionalText={setOptionalText}
          setObjectText={setObjectText}
          setSelected={setSelected}
          setGeneratedBy={setGeneratedBy}
          setStyle={setStyle}
          setRandom={setRandom}
          type1={type1}
          setType1={setType1}
          type2={type2}
          setType2={setType2}
          setBody={setBody}
          setColor={setColor}
          setPool={setPool}
          legendary={legendary}
          setLegendary={setLegendary}
          setIsPremium={setIsPremium}
          setBatchNum={setBatchNum}
          random={random}
          setModalVisible={props.setModalVisible}
          setModalType={props.setModalType}
          generationButtonClicked={props.generationButtonClicked}
          setGenerationButtonClicked={props.setGenerationButtonClicked}
          currencyValueFree={props.currencyValueFree}
          setCurrencyValueFree={props.setCurrencyValueFree}
          setFileName={setFileName}
          fileName={fileName}
          batchNum={batchNum}
          setModalSettings={props.setModalSettings}
          seed={seed}
          setSeed={setSeed}
          resetSeed={resetSeed}
          setResetSeed={setResetSeed}
        />
        {props.userInfo['proStatus'] == 0 &&
        props.userInfo['subscriptionTier'] == 0 &&
        window.innerWidth >= 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '65vw',
              height: '280px',
              maxHeight: '280px',
              minWidth: '300px',
              maxWidth: '900px',
              // backgroundColor: 'black',
            }}
          >
            <AdSense.Google
              client="ca-pub-4838487336494546"
              slot="6757796101"
              // style={{ width: '336px', height: '280px' }}
              style={{
                width: '65vw',
                maxHeight: '280px',
                minWidth: '300px',
                maxWidth: '900px',
              }}
              format=""
            />
          </div>
        ) : (
          <span />
        )}
        <Output
          uniqueIdentifier={props.uniqueIdentifier}
          isLoggedIn={props.isLoggedIn}
          modelType={modelType}
          optionalText={optionalText}
          objectText={objectText}
          selected={selected}
          generatedBy={generatedBy}
          style={style}
          random={random}
          type1={type1}
          type2={type2}
          body={body}
          color={color}
          legendary={legendary}
          setType1={setType1}
          setType2={setType2}
          setLegendary={setLegendary}
          pool={pool}
          batchNum={batchNum}
          sessionId={props.sessionId}
          isPremium={isPremium}
          fileName={fileName}
          setFileName={setFileName}
          setModalSettings={props.setModalSettings}
          userInfo={props.userInfo}
          setUserInfo={props.setUserInfo}
          generationButtonClicked={props.generationButtonClicked}
          setGenerationButtonClicked={props.setGenerationButtonClicked}
          seed={seed}
          setSeed={setSeed}
          resetSeed={resetSeed}
          setResetSeed={setResetSeed}
        />

        {props.userInfo['proStatus'] == 0 &&
        props.userInfo['subscriptionTier'] == 0 &&
        window.innerWidth >= 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '65vw',
              height: '280px',
              maxHeight: '280px',
              minWidth: '300px',
              maxWidth: '900px',
              // backgroundColor: 'black',
            }}
          >
            <AdSense.Google
              client="ca-pub-4838487336494546"
              slot="7687486973"
              style={{
                width: '65vw',
                height: '280px',
                maxHeight: '280px',
                minWidth: '300px',
                maxWidth: '900px',
              }}
              format=""
            />
          </div>
        ) : (
          <span />
        )}
      </div>
    </div>
  );
}

export { IO, SubscriptionIO };
