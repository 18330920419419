export function recordUserInteraction(userId, eventType, eventDetails) {
  fetch('/api/userInteractions/log', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId, eventType, eventDetails }),
  })
    .then(response => {
      if (!response.ok) {
        // Log to a monitoring service instead of throwing an error
        console.error(
          'Interaction logging failed with status:',
          response.status,
        );
      }
      return response.json(); // Process the response if needed, even if just for logging
    })
    .then(data => {
      // Optionally log the successful logging operation or handle the data
    })
    .catch(error => {
      // Log the error silently to a monitoring service or console for debugging purposes
      console.error('There was a problem with the fetch operation:', error);
    });

  // No need to return anything since this is a fire-and-forget logging operation
}
