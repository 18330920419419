import './App.css';

import { Sidebar } from './components/Sidebar';
import {
  StorageContainer,
  SharingContainer,
  FAQContainer,
  ReceiptContainer,
  ClassicGeneratorContainer,
} from './components/Container';

import ReferralContainer from './components/ReferralPage/Container';
import GeneratorContainer from './components/GeneratorPage/';
import MarketplaceContainer from './components/MarketplacePage/';

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route, useFetcher } from 'react-router-dom';
import Header from './components/Header';
import * as apiHelpers from './api-helpers';
import { DefaultDict } from 'nokemon-common-utils';
import Loader from './components/Loader';
import LoginModal from './components/LoginModal.js';
import GenericModal from './components/GenericModal.js';
import AwardNokepointsPage from './components/CompetitionPage';
import { NokemonProvider } from './context/NokemonContext';

import { useUserContext } from './context/UserContext';
import InfoPage from './components/InformationPage/InfoPage';
import TermsOfServicePage from './components/TermsOfServicePage/TermsOfServicePage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage/PrivacyPolicyPage';
import { EightGenerationLeftModal } from './components/Modals/';

function setStorageId() {
  let storageId = localStorage.getItem('storageId');
  let startSessionTime = localStorage.getItem('startSessionTime');

  if (!storageId || !startSessionTime) {
    storageId = uuidv4();
    localStorage.setItem('storageId', storageId);
    localStorage.setItem('startSessionTime', Date.now());
  }

  return storageId;
}

async function generateIdentifier() {
  const screenSize = `${window.screen.width}x${window.screen.height}`;
  const userAgent = window.navigator.userAgent;
  const language = window.navigator.language;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const colorDepth = window.screen.colorDepth;
  const hardwareConcurrency = window.navigator.hardwareConcurrency;
  const platform = window.navigator.platform;

  const text = `${screenSize}-${userAgent}-${language}-${timezone}-${colorDepth}-${hardwareConcurrency}-${platform}`;

  return btoa(text);
}

function App() {
  const userContext = useUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const [modalSettings, setModalSettings] = useState({});

  useEffect(() => {
    if (userContext.isLoggedIn) {
      if (userContext.userInfo.name !== 0) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    } else {
      setIsLoading(false);
    }
  }, [userContext]);

  useEffect(() => {
    const storageId = userContext.storageId;

    // Show the batch generation modal if the user has generated premium nokemon
    const showBatchGenerationModal = userInfo => {
      return new Promise(resolve => {
        if (
          userInfo.numPremiumNokemon > 0 &&
          !localStorage.getItem(`modalShown-batch-${storageId}`)
        ) {
          setTimeout(() => {
            setModalSettings({
              modalType: 'nokepointsBatchGeneration',
            });
            localStorage.setItem(`modalShown-batch-${storageId}`, 'true');
            resolve();
          }, 5000);
        } else {
          resolve();
        }
      });
    };

    // Show the image guided modal if it hasn't been shown before
    const showImageGuidedModal = () => {
      // if (!localStorage.getItem(`modalShown-annoucement-${storageId}`)) {
      //   setTimeout(() => {
      //     setModalSettings({
      //       modalType: 'nokepointsImageGuided',
      //     });
      //     // localStorage.setItem(`modalShown-${storageId}`, 'true');
      //     localStorage.setItem(`modalShown-annoucement-${storageId}`, true);
      //   }, 3000);
      // }
    };

    // Execute the functions
    (async () => {
      showImageGuidedModal();
    })();
  }, []);

  return (
    <>
      <div
        className="App"
        style={{
          overflow: 'hidden ',
        }}
      >
        {/* {isLoading ? <Loader /> : <></>} */}
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {/* <Generator /> */}
                  <GenericModal
                    modalSettings={modalSettings}
                    setModalSettings={setModalSettings}
                  />
                  <GeneratorContainer
                    userContext={userContext}
                    setModalSettings={setModalSettings}
                    setIsLoading={setIsLoading}
                  />
                </>
              }
            />
            <Route
              path="/marketplace"
              element={
                <>
                  {/* <Generator /> */}
                  <MarketplaceContainer
                    userContext={userContext}
                    setModalSettings={setModalSettings}
                    setIsLoading={setIsLoading}
                  />
                </>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <>
                  {/* <Generator /> */}
                  <PrivacyPolicyPage />
                </>
              }
            />
            <Route
              path="/terms-of-service"
              element={
                <>
                  {/* <Generator /> */}
                  <TermsOfServicePage />
                </>
              }
            />
            <Route
              path="/classic-generator"
              element={
                <>
                  {/* <Generator /> */}
                  <GenericModal
                    modalSettings={modalSettings}
                    setModalSettings={setModalSettings}
                  />
                  <ClassicGeneratorContainer
                    userContext={userContext}
                    setModalSettings={setModalSettings}
                    setIsLoading={setIsLoading}
                  />
                </>
              }
            />
            <Route
              path="/redeem"
              element={
                <AwardNokepointsPage
                  isLoggedIn={userContext.isLoggedIn}
                  setUserInfo={userContext.setUserInfo}
                  userInfo={userContext.userInfo}
                />
              }
            />
            <Route
              path="/storage"
              element={
                <>
                  <GenericModal
                    modalSettings={modalSettings}
                    setModalSettings={setModalSettings}
                  />
                  <StorageContainer
                    userContext={userContext}
                    userInfo={userContext.userInfo}
                    currencyValue={userContext.currencyValue}
                    setCurrencyValue={userContext.setCurrencyValue}
                    currencyValueFree={userContext.currencyValueFree}
                    setCurrencyValueFree={userContext.setCurrencyValueFree}
                    isLoading={isLoading}
                    isLoggedIn={userContext.isLoggedIn}
                    setIsLoggedIn={userContext.setIsLoggedIn}
                    setIsLoading={setIsLoading}
                    setModalSettings={setModalSettings}
                  />
                </>
              }
            />
            <Route
              path="/information"
              element={
                // <FAQContainer
                //   userContext={userContext}
                //   isLoggedIn={userContext.isLoggedIn}
                //   setIsLoggedIn={userContext.setIsLoggedIn}
                //   userInfo={userContext.userInfo}
                //   setIsLoading={setIsLoading}
                //   setModalSettings={setModalSettings}
                // />
                <InfoPage />
              }
            />
            {/* <Route
              path="/support"
              element={
                <ShopContainer
                  userContext={userContext}
                  isLoggedIn={userContext.isLoggedIn}
                  setIsLoggedIn={userContext.setIsLoggedIn}
                  userInfo={userContext.userInfo}
                  setIsLoading={setIsLoading}
                  setModalSettings={setModalSettings}
                />
              }
            /> */}
            <Route
              path="/sharing"
              element={
                <SharingContainer
                  userContext={userContext}
                  isLoggedIn={userContext.isLoggedIn}
                  setIsLoggedIn={userContext.setIsLoggedIn}
                  userInfo={userContext.userInfo}
                  setIsLoading={setIsLoading}
                />
              }
            />
            {/* <Route
              path="/classic-marketplace"
              element={
                <>
                  <GenericModal
                    modalSettings={modalSettings}
                    setModalSettings={setModalSettings}
                  />
                  <ShopContainer
                    userContext={userContext}
                    isLoggedIn={userContext.isLoggedIn}
                    setIsLoggedIn={userContext.setIsLoggedIn}
                    userInfo={userContext.userInfo}
                    setIsLoading={setIsLoading}
                    setModalSettings={setModalSettings}
                  />
                </>
              }
            /> */}
            {/* <Route
              path="/faq"
              element={
                <FAQ
                  isLoggedIn={userContext.isLoggedIn}
                  isLoading={isLoading}
                  setIsLoggedIn={userContext.setIsLoggedIn}
                  userInfo={userContext.userInfo}
                  setUserInfo={userContext.setUserInfo}
                  currencyValue={userContext.currencyValue}
                  setCurrencyValue={userContext.setCurrencyValue}
                  currencyValueFree={userContext.currencyValueFree}
                  setCurrencyValueFree={userContext.setCurrencyValueFree}
                  sessionId={userContext.sessionId}
                  uniqueIdentifier={userContext.uniqueIdentifier}
                  setIsLoading={setIsLoading}
                  setModalSettings={setModalSettings}
                />
              }
            /> */}

            <Route
              path="/success"
              element={<ReceiptContainer isLoggedIn={userContext.isLoggedIn} />}
            />
            <Route
              path="/referral"
              element={<ReferralContainer userContext={userContext} />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

function FAQ(props) {
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Sidebar
        isLoggedIn={props.isLoggedIn}
        setIsLoggedIn={props.setIsLoggedIn}
        userInfo={props.userInfo}
        setIsLoading={props.setIsLoading}
      />
      <FAQContainer setIsLoggedIn={props.setIsLoggedIn} />

      <div />
    </div>
  );
}

// function ManageSubscription(props) {
//   <div
//     style={{
//       display: 'flex',
//     }}
//   >
//     <Sidebar
//       isLoggedIn={props.isLoggedIn}
//       setIsLoggedIn={props.setIsLoggedIn}
//       userInfo={props.userInfo}
//     />
//     <SharingContainer setIsLoggedIn={props.setIsLoggedIn} />
//   </div>;
// }

export default App;
