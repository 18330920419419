import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { faTimes, faCopy } from '@fortawesome/free-solid-svg-icons';
import styled, { keyframes } from 'styled-components';
import LoginModal from '../LoginModal/LoginModal';
import { InteractiveComponent } from '../InformationPage/subcomponents/CreateYourOwnSection';
import { toast } from 'react-toastify';

import { recordUserInteraction } from '../../utils/userInteractionsLogger/userInteractionLogger';
import { retrieveUserEmailHashFromCookie } from '../../utils/users/users';

import { createStripeCheckoutSession } from '../MarketplacePage/subcomponents/ProductButton';

// Animation
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Modal Overlay
const ModalOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`;

// Modal Wrapper
const ModalWrapper = styled.div`
  animation: ${fadeIn} 0.3s ease-out;
  background-color: #181a1b;
  color: #ffffff;
  padding: 24px 50px;
  border-radius: 4px;
  width: auto;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-top: 4px solid;
  border-image: linear-gradient(to right, #4279eb, #934ac8, #d05e6a) 1;

  @media (max-width: 500px) {
    width: 250px;
  }
`;

// Modal Heading
const ModalHeading = styled.h2`
  font-size: 24px;
  color: #ffffff;
  font-weight: 500;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// Generations Remaining
const GenerationsRemaining = styled.div`
  font-size: 20px; // Larger font size for emphasis
  color: #4279eb; // Accent color for prominence
  margin: 20px 0 20px 0;
  font-weight: 500; // Bolder weight for importance
  text-align: center;
`;

// Modal Body
const ModalBody = styled.p`
  font-size: 16px;
  color: #e0e0e0;
  margin: 10px 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

// Primary Button
const PrimaryButton = styled.button`
  background: linear-gradient(45deg, #4279eb, #934ac8);
  color: #ffffff;
  border: none;
  padding: 12px 40px;
  margin-top: 18px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
  box-shadow: 0 1px 2px 0 rgba(67, 121, 235, 0.3);
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(67, 121, 235, 0.3);
    transform: translateY(-2px);
  }
`;

// Secondary Button
const SecondaryButton = styled.button`
  background-color: transparent;
  color: #d05e6a;
  border: none;
  padding: 10px 24px;
  margin-top: 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #292b2d;
  }
`;

// Icon Component
const Icon = styled.span`
  color: #f4b400;
  color: white;
  font-size: 32px;
  margin-bottom: 16px; // Added some spacing

  @media (max-width: 512px) {
    font-size: 20px;
  }
`;

const CloseButton = styled.button`
  align-self: flex-end;
  position: relative;
  height: 0;
  left: 4%;

  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;

  &:hover {
    color: #e0e0e0;
  }

  @media (max-width: 768px) {
    left: 10%;
  }
`;

// SignInButton component
const SignInButton = ({ userContext }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalCenterVisible, setModalCenterVisible] = useState(true);

  const handleLoginClick = () => {
    setModalVisible(true);
    setModalCenterVisible(true);
  };

  const handleLoading = () => {
    setModalCenterVisible(false);
  };

  const handleCloseModal = () => {
    setModalCenterVisible(false);
    setModalVisible(false);
  };

  return (
    <>
      <PrimaryButton onClick={handleLoginClick}>Sign In</PrimaryButton>
      {isModalVisible && (
        <LoginModal
          isVisible={isModalVisible}
          isModalCenterVisible={isModalCenterVisible}
          onLoginSuccess={handleLoading}
          onLoginFailure={handleCloseModal}
          userContext={userContext}
        />
      )}
    </>
  );
};

export default SignInButton;

export const SubscriptionPromptModal = ({ isVisible, onClose }) => {
  const [modalOpenTime, setModalOpenTime] = useState(null);

  useEffect(() => {
    if (isVisible) {
      setModalOpenTime(Date.now());
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <ModalOverlay>
      <ModalWrapper>
        <CloseButton
          onClick={async () => {
            onClose();
            const userId = await retrieveUserEmailHashFromCookie(
              localStorage.getItem('jwt'),
            );
            const eventType = 'ModalInteraction';
            const durationInSeconds = Math.round(
              (Date.now() - modalOpenTime) / 1000,
            );
            // Log the interaction details
            const eventDetails = {
              modal: 'SubscriptionPromptModal',
              openDuration: durationInSeconds,
              specificAction: 'Modal Closed', // Additional detail about the interaction
            };

            // Now call the recordUserInteraction function with the parameters
            recordUserInteraction(userId, eventType, eventDetails);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <Icon>✨</Icon>
        <ModalHeading>Keep Your Inspiration Flowing!</ModalHeading>{' '}
        <GenerationsRemaining>
          Looks like you're out of free generations for today.
        </GenerationsRemaining>
        <PrimaryButton
          onClick={async () => {
            // Calculate duration before closing the modal
            const durationInSeconds = Math.round(
              (Date.now() - modalOpenTime) / 1000,
            );

            // Retrieve the userId
            const userId = await retrieveUserEmailHashFromCookie(
              localStorage.getItem('jwt'),
            );

            // Log the interaction details
            const eventDetails = {
              modal: 'SubscriptionPromptModal',
              actionTaken: true, // Since the user has interacted by copying the code
              openDuration: durationInSeconds,
              specificAction: 'Click CTA', // Additional detail about the interaction
            };

            // Assuming eventType is 'ModalInteraction'
            recordUserInteraction(userId, 'ModalInteraction', eventDetails);
            /* Redirect to credits purchase page */
            window.location.href = '/marketplace#modal';
          }}
        >
          Subscribe Now
        </PrimaryButton>
        <br />
        <ModalBody>Subscribe to unlock more daily generations!</ModalBody>{' '}
      </ModalWrapper>
    </ModalOverlay>
  );
};

export const UpgradeSubscriptionPromptModal = ({ isVisible, onClose }) => {
  const [modalOpenTime, setModalOpenTime] = useState(null);

  useEffect(() => {
    if (isVisible) {
      setModalOpenTime(Date.now());
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <ModalOverlay>
      <ModalWrapper>
        <CloseButton
          onClick={async () => {
            onClose();
            const userId = await retrieveUserEmailHashFromCookie(
              localStorage.getItem('jwt'),
            );
            const eventType = 'ModalInteraction';
            const durationInSeconds = Math.round(
              (Date.now() - modalOpenTime) / 1000,
            );
            // Log the interaction details
            const eventDetails = {
              modal: 'UpgradeSubscriptionPromptModal',
              openDuration: durationInSeconds,
              specificAction: 'Modal Closed', // Additional detail about the interaction
            };

            // Now call the recordUserInteraction function with the parameters
            recordUserInteraction(userId, eventType, eventDetails);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <Icon>🚀</Icon>
        <ModalHeading>Upgrade Your Subscription</ModalHeading>
        <GenerationsRemaining>
          Looks like you're out of free generations for today.
        </GenerationsRemaining>
        <PrimaryButton
          onClick={async () => {
            // Calculate duration before closing the modal
            const durationInSeconds = Math.round(
              (Date.now() - modalOpenTime) / 1000,
            );

            // Retrieve the userId
            const userId = await retrieveUserEmailHashFromCookie(
              localStorage.getItem('jwt'),
            );

            // Log the interaction details
            const eventDetails = {
              modal: 'UpgradeSubscriptionPromptModal',
              actionTaken: true, // Since the user has interacted by copying the code
              openDuration: durationInSeconds,
              specificAction: 'Click CTA', // Additional detail about the interaction
            };

            // Assuming eventType is 'ModalInteraction'
            recordUserInteraction(userId, 'ModalInteraction', eventDetails);
            /* Redirect to credits purchase page */
            window.location.href = '/marketplace#modal';
          }}
        >
          Upgrade Now
        </PrimaryButton>
        <br />
        <ModalBody>
          Unlock more generations by upgrading your subscription
        </ModalBody>
      </ModalWrapper>
    </ModalOverlay>
  );
};

const BenefitsHeading = styled.h3`
  font-size: 24px;
  color: #e0e0e0;
  text-align: center;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const BenefitsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;

  li {
    font-size: 16px;
    color: #e0e0e0;
    margin-bottom: 12px;

    &::before {
      content: '✓';
      color: #4279eb;
      margin-right: 8px;
    }
  }

  @media (max-width: 768px) {
    li {
      font-size: 14px;
    }
  }
`;

export const PurchaseCreditsModal = ({ isVisible, onClose }) => {
  const [modalOpenTime, setModalOpenTime] = useState(null);

  useEffect(() => {
    if (isVisible) {
      setModalOpenTime(Date.now());
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <ModalOverlay>
      <ModalWrapper>
        <CloseButton
          onClick={async () => {
            onClose();
            const userId = await retrieveUserEmailHashFromCookie(
              localStorage.getItem('jwt'),
            );
            const eventType = 'ModalInteraction';
            const durationInSeconds = Math.round(
              (Date.now() - modalOpenTime) / 1000,
            );
            // Log the interaction details
            const eventDetails = {
              modal: 'PurchaseCreditsModal',
              openDuration: durationInSeconds,
              specificAction: 'Modal Closed', // Additional detail about the interaction
            };

            // Now call the recordUserInteraction function with the parameters
            recordUserInteraction(userId, eventType, eventDetails);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <ModalHeading>Try Create Your Own Mode</ModalHeading>
        <ModalBody>
          You've mastered the basics, now design Fakémon without limits!
        </ModalBody>
        <div style={{ margin: '10px 0 30px 0', width: '100%' }}>
          <InteractiveComponent smallMode={true} />
        </div>
        <BenefitsList>
          <li>Create truly unique Fakémon with your own descriptions.</li>
          <li>Get four variations of each design for even more inspiration.</li>
          <li>Experiment with fine-tuning your designs to your liking.</li>
        </BenefitsList>
        <PrimaryButton
          onClick={async () => {
            // Calculate duration before closing the modal
            const durationInSeconds = Math.round(
              (Date.now() - modalOpenTime) / 1000,
            );

            // Retrieve the userId
            const userId = await retrieveUserEmailHashFromCookie(
              localStorage.getItem('jwt'),
            );

            // Log the interaction details
            const eventDetails = {
              modal: 'PurchaseCreditsModal',
              actionTaken: true, // Since the user has interacted by copying the code
              openDuration: durationInSeconds,
              specificAction: 'Click CTA', // Additional detail about the interaction
            };

            // Assuming eventType is 'ModalInteraction'
            recordUserInteraction(userId, 'ModalInteraction', eventDetails);
            /* Redirect to credits purchase page */
            window.location.href = '/marketplace?mode=credits#modal';
          }}
        >
          Get Credits
        </PrimaryButton>
      </ModalWrapper>
    </ModalOverlay>
  );
};
// Modal Component
export const EightGenerationsLeftModal = ({
  isVisible,
  onClose,
  handleModelVersionChange,
  userContext,
}) => {
  if (!isVisible) return null;

  return (
    <ModalOverlay>
      <ModalWrapper>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <Icon>✨</Icon>
        <ModalHeading>Keep Exploring!</ModalHeading>
        <GenerationsRemaining>
          You have <strong>8 generations</strong> remaining today.
        </GenerationsRemaining>
        <ModalBody>Sign in to unlock even more daily generations.</ModalBody>
        <ModalBody>
          Or, switch to the classic models to generate more!
        </ModalBody>
        <SignInButton userContext={userContext}>Sign In</SignInButton>
        <SecondaryButton
          onClick={() => {
            if (onClose) {
              handleModelVersionChange('modelv2');
              onClose();
            }
          }}
        >
          Explore Classic Models
        </SecondaryButton>
      </ModalWrapper>
    </ModalOverlay>
  );
};

// Modal Component
export const ZeroGenerationsLeftModal = ({
  isVisible,
  onClose,
  handleModelVersionChange,
  userContext,
}) => {
  if (!isVisible) return null;

  return (
    <ModalOverlay>
      <ModalWrapper>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <Icon>✨</Icon>
        <ModalHeading>Keep Your Creations Going!</ModalHeading>
        <GenerationsRemaining>
          You've reached your daily limit today.
        </GenerationsRemaining>
        <ModalBody>Sign in to unlock even more daily generations.</ModalBody>
        <ModalBody>
          Or, switch to the classic models to generate more!
        </ModalBody>
        <SignInButton userContext={userContext}>Sign In</SignInButton>
        <SecondaryButton
          onClick={() => {
            if (onClose) {
              handleModelVersionChange('modelv2');
              onClose();
            }
          }}
        >
          Explore Classic Models
        </SecondaryButton>
      </ModalWrapper>
    </ModalOverlay>
  );
};

const handleCopyPromoCode = async (promoCode, onSuccess) => {
  try {
    await navigator.clipboard.writeText(promoCode);
    console.log('Promo code copied to clipboard!');

    if (onSuccess) {
      onSuccess(); // Execute the callback function if provided
    }
  } catch (error) {
    console.error('Failed to copy promo code:', error);
  }
};

export const PromoCodeModal = ({ isVisible, onClose }) => {
  const [promoCode, setPromoCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [modalOpenTime, setModalOpenTime] = useState(null);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setModalOpenTime(Date.now());
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) return;

    setIsLoading(true);
    const fetchPromoCode = async () => {
      try {
        const token = localStorage.getItem('jwt');
        if (!token) {
          throw new Error('User is not authenticated.');
        }

        const response = await fetch('/api/user/promoCode/get', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          // Handle different response statuses explicitly for more specific error messages
          if (response.status === 401) {
            throw new Error('Authentication required. Please log in.');
          } else if (response.status === 404) {
            throw new Error('Promo code not found.');
          } else {
            throw new Error('Failed to fetch promo code.');
          }
        }

        const data = await response.json();

        if (data.promoCode) {
          setPromoCode(data.promoCode);
        } else {
          throw new Error(data.error || 'Promo code not available.');
        }
      } catch (error) {
        console.error('Error fetching promo code:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPromoCode();
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <ModalOverlay>
      <ModalWrapper>
        <CloseButton
          onClick={async () => {
            onClose();
            const userId = await retrieveUserEmailHashFromCookie(
              localStorage.getItem('jwt'),
            );
            const eventType = 'ModalInteraction';
            const durationInSeconds = Math.round(
              (Date.now() - modalOpenTime) / 1000,
            );
            // Log the interaction details
            const eventDetails = {
              modal: 'PromoCodeModal',
              actionTaken: userInteracted, // Since the user has interacted by copying the code
              openDuration: durationInSeconds,
              specificAction: 'Modal Closed', // Additional detail about the interaction
            };

            // Now call the recordUserInteraction function with the parameters
            recordUserInteraction(userId, eventType, eventDetails);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <Icon>🎉</Icon>
        <ModalHeading>Your Dedication Inspires Us!</ModalHeading>
        <ModalBody>
          You're constantly exploring the power of our AI generator – we love
          it!
        </ModalBody>
        <PromoCodeDisplay>
          {isLoading ? (
            <PromoCode>Loading your exclusive promo code...</PromoCode>
          ) : error ? (
            <PromoCode>{error}</PromoCode>
          ) : (
            <>
              <PromoCodeText>
                As a token of our appreciation, enjoy <strong>25% off</strong>{' '}
                your next purchase with code:
              </PromoCodeText>
              <PromoCodeContainer>
                <PromoCode>
                  {promoCode}
                  <CopyButton
                    onClick={() => {
                      // Immediately mark as interacted
                      setUserInteracted(true);

                      // Perform the copy operation and log the interaction on success
                      handleCopyPromoCode(promoCode, async () => {
                        // Calculate duration before closing the modal
                        const durationInSeconds = Math.round(
                          (Date.now() - modalOpenTime) / 1000,
                        );

                        // Retrieve the userId
                        const userId = await retrieveUserEmailHashFromCookie(
                          localStorage.getItem('jwt'),
                        );

                        // Log the interaction details
                        const eventDetails = {
                          modal: 'PromoCodeModal',
                          actionTaken: true, // Since the user has interacted by copying the code
                          openDuration: durationInSeconds,
                          specificAction: 'Copy Promo Code', // Additional detail about the interaction
                        };

                        // Assuming eventType is 'ModalInteraction'
                        recordUserInteraction(
                          userId,
                          'ModalInteraction',
                          eventDetails,
                        );
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </CopyButton>
                </PromoCode>
              </PromoCodeContainer>
            </>
          )}
        </PromoCodeDisplay>
        <ModalBody>Enjoy exploring what you can create next!</ModalBody>
        <PrimaryButton
          onClick={async () => {
            // Calculate duration before closing the modal
            const durationInSeconds = Math.round(
              (Date.now() - modalOpenTime) / 1000,
            );

            // Retrieve the userId
            const userId = await retrieveUserEmailHashFromCookie(
              localStorage.getItem('jwt'),
            );

            // Log the interaction details
            const eventDetails = {
              modal: 'PromoCodeModal',
              actionTaken: true, // Since the user has interacted by copying the code
              openDuration: durationInSeconds,
              specificAction: 'Click CTA', // Additional detail about the interaction
            };

            // Assuming eventType is 'ModalInteraction'
            recordUserInteraction(userId, 'ModalInteraction', eventDetails);
            /* Redirect to credits purchase page */
            window.location.href = '/marketplace#modal';
          }}
        >
          Browse Marketplace
        </PrimaryButton>
      </ModalWrapper>
    </ModalOverlay>
  );
};
export const FreeTrialModal = ({ isVisible, onClose }) => {
  const [promoCode, setPromoCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [modalOpenTime, setModalOpenTime] = useState(null);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setModalOpenTime(Date.now());
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) return;

    setIsLoading(true);
    const fetchPromoCode = async () => {
      try {
        const token = localStorage.getItem('jwt');
        if (!token) {
          throw new Error('User is not authenticated.');
        }

        const response = await fetch('/api/user/promoCode/get', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          // Handle different response statuses explicitly for more specific error messages
          if (response.status === 401) {
            throw new Error('Authentication required. Please log in.');
          } else if (response.status === 404) {
            throw new Error('Promo code not found.');
          } else {
            throw new Error('Failed to fetch promo code.');
          }
        }

        const data = await response.json();

        if (data.promoCode) {
          setPromoCode(data.promoCode);
        } else {
          throw new Error(data.error || 'Promo code not available.');
        }
      } catch (error) {
        console.error('Error fetching promo code:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPromoCode();
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <ModalOverlay>
      <ModalWrapper>
        <CloseButton
          onClick={async () => {
            onClose();
            const userId = await retrieveUserEmailHashFromCookie(
              localStorage.getItem('jwt'),
            );
            const eventType = 'FreeTrialModal';
            const durationInSeconds = Math.round(
              (Date.now() - modalOpenTime) / 1000,
            );
            // Log the interaction details
            const eventDetails = {
              modal: 'PromoCodeModal',
              actionTaken: userInteracted, // Since the user has interacted by copying the code
              openDuration: durationInSeconds,
              specificAction: 'Modal Closed', // Additional detail about the interaction
            };

            // Now call the recordUserInteraction function with the parameters
            recordUserInteraction(userId, eventType, eventDetails);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <Icon>🎁 You've Been Chosen! 🎁</Icon>
        <br />
        <ModalHeading
          style={{
            textAlign: 'center',
          }}
        >
          Unlock a Free Month of Subscription
        </ModalHeading>
        <ModalBody>
          As one of our most dedicated creators, enjoy a free month to level up
          your Fakémon designs.
        </ModalBody>

        <PromoCodeDisplay>
          <>
            <PromoCodeContainer style={{ flexDirection: 'column' }}>
              <BenefitsHeading>Elite Trainer</BenefitsHeading>
              <BenefitsList>
                <br />
                <li>30 Generations per Day (Ultimate Model)</li>
                <li>Unlimited Generations per Day (Classic Model)</li>
                <li>20 Credits, gaining access to 'Create Your Own' mode</li>
                <li>200-slot storage capacity</li>
                <li>Ad-free experience</li>
              </BenefitsList>

              <PrimaryButton
                onClick={async () => {
                  // Calculate duration before closing the modal
                  const durationInSeconds = Math.round(
                    (Date.now() - modalOpenTime) / 1000,
                  );

                  // Retrieve the userId
                  const userId = await retrieveUserEmailHashFromCookie(
                    localStorage.getItem('jwt'),
                  );

                  // Log the interaction details
                  const eventDetails = {
                    modal: 'FreeTrialModal',
                    actionTaken: true, // Since the user has interacted by copying the code
                    openDuration: durationInSeconds,
                    specificAction: 'Click CTA', // Additional detail about the interaction
                  };

                  // Assuming eventType is 'ModalInteraction'
                  recordUserInteraction(
                    userId,
                    'ModalInteraction',
                    eventDetails,
                  );
                  /* Redirect to credits purchase page */

                  // True means to search for a free trial code
                  const { url } = await createStripeCheckoutSession(4, true);

                  // Redirect to Stripe checkout using the URL provided by your backend
                  window.location.href = url;
                }}
              >
                Claim Free Trial
              </PrimaryButton>
            </PromoCodeContainer>
          </>
        </PromoCodeDisplay>
        <ModalBody>Limited spots available, don't miss out!</ModalBody>
      </ModalWrapper>
    </ModalOverlay>
  );
};

const PromoCodeDisplay = styled.div`
  background-color: #2d2f33; // Adjusted for a more unified dark mode appearance
  color: #ffffff; // Brighter color for better readability and focus
  border: 1px solid #4279eb; // Accent color border for visual pop
  border-radius: 8px;
  padding: 16px 24px;
  margin: 24px 0; // Adjusted margin for better layout spacing
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); // Softened shadow for a sleek look
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05); // Subtle grow effect for interactivity
  }
`;

const PromoCodeText = styled.p`
  font-size: 16px;
  margin-bottom: 12px; // Adjusted spacing for clearer separation
  color: #e0e0e0; // Adjusted for better contrast against the dark background
`;

const PromoCode = styled.span`
  display: inline-block;
  background-color: #1e2124; // Darker background for promo code block
  color: #ffcc4d; // Bright accent color for the promo code itself
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 600; // Bolder weight for emphasis
  letter-spacing: 1.5px; // Increased spacing for better readability
  font-family: 'Courier New', Courier, monospace; // Monospaced font for code-like appearance
  font-size: 20px;
`;

const CopyButton = styled.button`
  background-color: transparent;
  border: none;
  color: #ffcc4d; // Use a color that stands out but fits the theme
  cursor: pointer;
  margin-left: 10px;
  padding: 0;

  &:hover {
    color: #ffffff; // Change color on hover for feedback
  }
`;

const PromoCodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;
