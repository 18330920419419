// LabeledSelect.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Title = styled.h4`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  text-transform: uppercase;
  color: #191a1a;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.selectorWidth ? props.selectorWidth : '48%')};
`;

function LabeledSelect({ title, options, onChange, value, selectorWidth }) {
  return (
    <SelectContainer selectorWidth={selectorWidth}>
      <Title>{title}:</Title>
      <Select
        className="selector"
        options={options}
        value={value}
        onChange={onChange}
        isOptionDisabled={option => option.disabled}
      />
    </SelectContainer>
  );
}

export default LabeledSelect;
