import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PlaneLogo from '../../plane-logo.png';
import CoinLogo from '../../coin-logo.png';
import FriendLogo from '../../friend-logo.png';

import ReferralLogo from '../../refer-friend-logo.png';
import SignUpLogo from '../../sign-up-logo.png';
import RewardLogo from '../../reward-logo.png';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2em 0;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  max-width: 1200px; // Example max-width

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 1em; // Add gap for visual separation
  }

  @media (max-width: 768px) {
    padding: 1em 0;
  }
`;

const ReferralItemSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: ${props => (props.active ? '2px solid #e6a000' : 'none')};
  width: 40%;

  @media (max-width: 1000px) {
    width: 80%;
    margin-bottom: 1em;
  }

  @media (max-width: 768px) {
    width: 90%;
    padding: 1.5em;
  }
`;

const ReferralItemSectionImage = styled.img`
  width: 28vw; // Responsive width
  max-width: 300px; // To prevent it from becoming too large
  min-width: 200px;
  margin-bottom: 1em;
`;

const ReferralItemSectionTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: -0.5px;
  margin-bottom: 1rem;
  color: #333;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
    white-space: normal; // Allow text wrapping
  }
`;

const ReferralItemSectionSubtitle = styled.h3`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #666;
  margin-top: 0.5rem;
  margin-bottom: 0rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const ReferralItemBenefitList = styled.ul`
  text-align: left;
  list-style: none;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 500;
  li {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #666;
    margin-top: 0.5rem;
    padding-bottom: 0.3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:before {
      height: 25px;
      padding-right: 10px;
      font-size: 40px;
      content: '*';
      color: #e6a000; // Use your primary color
    }
  }
`;
const VerticalDivider = styled.div`
  background: linear-gradient(to bottom, #4b6cb7, #4b6cb7);
  width: 2px;
  height: auto;
  margin: 0 1em;
  opacity: 0.2;

  @media (max-width: 1000px) {
    display: none; // Hide divider on smaller screens
  }
`;

const ReferralCardContainer = ({ activeCard, setActiveCard }) => {
  return (
    <Container>
      <ReferralItemSection
        // onClick={() => setActiveCard(1)}
        style={
          activeCard === 1
            ? { border: '2px solid #e6a000', transform: 'translateY(-5px)' }
            : { border: 'none' }
        }
      >
        <ReferralItemSectionImage src={SignUpLogo} alt="Friend sign-up" />
        <ReferralItemSectionTitle>
          Friend Joins, You Gain!
        </ReferralItemSectionTitle>
        <ReferralItemSectionSubtitle>
          When your referral signs up:
        </ReferralItemSectionSubtitle>

        <br />
        <br />
        <ReferralItemBenefitList>
          <li>Receive an extra +10 daily generations for immediate use.</li>
          <li>Your referral will receive an extra +10 daily generations.</li>
          {/* <li>Permanently increase your daily generation limit by 3.</li> */}
          <li>+1 referral point towards a bonus reward.</li>
        </ReferralItemBenefitList>
      </ReferralItemSection>
      <VerticalDivider />
      <ReferralItemSection
        // onClick={() => setActiveCard(2)}
        style={
          activeCard === 2
            ? { border: '2px solid #e6a000', transform: 'translateY(-5px)' }
            : { border: 'none' }
        }
      >
        <ReferralItemSectionImage src={RewardLogo} alt="Earn points" />
        <ReferralItemSectionTitle>
          Friend Buys, You Earn!
        </ReferralItemSectionTitle>
        <ReferralItemSectionSubtitle>
          When your referral makes their first purchase:
        </ReferralItemSectionSubtitle>
        <br />
        <br />
        <ReferralItemBenefitList>
          <li>Receive an extra +20 daily generations for immediate use.</li>
          <li>Earn 10% Credits from your referral's purchase amount.</li>
          <li>
            Your referral will earn an extra 10% Credits from their purchase
            amount.
          </li>
          {/* <li>+3 referral points.</li> */}
        </ReferralItemBenefitList>
      </ReferralItemSection>
    </Container>
  );
};

export default ReferralCardContainer;
