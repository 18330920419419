import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReferralCardContainer from './ReferralCardContainer';
import InformationDisplay from './ReferralCardDescription';

const HowItWorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%; // Default width
  border-radius: 8px;
  margin-top: 2rem;
  padding: 2rem;

  @media (max-width: 1200px) {
    width: 90%; // Increase width for smaller screens
  }

  @media (max-width: 768px) {
    margin-top: 1.5rem;
    padding: 1.5rem; // Smaller padding for smaller screens
  }

  @media (max-width: 480px) {
    width: 95%; // Full width for very small screens
    margin-top: 1rem;
    padding: 1rem;
  }
`;

const HowItWorksTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: clamp(1.25rem, 4.5vw, 3rem); // Fluid font size
  line-height: 1.2;
  letter-spacing: -0.5px;
  margin-bottom: 3.5rem;
  color: #333;
  white-space: nowrap;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 2rem; // Smaller margin for smaller screens
  }

  @media (max-width: 480px) {
    margin-bottom: 1.5rem;
    white-space: normal; // Allows text wrapping for very small screens
  }
`;

// In case you have defined your Title component elsewhere with different styles, you would replace it with ProgressTitle.

const HowItWorks = () => {
  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    // Scroll to the corresponding cardId when activeCard is 0, 1, or 2
    if (activeCard !== null) {
      const cardElement = document.getElementById(`card-${activeCard}`);
      cardElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [activeCard]);

  return (
    <HowItWorksContainer>
      <HowItWorksTitle>Referral Program: How it works?</HowItWorksTitle>
      <ReferralCardContainer
        activeCard={activeCard}
        setActiveCard={setActiveCard}
      />
      {activeCard !== null && <InformationDisplay activeCard={activeCard} />}
    </HowItWorksContainer>
  );
};

export default HowItWorks;
