import React from 'react';
import styled from 'styled-components';

const RewardsContainer = styled.div`
  background-color: #f7f7f7; // Contrasting background; adjust as needed
  padding: 2em 5%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const RewardItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0;
`;

const RewardIcon = styled.i`
  font-size: 2.5em;
  margin-right: 1em;
  color: #ffc107; // Adjust to match the website's color scheme
`;

const RewardTextContainer = styled.div``;

const ItemTitle = styled.h4`
  font-weight: 700;
  color: black;
  margin-bottom: 0.5em;
`;

const ItemDescription = styled.p`
  color: black;
  margin: 0;
`;

const Divider = styled.hr`
  width: 100%;
  border: 1px solid #e0e0e0; // Light grey divider
  margin: 1em 0;
`;

const RewardsDescription = () => {
  return (
    <RewardsContainer>
      <RewardItem>
        <RewardIcon className="fas fa-gift"></RewardIcon>
        <RewardTextContainer>
          <ItemTitle>Referral Bonus</ItemTitle>
          <ItemDescription>
            For every referral, earn an additional 10 free daily generations.
          </ItemDescription>
        </RewardTextContainer>
      </RewardItem>

      <Divider />

      <RewardItem>
        <RewardIcon className="fas fa-percentage"></RewardIcon>
        <RewardTextContainer>
          <ItemTitle>Purchase Bonus</ItemTitle>
          <ItemDescription>
            If your referral makes a purchase, get 10% of the purchase value.
          </ItemDescription>
        </RewardTextContainer>
      </RewardItem>
    </RewardsContainer>
  );
};

export default RewardsDescription;
