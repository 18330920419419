import React from 'react';
import PropTypes from 'prop-types';
import { useRandomTip } from './hooks/useRandomTip';
import { useLoaderProgress } from './hooks/useLoaderProgress';
import { useRandomLoaderMessage } from './hooks/useRandomLoaderMessage';
import {
  LoaderWrapper,
  ImagePlaceholder,
  PlaceholderIcon,
  LoaderText,
  TipSection,
  TipTitle,
  TipContent,
  ProgressBarContainer,
  ProgressIndicator,
  ProgressBar,
} from '../shared/styles/SharedLoaderStyles';

const BasicLoaderComponent = ({ isVisible }) => {
  const selectedTip = useRandomTip(isVisible);
  const progress = useLoaderProgress(isVisible);
  const randomLoaderMessage = useRandomLoaderMessage(isVisible);

  if (!selectedTip) return null; // Ensures the component gracefully handles empty states

  return (
    <LoaderWrapper isVisible={isVisible}>
      <ImagePlaceholder>
        <PlaceholderIcon
          src={selectedTip.imageSrc}
          alt={selectedTip.altText || 'Loader Placeholder'}
        />
        <LoaderText>{randomLoaderMessage}</LoaderText>
      </ImagePlaceholder>
      <TipSection>
        <TipTitle>Generation Tip</TipTitle>
        <TipContent>{selectedTip.text}</TipContent>
      </TipSection>
      <ProgressBarContainer>
        <ProgressIndicator>
          <ProgressBar progress={progress} />
        </ProgressIndicator>
      </ProgressBarContainer>
    </LoaderWrapper>
  );
};

BasicLoaderComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default BasicLoaderComponent;
