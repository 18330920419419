import BasicLoaderComponent from './BasicLoader/BasicLoaderComponent';
import NokepointsLoaderComponent from './NokepointsLoader/NokepointsLoaderComponent';

const LoaderComponent = ({
  isDisplayed,
  useCredits,
  beamStatus,
  queuePosition,
  generationSessionUUID,
}) => {
  // If the loader is not displayed, return nothing
  if (!isDisplayed) return null;
  if (useCredits) {
    // If the Nokepoints loader is used and the component is displayed
    return (
      <NokepointsLoaderComponent
        isVisible={isDisplayed}
        beamStatus={beamStatus}
        queuePosition={queuePosition}
        generationSessionUUID={generationSessionUUID}
      />
    );
  }

  // If the Basic loader is used and the component is displayed
  return <BasicLoaderComponent isVisible={isDisplayed} />;
};

export default LoaderComponent;
