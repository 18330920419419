import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <p className="loader-text">
        Loading<span class="period">.</span>
        <span class="period">.</span>
        <span class="period">.</span>
      </p>
    </div>
  );
};

export default Loader;
