import axios from 'axios';

/**
 * Fetches random Nokemon data.
 * This function makes a POST request to a specified API endpoint and returns the Nokemon data.
 * @returns {Promise<Object>} A promise that resolves to the Nokemon data including image URL and display text.
 */
export const fetchRandomNokemon = async generationSessionUUID => {
  if (!generationSessionUUID) {
    throw new Error('generationSessionUUID is required');
  }

  try {
    const endpoint = '/api/generation/basic/new/loggedIn/fakemon/sdxl/random';
    const response = await axios.post(endpoint, {
      batchNum: 1,
      cookie: localStorage.getItem('jwt'), // Ensure secure handling of JWTs
      generationSessionUUID: generationSessionUUID,
    });

    if (
      response.data &&
      response.data.fileName &&
      response.data.fileName.length > 0
    ) {
      const nokemonData = {
        image: `/api/generation/basic/images/fakemon?fileName=${response.data.fileName[0]}`,
        text: response.data.displayText,
      };
      return nokemonData;
    } else {
      throw new Error('Nokemon data is incomplete or missing');
    }
  } catch (error) {
    console.error('Error fetching random Nokemon:', error);
    throw error; // Rethrow the error for handling in the component
  }
};
