import React from 'react';
import Modal from './Modal';

import './NokepointsImageGuidedModal.css';
// import NokemonWeekendDealImage from '../nokemon-weekend-deal.png'; // Assuming you have a relevant image for the deal
import NokemonImageGuidedGIF from '../Nokemon SD Ad v2.gif';
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 2em; // Default font-size
  color: #333;
  margin-bottom: 0.5em;

  @media (max-width: 768px) {
    // For tablets
    font-size: 1.75em;
  }

  @media (max-width: 480px) {
    // For mobile phones
    font-size: 1.5em;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.5em; // Default font-size
  color: #666;
  margin-bottom: 1em;

  @media (max-width: 768px) {
    font-size: 1.25em;
  }

  @media (max-width: 480px) {
    font-size: 1em;
  }
`;

const Section = styled.div`
  width: 80%; // Default width
  margin-bottom: 2em;
  color: black;

  @media (max-width: 768px) {
    width: 90%; // Slightly more width on smaller tablets
  }

  @media (max-width: 480px) {
    width: 95%; // Use more of the screen on mobile phones
  }

  h2,
  h3,
  p {
    @media (max-width: 768px) {
      font-size: 0.9em; // Smaller text on tablets
    }

    @media (max-width: 480px) {
      font-size: 0.8em; // Even smaller text on mobile phones
    }
  }
`;
const buttonStyles = `
  padding: 12px 24px;
  font-size: 18px; // Default font-size
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;
  width: 80%; // Default width
  margin-bottom: 8px; // Space between the buttons

  @media (max-width: 768px) {
    font-size: 16px; // Slightly smaller font on tablets
    padding: 10px 20px;
    width: 90%; // More width on tablets
  }

  @media (max-width: 480px) {
    font-size: 14px; // Smaller font on mobile phones
    padding: 8px 16px;
    width: 90%; // Full width on mobile phones
  }
`;

const PrimaryButton = styled.button`
  background-color: #5c4d7d; // Primary color
  color: white;
  border: none;

  &:hover {
    background-color: #4a3c63; // A slightly darker shade on hover
  }

  ${buttonStyles}
`;

const SecondaryButton = styled.button`
  background-color: transparent;
  color: #5c4d7d; // Same as primary button's background to tie them together
  border: 2px solid #5c4d7d; // Solid border to match the primary button's background

  &:hover {
    border-color: #4a3c63; // Darken the border on hover
    color: #4a3c63;
  }

  ${buttonStyles}
`;
const AnnouncementModal = ({ isOpen, setModalSettings }) => {
  const handlePrimaryButtonClick = () => {
    window.location.href = 'https://nokemon.eloie.tech/redeem#modal';
  };

  const handleSecondaryButtonClick = () => {
    window.location.href = 'https://nokemon.eloie.tech/marketplace#modal';
  };
  return (
    <Modal
      isOpen={isOpen}
      setModalSettings={setModalSettings}
      modalContainerClassName="NokepointsImageGuidedModal-modal-container-expanded"
    >
      <div className="NokepointsImageGuidedModal-container">
        <Section>
          <Title>🌟 Exclusive Weekend Deal! 🌟</Title>
          <Subtitle>
            Get 10 Free Nokepoints & 50% OFF on All Purchases!
          </Subtitle>
          <img
            className="NokepointsImageGuidedModal-deal-image"
            src={NokemonImageGuidedGIF}
            alt="Weekend Deal"
            style={{
              width: '100%',
            }}
          />
        </Section>
        <Section id="section-weekend-deal">
          <h3>What's The Deal?</h3>
          <p>
            This weekend only! Claim your 10 free Nokepoints to try our advanced
            generation features and enjoy a 50% discount on all products using
            code <strong>WEEKEND50</strong>. Don’t miss out on this amazing
            offer!
          </p>
        </Section>
        <PrimaryButton onClick={handlePrimaryButtonClick}>
          Claim Free Nokepoints
        </PrimaryButton>
        <SecondaryButton onClick={handleSecondaryButtonClick}>
          Use Code WEEKEND50
        </SecondaryButton>
        {/* Optionally, include the Google Login button if user authentication is required */}
        {/* <ModalGoogleLoginButton /> */}
      </div>
    </Modal>
  );
};

export default AnnouncementModal;
