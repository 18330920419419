import React, { createContext, useState, useContext } from 'react';

// Create a context
const IOContext = createContext();

// Create a provider component
export function IOProvider({ children }) {
  const [type1, setType1] = useState(undefined);
  const [type2, setType2] = useState(undefined);
  const [style, setStyle] = useState('3D model');
  const [modelType, setModelType] = useState('sd_v1');
  const [batchNum, setBatchNum] = useState(1);
  const [loadingState, setLoadingState] = useState(false);
  const [outputData, setOutputData] = useState(undefined);
  const [random, setRandom] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const [generatedBy, setGeneratedBy] = useState('random');

  const [fileName, setFileName] = useState(undefined);

  // Pass the state and setters as the context value
  return (
    <IOContext.Provider
      value={{
        type1,
        setType1,
        type2,
        setType2,
        style,
        setStyle,
        modelType,
        setModelType,
        batchNum,
        setBatchNum,
        loadingState,
        setLoadingState,
        outputData,
        setOutputData,
        random,
        setRandom,
        selected,
        setSelected,
        generatedBy,
        setGeneratedBy,
        fileName,
        setFileName,
      }}
    >
      {children}
    </IOContext.Provider>
  );
}

// Create a custom hook to use the context
export function useIOContext() {
  return useContext(IOContext);
}
