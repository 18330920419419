import LabeledSelect from '../../Common/LabeledSelect';

// Then, in your component, you can use this new component like so:
function ModelTypeSelect({ modelType, setModelType }) {
  return (
    <LabeledSelect
      title="Model Type"
      options={[
        {
          value: 'sd_v1',
          label: 'Model v2.0',
        },
        { value: 'dalle', label: 'Model v1.0', disabled: true },
      ]}
      onChange={obj => {
        setModelType(obj['value']);
      }}
      value={{ label: 'Model v2.0 (latest)', value: 'sd_v1' }}
      defaultValue={{ label: 'Model v2.0 (latest)', value: 'sd_v1' }}
    />
  );
}

export default ModelTypeSelect;
