// GenerationTimer.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TimeInfoContainer,
  TimeText,
  TimeContainer,
  AnimatedIcon,
} from '../styles/NokepointsLoader.styles.js';

import {
  ProgressBarContainer,
  ProgressIndicator,
  ProgressBar,
} from '../../shared/styles/SharedLoaderStyles';
import { faHourglassHalf, faClock } from '@fortawesome/free-solid-svg-icons';

import useEstimatedWaitTime from '../hooks/useEstimatedWaitTime';
import { useProgress } from '../hooks/useProgress';

const GenerationTimer = ({
  lastComplete,
  lastPending,
  beamStatus,
  queuePosition,
}) => {
  const [startTime, setStartTime] = useState(new Date());
  const [elapsedTime, setElapsedTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(null);

  const waitTime = useEstimatedWaitTime(
    lastComplete,
    lastPending,
    beamStatus,
    queuePosition,
  );

  const progress = useProgress(elapsedTime, remainingTime, beamStatus);

  useEffect(() => {
    if (waitTime !== null && waitTime !== remainingTime) {
      setRemainingTime(waitTime);
    }
  }, [waitTime]);

  useEffect(() => {
    if (remainingTime === null) {
      setStartTime(new Date()); // Reset the start time only when reaching null
    }
  }, [remainingTime]);

  useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime(oldElapsedTime => oldElapsedTime + 1);
    }, 1000);

    // Clean up the interval on unmount
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const countdown = setInterval(() => {
      setRemainingTime(oldRemainingTime => {
        if (oldRemainingTime === null) {
          return null;
        } else {
          return Math.max(oldRemainingTime - 1, 0);
        }
      });
    }, 1000);
    // Clean up the interval on unmount
    return () => clearInterval(countdown);
  }, []);

  return (
    <>
      <TimeInfoContainer>
        {beamStatus === 'COMPLETE' ? (
          <TimeText>Loading in your Fakémon Image...</TimeText>
        ) : (
          <TimeContainer>
            <TimeText>
              <AnimatedIcon icon={faClock} />
              Time elapsed: {elapsedTime + 's'}
            </TimeText>
            <TimeText>
              <AnimatedIcon icon={faHourglassHalf} />
              Estimated wait:{' '}
              {remainingTime > 0
                ? `${remainingTime}s`
                : remainingTime === 0
                ? 'Done!'
                : 'Calculating...'}
            </TimeText>
          </TimeContainer>
        )}
      </TimeInfoContainer>

      <ProgressIndicator>
        <ProgressBar progress={progress} />
      </ProgressIndicator>
    </>
  );
};

GenerationTimer.propTypes = {
  queuePosition: PropTypes.number,
};

export default GenerationTimer;
