import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ButtonWithDescription.css';

const ButtonWithDescription = ({ button, description, defaultShow }) => {
  const [showDescription, setShowDescription] = useState(false);
  const [descriptionFade, setDescriptionFade] = useState(false);

  const buttonRef = useRef(null);
  const descriptionRef = useRef(null);

  // useEffect(() => {
  //   if (defaultShow) {
  //     const startSessionTime = localStorage.getItem('startSessionTime');

  //     if (Date.now() - startSessionTime < 360000000) {
  //       setShowDescription(true);
  //       setTimeout(() => setDescriptionFade(true), 5000);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (showDescription) {
      const descriptionRect = descriptionRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;

      let rightEdge = descriptionRect.left + descriptionRect.width;
      let offset = rightEdge - screenWidth;
      rightEdge = descriptionRect.left - offset;
      descriptionRef.current.style.marginLeft = `-${offset}px`;
    }
  }, [showDescription]);

  return (
    <div
      className="ButtonWithDescription-container"
      style={buttonRef.style}
      onMouseEnter={() => setShowDescription(true)}
      onMouseLeave={() => setShowDescription(false)}
    >
      {button}
      {showDescription && (
        <div
          ref={descriptionRef}
          className={`ButtonWithDescription-description ${
            descriptionFade ? 'fade-out' : ''
          }`}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default ButtonWithDescription;
