import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import LogoSrc from '../../../generator-logo.png';

const glowSpread = keyframes`
  0% {
    filter: drop-shadow(0 0 0px #4279eb) /* Starting blue */
            drop-shadow(0 0 0px #934ac8) /* Purple */
            drop-shadow(0 0 0px #d05e6a); /* Pink */
  }
  100% {
    filter: drop-shadow(0 0 50px #4279eb) /* Starting blue */
            drop-shadow(0 0 15px #934ac8) /* Purple */
            drop-shadow(0 0 70px #d05e6a); /* Pink */
  }
`;

const retroGlowSpread = keyframes`
  0% {
    filter: drop-shadow(0 0 0px #f1b959) /* Retro orange */
            drop-shadow(0 0 0px #fca142) /* Retro yellow */
            drop-shadow(0 0 0px #da6196); /* Retro pink */
  }
  100% {
    filter: drop-shadow(0 0 50px #f1b959) /* Retro orange */
            drop-shadow(0 0 15px #fca142) /* Retro yellow */ 
            drop-shadow(0 0 70px #da6196); /* Retro pink */  
  }
`;

const Logo = styled.img`
  width: 150px;
  max-width: 150px; // Maximum size to maintain design integrity
  min-width: 80px; // Minimum size to ensure visibility
  animation: ${({ modelVersion }) =>
      modelVersion === 'modelv3' ? glowSpread : retroGlowSpread}
    1s ease-in-out forwards; // 'forwards' will keep the end state after animation
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1); // Slight zoom effect on hover
    filter: drop-shadow(0 0 25px #9e9e9e) /* Silver */
      drop-shadow(0 0 50px #673ab7) /* Deep purple */
      drop-shadow(0 0 75px #e0e0f8) /* Light purple */;
  }

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
    width: 125px;
  }
`;
const Container = styled.div`
  position: absolute; // Make it fixed relative to the viewport
  top: 37.5%; // Center vertically
  left: calc(30px + 50%); // Center horizontally
  transform: translate(
    -50%,
    -50%
  ); // Offset the container by half its width and height to truly center it
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 100; // Ensure it stays above other content
  height: 200px;
  width: 1000px;

  @media (max-width: 768px) {
    left: calc(50%); // Center horizontally
  }

  @media (max-width: 420px) {
    width: 80vw;

    top: 37.5%; // Center vertically
  }

  @media (max-height: 800px) {
    position: relative;
    transform: translate(0, -50%);
    width: 80%;
    left: 0;
  }
`;

const TypingText = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 1.8em;
  color: white;
  width: 50%;
  height: 200px;
  margin: 0 auto;
  line-height: 1.6;
  font-weight: 600;
  overflow: hidden;
  position: absolute;
  bottom: -180px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    font-size: 1.5em;
    width: 80%;
    max-width: 80vw;
  }

  @media (max-width: 420px) {
    font-size: 1.2em;
    width: 80%;
    max-width: 80vw;
  }

  @media (max-height: 800px) {
    font-size: 1.1rem;
  }
`;

const IntroductionPlaceholder = ({ modelVersion }) => {
  const [text, setText] = useState('');
  const [isLogoAnimationFinished, setIsLogoAnimationFinished] = useState(false);
  const finalText = 'What kind of Fakémon would you like to generate today?';

  useEffect(() => {
    // Assuming the glowSpread animation duration is 2 seconds
    const logoAnimationTimeout = setTimeout(() => {
      setIsLogoAnimationFinished(true);
    }, 1000);

    return () => clearTimeout(logoAnimationTimeout);
  }, []);

  useEffect(() => {
    let currentIndex = 0;
    let interval;

    if (isLogoAnimationFinished) {
      interval = setInterval(() => {
        if (currentIndex <= finalText.length) {
          setText(finalText.slice(0, currentIndex));
          currentIndex++;
        } else {
          clearInterval(interval);
        }
      }, 20);
    }

    return () => interval && clearInterval(interval);
  }, [isLogoAnimationFinished]);

  return (
    <Container>
      <Logo modelVersion={modelVersion} src={LogoSrc} alt="Logo" />
      {isLogoAnimationFinished && <TypingText>{text}</TypingText>}
    </Container>
  );
};

export default IntroductionPlaceholder;
