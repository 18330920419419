import axios from 'axios';

const apiClient = axios.create({
  baseURL: '/api/beam',
  timeout: 10000, // 10 seconds timeout
});

// A helper function to perform API calls with retry logic
const fetchWithRetry = async (url, retries = 3) => {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await apiClient.get(url);
      return response.data;
    } catch (error) {
      if (i === retries - 1) throw error;
      await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
    }
  }
};

export const fetchLastTaskTimes = async () => {
  try {
    const [lastComplete, lastPending] = await Promise.all([
      fetchWithRetry('/get/last/complete'),
      fetchWithRetry('/get/last/pending'),
    ]);

    return {
      lastTaskEndTime: lastComplete.timeSinceComplete,
      lastTaskStartPendingTime: lastPending.timeSincePending,
    };
  } catch (error) {
    console.error('Error fetching last task times:', error);
    throw new Error('Failed to fetch task times'); // Rethrow a new error to be handled by the caller
  }
};
