import React from 'react';
import Modal from './Modal';

import './NokepointsImageGuidedModal.css';
import NokemonBatchAd from '../nokemon-batch-ad-v3.png';
import styled from 'styled-components';

const Section = styled.div`
  width: 85%;
  margin-bottom: 2em;
  h2 {
    font-size: 1.3em;
    color: #444;
    margin-bottom: 0.25em;
  }

  h3 {
    font-size: 1.1em;
    color: #444;
    margin-bottom: 0.25em;
  }

  p {
    font-size: 1em;
    color: #666;
  }
`;

const AdvancedBatchGenerationButton = ({ text, icon }) => {
  return (
    <button
      className="google-subscription-button"
      style={{ marginBottom: '10px' }}
      onClick={() =>
        (window.location.href =
          '/information?mode=0&#section-what-is-batch-generation')
      }
    >
      <p className="google-subscription-button-text">{text}</p>
    </button>
  );
};

const NokepointsBatchGenerationModal = ({ isOpen, setModalSettings }) => {
  return (
    <Modal
      isOpen={isOpen}
      setModalSettings={setModalSettings}
      modalContainerClassName="NokepointsImageGuidedModal-modal-container-expanded"
    >
      <div className="NokepointsImageGuidedModal-container">
        <Section>
          <h2>🎉 Advanced Batch Generation 🎉</h2>
        </Section>
        <img
          className="NokepointsImageGuidedModal-gif"
          src={NokemonBatchAd}
          style={{}}
        ></img>
        <Section id="section-introducing-text-guided-generation">
          <h3>What's New?</h3>
          <p
            style={{
              textAlign: 'left',
            }}
          >
            We're excited to introduce advanced batch generation, a feature that
            allows you to generate multiple Fakemon at once!
          </p>
          <p
            style={{
              textAlign: 'left',
            }}
          >
            You can choose to generate 5, 10, 20, 50, or 100 Fakemon in a single
            batch. This feature is perfect for those who want to explore a
            variety of designs and concepts quickly and efficiently.
          </p>
          <p
            style={{
              textAlign: 'left',
            }}
          >
            Start your batch generation journey today!
          </p>
        </Section>
        <AdvancedBatchGenerationButton text={'Learn More'} />
        {/* <p className="LoginPromptModal-login-modal-subtitle-center">
          <b>Check it out here!</b>
        </p> */}
      </div>
    </Modal>
  );
};

export default NokepointsBatchGenerationModal;
