export default class ModalManager {
  constructor(setActiveModal) {
    this.setActiveModal = setActiveModal;
    this.modalStrategies = {
      notRegistered: new NotRegisteredStrategy(setActiveModal),
      registered: new RegisteredStrategy(setActiveModal),
    };
  }

  async handleModal(userInfo, endpointModifier) {
    const strategyKey =
      endpointModifier === 'notLoggedIn' ? 'notRegistered' : 'registered';

    const strategy = this.modalStrategies[strategyKey];

    if (strategy) {
      await strategy.execute(userInfo);
    }
  }
}

// Abstract base class for modal strategies
class ModalStrategy {
  constructor(setActiveModal) {
    this.setActiveModal = setActiveModal;
  }

  async showModalAfterDelay(modalType) {
    await new Promise(resolve => setTimeout(resolve, 3000));
    this.setActiveModal(modalType);
  }
}

// Strategy for not registered users
class NotRegisteredStrategy extends ModalStrategy {
  manageGenerationCount() {
    let generationCount = parseInt(localStorage.getItem('generationCount'), 10);
    if (isNaN(generationCount)) {
      localStorage.setItem('generationCount', 9);
      generationCount = 9;
    } else {
      generationCount = Math.max(generationCount - 1, 0);
      localStorage.setItem('generationCount', generationCount);
      if (generationCount === 0) {
        localStorage.removeItem('generationCount');
      }
    }
    return generationCount;
  }
  async execute() {
    const generationCount = this.manageGenerationCount();

    if (generationCount === 8) {
      await this.showModalAfterDelay('NOT_REGISTERED_EIGHT_GENERATIONS_LEFT');
    } else if (generationCount === 0) {
      await this.showModalAfterDelay('NOT_REGISTERED_ZERO_GENERATIONS_LEFT');
    }
  }
}

// Strategy for registered users
class RegisteredStrategy extends ModalStrategy {
  async execute(userInfo) {
    if (userInfo.currencyValueFree === 1) {
      const modalType = userInfo.isSubscribed
        ? 'SUBSCRIBED_UPGRADE_ZERO_GENERATIONS_LEFT'
        : 'REGISTERED_ZERO_GENERATIONS_LEFT';
      await this.showModalAfterDelay(modalType);
      return;
    }

    if (
      shouldTriggerCreditsModal(
        userInfo.currencyValueFree,
        userInfo.currencyValue,
        userInfo.numSessions,
      )
    ) {
      await this.showModalAfterDelay('PURCHASE_CREDITS_MODAL');
      return;
    }

    const shouldTriggerPromoCodeModal = await decideOnPromoCodeModalTriggering(
      userInfo,
    );

    if (shouldTriggerPromoCodeModal) {
      await this.showModalAfterDelay('PROMO_CODE_MODAL');
      return;
    }
  }
}

function shouldTriggerCreditsModal(
  generationsRemaining,
  numCredits,
  numSessions,
) {
  if (numSessions < 2 || generationsRemaining > 5 || numCredits > 0) {
    return false;
  }

  // Trigger on the second session, every 5th, AND when generations hit the threshold AND users have no credits
  return (
    (numSessions === 2 || numSessions % 5 === 0) &&
    generationsRemaining === 5 &&
    numCredits === 0
  );
}

async function checkPromoCodePopupEligibility(token) {
  const promoCodeCheckUrl = '/api/user/hasPromoCodePopup/get';
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `${token}`,
    },
  };

  try {
    const response = await fetch(promoCodeCheckUrl, requestOptions);
    if (!response.ok) {
      throw new Error('Failed to fetch promo code popup status.');
    }
    const { showPopup: isEligibleForPromo } = await response.json();
    return isEligibleForPromo;
  } catch (error) {
    console.error('Error checking promo code popup eligibility:', error);
    return false;
  }
}

async function updatePromoCodePopupStatus(token) {
  const updatePromoCodeStatusUrl = '/api/user/hasPromoCodePopup/update';
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `${token}`,
    },
  };

  try {
    const response = await fetch(updatePromoCodeStatusUrl, requestOptions);
    if (!response.ok) {
      throw new Error('Failed to update promo code popup status.');
    }
  } catch (error) {
    console.error('Error updating promo code popup status:', error);
  }
}

async function decideOnPromoCodeModalTriggering(userInfo) {
  // TODO: Develop a more robust way to check for hasPromoCode, by checking datastore
  const token = localStorage.getItem('jwt');

  if (!token) {
    console.log('No JWT token found.');
    return false;
  }

  try {
    const isEligibleForPromo = await checkPromoCodePopupEligibility(token);
    if (isEligibleForPromo) {
      await updatePromoCodePopupStatus(token);
      // Implement the UI logic to show promo code modal here
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error deciding on promo code modal triggering:', error);
    return false;
  }
}
