import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faStar,
  faTrash,
  faShare,
} from '@fortawesome/free-solid-svg-icons';
import {
  AddButton,
  DeleteButton,
  ImportButton,
  ShareButton,
} from '../StorageButtons'; // Import the buttons
import { usePopper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import { sleep } from 'nokemon-common-utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import NokemonContext from '../../context/NokemonContext';

const SettingsButton = styled.button`
  position: ${props => (props.position ? props.position : 'absolute')};
  bottom: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333333;

  &:hover {
    color: #007bff;
  }
`;

const Menu = styled.div`
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 100;
  min-width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  &.menu-enter {
    opacity: 0;
  }

  &.menu-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  &.menu-exit {
    opacity: 1;
  }

  &.menu-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;

const MenuItem = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  padding: 10px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
    color: #007bff;
  }

  &:active {
    color: #0056b3;
  }

  svg {
    margin-right: 10px;
  }
`;

const getNokemonFromDatastoreByFileName = async function (fileName) {
  return await fetch('/api/nokemon/share/fileName', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      fileName: fileName,
    }),
  });
};

function addToFavourites(fileName) {
  const res = fetch('/api/user/favourite/add', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('jwt'),
    },
    method: 'POST',
    body: JSON.stringify({
      fileName: fileName,
    }),
  });

  return res;
}

function removeFromFavourites(fileName) {
  const res = fetch('/api/user/favourite/delete', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('jwt'),
    },
    method: 'POST',
    body: JSON.stringify({
      fileName: fileName,
    }),
  });

  return res;
}

const MenuAddButtonItem = props => {
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);

  const handleClick = async () => {
    // Add your AddButton functionality here
    props.setIsAddButtonDisabled(true);
    const res = await addToFavourites(props.fileName);
    const text = await res.text();
    if (res.status !== 200) {
      toast.error(text);
    } else {
      toast.success(text);
      setIsAddButtonClicked(true);
    }
    await sleep(1000);
    props.setIsAddButtonDisabled(false);
  };

  return (
    <MenuItem onClick={handleClick}>
      <AddButton
        fileName={props.fileName}
        isAddButtonDisabled={props.isAddButtonDisabled}
        setIsAddButtonDisabled={props.setIsAddButtonDisabled}
      />
      <span>Add to Favourites</span>
    </MenuItem>
  );
};

const MenuDeleteButtonItem = props => {
  const handleClick = async () => {
    // Add your DeleteButton functionality here
    props.setIsDeleteButtonDisabled(true);
    const res = await removeFromFavourites(props.fileName);
    const text = await res.text();
    if (res.status !== 200) {
      toast.error(text);
    } else {
      const updatedCollection = props.collection.filter(
        item => item !== props.fileName,
      );
      props.setCollection(updatedCollection);
      toast.success(text);
    }
    await sleep(1000);
    props.setIsDeleteButtonDisabled(false);
  };

  return (
    <MenuItem onClick={handleClick}>
      <DeleteButton
        fileName={props.fileName}
        collection={props.collection}
        setCollection={props.setCollection}
        isDeleteButtonDisabled={props.isDeleteButtonDisabled}
        setIsDeleteButtonDisabled={props.setIsDeleteButtonDisabled}
      />
      <span>Delete from Favourites</span>
    </MenuItem>
  );
};

const MenuShareButtonItem = props => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = async () => {
    // Add your ShareButton functionality here
    setIsDisabled(true);
    const res = await getNokemonFromDatastoreByFileName(props.fileName);
    if (res.status !== 200) {
      toast.error('Unable to share at this moment, please try again later.');
      return;
    }
    const body = await res.json();
    window.navigator.clipboard.writeText(
      `https://nokemon.eloie.tech/sharing?nokemon=${body.uuid}`,
    );
    toast.success('Nokémon URL copied to clipboard!');
    await sleep(3000);
    setIsDisabled(false);
  };

  return (
    <MenuItem onClick={handleClick}>
      <ShareButton fileName={props.fileName} />
      <span>Share Nokemon</span>
    </MenuItem>
  );
};
const MenuImportButtonItem = props => {
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const { setNokemonData } = useContext(NokemonContext);

  const extractUuidFromFileName = fileName => {
    let uuid = fileName.split('uuid=')[1];
    return uuid;
  };

  const handleClick = async () => {
    setIsDisabled(true);
    const uuid = extractUuidFromFileName(props.fileName);
    let response;
    if (uuid) {
      response = await fetch(`/api/nokemon/uuid=${uuid}`);
    } else {
      response = await fetch(`/api/nokemon/fileName=${props.fileName}`);
    }
    const data = await response.json();
    setNokemonData(data);
    navigate('/');
    setIsDisabled(false);
  };

  return (
    <MenuItem onClick={handleClick} disabled={isDisabled}>
      <ImportButton fileName={props.fileName} />
      <span>Import Nokemon</span>
    </MenuItem>
  );
};

export function SettingsMenu(props) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const { styles, attributes } = usePopper(buttonRef.current, menuRef.current, {
    placement: props.placement ? props.placement : 'top-end',
  });

  useEffect(() => {
    const closeMenu = e => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', closeMenu);
    } else {
      document.removeEventListener('mousedown', closeMenu);
    }

    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, [isOpen]);

  return (
    <div>
      <SettingsButton
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        position={props.position}
      >
        <FontAwesomeIcon icon={faCog} />
      </SettingsButton>
      <CSSTransition in={isOpen} timeout={200} classNames="menu" unmountOnExit>
        <Menu ref={menuRef} style={styles.popper} {...attributes.popper}>
          {props.isImportButtonDisplayed ? (
            <MenuImportButtonItem {...props} />
          ) : null}
          {props.isAddButtonDisplayed ? <MenuAddButtonItem {...props} /> : null}
          {props.isDeleteButtonDisplayed ? (
            <MenuDeleteButtonItem {...props} />
          ) : null}
          {props.isShareButtonDisplayed ? (
            <MenuShareButtonItem {...props} />
          ) : null}
        </Menu>
      </CSSTransition>
    </div>
  );
}
