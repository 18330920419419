import React, { useState } from 'react';
import { RadioButton } from 'react-radio-buttons';
import { RadioGroup } from 'react-radio-buttons';
import Select, { NonceProvider } from 'react-select';
import { constants } from 'nokemon-common-utils';

const { types } = constants;

export function AdvancedOldModelOptions(props) {
  const [generatedBy, setGeneratedBy] = useState('type');
  const [selected, setSelected] = useState(undefined);
  const [modelType, setModelType] = useState('sd_v1');
  const [type1, setType1] = useState(undefined);
  const [type2, setType2] = useState('None');
  const [legendary, setLegendary] = useState(undefined);
  const [body, setBody] = useState(undefined);
  const [color, setColor] = useState(undefined);
  const [pool, setPool] = useState('False');
  const [style, setStyle] = useState('3d model');
  const [batchNum, setBatchNum] = useState(1);
  const [availability, setAvailability] = useState('calculating');

  const [nokepoints, setCredits] = useState('...');
  // Your code for another modelType goes here
  // Your code for modelType 'sdv1' goes here
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '92%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <h4 className="inputTitle">Generate By:</h4>

          <RadioGroup
            className="radioGroup"
            value={generatedBy}
            onChange={value => {
              // Reset batchNum on radio button click
              setBatchNum(1);
              props.setBatchNum(1);
              props.setFileName(undefined);

              setGeneratedBy(value);
              setSelected(undefined);
            }}
            horizontal
          >
            <RadioButton
              value="type"
              data-cy="typeRadioButtonAdvanced"
              pointColor="#191a1a"
              rootColor="#191a1a"
            >
              Type
            </RadioButton>
            <RadioButton
              value="random"
              data-cy="randomRadioButtonAdvanced"
              pointColor="#191a1a"
              rootColor="#191a1a"
            >
              Random
            </RadioButton>
            <RadioButton
              value="batch"
              data-cy="batchRadioButtonAdvanced"
              pointColor="#191a1a"
              rootColor="#191a1a"
              disabled={true}
            >
              Batch (coming soon){' '}
              {/* <p
                style={{
                  position: 'absolute',
                  margin: '0',
                  padding: '0',
                  marginTop: '-42px',
                  marginLeft: '-14px',
                  fontSize: '16px',
                  color: 'red',
                  textShadow: '1px 1px 10px red, 1px 1px 10px red',
                }}
              >
                new
              </p> */}
            </RadioButton>
          </RadioGroup>
          <h4 className="inputTitle">Image Style:</h4>
          <Select
            className="selector"
            options={[{ value: '3D model', label: '3D Style' }]}
            onChange={obj => {
              setStyle(obj['value']);
            }}
            isOptionDisabled={option => option.disabled}
            // defaultValue={{ label: 'Sugimori (new)', value: 'sugimori' }}
            defaultValue={{ label: '3D Style', value: '3d model' }}
          />

          {generatedBy == 'random' ? (
            <h1
              style={{
                position: 'relative',
                textAlign: 'center',
                fontSize: '100px',
                marginTop: '55px',
              }}
            ></h1>
          ) : generatedBy == 'batch' ? (
            <div>
              <h4 className="inputTitle">Number Of Generations:</h4>

              <Select
                className="selector"
                options={[
                  {
                    label: 10,
                    value: 10,
                  },
                  {
                    label: 30,
                    value: 30,
                  },
                  {
                    label: 50,
                    value: 50,
                  },
                  {
                    label: 100,
                    value: 100,
                  },
                  {
                    label: 200,
                    value: 200,
                  },
                ]}
                onChange={obj => {
                  setSelected(obj['value']);
                  setBatchNum(obj['value']);
                }}
              />
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '48%',
                  }}
                >
                  <h4 className="inputTitle">Type 1:</h4>

                  <Select
                    className="selector"
                    options={types.filter(type => type.value != type2)}
                    onChange={obj => {
                      setSelected(obj['value']);
                      setType1(obj['value']);
                    }}
                    isOptionDisabled={option => option.disabled}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '48%',
                  }}
                >
                  <h4 className="inputTitle">Type 2:</h4>

                  <Select
                    className="selector"
                    options={[
                      { value: 'None', label: 'None' },
                      ...types,
                    ].filter(type => type.value != type1)}
                    defaultValue={{ value: 'None', label: 'None' }}
                    onChange={obj => {
                      setSelected(obj['value']);
                      setType2(obj['value']);
                    }}
                    isOptionDisabled={option => option.disabled}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    width: '48%',
                  }}
                >
                  <h4 className="inputTitle">Legendary:</h4>
                  <Select
                    className="selector"
                    options={[
                      { value: 'True', label: 'True' },
                      { value: 'False', label: 'False' },
                    ]}
                    onChange={obj => {
                      setSelected(obj['value']);
                      setLegendary(obj['value']);
                    }}
                    isOptionDisabled={option => option.disabled}
                  />
                </div>
                {/* <div
                  style={{
                    width: '48%',
                  }}
                >
                  <h4 className="inputTitle">Body:</h4>
                  <Select
                    className="selector"
                    options={[
                      { label: 'with wings', value: 'with wings' },
                      {
                        label: 'bipedal with tail',
                        value: 'bipedal with tail',
                      },
                      {
                        label: 'bipedal without tail',
                        value: 'bipedal without tail',
                      },
                      { label: 'quadruped', value: 'quadruped' },
                      { label: 'serpentine', value: 'serpentine' },
                      { label: 'multi-winged', value: 'multi-winged' },
                      { label: 'head and arms', value: 'head and arms' },
                      { label: 'head and base', value: 'head and base' },
                      { label: 'multi-body', value: 'multi-body' },
                      { label: 'head and legs', value: 'head and legs' },
                      { label: 'insectoid', value: 'insectoid' },
                      { label: 'just a head', value: 'just a head' },
                      { label: 'with fins', value: 'with fins' },
                    ]}
                    onChange={obj => {
                      setSelected(obj['value']);
                      setBody(obj['value']);
                    }}
                    isOptionDisabled={option => option.disabled}
                  />
                </div> */}
              </div>
              {/* <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '48%',
                  }}
                >
                  <h4 className="inputTitle">Color:</h4>

                  <Select
                    className="selector"
                    options={[
                      { value: 'black', label: 'Black' },
                      { value: 'yellow', label: 'Yellow' },
                      { value: 'white', label: 'White' },
                      { value: 'purple', label: 'Purple' },
                      { value: 'blue', label: 'Blue' },
                      { value: 'red', label: 'Red' },
                      { value: 'green', label: 'Green' },
                      { value: 'gray', label: 'Gray' },
                      { value: 'brown', label: 'Brown' },
                      { value: 'pink', label: 'Pink' },
                    ]}
                    onChange={obj => {
                      setSelected(obj['value']);
                      setColor(obj['value']);
                    }}
                    isOptionDisabled={option => option.disabled}
                  />
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
