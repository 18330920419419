const types = [
  { label: 'Normal', value: 'Normal' },
  { label: 'Fire', value: 'Fire' },
  { label: 'Water', value: 'Water' },
  { label: 'Grass', value: 'Grass' },
  { label: 'Electric', value: 'Electric' },
  { label: 'Ice', value: 'Ice' },
  { label: 'Fighting', value: 'Fighting' },
  { label: 'Poison', value: 'Poison' },
  { label: 'Ground', value: 'Ground' },
  { label: 'Flying', value: 'Flying' },
  { label: 'Psychic', value: 'Psychic' },
  { label: 'Bug', value: 'Bug' },
  { label: 'Rock', value: 'Rock' },
  { label: 'Ghost', value: 'Ghost' },
  { label: 'Dark', value: 'Dark' },
  { label: 'Dragon', value: 'Dragon' },
  { label: 'Steel', value: 'Steel' },
  { label: 'Fairy', value: 'Fairy' },
];

const colors = [
  { label: 'Blue', value: 'Blue' },
  { label: 'Brown', value: 'Brown' },
  { label: 'Green', value: 'Green' },
  { label: 'Pink', value: 'Pink' },
  { label: 'Purple', value: 'Purple' },
  { label: 'Red', value: 'Red' },
  { label: 'Yellow', value: 'Yellow' },
];

const pokemon = [
  { label: 'Bulbasaur', value: 'Bulbasaur', generation: 1 },
  { label: 'Ivysaur', value: 'Ivysaur', generation: 1 },
  { label: 'Venusaur', value: 'Venusaur', generation: 1 },
  { label: 'Charmander', value: 'Charmander', generation: 1 },
  { label: 'Charmeleon', value: 'Charmeleon', generation: 1 },
  { label: 'Charizard', value: 'Charizard', generation: 1 },
  { label: 'Squirtle', value: 'Squirtle', generation: 1 },
  { label: 'Wartortle', value: 'Wartortle', generation: 1 },
  { label: 'Blastoise', value: 'Blastoise', generation: 1 },
  { label: 'Caterpie', value: 'Caterpie', generation: 1 },
  { label: 'Metapod', value: 'Metapod', generation: 1 },
  { label: 'Butterfree', value: 'Butterfree', generation: 1 },
  { label: 'Weedle', value: 'Weedle', generation: 1 },
  { label: 'Kakuna', value: 'Kakuna', generation: 1 },
  { label: 'Beedrill', value: 'Beedrill', generation: 1 },
  { label: 'Pidgey', value: 'Pidgey', generation: 1 },
  { label: 'Pidgeotto', value: 'Pidgeotto', generation: 1 },
  { label: 'Pidgeot', value: 'Pidgeot', generation: 1 },
  { label: 'Rattata', value: 'Rattata', generation: 1 },
  { label: 'Raticate', value: 'Raticate', generation: 1 },
  { label: 'Spearow', value: 'Spearow', generation: 1 },
  { label: 'Fearow', value: 'Fearow', generation: 1 },
  { label: 'Ekans', value: 'Ekans', generation: 1 },
  { label: 'Arbok', value: 'Arbok', generation: 1 },
  { label: 'Pikachu', value: 'Pikachu', generation: 1 },
  { label: 'Raichu', value: 'Raichu', generation: 1 },
  { label: 'Sandshrew', value: 'Sandshrew', generation: 1 },
  { label: 'Sandslash', value: 'Sandslash', generation: 1 },
  { label: 'Nidoran♀', value: 'Nidoran♀', generation: 1 },
  { label: 'Nidorina', value: 'Nidorina', generation: 1 },
  { label: 'Nidoqueen', value: 'Nidoqueen', generation: 1 },
  { label: 'Nidoran♂', value: 'Nidoran♂', generation: 1 },
  { label: 'Nidorino', value: 'Nidorino', generation: 1 },
  { label: 'Nidoking', value: 'Nidoking', generation: 1 },
  { label: 'Clefairy', value: 'Clefairy', generation: 1 },
  { label: 'Clefable', value: 'Clefable', generation: 1 },
  { label: 'Vulpix', value: 'Vulpix', generation: 1 },
  { label: 'Ninetales', value: 'Ninetales', generation: 1 },
  { label: 'Jigglypuff', value: 'Jigglypuff', generation: 1 },
  { label: 'Wigglytuff', value: 'Wigglytuff', generation: 1 },
  { label: 'Zubat', value: 'Zubat', generation: 1 },
  { label: 'Golbat', value: 'Golbat', generation: 1 },
  { label: 'Oddish', value: 'Oddish', generation: 1 },
  { label: 'Gloom', value: 'Gloom', generation: 1 },
  { label: 'Vileplume', value: 'Vileplume', generation: 1 },
  { label: 'Paras', value: 'Paras', generation: 1 },
  { label: 'Parasect', value: 'Parasect', generation: 1 },
  { label: 'Venonat', value: 'Venonat', generation: 1 },
  { label: 'Venomoth', value: 'Venomoth', generation: 1 },
  { label: 'Diglett', value: 'Diglett', generation: 1 },
  { label: 'Dugtrio', value: 'Dugtrio', generation: 1 },
  { label: 'Meowth', value: 'Meowth', generation: 1 },
  { label: 'Persian', value: 'Persian', generation: 1 },
  { label: 'Psyduck', value: 'Psyduck', generation: 1 },
  { label: 'Golduck', value: 'Golduck', generation: 1 },
  { label: 'Mankey', value: 'Mankey', generation: 1 },
  { label: 'Primeape', value: 'Primeape', generation: 1 },
  { label: 'Growlithe', value: 'Growlithe', generation: 1 },
  { label: 'Arcanine', value: 'Arcanine', generation: 1 },
  { label: 'Poliwag', value: 'Poliwag', generation: 1 },
  { label: 'Poliwhirl', value: 'Poliwhirl', generation: 1 },
  { label: 'Poliwrath', value: 'Poliwrath', generation: 1 },
  { label: 'Abra', value: 'Abra', generation: 1 },
  { label: 'Kadabra', value: 'Kadabra', generation: 1 },
  { label: 'Alakazam', value: 'Alakazam', generation: 1 },
  { label: 'Machop', value: 'Machop', generation: 1 },
  { label: 'Machoke', value: 'Machoke', generation: 1 },
  { label: 'Machamp', value: 'Machamp', generation: 1 },
  { label: 'Bellsprout', value: 'Bellsprout', generation: 1 },
  { label: 'Weepinbell', value: 'Weepinbell', generation: 1 },
  { label: 'Victreebel', value: 'Victreebel', generation: 1 },
  { label: 'Tentacool', value: 'Tentacool', generation: 1 },
  { label: 'Tentacruel', value: 'Tentacruel', generation: 1 },
  { label: 'Geodude', value: 'Geodude', generation: 1 },
  { label: 'Graveler', value: 'Graveler', generation: 1 },
  { label: 'Golem', value: 'Golem', generation: 1 },
  { label: 'Ponyta', value: 'Ponyta', generation: 1 },
  { label: 'Rapidash', value: 'Rapidash', generation: 1 },
  { label: 'Slowpoke', value: 'Slowpoke', generation: 1 },
  { label: 'Slowbro', value: 'Slowbro', generation: 1 },
  { label: 'Magnemite', value: 'Magnemite', generation: 1 },
  { label: 'Magneton', value: 'Magneton', generation: 1 },
  { label: "Farfetch'd", value: "Farfetch'd", generation: 1 },
  { label: 'Doduo', value: 'Doduo', generation: 1 },
  { label: 'Dodrio', value: 'Dodrio', generation: 1 },
  { label: 'Seel', value: 'Seel', generation: 1 },
  { label: 'Dewgong', value: 'Dewgong', generation: 1 },
  { label: 'Grimer', value: 'Grimer', generation: 1 },
  { label: 'Muk', value: 'Muk', generation: 1 },
  { label: 'Shellder', value: 'Shellder', generation: 1 },
  { label: 'Cloyster', value: 'Cloyster', generation: 1 },
  { label: 'Gastly', value: 'Gastly', generation: 1 },
  { label: 'Haunter', value: 'Haunter', generation: 1 },
  { label: 'Gengar', value: 'Gengar', generation: 1 },
  { label: 'Onix', value: 'Onix', generation: 1 },
  { label: 'Drowzee', value: 'Drowzee', generation: 1 },
  { label: 'Hypno', value: 'Hypno', generation: 1 },
  { label: 'Krabby', value: 'Krabby', generation: 1 },
  { label: 'Kingler', value: 'Kingler', generation: 1 },
  { label: 'Voltorb', value: 'Voltorb', generation: 1 },
  { label: 'Electrode', value: 'Electrode', generation: 1 },
  { label: 'Exeggcute', value: 'Exeggcute', generation: 1 },
  { label: 'Exeggutor', value: 'Exeggutor', generation: 1 },
  { label: 'Cubone', value: 'Cubone', generation: 1 },
  { label: 'Marowak', value: 'Marowak', generation: 1 },
  { label: 'Hitmonlee', value: 'Hitmonlee', generation: 1 },
  { label: 'Hitmonchan', value: 'Hitmonchan', generation: 1 },
  { label: 'Lickitung', value: 'Lickitung', generation: 1 },
  { label: 'Koffing', value: 'Koffing', generation: 1 },
  { label: 'Weezing', value: 'Weezing', generation: 1 },
  { label: 'Rhyhorn', value: 'Rhyhorn', generation: 1 },
  { label: 'Rhydon', value: 'Rhydon', generation: 1 },
  { label: 'Chansey', value: 'Chansey', generation: 1 },
  { label: 'Tangela', value: 'Tangela', generation: 1 },
  { label: 'Kangaskhan', value: 'Kangaskhan', generation: 1 },
  { label: 'Horsea', value: 'Horsea', generation: 1 },
  { label: 'Seadra', value: 'Seadra', generation: 1 },
  { label: 'Goldeen', value: 'Goldeen', generation: 1 },
  { label: 'Seaking', value: 'Seaking', generation: 1 },
  { label: 'Staryu', value: 'Staryu', generation: 1 },
  { label: 'Starmie', value: 'Starmie', generation: 1 },
  { label: 'Mr. Mime', value: 'Mr. Mime', generation: 1 },
  { label: 'Scyther', value: 'Scyther', generation: 1 },
  { label: 'Jynx', value: 'Jynx', generation: 1 },
  { label: 'Electabuzz', value: 'Electabuzz', generation: 1 },
  { label: 'Magmar', value: 'Magmar', generation: 1 },
  { label: 'Pinsir', value: 'Pinsir', generation: 1 },
  { label: 'Tauros', value: 'Tauros', generation: 1 },
  { label: 'Magikarp', value: 'Magikarp', generation: 1 },
  { label: 'Gyarados', value: 'Gyarados', generation: 1 },
  { label: 'Lapras', value: 'Lapras', generation: 1 },
  { label: 'Ditto', value: 'Ditto', generation: 1 },
  { label: 'Eevee', value: 'Eevee', generation: 1 },
  { label: 'Vaporeon', value: 'Vaporeon', generation: 1 },
  { label: 'Jolteon', value: 'Jolteon', generation: 1 },
  { label: 'Flareon', value: 'Flareon', generation: 1 },
  { label: 'Porygon', value: 'Porygon', generation: 1 },
  { label: 'Omanyte', value: 'Omanyte', generation: 1 },
  { label: 'Omastar', value: 'Omastar', generation: 1 },
  { label: 'Kabuto', value: 'Kabuto', generation: 1 },
  { label: 'Kabutops', value: 'Kabutops', generation: 1 },
  { label: 'Aerodactyl', value: 'Aerodactyl', generation: 1 },
  { label: 'Snorlax', value: 'Snorlax', generation: 1 },
  { label: 'Articuno', value: 'Articuno', generation: 1 },
  { label: 'Zapdos', value: 'Zapdos', generation: 1 },
  { label: 'Moltres', value: 'Moltres', generation: 1 },
  { label: 'Dratini', value: 'Dratini', generation: 1 },
  { label: 'Dragonair', value: 'Dragonair', generation: 1 },
  { label: 'Dragonite', value: 'Dragonite', generation: 1 },
  { label: 'Mewtwo', value: 'Mewtwo', generation: 1 },
  { label: 'Mew', value: 'Mew', generation: 1 },
  { label: 'Chikorita', value: 'Chikorita', generation: 2 },
  { label: 'Bayleef', value: 'Bayleef', generation: 2 },
  { label: 'Meganium', value: 'Meganium', generation: 2 },
  { label: 'Cyndaquil', value: 'Cyndaquil', generation: 2 },
  { label: 'Quilava', value: 'Quilava', generation: 2 },
  { label: 'Typhlosion', value: 'Typhlosion', generation: 2 },
  { label: 'Totodile', value: 'Totodile', generation: 2 },
  { label: 'Croconaw', value: 'Croconaw', generation: 2 },
  { label: 'Feraligatr', value: 'Feraligatr', generation: 2 },
  { label: 'Sentret', value: 'Sentret', generation: 2 },
  { label: 'Furret', value: 'Furret', generation: 2 },
  { label: 'Hoothoot', value: 'Hoothoot', generation: 2 },
  { label: 'Noctowl', value: 'Noctowl', generation: 2 },
  { label: 'Ledyba', value: 'Ledyba', generation: 2 },
  { label: 'Ledian', value: 'Ledian', generation: 2 },
  { label: 'Spinarak', value: 'Spinarak', generation: 2 },
  { label: 'Ariados', value: 'Ariados', generation: 2 },
  { label: 'Crobat', value: 'Crobat', generation: 2 },
  { label: 'Chinchou', value: 'Chinchou', generation: 2 },
  { label: 'Lanturn', value: 'Lanturn', generation: 2 },
  { label: 'Pichu', value: 'Pichu', generation: 2 },
  { label: 'Cleffa', value: 'Cleffa', generation: 2 },
  { label: 'Igglybuff', value: 'Igglybuff', generation: 2 },
  { label: 'Togepi', value: 'Togepi', generation: 2 },
  { label: 'Togetic', value: 'Togetic', generation: 2 },
  { label: 'Natu', value: 'Natu', generation: 2 },
  { label: 'Xatu', value: 'Xatu', generation: 2 },
  { label: 'Mareep', value: 'Mareep', generation: 2 },
  { label: 'Flaaffy', value: 'Flaaffy', generation: 2 },
  { label: 'Ampharos', value: 'Ampharos', generation: 2 },
  { label: 'Bellossom', value: 'Bellossom', generation: 2 },
  { label: 'Marill', value: 'Marill', generation: 2 },
  { label: 'Azumarill', value: 'Azumarill', generation: 2 },
  { label: 'Sudowoodo', value: 'Sudowoodo', generation: 2 },
  { label: 'Politoed', value: 'Politoed', generation: 2 },
  { label: 'Hoppip', value: 'Hoppip', generation: 2 },
  { label: 'Skiploom', value: 'Skiploom', generation: 2 },
  { label: 'Jumpluff', value: 'Jumpluff', generation: 2 },
  { label: 'Aipom', value: 'Aipom', generation: 2 },
  { label: 'Sunkern', value: 'Sunkern', generation: 2 },
  { label: 'Sunflora', value: 'Sunflora', generation: 2 },
  { label: 'Yanma', value: 'Yanma', generation: 2 },
  { label: 'Wooper', value: 'Wooper', generation: 2 },
  { label: 'Quagsire', value: 'Quagsire', generation: 2 },
  { label: 'Espeon', value: 'Espeon', generation: 2 },
  { label: 'Umbreon', value: 'Umbreon', generation: 2 },
  { label: 'Murkrow', value: 'Murkrow', generation: 2 },
  { label: 'Slowking', value: 'Slowking', generation: 2 },
  { label: 'Misdreavus', value: 'Misdreavus', generation: 2 },
  { label: 'Unown', value: 'Unown', generation: 2 },
  { label: 'Wobbuffet', value: 'Wobbuffet', generation: 2 },
  { label: 'Girafarig', value: 'Girafarig', generation: 2 },
  { label: 'Pineco', value: 'Pineco', generation: 2 },
  { label: 'Forretress', value: 'Forretress', generation: 2 },
  { label: 'Dunsparce', value: 'Dunsparce', generation: 2 },
  { label: 'Gligar', value: 'Gligar', generation: 2 },
  { label: 'Steelix', value: 'Steelix', generation: 2 },
  { label: 'Snubbull', value: 'Snubbull', generation: 2 },
  { label: 'Granbull', value: 'Granbull', generation: 2 },
  { label: 'Qwilfish', value: 'Qwilfish', generation: 2 },
  { label: 'Scizor', value: 'Scizor', generation: 2 },
  { label: 'Shuckle', value: 'Shuckle', generation: 2 },
  { label: 'Heracross', value: 'Heracross', generation: 2 },
  { label: 'Sneasel', value: 'Sneasel', generation: 2 },
  { label: 'Teddiursa', value: 'Teddiursa', generation: 2 },
  { label: 'Ursaring', value: 'Ursaring', generation: 2 },
  { label: 'Slugma', value: 'Slugma', generation: 2 },
  { label: 'Magcargo', value: 'Magcargo', generation: 2 },
  { label: 'Swinub', value: 'Swinub', generation: 2 },
  { label: 'Piloswine', value: 'Piloswine', generation: 2 },
  { label: 'Corsola', value: 'Corsola', generation: 2 },
  { label: 'Remoraid', value: 'Remoraid', generation: 2 },
  { label: 'Octillery', value: 'Octillery', generation: 2 },
  { label: 'Delibird', value: 'Delibird', generation: 2 },
  { label: 'Mantine', value: 'Mantine', generation: 2 },
  { label: 'Skarmory', value: 'Skarmory', generation: 2 },
  { label: 'Houndour', value: 'Houndour', generation: 2 },
  { label: 'Houndoom', value: 'Houndoom', generation: 2 },
  { label: 'Kingdra', value: 'Kingdra', generation: 2 },
  { label: 'Phanpy', value: 'Phanpy', generation: 2 },
  { label: 'Donphan', value: 'Donphan', generation: 2 },
  { label: 'Porygon2', value: 'Porygon2', generation: 2 },
  { label: 'Stantler', value: 'Stantler', generation: 2 },
  { label: 'Smeargle', value: 'Smeargle', generation: 2 },
  { label: 'Tyrogue', value: 'Tyrogue', generation: 2 },
  { label: 'Hitmontop', value: 'Hitmontop', generation: 2 },
  { label: 'Smoochum', value: 'Smoochum', generation: 2 },
  { label: 'Elekid', value: 'Elekid', generation: 2 },
  { label: 'Magby', value: 'Magby', generation: 2 },
  { label: 'Miltank', value: 'Miltank', generation: 2 },
  { label: 'Blissey', value: 'Blissey', generation: 2 },
  { label: 'Raikou', value: 'Raikou', generation: 2 },
  { label: 'Entei', value: 'Entei', generation: 2 },
  { label: 'Suicune', value: 'Suicune', generation: 2 },
  { label: 'Larvitar', value: 'Larvitar', generation: 2 },
  { label: 'Pupitar', value: 'Pupitar', generation: 2 },
  { label: 'Tyranitar', value: 'Tyranitar', generation: 2 },
  { label: 'Lugia', value: 'Lugia', generation: 2 },
  { label: 'Ho-Oh', value: 'Ho-Oh', generation: 2 },
  { label: 'Celebi', value: 'Celebi', generation: 2 },
  { label: 'Treecko', value: 'Treecko', generation: 3 },
  { label: 'Grovyle', value: 'Grovyle', generation: 3 },
  { label: 'Sceptile', value: 'Sceptile', generation: 3 },
  { label: 'Torchic', value: 'Torchic', generation: 3 },
  { label: 'Combusken', value: 'Combusken', generation: 3 },
  { label: 'Blaziken', value: 'Blaziken', generation: 3 },
  { label: 'Mudkip', value: 'Mudkip', generation: 3 },
  { label: 'Marshtomp', value: 'Marshtomp', generation: 3 },
  { label: 'Swampert', value: 'Swampert', generation: 3 },
  { label: 'Poochyena', value: 'Poochyena', generation: 3 },
  { label: 'Mightyena', value: 'Mightyena', generation: 3 },
  { label: 'Zigzagoon', value: 'Zigzagoon', generation: 3 },
  { label: 'Linoone', value: 'Linoone', generation: 3 },
  { label: 'Wurmple', value: 'Wurmple', generation: 3 },
  { label: 'Silcoon', value: 'Silcoon', generation: 3 },
  { label: 'Beautifly', value: 'Beautifly', generation: 3 },
  { label: 'Cascoon', value: 'Cascoon', generation: 3 },
  { label: 'Dustox', value: 'Dustox', generation: 3 },
  { label: 'Lotad', value: 'Lotad', generation: 3 },
  { label: 'Lombre', value: 'Lombre', generation: 3 },
  { label: 'Ludicolo', value: 'Ludicolo', generation: 3 },
  { label: 'Seedot', value: 'Seedot', generation: 3 },
  { label: 'Nuzleaf', value: 'Nuzleaf', generation: 3 },
  { label: 'Shiftry', value: 'Shiftry', generation: 3 },
  { label: 'Taillow', value: 'Taillow', generation: 3 },
  { label: 'Swellow', value: 'Swellow', generation: 3 },
  { label: 'Wingull', value: 'Wingull', generation: 3 },
  { label: 'Pelipper', value: 'Pelipper', generation: 3 },
  { label: 'Ralts', value: 'Ralts', generation: 3 },
  { label: 'Kirlia', value: 'Kirlia', generation: 3 },
  { label: 'Gardevoir', value: 'Gardevoir', generation: 3 },
  { label: 'Surskit', value: 'Surskit', generation: 3 },
  { label: 'Masquerain', value: 'Masquerain', generation: 3 },
  { label: 'Shroomish', value: 'Shroomish', generation: 3 },
  { label: 'Breloom', value: 'Breloom', generation: 3 },
  { label: 'Slakoth', value: 'Slakoth', generation: 3 },
  { label: 'Vigoroth', value: 'Vigoroth', generation: 3 },
  { label: 'Slaking', value: 'Slaking', generation: 3 },
  { label: 'Nincada', value: 'Nincada', generation: 3 },
  { label: 'Ninjask', value: 'Ninjask', generation: 3 },
  { label: 'Shedinja', value: 'Shedinja', generation: 3 },
  { label: 'Whismur', value: 'Whismur', generation: 3 },
  { label: 'Loudred', value: 'Loudred', generation: 3 },
  { label: 'Exploud', value: 'Exploud', generation: 3 },
  { label: 'Makuhita', value: 'Makuhita', generation: 3 },
  { label: 'Hariyama', value: 'Hariyama', generation: 3 },
  { label: 'Azurill', value: 'Azurill', generation: 3 },
  { label: 'Nosepass', value: 'Nosepass', generation: 3 },
  { label: 'Skitty', value: 'Skitty', generation: 3 },
  { label: 'Delcatty', value: 'Delcatty', generation: 3 },
  { label: 'Sableye', value: 'Sableye', generation: 3 },
  { label: 'Mawile', value: 'Mawile', generation: 3 },
  { label: 'Aron', value: 'Aron', generation: 3 },
  { label: 'Lairon', value: 'Lairon', generation: 3 },
  { label: 'Aggron', value: 'Aggron', generation: 3 },
  { label: 'Meditite', value: 'Meditite', generation: 3 },
  { label: 'Medicham', value: 'Medicham', generation: 3 },
  { label: 'Electrike', value: 'Electrike', generation: 3 },
  { label: 'Manectric', value: 'Manectric', generation: 3 },
  { label: 'Plusle', value: 'Plusle', generation: 3 },
  { label: 'Minun', value: 'Minun', generation: 3 },
  { label: 'Volbeat', value: 'Volbeat', generation: 3 },
  { label: 'Illumise', value: 'Illumise', generation: 3 },
  { label: 'Roselia', value: 'Roselia', generation: 3 },
  { label: 'Gulpin', value: 'Gulpin', generation: 3 },
  { label: 'Swalot', value: 'Swalot', generation: 3 },
  { label: 'Carvanha', value: 'Carvanha', generation: 3 },
  { label: 'Sharpedo', value: 'Sharpedo', generation: 3 },
  { label: 'Wailmer', value: 'Wailmer', generation: 3 },
  { label: 'Wailord', value: 'Wailord', generation: 3 },
  { label: 'Numel', value: 'Numel', generation: 3 },
  { label: 'Camerupt', value: 'Camerupt', generation: 3 },
  { label: 'Torkoal', value: 'Torkoal', generation: 3 },
  { label: 'Spoink', value: 'Spoink', generation: 3 },
  { label: 'Grumpig', value: 'Grumpig', generation: 3 },
  { label: 'Spinda', value: 'Spinda', generation: 3 },
  { label: 'Trapinch', value: 'Trapinch', generation: 3 },
  { label: 'Vibrava', value: 'Vibrava', generation: 3 },
  { label: 'Flygon', value: 'Flygon', generation: 3 },
  { label: 'Cacnea', value: 'Cacnea', generation: 3 },
  { label: 'Cacturne', value: 'Cacturne', generation: 3 },
  { label: 'Swablu', value: 'Swablu', generation: 3 },
  { label: 'Altaria', value: 'Altaria', generation: 3 },
  { label: 'Zangoose', value: 'Zangoose', generation: 3 },
  { label: 'Seviper', value: 'Seviper', generation: 3 },
  { label: 'Lunatone', value: 'Lunatone', generation: 3 },
  { label: 'Solrock', value: 'Solrock', generation: 3 },
  { label: 'Barboach', value: 'Barboach', generation: 3 },
  { label: 'Whiscash', value: 'Whiscash', generation: 3 },
  { label: 'Corphish', value: 'Corphish', generation: 3 },
  { label: 'Crawdaunt', value: 'Crawdaunt', generation: 3 },
  { label: 'Baltoy', value: 'Baltoy', generation: 3 },
  { label: 'Claydol', value: 'Claydol', generation: 3 },
  { label: 'Lileep', value: 'Lileep', generation: 3 },
  { label: 'Cradily', value: 'Cradily', generation: 3 },
  { label: 'Anorith', value: 'Anorith', generation: 3 },
  { label: 'Armaldo', value: 'Armaldo', generation: 3 },
  { label: 'Feebas', value: 'Feebas', generation: 3 },
  { label: 'Milotic', value: 'Milotic', generation: 3 },
  { label: 'Castform', value: 'Castform', generation: 3 },
  { label: 'Kecleon', value: 'Kecleon', generation: 3 },
  { label: 'Shuppet', value: 'Shuppet', generation: 3 },
  { label: 'Banette', value: 'Banette', generation: 3 },
  { label: 'Duskull', value: 'Duskull', generation: 3 },
  { label: 'Dusclops', value: 'Dusclops', generation: 3 },
  { label: 'Tropius', value: 'Tropius', generation: 3 },
  { label: 'Chimecho', value: 'Chimecho', generation: 3 },
  { label: 'Absol', value: 'Absol', generation: 3 },
  { label: 'Wynaut', value: 'Wynaut', generation: 3 },
  { label: 'Snorunt', value: 'Snorunt', generation: 3 },
  { label: 'Glalie', value: 'Glalie', generation: 3 },
  { label: 'Spheal', value: 'Spheal', generation: 3 },
  { label: 'Sealeo', value: 'Sealeo', generation: 3 },
  { label: 'Walrein', value: 'Walrein', generation: 3 },
  { label: 'Clamperl', value: 'Clamperl', generation: 3 },
  { label: 'Huntail', value: 'Huntail', generation: 3 },
  { label: 'Gorebyss', value: 'Gorebyss', generation: 3 },
  { label: 'Relicanth', value: 'Relicanth', generation: 3 },
  { label: 'Luvdisc', value: 'Luvdisc', generation: 3 },
  { label: 'Bagon', value: 'Bagon', generation: 3 },
  { label: 'Shelgon', value: 'Shelgon', generation: 3 },
  { label: 'Salamence', value: 'Salamence', generation: 3 },
  { label: 'Beldum', value: 'Beldum', generation: 3 },
  { label: 'Metang', value: 'Metang', generation: 3 },
  { label: 'Metagross', value: 'Metagross', generation: 3 },
  { label: 'Regirock', value: 'Regirock', generation: 3 },
  { label: 'Regice', value: 'Regice', generation: 3 },
  { label: 'Registeel', value: 'Registeel', generation: 3 },
  { label: 'Latias', value: 'Latias', generation: 3 },
  { label: 'Latios', value: 'Latios', generation: 3 },
  { label: 'Kyogre', value: 'Kyogre', generation: 3 },
  { label: 'Groudon', value: 'Groudon', generation: 3 },
  { label: 'Rayquaza', value: 'Rayquaza', generation: 3 },
  { label: 'Jirachi', value: 'Jirachi', generation: 3 },
  { label: 'Deoxys', value: 'Deoxys', generation: 3 },
  { label: 'Turtwig', value: 'Turtwig', generation: 4 },
  { label: 'Grotle', value: 'Grotle', generation: 4 },
  { label: 'Torterra', value: 'Torterra', generation: 4 },
  { label: 'Chimchar', value: 'Chimchar', generation: 4 },
  { label: 'Monferno', value: 'Monferno', generation: 4 },
  { label: 'Infernape', value: 'Infernape', generation: 4 },
  { label: 'Piplup', value: 'Piplup', generation: 4 },
  { label: 'Prinplup', value: 'Prinplup', generation: 4 },
  { label: 'Empoleon', value: 'Empoleon', generation: 4 },
  { label: 'Starly', value: 'Starly', generation: 4 },
  { label: 'Staravia', value: 'Staravia', generation: 4 },
  { label: 'Staraptor', value: 'Staraptor', generation: 4 },
  { label: 'Bidoof', value: 'Bidoof', generation: 4 },
  { label: 'Bibarel', value: 'Bibarel', generation: 4 },
  { label: 'Kricketot', value: 'Kricketot', generation: 4 },
  { label: 'Kricketune', value: 'Kricketune', generation: 4 },
  { label: 'Shinx', value: 'Shinx', generation: 4 },
  { label: 'Luxio', value: 'Luxio', generation: 4 },
  { label: 'Luxray', value: 'Luxray', generation: 4 },
  { label: 'Budew', value: 'Budew', generation: 4 },
  { label: 'Roserade', value: 'Roserade', generation: 4 },
  { label: 'Cranidos', value: 'Cranidos', generation: 4 },
  { label: 'Rampardos', value: 'Rampardos', generation: 4 },
  { label: 'Shieldon', value: 'Shieldon', generation: 4 },
  { label: 'Bastiodon', value: 'Bastiodon', generation: 4 },
  { label: 'Burmy', value: 'Burmy', generation: 4 },
  { label: 'Wormadam', value: 'Wormadam', generation: 4 },
  { label: 'Mothim', value: 'Mothim', generation: 4 },
  { label: 'Combee', value: 'Combee', generation: 4 },
  { label: 'Vespiquen', value: 'Vespiquen', generation: 4 },
  { label: 'Pachirisu', value: 'Pachirisu', generation: 4 },
  { label: 'Buizel', value: 'Buizel', generation: 4 },
  { label: 'Floatzel', value: 'Floatzel', generation: 4 },
  { label: 'Cherubi', value: 'Cherubi', generation: 4 },
  { label: 'Cherrim', value: 'Cherrim', generation: 4 },
  { label: 'Shellos', value: 'Shellos', generation: 4 },
  { label: 'Gastrodon', value: 'Gastrodon', generation: 4 },
  { label: 'Ambipom', value: 'Ambipom', generation: 4 },
  { label: 'Drifloon', value: 'Drifloon', generation: 4 },
  { label: 'Drifblim', value: 'Drifblim', generation: 4 },
  { label: 'Buneary', value: 'Buneary', generation: 4 },
  { label: 'Lopunny', value: 'Lopunny', generation: 4 },
  { label: 'Mismagius', value: 'Mismagius', generation: 4 },
  { label: 'Honchkrow', value: 'Honchkrow', generation: 4 },
  { label: 'Glameow', value: 'Glameow', generation: 4 },
  { label: 'Purugly', value: 'Purugly', generation: 4 },
  { label: 'Chingling', value: 'Chingling', generation: 4 },
  { label: 'Stunky', value: 'Stunky', generation: 4 },
  { label: 'Skuntank', value: 'Skuntank', generation: 4 },
  { label: 'Bronzor', value: 'Bronzor', generation: 4 },
  { label: 'Bronzong', value: 'Bronzong', generation: 4 },
  { label: 'Bonsly', value: 'Bonsly', generation: 4 },
  { label: 'Mime Jr.', value: 'Mime Jr.', generation: 4 },
  { label: 'Happiny', value: 'Happiny', generation: 4 },
  { label: 'Chatot', value: 'Chatot', generation: 4 },
  { label: 'Spiritomb', value: 'Spiritomb', generation: 4 },
  { label: 'Gible', value: 'Gible', generation: 4 },
  { label: 'Gabite', value: 'Gabite', generation: 4 },
  { label: 'Garchomp', value: 'Garchomp', generation: 4 },
  { label: 'Munchlax', value: 'Munchlax', generation: 4 },
  { label: 'Riolu', value: 'Riolu', generation: 4 },
  { label: 'Lucario', value: 'Lucario', generation: 4 },
  { label: 'Hippopotas', value: 'Hippopotas', generation: 4 },
  { label: 'Hippowdon', value: 'Hippowdon', generation: 4 },
  { label: 'Skorupi', value: 'Skorupi', generation: 4 },
  { label: 'Drapion', value: 'Drapion', generation: 4 },
  { label: 'Croagunk', value: 'Croagunk', generation: 4 },
  { label: 'Toxicroak', value: 'Toxicroak', generation: 4 },
  { label: 'Carnivine', value: 'Carnivine', generation: 4 },
  { label: 'Finneon', value: 'Finneon', generation: 4 },
  { label: 'Lumineon', value: 'Lumineon', generation: 4 },
  { label: 'Mantyke', value: 'Mantyke', generation: 4 },
  { label: 'Snover', value: 'Snover', generation: 4 },
  { label: 'Abomasnow', value: 'Abomasnow', generation: 4 },
  { label: 'Weavile', value: 'Weavile', generation: 4 },
  { label: 'Magnezone', value: 'Magnezone', generation: 4 },
  { label: 'Lickilicky', value: 'Lickilicky', generation: 4 },
  { label: 'Rhyperior', value: 'Rhyperior', generation: 4 },
  { label: 'Tangrowth', value: 'Tangrowth', generation: 4 },
  { label: 'Electivire', value: 'Electivire', generation: 4 },
  { label: 'Magmortar', value: 'Magmortar', generation: 4 },
  { label: 'Togekiss', value: 'Togekiss', generation: 4 },
  { label: 'Yanmega', value: 'Yanmega', generation: 4 },
  { label: 'Leafeon', value: 'Leafeon', generation: 4 },
  { label: 'Glaceon', value: 'Glaceon', generation: 4 },
  { label: 'Gliscor', value: 'Gliscor', generation: 4 },
  { label: 'Mamoswine', value: 'Mamoswine', generation: 4 },
  { label: 'Porygon-Z', value: 'Porygon-Z', generation: 4 },
  { label: 'Gallade', value: 'Gallade', generation: 4 },
  { label: 'Probopass', value: 'Probopass', generation: 4 },
  { label: 'Dusknoir', value: 'Dusknoir', generation: 4 },
  { label: 'Froslass', value: 'Froslass', generation: 4 },
  { label: 'Rotom', value: 'Rotom', generation: 4 },
  { label: 'Uxie', value: 'Uxie', generation: 4 },
  { label: 'Mesprit', value: 'Mesprit', generation: 4 },
  { label: 'Azelf', value: 'Azelf', generation: 4 },
  { label: 'Dialga', value: 'Dialga', generation: 4 },
  { label: 'Palkia', value: 'Palkia', generation: 4 },
  { label: 'Heatran', value: 'Heatran', generation: 4 },
  { label: 'Regigigas', value: 'Regigigas', generation: 4 },
  { label: 'Giratina', value: 'Giratina', generation: 4 },
  { label: 'Cresselia', value: 'Cresselia', generation: 4 },
  { label: 'Phione', value: 'Phione', generation: 4 },
  { label: 'Manaphy', value: 'Manaphy', generation: 4 },
  { label: 'Darkrai', value: 'Darkrai', generation: 4, disabled: true },
  { label: 'Shaymin', value: 'Shaymin', generation: 4, disabled: true },
  { label: 'Arceus', value: 'Arceus', generation: 4 },
  { label: 'Victini', value: 'Victini', generation: 5, disabled: true },
  { label: 'Snivy', value: 'Snivy', generation: 5, disabled: true },
  { label: 'Servine', value: 'Servine', generation: 5, disabled: true },
  { label: 'Serperior', value: 'Serperior', generation: 5, disabled: true },
  { label: 'Tepig', value: 'Tepig', generation: 5, disabled: true },
  { label: 'Pignite', value: 'Pignite', generation: 5, disabled: true },
  { label: 'Emboar', value: 'Emboar', generation: 5, disabled: true },
  { label: 'Oshawott', value: 'Oshawott', generation: 5, disabled: true },
  { label: 'Dewott', value: 'Dewott', generation: 5, disabled: true },
  { label: 'Samurott', value: 'Samurott', generation: 5, disabled: true },
  { label: 'Patrat', value: 'Patrat', generation: 5, disabled: true },
  { label: 'Watchog', value: 'Watchog', generation: 5, disabled: true },
  { label: 'Lillipup', value: 'Lillipup', generation: 5 },
  { label: 'Herdier', value: 'Herdier', generation: 5 },
  { label: 'Stoutland', value: 'Stoutland', generation: 5 },
  { label: 'Purrloin', value: 'Purrloin', generation: 5 },
  { label: 'Liepard', value: 'Liepard', generation: 5 },
  { label: 'Pansage', value: 'Pansage', generation: 5, disabled: true },
  { label: 'Simisage', value: 'Simisage', generation: 5, disabled: true },
  { label: 'Pansear', value: 'Pansear', generation: 5, disabled: true },
  { label: 'Simisear', value: 'Simisear', generation: 5, disabled: true },
  { label: 'Panpour', value: 'Panpour', generation: 5, disabled: true },
  { label: 'Simipour', value: 'Simipour', generation: 5, disabled: true },
  { label: 'Munna', value: 'Munna', generation: 5 },
  { label: 'Musharna', value: 'Musharna', generation: 5 },
  { label: 'Pidove', value: 'Pidove', generation: 5 },
  { label: 'Tranquill', value: 'Tranquill', generation: 5 },
  { label: 'Unfezant', value: 'Unfezant', generation: 5 },
  { label: 'Blitzle', value: 'Blitzle', generation: 5, disabled: true },
  { label: 'Zebstrika', value: 'Zebstrika', generation: 5, disabled: true },
  { label: 'Roggenrola', value: 'Roggenrola', generation: 5 },
  { label: 'Boldore', value: 'Boldore', generation: 5 },
  { label: 'Gigalith', value: 'Gigalith', generation: 5 },
  { label: 'Woobat', value: 'Woobat', generation: 5 },
  { label: 'Swoobat', value: 'Swoobat', generation: 5 },
  { label: 'Drilbur', value: 'Drilbur', generation: 5 },
  { label: 'Excadrill', value: 'Excadrill', generation: 5 },
  { label: 'Audino', value: 'Audino', generation: 5 },
  { label: 'Timburr', value: 'Timburr', generation: 5 },
  { label: 'Gurdurr', value: 'Gurdurr', generation: 5 },
  { label: 'Conkeldurr', value: 'Conkeldurr', generation: 5 },
  { label: 'Tympole', value: 'Tympole', generation: 5 },
  { label: 'Palpitoad', value: 'Palpitoad', generation: 5 },
  { label: 'Seismitoad', value: 'Seismitoad', generation: 5 },
  { label: 'Throh', value: 'Throh', generation: 5 },
  { label: 'Sawk', value: 'Sawk', generation: 5 },
  { label: 'Sewaddle', value: 'Sewaddle', generation: 5, disabled: true },
  { label: 'Swadloon', value: 'Swadloon', generation: 5, disabled: true },
  { label: 'Leavanny', value: 'Leavanny', generation: 5, disabled: true },
  { label: 'Venipede', value: 'Venipede', generation: 5 },
  { label: 'Whirlipede', value: 'Whirlipede', generation: 5 },
  { label: 'Scolipede', value: 'Scolipede', generation: 5 },
  { label: 'Cottonee', value: 'Cottonee', generation: 5 },
  { label: 'Whimsicott', value: 'Whimsicott', generation: 5 },
  { label: 'Petilil', value: 'Petilil', generation: 5 },
  { label: 'Lilligant', value: 'Lilligant', generation: 5 },
  { label: 'Basculin', value: 'Basculin', generation: 5 },
  { label: 'Sandile', value: 'Sandile', generation: 5 },
  { label: 'Krokorok', value: 'Krokorok', generation: 5 },
  { label: 'Krookodile', value: 'Krookodile', generation: 5 },
  { label: 'Darumaka', value: 'Darumaka', generation: 5 },
  { label: 'Darmanitan', value: 'Darmanitan', generation: 5 },
  { label: 'Maractus', value: 'Maractus', generation: 5 },
  { label: 'Dwebble', value: 'Dwebble', generation: 5 },
  { label: 'Crustle', value: 'Crustle', generation: 5 },
  { label: 'Scraggy', value: 'Scraggy', generation: 5 },
  { label: 'Scrafty', value: 'Scrafty', generation: 5 },
  { label: 'Sigilyph', value: 'Sigilyph', generation: 5 },
  { label: 'Yamask', value: 'Yamask', generation: 5 },
  { label: 'Cofagrigus', value: 'Cofagrigus', generation: 5 },
  { label: 'Tirtouga', value: 'Tirtouga', generation: 5 },
  { label: 'Carracosta', value: 'Carracosta', generation: 5 },
  { label: 'Archen', value: 'Archen', generation: 5 },
  { label: 'Archeops', value: 'Archeops', generation: 5 },
  { label: 'Trubbish', value: 'Trubbish', generation: 5 },
  { label: 'Garbodor', value: 'Garbodor', generation: 5 },
  { label: 'Zorua', value: 'Zorua', generation: 5 },
  { label: 'Zoroark', value: 'Zoroark', generation: 5 },
  { label: 'Minccino', value: 'Minccino', generation: 5 },
  { label: 'Cinccino', value: 'Cinccino', generation: 5 },
  { label: 'Gothita', value: 'Gothita', generation: 5 },
  { label: 'Gothorita', value: 'Gothorita', generation: 5 },
  { label: 'Gothitelle', value: 'Gothitelle', generation: 5 },
  { label: 'Solosis', value: 'Solosis', generation: 5 },
  { label: 'Duosion', value: 'Duosion', generation: 5 },
  { label: 'Reuniclus', value: 'Reuniclus', generation: 5 },
  { label: 'Ducklett', value: 'Ducklett', generation: 5, disabled: true },
  { label: 'Swanna', value: 'Swanna', generation: 5, disabled: true },
  { label: 'Vanillite', value: 'Vanillite', generation: 5 },
  { label: 'Vanillish', value: 'Vanillish', generation: 5 },
  { label: 'Vanilluxe', value: 'Vanilluxe', generation: 5 },
  { label: 'Deerling', value: 'Deerling', generation: 5, disabled: true },
  { label: 'Sawsbuck', value: 'Sawsbuck', generation: 5, disabled: true },
  { label: 'Emolga', value: 'Emolga', generation: 5 },
  { label: 'Karrablast', value: 'Karrablast', generation: 5 },
  { label: 'Escavalier', value: 'Escavalier', generation: 5 },
  { label: 'Foongus', value: 'Foongus', generation: 5 },
  { label: 'Amoonguss', value: 'Amoonguss', generation: 5 },
  { label: 'Frillish', value: 'Frillish', generation: 5 },
  { label: 'Jellicent', value: 'Jellicent', generation: 5 },
  { label: 'Alomomola', value: 'Alomomola', generation: 5, disabled: true },
  { label: 'Joltik', value: 'Joltik', generation: 5 },
  { label: 'Galvantula', value: 'Galvantula', generation: 5 },
  { label: 'Ferroseed', value: 'Ferroseed', generation: 5 },
  { label: 'Ferrothorn', value: 'Ferrothorn', generation: 5 },
  { label: 'Klink', value: 'Klink', generation: 5 },
  { label: 'Klang', value: 'Klang', generation: 5 },
  { label: 'Klinklang', value: 'Klinklang', generation: 5 },
  { label: 'Tynamo', value: 'Tynamo', generation: 5, disabled: true },
  { label: 'Eelektrik', value: 'Eelektrik', generation: 5, disabled: true },
  { label: 'Eelektross', value: 'Eelektross', generation: 5, disabled: true },
  { label: 'Elgyem', value: 'Elgyem', generation: 5 },
  { label: 'Beheeyem', value: 'Beheeyem', generation: 5 },
  { label: 'Litwick', value: 'Litwick', generation: 5 },
  { label: 'Lampent', value: 'Lampent', generation: 5 },
  { label: 'Chandelure', value: 'Chandelure', generation: 5 },
  { label: 'Axew', value: 'Axew', generation: 5 },
  { label: 'Fraxure', value: 'Fraxure', generation: 5 },
  { label: 'Haxorus', value: 'Haxorus', generation: 5 },
  { label: 'Cubchoo', value: 'Cubchoo', generation: 5 },
  { label: 'Beartic', value: 'Beartic', generation: 5 },
  { label: 'Cryogonal', value: 'Cryogonal', generation: 5 },
  { label: 'Shelmet', value: 'Shelmet', generation: 5 },
  { label: 'Accelgor', value: 'Accelgor', generation: 5 },
  { label: 'Stunfisk', value: 'Stunfisk', generation: 5, disabled: true },
  { label: 'Mienfoo', value: 'Mienfoo', generation: 5 },
  { label: 'Mienshao', value: 'Mienshao', generation: 5 },
  { label: 'Druddigon', value: 'Druddigon', generation: 5 },
  { label: 'Golett', value: 'Golett', generation: 5 },
  { label: 'Golurk', value: 'Golurk', generation: 5 },
  { label: 'Pawniard', value: 'Pawniard', generation: 5 },
  { label: 'Bisharp', value: 'Bisharp', generation: 5 },
  { label: 'Bouffalant', value: 'Bouffalant', generation: 5 },
  { label: 'Rufflet', value: 'Rufflet', generation: 5 },
  { label: 'Braviary', value: 'Braviary', generation: 5 },
  { label: 'Vullaby', value: 'Vullaby', generation: 5 },
  { label: 'Mandibuzz', value: 'Mandibuzz', generation: 5 },
  { label: 'Heatmor', value: 'Heatmor', generation: 5 },
  { label: 'Durant', value: 'Durant', generation: 5 },
  { label: 'Deino', value: 'Deino', generation: 5 },
  { label: 'Zweilous', value: 'Zweilous', generation: 5 },
  { label: 'Hydreigon', value: 'Hydreigon', generation: 5 },
  { label: 'Larvesta', value: 'Larvesta', generation: 5 },
  { label: 'Volcarona', value: 'Volcarona', generation: 5 },
  { label: 'Cobalion', value: 'Cobalion', generation: 5 },
  { label: 'Terrakion', value: 'Terrakion', generation: 5 },
  { label: 'Virizion', value: 'Virizion', generation: 5 },
  { label: 'Tornadus', value: 'Tornadus', generation: 5 },
  { label: 'Thundurus', value: 'Thundurus', generation: 5 },
  { label: 'Reshiram', value: 'Reshiram', generation: 5 },
  { label: 'Zekrom', value: 'Zekrom', generation: 5 },
  { label: 'Landorus', value: 'Landorus', generation: 5 },
  { label: 'Kyurem', value: 'Kyurem', generation: 5 },
  { label: 'Keldeo', value: 'Keldeo', generation: 5 },
  { label: 'Meloetta', value: 'Meloetta', generation: 5, disabled: true },
  { label: 'Genesect', value: 'Genesect', generation: 5 },
  { label: 'Chespin', value: 'Chespin', generation: 6, disabled: true },
  { label: 'Quilladin', value: 'Quilladin', generation: 6, disabled: true },
  { label: 'Chesnaught', value: 'Chesnaught', generation: 6, disabled: true },
  { label: 'Fennekin', value: 'Fennekin', generation: 6, disabled: true },
  { label: 'Braixen', value: 'Braixen', generation: 6, disabled: true },
  { label: 'Delphox', value: 'Delphox', generation: 6, disabled: true },
  { label: 'Froakie', value: 'Froakie', generation: 6, disabled: true },
  { label: 'Frogadier', value: 'Frogadier', generation: 6, disabled: true },
  { label: 'Greninja', value: 'Greninja', generation: 6, disabled: true },
  { label: 'Bunnelby', value: 'Bunnelby', generation: 6, disabled: true },
  { label: 'Diggersby', value: 'Diggersby', generation: 6, disabled: true },
  { label: 'Fletchling', value: 'Fletchling', generation: 6, disabled: true },
  { label: 'Fletchinder', value: 'Fletchinder', generation: 6, disabled: true },
  { label: 'Talonflame', value: 'Talonflame', generation: 6, disabled: true },
  { label: 'Scatterbug', value: 'Scatterbug', generation: 6, disabled: true },
  { label: 'Spewpa', value: 'Spewpa', generation: 6, disabled: true },
  { label: 'Vivillon', value: 'Vivillon', generation: 6, disabled: true },
  { label: 'Litleo', value: 'Litleo', generation: 6, disabled: true },
  { label: 'Pyroar', value: 'Pyroar', generation: 6, disabled: true },
  { label: 'Flabébé', value: 'Flabébé', generation: 6, disabled: true },
  { label: 'Floette', value: 'Floette', generation: 6, disabled: true },
  { label: 'Florges', value: 'Florges', generation: 6, disabled: true },
  { label: 'Skiddo', value: 'Skiddo', generation: 6, disabled: true },
  { label: 'Gogoat', value: 'Gogoat', generation: 6, disabled: true },
  { label: 'Pancham', value: 'Pancham', generation: 6, disabled: true },
  { label: 'Pangoro', value: 'Pangoro', generation: 6, disabled: true },
  { label: 'Furfrou', value: 'Furfrou', generation: 6, disabled: true },
  { label: 'Espurr', value: 'Espurr', generation: 6 },
  { label: 'Meowstic', value: 'Meowstic', generation: 6 },
  { label: 'Honedge', value: 'Honedge', generation: 6 },
  { label: 'Doublade', value: 'Doublade', generation: 6 },
  { label: 'Aegislash', value: 'Aegislash', generation: 6 },
  { label: 'Spritzee', value: 'Spritzee', generation: 6 },
  { label: 'Aromatisse', value: 'Aromatisse', generation: 6 },
  { label: 'Swirlix', value: 'Swirlix', generation: 6 },
  { label: 'Slurpuff', value: 'Slurpuff', generation: 6 },
  { label: 'Inkay', value: 'Inkay', generation: 6 },
  { label: 'Malamar', value: 'Malamar', generation: 6 },
  { label: 'Binacle', value: 'Binacle', generation: 6 },
  { label: 'Barbaracle', value: 'Barbaracle', generation: 6 },
  { label: 'Skrelp', value: 'Skrelp', generation: 6 },
  { label: 'Dragalge', value: 'Dragalge', generation: 6 },
  { label: 'Clauncher', value: 'Clauncher', generation: 6 },
  { label: 'Clawitzer', value: 'Clawitzer', generation: 6 },
  { label: 'Helioptile', value: 'Helioptile', generation: 6 },
  { label: 'Heliolisk', value: 'Heliolisk', generation: 6 },
  { label: 'Tyrunt', value: 'Tyrunt', generation: 6 },
  { label: 'Tyrantrum', value: 'Tyrantrum', generation: 6 },
  { label: 'Amaura', value: 'Amaura', generation: 6 },
  { label: 'Aurorus', value: 'Aurorus', generation: 6 },
  { label: 'Sylveon', value: 'Sylveon', generation: 6 },
  { label: 'Hawlucha', value: 'Hawlucha', generation: 6 },
  { label: 'Dedenne', value: 'Dedenne', generation: 6 },
  { label: 'Carbink', value: 'Carbink', generation: 6 },
  { label: 'Goomy', value: 'Goomy', generation: 6 },
  { label: 'Sliggoo', value: 'Sliggoo', generation: 6 },
  { label: 'Goodra', value: 'Goodra', generation: 6 },
  { label: 'Klefki', value: 'Klefki', generation: 6 },
  { label: 'Phantump', value: 'Phantump', generation: 6 },
  { label: 'Trevenant', value: 'Trevenant', generation: 6 },
  { label: 'Pumpkaboo', value: 'Pumpkaboo', generation: 6 },
  { label: 'Gourgeist', value: 'Gourgeist', generation: 6 },
  { label: 'Bergmite', value: 'Bergmite', generation: 6 },
  { label: 'Avalugg', value: 'Avalugg', generation: 6 },
  { label: 'Noibat', value: 'Noibat', generation: 6 },
  { label: 'Noivern', value: 'Noivern', generation: 6 },
  { label: 'Xerneas', value: 'Xerneas', generation: 6 },
  { label: 'Yveltal', value: 'Yveltal', generation: 6 },
  { label: 'Zygarde', value: 'Zygarde', generation: 6 },
  { label: 'Diancie', value: 'Diancie', generation: 6 },
  { label: 'Hoopa', value: 'Hoopa', generation: 6, disabled: true },
  { label: 'Volcanion', value: 'Volcanion', generation: 6 },
  { label: 'Rowlet', value: 'Rowlet', generation: 7 },
  { label: 'Dartrix', value: 'Dartrix', generation: 7 },
  { label: 'Decidueye', value: 'Decidueye', generation: 7 },
  { label: 'Litten', value: 'Litten', generation: 7 },
  { label: 'Torracat', value: 'Torracat', generation: 7 },
  { label: 'Incineroar', value: 'Incineroar', generation: 7 },
  { label: 'Popplio', value: 'Popplio', generation: 7 },
  { label: 'Brionne', value: 'Brionne', generation: 7 },
  { label: 'Primarina', value: 'Primarina', generation: 7 },
  { label: 'Pikipek', value: 'Pikipek', generation: 7, disabled: true },
  { label: 'Trumbeak', value: 'Trumbeak', generation: 7, disabled: true },
  { label: 'Toucannon', value: 'Toucannon', generation: 7, disabled: true },
  { label: 'Yungoos', value: 'Yungoos', generation: 7, disabled: true },
  { label: 'Gumshoos', value: 'Gumshoos', generation: 7, disabled: true },
  { label: 'Grubbin', value: 'Grubbin', generation: 7 },
  { label: 'Charjabug', value: 'Charjabug', generation: 7 },
  { label: 'Vikavolt', value: 'Vikavolt', generation: 7 },
  { label: 'Crabrawler', value: 'Crabrawler', generation: 7, disabled: true },
  {
    label: 'Crabominable',
    value: 'Crabominable',
    generation: 7,
    disabled: true,
  },
  { label: 'Oricorio', value: 'Oricorio', generation: 7, disabled: true },
  { label: 'Cutiefly', value: 'Cutiefly', generation: 7 },
  { label: 'Ribombee', value: 'Ribombee', generation: 7 },
  { label: 'Rockruff', value: 'Rockruff', generation: 7 },
  { label: 'Lycanroc', value: 'Lycanroc', generation: 7 },
  { label: 'Wishiwashi', value: 'Wishiwashi', generation: 7 },
  { label: 'Mareanie', value: 'Mareanie', generation: 7 },
  { label: 'Toxapex', value: 'Toxapex', generation: 7 },
  { label: 'Mudbray', value: 'Mudbray', generation: 7 },
  { label: 'Mudsdale', value: 'Mudsdale', generation: 7 },
  { label: 'Dewpider', value: 'Dewpider', generation: 7 },
  { label: 'Araquanid', value: 'Araquanid', generation: 7 },
  { label: 'Fomantis', value: 'Fomantis', generation: 7 },
  { label: 'Lurantis', value: 'Lurantis', generation: 7 },
  { label: 'Morelull', value: 'Morelull', generation: 7 },
  { label: 'Shiinotic', value: 'Shiinotic', generation: 7 },
  { label: 'Salandit', value: 'Salandit', generation: 7 },
  { label: 'Salazzle', value: 'Salazzle', generation: 7 },
  { label: 'Stufful', value: 'Stufful', generation: 7 },
  { label: 'Bewear', value: 'Bewear', generation: 7 },
  { label: 'Bounsweet', value: 'Bounsweet', generation: 7 },
  { label: 'Steenee', value: 'Steenee', generation: 7 },
  { label: 'Tsareena', value: 'Tsareena', generation: 7 },
  { label: 'Comfey', value: 'Comfey', generation: 7 },
  { label: 'Oranguru', value: 'Oranguru', generation: 7 },
  { label: 'Passimian', value: 'Passimian', generation: 7 },
  { label: 'Wimpod', value: 'Wimpod', generation: 7 },
  { label: 'Golisopod', value: 'Golisopod', generation: 7 },
  { label: 'Sandygast', value: 'Sandygast', generation: 7 },
  { label: 'Palossand', value: 'Palossand', generation: 7 },
  { label: 'Pyukumuku', value: 'Pyukumuku', generation: 7 },
  { label: 'Type: Null', value: 'Type: Null', generation: 7 },
  { label: 'Silvally', value: 'Silvally', generation: 7 },
  { label: 'Minior', value: 'Minior', generation: 7, disabled: true },
  { label: 'Komala', value: 'Komala', generation: 7, disabled: true },
  { label: 'Turtonator', value: 'Turtonator', generation: 7 },
  { label: 'Togedemaru', value: 'Togedemaru', generation: 7 },
  { label: 'Mimikyu', value: 'Mimikyu', generation: 7 },
  { label: 'Bruxish', value: 'Bruxish', generation: 7, disabled: true },
  { label: 'Drampa', value: 'Drampa', generation: 7 },
  { label: 'Dhelmise', value: 'Dhelmise', generation: 7 },
  { label: 'Jangmo-o', value: 'Jangmo-o', generation: 7 },
  { label: 'Hakamo-o', value: 'Hakamo-o', generation: 7 },
  { label: 'Kommo-o', value: 'Kommo-o', generation: 7 },
  { label: 'Tapu Koko', value: 'Tapu Koko', generation: 7 },
  { label: 'Tapu Lele', value: 'Tapu Lele', generation: 7 },
  { label: 'Tapu Bulu', value: 'Tapu Bulu', generation: 7 },
  { label: 'Tapu Fini', value: 'Tapu Fini', generation: 7 },
  { label: 'Cosmog', value: 'Cosmog', generation: 7 },
  { label: 'Cosmoem', value: 'Cosmoem', generation: 7 },
  { label: 'Solgaleo', value: 'Solgaleo', generation: 7 },
  { label: 'Lunala', value: 'Lunala', generation: 7 },
  { label: 'Nihilego', value: 'Nihilego', generation: 7 },
  { label: 'Buzzwole', value: 'Buzzwole', generation: 7 },
  { label: 'Pheromosa', value: 'Pheromosa', generation: 7 },
  { label: 'Xurkitree', value: 'Xurkitree', generation: 7 },
  { label: 'Celesteela', value: 'Celesteela', generation: 7 },
  { label: 'Kartana', value: 'Kartana', generation: 7 },
  { label: 'Guzzlord', value: 'Guzzlord', generation: 7 },
  { label: 'Necrozma', value: 'Necrozma', generation: 7 },
];

const pokemonNameToNumber = {
  Bulbasaur: '001',
  Ivysaur: '002',
  Venusaur: '003',
  Charmander: '004',
  Charmeleon: '005',
  Charizard: '006',
  Squirtle: '007',
  Wartortle: '008',
  Blastoise: '009',
  Caterpie: '010',
  Metapod: '011',
  Butterfree: '012',
  Weedle: '013',
  Kakuna: '014',
  Beedrill: '015',
  Pidgey: '016',
  Pidgeotto: '017',
  Pidgeot: '018',
  Rattata: '019',
  Raticate: '020',
  Spearow: '021',
  Fearow: '022',
  Ekans: '023',
  Arbok: '024',
  Pikachu: '025',
  Raichu: '026',
  Sandshrew: '027',
  Sandslash: '028',
  'Nidoran\u2640': '029',
  Nidorina: '030',
  Nidoqueen: '031',
  'Nidoran\u2642': '032',
  Nidorino: '033',
  Nidoking: '034',
  Clefairy: '035',
  Clefable: '036',
  Vulpix: '037',
  Ninetales: '038',
  Jigglypuff: '039',
  Wigglytuff: '040',
  Zubat: '041',
  Golbat: '042',
  Oddish: '043',
  Gloom: '044',
  Vileplume: '045',
  Paras: '046',
  Parasect: '047',
  Venonat: '048',
  Venomoth: '049',
  Diglett: '050',
  Dugtrio: '051',
  Meowth: '052',
  Persian: '053',
  Psyduck: '054',
  Golduck: '055',
  Mankey: '056',
  Primeape: '057',
  Growlithe: '058',
  Arcanine: '059',
  Poliwag: '060',
  Poliwhirl: '061',
  Poliwrath: '062',
  Abra: '063',
  Kadabra: '064',
  Alakazam: '065',
  Machop: '066',
  Machoke: '067',
  Machamp: '068',
  Bellsprout: '069',
  Weepinbell: '070',
  Victreebel: '071',
  Tentacool: '072',
  Tentacruel: '073',
  Geodude: '074',
  Graveler: '075',
  Golem: '076',
  Ponyta: '077',
  Rapidash: '078',
  Slowpoke: '079',
  Slowbro: '080',
  Magnemite: '081',
  Magneton: '082',
  "Farfetch'd": '083',
  Doduo: '084',
  Dodrio: '085',
  Seel: '086',
  Dewgong: '087',
  Grimer: '088',
  Muk: '089',
  Shellder: '090',
  Cloyster: '091',
  Gastly: '092',
  Haunter: '093',
  Gengar: '094',
  Onix: '095',
  Drowzee: '096',
  Hypno: '097',
  Krabby: '098',
  Kingler: '099',
  Voltorb: '100',
  Electrode: '101',
  Exeggcute: '102',
  Exeggutor: '103',
  Cubone: '104',
  Marowak: '105',
  Hitmonlee: '106',
  Hitmonchan: '107',
  Lickitung: '108',
  Koffing: '109',
  Weezing: '110',
  Rhyhorn: '111',
  Rhydon: '112',
  Chansey: '113',
  Tangela: '114',
  Kangaskhan: '115',
  Horsea: '116',
  Seadra: '117',
  Goldeen: '118',
  Seaking: '119',
  Staryu: '120',
  Starmie: '121',
  'Mr. Mime': '122',
  Scyther: '123',
  Jynx: '124',
  Electabuzz: '125',
  Magmar: '126',
  Pinsir: '127',
  Tauros: '128',
  Magikarp: '129',
  Gyarados: '130',
  Lapras: '131',
  Ditto: '132',
  Eevee: '133',
  Vaporeon: '134',
  Jolteon: '135',
  Flareon: '136',
  Porygon: '137',
  Omanyte: '138',
  Omastar: '139',
  Kabuto: '140',
  Kabutops: '141',
  Aerodactyl: '142',
  Snorlax: '143',
  Articuno: '144',
  Zapdos: '145',
  Moltres: '146',
  Dratini: '147',
  Dragonair: '148',
  Dragonite: '149',
  Mewtwo: '150',
  Mew: '151',
  Chikorita: '152',
  Bayleef: '153',
  Meganium: '154',
  Cyndaquil: '155',
  Quilava: '156',
  Typhlosion: '157',
  Totodile: '158',
  Croconaw: '159',
  Feraligatr: '160',
  Sentret: '161',
  Furret: '162',
  Hoothoot: '163',
  Noctowl: '164',
  Ledyba: '165',
  Ledian: '166',
  Spinarak: '167',
  Ariados: '168',
  Crobat: '169',
  Chinchou: '170',
  Lanturn: '171',
  Pichu: '172',
  Cleffa: '173',
  Igglybuff: '174',
  Togepi: '175',
  Togetic: '176',
  Natu: '177',
  Xatu: '178',
  Mareep: '179',
  Flaaffy: '180',
  Ampharos: '181',
  Bellossom: '182',
  Marill: '183',
  Azumarill: '184',
  Sudowoodo: '185',
  Politoed: '186',
  Hoppip: '187',
  Skiploom: '188',
  Jumpluff: '189',
  Aipom: '190',
  Sunkern: '191',
  Sunflora: '192',
  Yanma: '193',
  Wooper: '194',
  Quagsire: '195',
  Espeon: '196',
  Umbreon: '197',
  Murkrow: '198',
  Slowking: '199',
  Misdreavus: '200',
  Unown: '201',
  Wobbuffet: '202',
  Girafarig: '203',
  Pineco: '204',
  Forretress: '205',
  Dunsparce: '206',
  Gligar: '207',
  Steelix: '208',
  Snubbull: '209',
  Granbull: '210',
  Qwilfish: '211',
  Scizor: '212',
  Shuckle: '213',
  Heracross: '214',
  Sneasel: '215',
  Teddiursa: '216',
  Ursaring: '217',
  Slugma: '218',
  Magcargo: '219',
  Swinub: '220',
  Piloswine: '221',
  Corsola: '222',
  Remoraid: '223',
  Octillery: '224',
  Delibird: '225',
  Mantine: '226',
  Skarmory: '227',
  Houndour: '228',
  Houndoom: '229',
  Kingdra: '230',
  Phanpy: '231',
  Donphan: '232',
  Porygon2: '233',
  Stantler: '234',
  Smeargle: '235',
  Tyrogue: '236',
  Hitmontop: '237',
  Smoochum: '238',
  Elekid: '239',
  Magby: '240',
  Miltank: '241',
  Blissey: '242',
  Raikou: '243',
  Entei: '244',
  Suicune: '245',
  Larvitar: '246',
  Pupitar: '247',
  Tyranitar: '248',
  Lugia: '249',
  'Ho-Oh': '250',
  Celebi: '251',
  Treecko: '252',
  Grovyle: '253',
  Sceptile: '254',
  Torchic: '255',
  Combusken: '256',
  Blaziken: '257',
  Mudkip: '258',
  Marshtomp: '259',
  Swampert: '260',
  Poochyena: '261',
  Mightyena: '262',
  Zigzagoon: '263',
  Linoone: '264',
  Wurmple: '265',
  Silcoon: '266',
  Beautifly: '267',
  Cascoon: '268',
  Dustox: '269',
  Lotad: '270',
  Lombre: '271',
  Ludicolo: '272',
  Seedot: '273',
  Nuzleaf: '274',
  Shiftry: '275',
  Taillow: '276',
  Swellow: '277',
  Wingull: '278',
  Pelipper: '279',
  Ralts: '280',
  Kirlia: '281',
  Gardevoir: '282',
  Surskit: '283',
  Masquerain: '284',
  Shroomish: '285',
  Breloom: '286',
  Slakoth: '287',
  Vigoroth: '288',
  Slaking: '289',
  Nincada: '290',
  Ninjask: '291',
  Shedinja: '292',
  Whismur: '293',
  Loudred: '294',
  Exploud: '295',
  Makuhita: '296',
  Hariyama: '297',
  Azurill: '298',
  Nosepass: '299',
  Skitty: '300',
  Delcatty: '301',
  Sableye: '302',
  Mawile: '303',
  Aron: '304',
  Lairon: '305',
  Aggron: '306',
  Meditite: '307',
  Medicham: '308',
  Electrike: '309',
  Manectric: '310',
  Plusle: '311',
  Minun: '312',
  Volbeat: '313',
  Illumise: '314',
  Roselia: '315',
  Gulpin: '316',
  Swalot: '317',
  Carvanha: '318',
  Sharpedo: '319',
  Wailmer: '320',
  Wailord: '321',
  Numel: '322',
  Camerupt: '323',
  Torkoal: '324',
  Spoink: '325',
  Grumpig: '326',
  Spinda: '327',
  Trapinch: '328',
  Vibrava: '329',
  Flygon: '330',
  Cacnea: '331',
  Cacturne: '332',
  Swablu: '333',
  Altaria: '334',
  Zangoose: '335',
  Seviper: '336',
  Lunatone: '337',
  Solrock: '338',
  Barboach: '339',
  Whiscash: '340',
  Corphish: '341',
  Crawdaunt: '342',
  Baltoy: '343',
  Claydol: '344',
  Lileep: '345',
  Cradily: '346',
  Anorith: '347',
  Armaldo: '348',
  Feebas: '349',
  Milotic: '350',
  Castform: '351',
  Kecleon: '352',
  Shuppet: '353',
  Banette: '354',
  Duskull: '355',
  Dusclops: '356',
  Tropius: '357',
  Chimecho: '358',
  Absol: '359',
  Wynaut: '360',
  Snorunt: '361',
  Glalie: '362',
  Spheal: '363',
  Sealeo: '364',
  Walrein: '365',
  Clamperl: '366',
  Huntail: '367',
  Gorebyss: '368',
  Relicanth: '369',
  Luvdisc: '370',
  Bagon: '371',
  Shelgon: '372',
  Salamence: '373',
  Beldum: '374',
  Metang: '375',
  Metagross: '376',
  Regirock: '377',
  Regice: '378',
  Registeel: '379',
  Latias: '380',
  Latios: '381',
  Kyogre: '382',
  Groudon: '383',
  Rayquaza: '384',
  Jirachi: '385',
  Deoxys: '386',
  Turtwig: '387',
  Grotle: '388',
  Torterra: '389',
  Chimchar: '390',
  Monferno: '391',
  Infernape: '392',
  Piplup: '393',
  Prinplup: '394',
  Empoleon: '395',
  Starly: '396',
  Staravia: '397',
  Staraptor: '398',
  Bidoof: '399',
  Bibarel: '400',
  Kricketot: '401',
  Kricketune: '402',
  Shinx: '403',
  Luxio: '404',
  Luxray: '405',
  Budew: '406',
  Roserade: '407',
  Cranidos: '408',
  Rampardos: '409',
  Shieldon: '410',
  Bastiodon: '411',
  Burmy: '412',
  Wormadam: '413',
  Mothim: '414',
  Combee: '415',
  Vespiquen: '416',
  Pachirisu: '417',
  Buizel: '418',
  Floatzel: '419',
  Cherubi: '420',
  Cherrim: '421',
  Shellos: '422',
  Gastrodon: '423',
  Ambipom: '424',
  Drifloon: '425',
  Drifblim: '426',
  Buneary: '427',
  Lopunny: '428',
  Mismagius: '429',
  Honchkrow: '430',
  Glameow: '431',
  Purugly: '432',
  Chingling: '433',
  Stunky: '434',
  Skuntank: '435',
  Bronzor: '436',
  Bronzong: '437',
  Bonsly: '438',
  'Mime Jr.': '439',
  Happiny: '440',
  Chatot: '441',
  Spiritomb: '442',
  Gible: '443',
  Gabite: '444',
  Garchomp: '445',
  Munchlax: '446',
  Riolu: '447',
  Lucario: '448',
  Hippopotas: '449',
  Hippowdon: '450',
  Skorupi: '451',
  Drapion: '452',
  Croagunk: '453',
  Toxicroak: '454',
  Carnivine: '455',
  Finneon: '456',
  Lumineon: '457',
  Mantyke: '458',
  Snover: '459',
  Abomasnow: '460',
  Weavile: '461',
  Magnezone: '462',
  Lickilicky: '463',
  Rhyperior: '464',
  Tangrowth: '465',
  Electivire: '466',
  Magmortar: '467',
  Togekiss: '468',
  Yanmega: '469',
  Leafeon: '470',
  Glaceon: '471',
  Gliscor: '472',
  Mamoswine: '473',
  'Porygon-Z': '474',
  Gallade: '475',
  Probopass: '476',
  Dusknoir: '477',
  Froslass: '478',
  Rotom: '479',
  Uxie: '480',
  Mesprit: '481',
  Azelf: '482',
  Dialga: '483',
  Palkia: '484',
  Heatran: '485',
  Regigigas: '486',
  Giratina: '487',
  Cresselia: '488',
  Phione: '489',
  Manaphy: '490',
  Darkrai: '491',
  Shaymin: '492',
  Arceus: '493',
  Victini: '494',
  Snivy: '495',
  Servine: '496',
  Serperior: '497',
  Tepig: '498',
  Pignite: '499',
  Emboar: '500',
  Oshawott: '501',
  Dewott: '502',
  Samurott: '503',
  Patrat: '504',
  Watchog: '505',
  Lillipup: '506',
  Herdier: '507',
  Stoutland: '508',
  Purrloin: '509',
  Liepard: '510',
  Pansage: '511',
  Simisage: '512',
  Pansear: '513',
  Simisear: '514',
  Panpour: '515',
  Simipour: '516',
  Munna: '517',
  Musharna: '518',
  Pidove: '519',
  Tranquill: '520',
  Unfezant: '521',
  Blitzle: '522',
  Zebstrika: '523',
  Roggenrola: '524',
  Boldore: '525',
  Gigalith: '526',
  Woobat: '527',
  Swoobat: '528',
  Drilbur: '529',
  Excadrill: '530',
  Audino: '531',
  Timburr: '532',
  Gurdurr: '533',
  Conkeldurr: '534',
  Tympole: '535',
  Palpitoad: '536',
  Seismitoad: '537',
  Throh: '538',
  Sawk: '539',
  Sewaddle: '540',
  Swadloon: '541',
  Leavanny: '542',
  Venipede: '543',
  Whirlipede: '544',
  Scolipede: '545',
  Cottonee: '546',
  Whimsicott: '547',
  Petilil: '548',
  Lilligant: '549',
  Basculin: '550',
  Sandile: '551',
  Krokorok: '552',
  Krookodile: '553',
  Darumaka: '554',
  Darmanitan: '555',
  Maractus: '556',
  Dwebble: '557',
  Crustle: '558',
  Scraggy: '559',
  Scrafty: '560',
  Sigilyph: '561',
  Yamask: '562',
  Cofagrigus: '563',
  Tirtouga: '564',
  Carracosta: '565',
  Archen: '566',
  Archeops: '567',
  Trubbish: '568',
  Garbodor: '569',
  Zorua: '570',
  Zoroark: '571',
  Minccino: '572',
  Cinccino: '573',
  Gothita: '574',
  Gothorita: '575',
  Gothitelle: '576',
  Solosis: '577',
  Duosion: '578',
  Reuniclus: '579',
  Ducklett: '580',
  Swanna: '581',
  Vanillite: '582',
  Vanillish: '583',
  Vanilluxe: '584',
  Deerling: '585',
  Sawsbuck: '586',
  Emolga: '587',
  Karrablast: '588',
  Escavalier: '589',
  Foongus: '590',
  Amoonguss: '591',
  Frillish: '592',
  Jellicent: '593',
  Alomomola: '594',
  Joltik: '595',
  Galvantula: '596',
  Ferroseed: '597',
  Ferrothorn: '598',
  Klink: '599',
  Klang: '600',
  Klinklang: '601',
  Tynamo: '602',
  Eelektrik: '603',
  Eelektross: '604',
  Elgyem: '605',
  Beheeyem: '606',
  Litwick: '607',
  Lampent: '608',
  Chandelure: '609',
  Axew: '610',
  Fraxure: '611',
  Haxorus: '612',
  Cubchoo: '613',
  Beartic: '614',
  Cryogonal: '615',
  Shelmet: '616',
  Accelgor: '617',
  Stunfisk: '618',
  Mienfoo: '619',
  Mienshao: '620',
  Druddigon: '621',
  Golett: '622',
  Golurk: '623',
  Pawniard: '624',
  Bisharp: '625',
  Bouffalant: '626',
  Rufflet: '627',
  Braviary: '628',
  Vullaby: '629',
  Mandibuzz: '630',
  Heatmor: '631',
  Durant: '632',
  Deino: '633',
  Zweilous: '634',
  Hydreigon: '635',
  Larvesta: '636',
  Volcarona: '637',
  Cobalion: '638',
  Terrakion: '639',
  Virizion: '640',
  Tornadus: '641',
  Thundurus: '642',
  Reshiram: '643',
  Zekrom: '644',
  Landorus: '645',
  Kyurem: '646',
  Keldeo: '647',
  Meloetta: '648',
  Genesect: '649',
  Chespin: '650',
  Quilladin: '651',
  Chesnaught: '652',
  Fennekin: '653',
  Braixen: '654',
  Delphox: '655',
  Froakie: '656',
  Frogadier: '657',
  Greninja: '658',
  Bunnelby: '659',
  Diggersby: '660',
  Fletchling: '661',
  Fletchinder: '662',
  Talonflame: '663',
  Scatterbug: '664',
  Spewpa: '665',
  Vivillon: '666',
  Litleo: '667',
  Pyroar: '668',
  'Flab\u00e9b\u00e9': '669',
  Floette: '670',
  Florges: '671',
  Skiddo: '672',
  Gogoat: '673',
  Pancham: '674',
  Pangoro: '675',
  Furfrou: '676',
  Espurr: '677',
  Meowstic: '678',
  Honedge: '679',
  Doublade: '680',
  Aegislash: '681',
  Spritzee: '682',
  Aromatisse: '683',
  Swirlix: '684',
  Slurpuff: '685',
  Inkay: '686',
  Malamar: '687',
  Binacle: '688',
  Barbaracle: '689',
  Skrelp: '690',
  Dragalge: '691',
  Clauncher: '692',
  Clawitzer: '693',
  Helioptile: '694',
  Heliolisk: '695',
  Tyrunt: '696',
  Tyrantrum: '697',
  Amaura: '698',
  Aurorus: '699',
  Sylveon: '700',
  Hawlucha: '701',
  Dedenne: '702',
  Carbink: '703',
  Goomy: '704',
  Sliggoo: '705',
  Goodra: '706',
  Klefki: '707',
  Phantump: '708',
  Trevenant: '709',
  Pumpkaboo: '710',
  Gourgeist: '711',
  Bergmite: '712',
  Avalugg: '713',
  Noibat: '714',
  Noivern: '715',
  Xerneas: '716',
  Yveltal: '717',
  Zygarde: '718',
  Diancie: '719',
  Hoopa: '720',
  Volcanion: '721',
  Rowlet: '722',
  Dartrix: '723',
  Decidueye: '724',
  Litten: '725',
  Torracat: '726',
  Incineroar: '727',
  Popplio: '728',
  Brionne: '729',
  Primarina: '730',
  Pikipek: '731',
  Trumbeak: '732',
  Toucannon: '733',
  Yungoos: '734',
  Gumshoos: '735',
  Grubbin: '736',
  Charjabug: '737',
  Vikavolt: '738',
  Crabrawler: '739',
  Crabominable: '740',
  Oricorio: '741',
  Cutiefly: '742',
  Ribombee: '743',
  Rockruff: '744',
  Lycanroc: '745',
  Wishiwashi: '746',
  Mareanie: '747',
  Toxapex: '748',
  Mudbray: '749',
  Mudsdale: '750',
  Dewpider: '751',
  Araquanid: '752',
  Fomantis: '753',
  Lurantis: '754',
  Morelull: '755',
  Shiinotic: '756',
  Salandit: '757',
  Salazzle: '758',
  Stufful: '759',
  Bewear: '760',
  Bounsweet: '761',
  Steenee: '762',
  Tsareena: '763',
  Comfey: '764',
  Oranguru: '765',
  Passimian: '766',
  Wimpod: '767',
  Golisopod: '768',
  Sandygast: '769',
  Palossand: '770',
  Pyukumuku: '771',
  'Type: Null': '772',
  Silvally: '773',
  Minior: '774',
  Komala: '775',
  Turtonator: '776',
  Togedemaru: '777',
  Mimikyu: '778',
  Bruxish: '779',
  Drampa: '780',
  Dhelmise: '781',
  'Jangmo-o': '782',
  'Hakamo-o': '783',
  'Kommo-o': '784',
  'Tapu Koko': '785',
  'Tapu Lele': '786',
  'Tapu Bulu': '787',
  'Tapu Fini': '788',
  Cosmog: '789',
  Cosmoem: '790',
  Solgaleo: '791',
  Lunala: '792',
  Nihilego: '793',
  Buzzwole: '794',
  Pheromosa: '795',
  Xurkitree: '796',
  Celesteela: '797',
  Kartana: '798',
  Guzzlord: '799',
  Necrozma: '800',
  Magearna: '801',
};

const fakemonToName = {
  'type1=Ghost_type2=Dragon_legendary_k=1024_p=0.99_score=89.00989532470703_count=1449.png':
    'Halluwhisp',
  'type1=Ghost_type2=Flying__k=1024_p=0.99_score=134.4375_count=2575.png':
    'Azulue',
  'type1=Electric_type2=Fairy__k=1024_p=0.99_score=134.40914916992188_count=664.png':
    'Earaposa',
  'type1=Fairy_type2=Electric__k=1024_p=0.99_score=133.02581787109375_count=73.png':
    'Tigou',
  'type1=Water_type2=Dragon__k=1024_p=0.99_score=0.8191375136375427_count=8680.png':
    'Sliggivor',
  'type1=Dragon_type2=Ghost__k=1024_p=0.99_score=0.29191771149635315_count=5258.png':
    'Grimbleghast',
};

const shinyPokemon = [
  'type1=Normal_type2=Grass_legendary_k=1024_p=0.99_score=0.59062659740448_count_shiny=8458.png',
];

const legendaryPokemon = [
  'type1=Flying_type2=Water_legendary_k=1024_p=0.99_score=0.4229212999343872_count=16508.png',
  'type1=Ice_type2=Flying__k=1024_p=0.99_score=0.11349654942750931_count=10842.png',

  'type1=Flying_type2=Grass_legendary_k=1024_p=0.99_score=0.23307019472122192_count=5781.png',
  'type1=Flying_type2=Dragon_legendary_k=1024_p=0.99_score=0.04537614807486534_count=10854.png',
  'type1=Flying_type2=Fire_legendary_k=1024_p=0.99_score=0.24240243434906006_count=8865.png',
  'type1=Flying_type2=Ice__k=1024_p=0.99_score=0.26109960675239563_count=11177.png',
  'type1=Poison_type2=Flying__k=1024_p=0.99_score=0.28501391410827637_count=9767.png',
  'type1=Flying_type2=Water_legendary_k=1024_p=0.99_score=0.7503173351287842_count=5509.png',
  'type1=Flying_type2=Psychic_legendary_k=1024_p=0.99_score=0.4963664710521698_count=6436.png',
  'type1=Flying_type2=Fire_legendary_k=1024_p=0.99_score=0.24240243434906006_count=8865.png',
  'type1=Flying_type2=Ice__k=1  024_p=0.99_score=0.26109960675239563_count=11177.png',
  'type1=Flying_type2=Bug_legendary_k=1024_p=0.99_score=0.194013312458992_count=14222.png',

  'type1=Flying_type2=Dragon__k=1024_p=0.99_score=0.38846850395202637_count=17872.png',
  'type1=Fairy_type2=Flying_legendary_k=1024_p=0.99_score=107.57249450683594_count=1895.png',

  'type1=Dark_type2=Flying_legendary_k=1024_p=0.99_score=96.17015075683594_count=1791.png',
  'type1=Grass_type2=Flying__k=1024_p=0.99_score=0.12182391434907913_count=7014.png',

  'type1=Flying_type2=Poison_legendary_k=1024_p=0.99_score=0.613711416721344_count=16642.png',

  'type1=Flying_type2=Dragon_legendary_k=1024_p=0.99_score=0.04537614807486534_count=10854.png',
  'type1=Flying_type2=Fairy__k=1024_p=0.99_score=0.7612209320068359_count=21820.png',
  'type1=Flying_type2=Ice_legendary_k=1024_p=0.99_score=0.19792570173740387_count=13886.png',
  'type1=Ice_type2=Bug_legendary_k=1024_p=0.99_score=89.58811950683594_count=1724.png',
  'type1=Poison_type2=Electric_legendary_k=1024_p=0.99_score=125.57803344726562_count=2315.png',
  'type1=Ice_type2=Fairy_legendary_k=1024_p=0.99_score=85.7862548828125_count=1488.png',
  'type1=Grass_type2=Normal_legendary_k=1024_p=0.99_score=102.8133544921875_count=3446.png',
  'type1=Dragon_type2=Grass_legendary_k=1024_p=0.99_score=0.30286452174186707_count=6455.png',
  'type1=Fighting_type2=Water_legendary_k=1024_p=0.99_score=0.6188176870346069_count=11059.png',
  'type1=Electric_type2=Ghost_legendary_k=1024_p=0.99_score=0.2671561539173126_count=15758.png',
  'type1=Normal_type2=Water_legendary_k=1024_p=0.99_score=107.5401382446289_count=3241.png',
  'type1=Psychic_type2=Ghost_legendary_k=1024_p=0.99_score=137.14279174804688_count=1998.png',
  'type1=Fairy_type2=Bug_legendary_k=1024_p=0.99_score=116.68476867675781_count=2337.png',
  'type1=Grass_type2=Psychic_legendary_k=1024_p=0.99_score=0.007404795847833157_count=5223.png',
  'type1=Dark_type2=Fairy_legendary_k=1024_p=0.99_score=0.25805234909057617_count=6992.png',
  'type1=Fairy_type2=Flying_legendary_k=1024_p=0.99_score=123.53060150146484_count=1893.png',
  'type1=Dark_type2=Steel_legendary_k=1024_p=0.99_score=0.730705738067627_count=8176.png',
  'type1=Ice_type2=Dragon_legendary_k=1024_p=0.99_score=0.4505254030227661_count=5992.png',
  'type1=Dark_type2=Dragon_legendary_k=1024_p=0.99_score=94.87100219726562_count=1142.png',
  'type1=Dragon_type2=Dark_legendary_k=1024_p=0.99_score=0.6505982875823975_count=7909.png',
  'type1=Electric_type2=Grass_legendary_k=1024_p=0.99_score=128.4713134765625_count=2426.png',
  'type1=Fairy_type2=Fighting_legendary_k=1024_p=0.99_score=142.86984252929688_count=2052.png',
  'type1=Fairy_type2=Ice_legendary_k=1024_p=0.99_score=0.7881982326507568_count=4357.png',
  'type1=Flying_type2=Dragon_legendary_k=1024_p=0.99_score=0.04537614807486534_count=10854.png',
  'type1=Bug_type2=Flying_legendary_k=1024_p=0.99_score=103.04885864257812_count=3131.png',
  'type1=Flying_type2=Fire_legendary_k=1024_p=0.99_score=0.24240243434906006_count=8865.png',
  'type1=Steel_type2=Bug_legendary_k=1024_p=0.99_score=0.45863237977027893_count=5422.png',
  'type1=Fairy_type2=Fighting_legendary_k=1024_p=0.99_score=0.10827728360891342_count=10500.png',
  'type1=Fairy_type2=Bug_legendary_k=1024_p=0.99_score=0.14483188092708588_count=6731.png',
  'type1=Poison_type2=Ghost_legendary_k=1024_p=0.99_score=0.6090834736824036_count=7024.png',
  'type1=Dragon_type2=Grass_legendary_k=1024_p=0.99_score=105.752685546875_count=2769.png',
  'type1=Water_type2=_legendary_k=1024_p=0.99_score=87.62641906738281_count=195.png',
  'type1=Psychic_type2=Rock_legendary_k=1024_p=0.99_score=0.6269584894180298_count=8812.png',
  'type1=Rock_type2=Dark_legendary_k=1024_p=0.99_score=103.29177856445312_count=1061.png',
  'type1=Ghost_type2=Dragon_legendary_k=1024_p=0.99_score=89.00989532470703_count=1449.png',
  'type1=Bug_type2=Grass_legendary_k=1024_p=0.99_score=0.9153689742088318_count=9940.png',
  'type1=Dark_type2=Ghost_legendary_k=1024_p=0.99_score=0.9040758609771729_count=6451.png',
  'type1=Grass_type2=Ghost_legendary_k=1024_p=0.99_score=0.03964412212371826_count=5987.png',
  'type1=Grass_type2=Electric_legendary_k=1024_p=0.99_score=122.08161926269531_count=703.png',
  'type1=Dragon_type2=Fire_legendary_k=1024_p=0.99_score=0.18535657227039337_count=6672.png',
  'type1=Normal_type2=Fighting_legendary_k=1024_p=0.99_score=100.90109252929688_count=2097.png',
  'type1=Ghost_type2=Ice_legendary_k=1024_p=0.99_score=0.06457185000181198_count=9894.png',
  'type1=Fairy_type2=Bug_legendary_k=1024_p=0.99_score=111.63961029052734_count=2896.png',
  'type1=Bug_type2=Poison_legendary_k=1024_p=0.99_score=0.08535841852426529_count=5850.png',
  'type1=Fairy_type2=Normal_legendary_k=1024_p=0.99_score=0.3894668221473694_count=7484.png',
  'type1=Psychic_type2=Electric_legendary_k=1024_p=0.99_score=0.09589187055826187_count=7950.png',
  'type1=Ground_type2=Electric_legendary_k=1024_p=0.99_score=132.34234619140625_count=2112.png',
  'type1=Fighting_type2=Ghost_legendary_k=1024_p=0.99_score=0.14780689775943756_count=9279.png',
  'type1=Dark_type2=Water_legendary_k=1024_p=0.99_score=0.09526637941598892_count=7047.png',
  'type1=Fighting_type2=Poison_legendary_k=1024_p=0.99_score=0.5325353145599365_count=7021.png',
  'type1=Poison_type2=Dragon_legendary_k=1024_p=0.99_score=0.0808463767170906_count=8319.png',
  'type1=Ice_type2=Ghost_legendary_k=1024_p=0.99_score=128.64349365234375_count=1559.png',
  'type1=Electric_type2=Dragon_legendary_k=1024_p=0.99_score=106.12646484375_count=2462.png',
  'type1=Ice_type2=Psychic_legendary_k=1024_p=0.99_score=0.46565958857536316_count=4537.png',
  'type1=Normal_type2=Electric_legendary_k=1024_p=0.99_score=0.7496631145477295_count=9052.png',
  'type1=Dark_type2=Psychic_legendary_k=1024_p=0.99_score=161.92318725585938_count=1336.png',
  'type1=Psychic_type2=Fighting_legendary_k=1024_p=0.99_score=135.7406005859375_count=710.png',
  'type1=Ground_type2=Electric_legendary_k=1024_p=0.99_score=86.71041107177734_count=1301.png',
  'type1=Dragon_type2=Psychic_legendary_k=1024_p=0.99_score=0.8914474844932556_count=7177.png',
  'type1=Psychic_type2=Electric_legendary_k=1024_p=0.99_score=135.27078247070312_count=3326.png',
  'type1=Grass_type2=Electric_legendary_k=1024_p=0.99_score=123.40126037597656_count=18.png',
  'type1=Fire_type2=Dark_legendary_k=1024_p=0.99_score=115.05867767333984_count=1862.png',
  'type1=Fire_type2=Water_legendary_k=1024_p=0.99_score=0.4902724623680115_count=6844.png',
  'type1=Water_type2=Dragon_legendary_k=1024_p=0.99_score=0.3335613012313843_count=4061.png',
  'type1=Fire_type2=Fairy_legendary_k=1024_p=0.99_score=0.09394116699695587_count=14151.png',
  'type1=Psychic_type2=Normal_legendary_k=1024_p=0.99_score=124.03227996826172_count=370.png',
  'type1=Ghost_type2=Dragon_legendary_k=1024_p=0.99_score=90.10086059570312_count=1450.png',
  'type1=Poison_type2=Psychic_legendary_k=1024_p=0.99_score=127.5399398803711_count=2886.png',
  'type1=Bug_type2=Fairy_legendary_k=1024_p=0.99_score=0.12370345741510391_count=5031.png',
  'type1=Fairy_type2=Grass_legendary_k=1024_p=0.99_score=0.45795759558677673_count=6460.png',
  'type1=Dragon_type2=Poison_legendary_k=1024_p=0.99_score=0.542637288570404_count=7048.png',
  'type1=Ice_type2=Dragon_legendary_k=1024_p=0.99_score=0.7786937952041626_count=10732.png',
  'type1=Fire_type2=Water_legendary_k=1024_p=0.99_score=0.0034989546984434128_count=8193.png',
  'type1=Ghost_type2=_legendary_k=1024_p=0.99_score=0.21730108559131622_count=5523.png',
  'type1=Bug_type2=Grass_legendary_k=1024_p=0.99_score=141.04666137695312_count=1743.png',
  'type1=Dragon_type2=Psychic_legendary_k=1024_p=0.99_score=119.18772888183594_count=3124.png',
  'type1=Bug_type2=Poison_legendary_k=1024_p=0.99_score=108.03033447265625_count=263.png',
  'type1=Water_type2=Dark_legendary_k=1024_p=0.99_score=116.79078674316406_count=222.png',
  'type1=Psychic_type2=Ice_legendary_k=1024_p=0.99_score=0.7645700573921204_count=8272.png',
  'type1=Electric_type2=Dark_legendary_k=1024_p=0.99_score=0.08371268957853317_count=9597.png',
  'type1=Fighting_type2=Ghost_legendary_k=1024_p=0.99_score=0.11431316286325455_count=9333.png',
  'type1=Dark_type2=Ghost_legendary_k=1024_p=0.99_score=0.5458140969276428_count=8122.png',
  'type1=Dragon_type2=Normal_legendary_k=1024_p=0.99_score=0.6100544929504395_count=10546.png',
  'type1=Electric_type2=_legendary_k=1024_p=0.99_score=70.25439453125_count=741.png',
  'type1=Water_type2=Flying_legendary_k=1024_p=0.99_score=0.7574815154075623_count=10723.png',
  'type1=Ghost_type2=Ice_legendary_k=1024_p=0.99_score=0.09021079540252686_count=4094.png',
  'type1=Ground_type2=Electric_legendary_k=1024_p=0.99_score=0.6814238429069519_count=5668.png',
  'type1=Rock_type2=Ice_legendary_k=1024_p=0.99_score=0.6580048203468323_count=8602.png',
  'type1=Normal_type2=Fairy_legendary_k=1024_p=0.99_score=0.2930032014846802_count=8621.png',
  'type1=Normal_type2=Grass_legendary_k=1024_p=0.99_score=0.59062659740448_count=8458.png',
  'type1=Normal_type2=Grass_legendary_k=1024_p=0.99_score=0.59062659740448_count_shiny=8458.png',
  'type1=Fairy_type2=Flying_legendary_k=1024_p=0.99_score=114.93587493896484_count=3490.png',
  'type1=Electric_type2=Dark_legendary_k=1024_p=0.99_score=97.31852722167969_count=1387.png',
  'type1=Normal_type2=_legendary_k=1024_p=0.99_score=99.05715942382812_count=2547.png',
  'type1=Normal_type2=Fairy_legendary_k=1024_p=0.99_score=0.24229736626148224_count=8622.png',
  'type1=Grass_type2=Psychic_legendary_k=1024_p=0.99_score=0.011987105011940002_count=5879.png',
  'type1=Fire_type2=Bug_legendary_k=1024_p=0.99_score=106.39511108398438_count=1856.png',
  'type1=Poison_type2=Dragon_legendary_k=1024_p=0.99_score=111.33563232421875_count=3280.png',
  'type1=Fire_type2=Dragon_legendary_k=1024_p=0.99_score=105.81150817871094_count=3458.png',
  'type1=Steel_type2=Electric_legendary_k=1024_p=0.99_score=0.7464801669120789_count=8128.png',
  'type1=Dragon_type2=Psychic_legendary_k=1024_p=0.99_score=0.10808689147233963_count=7178.png',
  'type1=Fire_type2=Ice_legendary_k=1024_p=0.99_score=0.19316083192825317_count=4635.png',
  'type1=Fairy_type2=Dark_legendary_k=1024_p=0.99_score=105.53047943115234_count=2050.png',
  'type1=Dragon_type2=Bug_legendary_k=1024_p=0.99_score=0.8467841744422913_count=8827.png',
];

export {
  types,
  pokemon,
  colors,
  pokemonNameToNumber,
  fakemonToName,
  legendaryPokemon,
  shinyPokemon,
};
