// useGeneratorQueueStatus.js
import { useState, useEffect } from 'react';

const GENERATOR_STATUS = {
  CALCULATING: 'Calculating Generator Status...',
  COLD_START: 'Initiating AI: Subsequent generations will be faster.',
  WARMING_UP: 'Getting Ready To Generate...',
  IDLE: 'Idle',
  READY: 'Generating Your Fakémon...',
  FINISHED: 'Your Fakémon Is Ready!',
};

const QUEUE_STATUS = {
  CALCULATING: 'Calculating Queue Position...',
  IN_QUEUE: 'Waiting in Queue...',
  ACTIVE_GENERATION: 'Generating Fakémon...',
};

const COLD_START_TIME = 180; // 3 minutes

const isValidDate = dateString => {
  return dateString && !isNaN(new Date(dateString).getTime());
};

const useGeneratorQueueStatus = (
  lastComplete,
  lastPending,
  beamStatus,
  queuePosition,
) => {
  const [generatorStatus, setGeneratorStatus] = useState(
    GENERATOR_STATUS.CALCULATING,
  );
  const [queueStatus, setQueueStatus] = useState(QUEUE_STATUS.CALCULATING);

  useEffect(() => {
    if (beamStatus === 'COMPLETE') {
      setGeneratorStatus(GENERATOR_STATUS.FINISHED);
      setQueueStatus(QUEUE_STATUS.ACTIVE_GENERATION);
      return;
    }
    if (beamStatus === 'RUNNING') {
      setGeneratorStatus(GENERATOR_STATUS.READY);
      setQueueStatus(QUEUE_STATUS.ACTIVE_GENERATION);
      return;
    }

    const now = new Date();
    const lastCompleteTime = isValidDate(lastComplete)
      ? new Date(lastComplete)
      : now;
    const lastPendingTime = isValidDate(lastPending)
      ? new Date(lastPending)
      : now;

    const timeSinceLastComplete = (now - lastCompleteTime) / 1000;
    const timeSinceLastPending = (now - lastPendingTime) / 1000;

    const isColdStart =
      timeSinceLastComplete && timeSinceLastComplete > COLD_START_TIME;
    const generatorUsedRecently =
      timeSinceLastPending && timeSinceLastPending < COLD_START_TIME;

    if (isColdStart) {
      setGeneratorStatus(
        generatorUsedRecently
          ? GENERATOR_STATUS.WARMING_UP
          : GENERATOR_STATUS.COLD_START,
      );
    } else if (generatorUsedRecently) {
      setGeneratorStatus(GENERATOR_STATUS.WARMING_UP);
    }

    // Update queue status
    if (
      queuePosition === undefined ||
      queuePosition === null ||
      queuePosition === 0
    ) {
      if (isColdStart) {
        setGeneratorStatus(
          generatorUsedRecently
            ? GENERATOR_STATUS.WARMING_UP
            : GENERATOR_STATUS.COLD_START,
        );
      } else {
        setQueueStatus(QUEUE_STATUS.CALCULATING);
      }
    } else if (queuePosition === 1) {
      setQueueStatus(
        generatorUsedRecently
          ? 'Getting Ready To Generate'
          : QUEUE_STATUS.ACTIVE_GENERATION,
      );
    } else {
      setGeneratorStatus(GENERATOR_STATUS.WARMING_UP);
      setQueueStatus(QUEUE_STATUS.IN_QUEUE + ' ' + queuePosition.toString());
    }
  }, [lastComplete, lastPending, beamStatus, queuePosition]);

  return { generatorStatus, queueStatus };
};

export default useGeneratorQueueStatus;
