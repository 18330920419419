import styled from 'styled-components';
import ReferralTracker from './ProgressTracker';
import HowItWorks from './ReferralInstructions';
import ReferralInfoComponent from './ReferralInfoComponent';
import ProgressTracker from './ProgressTracker';
import ReferralStats from './ReferralStats';
import HeaderImageLogo from '../../nokemon-gift-logo.png';

const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  flex-direction: row; // Default direction
  justify-content: center;
  align-items: flex-start; // Align items to the start of the flex container
  background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
  text-align: center;
  width: 100%;
  padding: 150px 15px;

  @media (max-width: 1450px) {
    // Adjust this value as needed
    flex-direction: column; // Stack the children on smaller screens
    justify-content: center;
    alight-items: center;
  }
  @media (max-width: 768px) {
    width: auto;
    padding: 70px 15px 75px 15px;
  }
`;

const Title = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: clamp(2.5rem, 8vw, 5rem); // Responsive font size
  font-weight: 700;
  color: white;
  margin: 0;
  text-align: center;
  letter-spacing: 1px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const ProgressTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: clamp(1.5rem, 5vw, 2rem); // Responsive font size
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 1px;
  margin: 0; // Adjusted to remove additional space
  margin-bottom: 1rem;
  padding: 0;
  opacity: 0.9;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
`;

const HeaderImage = styled.img`
  width: 600px;
  opacity: 35%;
  position: absolute;
  top: 20px;
  transform: scaleX(-1);

  @media (max-width: 1210px) {
    display: none;
  }
`;

// Adjust the LeftSection and RightSection styles as follows:
const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; // Take full width on smaller screens
  margin-top: 10px;

  @media (min-width: 1450px) {
    width: 50%; // Half width on larger screens
  }
`;
const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; // Take full width on smaller screens
  margin-top: 20px;
  margin-right: 60px;

  @media (min-width: 1450px) {
    width: 50%; // Half width on larger screens
  }
`;
const Header = ({ userInfo, isLoggedIn }) => {
  return (
    <HeaderContainer>
      <LeftSection>
        <Title>Refer-a-Friend</Title>
        <ReferralInfoComponent
          isLoggedIn={isLoggedIn}
          referralCode={userInfo['referralCode']}
        />
      </LeftSection>
      <RightSection>
        {/* <HeaderImage src={HeaderImageLogo} /> */}
        <ProgressTitle>Your Progress Overview</ProgressTitle>
        <ReferralStats userInfo={userInfo} />
        <ProgressTracker userInfo={userInfo} />
      </RightSection>
    </HeaderContainer>
  );
};

export default Header;
