import React from 'react';
import styled from 'styled-components';

import {
  TooltipManager,
  GeneralTooltipComponent,
} from '../../Tooltips/Tooltips.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 8px;
`;

const Label = styled.label`
  color: #fff;
  margin-right: 10px;
  font-family: Inter, sans-serif;
`;

const Input = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const Toggle = styled.div`
  cursor: pointer;
  display: inline-block;
  height: 24px;
  width: 48px;
  background: #2e2f2f;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;

  ${({ checked }) =>
    checked &&
    `
    background: #6464ec;
  `}
`;

const Knob = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 45px;
  transition: 0.2s;

  ${({ checked }) =>
    checked &&
    `
    left: calc(100% - 2px);
    transform: translateX(-100%);
  `}
`;

const SeedToggle = ({ hide, keepSeed, setKeepSeed }) => {
  const handleToggle = () => {
    setKeepSeed(!keepSeed);
  };

  return (
    <Container hide={hide}>
      <TooltipManager>
        <FontAwesomeIcon icon={faInfoCircle} /> &nbsp;
        <GeneralTooltipComponent
          title={'Fine-tune your Fakémon'}
          subTitle={
            'By selecting this option, the AI will try to keep the overall design of the previous generation.'
          }
        />
      </TooltipManager>
      <Label htmlFor="seed-toggle">Modify Previous Generation?</Label>
      <Input
        id="seed-toggle"
        type="checkbox"
        checked={keepSeed}
        onChange={handleToggle}
      />
      <Toggle checked={keepSeed} onClick={handleToggle}>
        <Knob checked={keepSeed} />
      </Toggle>
    </Container>
  );
};

export default SeedToggle;
