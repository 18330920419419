function Footer(props) {
  return (
    <div className="footer">
      <footer>
        {' '}
        <br />
        <br />
        <small>
          &copy; Copyright 2023, Liam Eloie. No copyright or trademark
          infringement is intended in using Pokémon content.
        </small>{' '}
        <br />
      </footer>
    </div>
  );
}

export default Footer;
