import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/fontawesome-free-solid';
import { VMAvailability } from '../../VMAvailability';

import ModelTypeSelect from './ModelTypeSelect';
import ModelOptionsInterface from './ModelOptionsInterface';
import LearnMoreButton from '../../Buttons/LearnMoreButton';
import NokepointsButton from '../../Buttons/NokepointsButton';
import { toast } from 'react-toastify';
import styled from 'styled-components';

// Define your styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92%;
`;

const OtherContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const GenerateButton = styled.button`
  background-color: purple;
  font-size: 18px;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 10px;

  &:disabled {
    background-color: #888; // Change the background color to a darker shade
    cursor: not-allowed; // Change the cursor to indicate that the button is not clickable
  }
`;

export async function checkGenerationSession(cookie) {
  // Make a request to your backend to check the generation session
  const response = await fetch(
    '/api/generation/advanced/check-generation-session',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cookie }),
    },
  );

  // Parse the response
  const data = await response.json();

  // Return the result
  return data.hasActiveSession;
}

export function AdvancedInput(props) {
  const [modelType, setModelType] = useState('sd_v1');
  const [availability, setAvailability] = useState('calculating');
  const [generatedBy, setGeneratedBy] = useState('type');
  const [selected, setSelected] = useState(undefined);
  const [style, setStyle] = useState('3d model');
  const [batchNum, setBatchNum] = useState(1);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    props.setGeneratedBy(generatedBy);
  }, [generatedBy]);

  const handleTypeGeneration = () => {
    if (props.type1 === undefined) {
      toast(`Please select a value for Type 1`);
      props.setGenerationButtonClicked(false);
      return false;
    }

    if (props.type2 === undefined) {
      toast(`Please select a value for Type 2`);
      props.setGenerationButtonClicked(false);
      return false;
    }

    props.setGeneratedBy(generatedBy);
    props.setSelected(selected);
    props.setStyle(style);
    props.setType1(props.type1);
    props.setType2(props.type2);
    props.setLegendary('false');
    props.setRandom(Math.random());
    props.setOverlay(true);

    setTimeout(() => {
      props.setOverlay(false);
    }, 3500);

    return true;
  };

  const handleBatchGeneration = () => {
    if (batchNum === undefined) {
      toast(`Please select a value for "Number of Generations"`);
      return false;
    }

    props.setGeneratedBy(generatedBy);
    props.setSelected(generatedBy);
    props.setStyle(style);
    props.setGlobalBatchNum(batchNum);
    props.setRandom(Math.random());
    props.setOverlay(true);

    setTimeout(() => {
      props.setOverlay(false);
    }, 3500);

    return true;
  };

  const handleRandomGeneration = () => {
    props.setGeneratedBy(generatedBy);
    props.setSelected(generatedBy);
    props.setStyle(style);
    props.setRandom(Math.random());
    props.setOverlay(true);

    setTimeout(() => {
      props.setOverlay(false);
    }, 3500);

    return true;
  };

  const handleUnavailableResources = () => {
    if (!props.isLoggedIn) {
      toast.error('Please log in to use Advanced Generation.');
    } else {
      if (availability === 'calculating') {
        toast(
          'Calculating whether resources available. Try again in a few seconds.',
        );
      } else if (availability === 'unavailable') {
        toast.error(
          'Resources unavailable at the moment. Please try again later.',
        );
      }
    }
  };

  const handleGenerateClick = async () => {
    if (availability !== 'available') {
      handleUnavailableResources();
      return;
    }

    if (props.userInfo['currencyValue'] === '...') {
      toast('Calculating Credits, try again in a few seconds.');
      return;
    }

    if (props.userInfo['currencyValue'] === 0) {
      toast.error('You do not have enough Credits.');
      props.setModalSettings({ modalType: 'nokepointsPrompt' });
      return;
    }

    // Disable the button
    setButtonDisabled(true);

    // Check the generation session status
    const hasActiveSession = await checkGenerationSession(
      localStorage.getItem('jwt'),
    );

    // If the user has an active session, show an error message
    if (hasActiveSession) {
      toast.error(
        'You already have a generation session in progress. Please wait for it to finish before starting a new one.',
      );
      setButtonDisabled(false);
      document.getElementById('submitButton').disabled = false;
      return;
    }

    props.setGenerationButtonClicked(true);
    props.setIsPremium(true);

    let generationSuccess;
    switch (generatedBy) {
      case 'type':
        generationSuccess = handleTypeGeneration();
        break;
      case 'batch':
        generationSuccess = handleBatchGeneration();
        break;
      case 'random':
        generationSuccess = handleRandomGeneration();
        break;
      default:
        toast.error('Invalid generation method.');
        return;
    }

    if (!generationSuccess) {
      document.getElementById('submitButton').disabled = false;
      setButtonDisabled(false);
    } else {
      // Start polling to check the status of the generation session
      const pollInterval = setInterval(async () => {
        // Check the generation session status
        const hasActiveSession = await checkGenerationSession(
          localStorage.getItem('jwt'),
        );
        // If the generation session has finished, re-enable the button and stop polling
        if (!hasActiveSession) {
          document.getElementById('submitButton').disabled = false;
          setButtonDisabled(false);
          clearInterval(pollInterval);
        }
      }, 5000); // Poll every 5 seconds
    }
  };

  return (
    <Container>
      <Header>
        <VMAvailability
          setVMAvailability={setAvailability}
          isLoggedIn={props.isLoggedIn}
          modelType={props.modelType}
        />
        <ButtonContainer>
          <LearnMoreButton />
          <NokepointsButton
            value={props.userInfo['currencyValue']}
            defaultShow={true}
            zIndex={0}
          />
        </ButtonContainer>
      </Header>
      <MainContent>
        <OtherContent>
          <ModelTypeSelect modelType={modelType} setModelType={setModelType} />
          <ModelOptionsInterface
            {...props}
            setGeneratedBy={setGeneratedBy}
            setBatchNum={setBatchNum}
            availability={availability}
          />
        </OtherContent>
        <GenerateButton
          type="button"
          id="submitButton"
          onClick={handleGenerateClick}
        >
          Generate ({' '}
          <FontAwesomeIcon icon={Icons.faCoins} style={{ color: 'gold' }} />{' '}
          &nbsp;x{batchNum != undefined ? batchNum : 1})
        </GenerateButton>
      </MainContent>
    </Container>
  );
}
