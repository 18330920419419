import React from 'react';
import Modal from './Modal';

import './LoginModal.css';
import GoogleLoginComponent from './GoogleLogin';

import LoginLogo from '../Login Logo.png';
import NokemonSDMascot from '../nokemon_sdv1_mascot.png';

const nokemonImages = [
  // 'https://nokemon.eloie.tech//api/generation/basic/images/fakemon?fileName=type1=Dark_type2=Electric__k=1024_p=0.99_score=0.17075659334659576_count=9195.png',
  'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Grass_type2=Electric_legendary_k=1024_p=0.99_score=122.08161926269531_count=703.png',
];

export const ModalGoogleLoginButton = ({ renderProps }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
      }}
    >
      <button
        className="LoginModal-google-login-button"
        onClick={renderProps.onClick}
        disabled={renderProps.disabled}
      >
        <div className="google-logo"></div>
        <p className="google-login-button-text">Continue with Google</p>
      </button>
      <p
        style={{ fontSize: '14px', fontWeight: '100', width: '100%' }}
        className="LoginPromptModal-login-modal-subtitle-center"
      >
        Cookies are used to remember you across sessions
      </p>
    </div>
  );
};

const LoginModal = ({ isOpen, setModalSettings }) => {
  return (
    <Modal isOpen={isOpen} setModalSettings={setModalSettings}>
      <div className="LoginModal-login-modal">
        <h2 className="LoginModal-login-modal-title">Welcome to Nokémon!</h2>
        <p className="login-modal-subtitle-center">
          <b>Sign In!</b>
        </p>
        <img
          className="LoginModal-login-modal-logo"
          src={NokemonSDMascot}
        ></img>

        <GoogleLoginComponent
          render={renderProps => (
            <ModalGoogleLoginButton renderProps={renderProps} />
          )}
        />
      </div>
      <div className="LoginModal-login-modal-hr"></div>
      <p className="LoginModal-login-modal-subtitle-center">
        Start discovering your own AI-generated creatures now.
      </p>
    </Modal>
  );
};

export default LoginModal;
