import React from 'react';
import styled from 'styled-components';
// ... other imports
import HandLogo from '../../hand-logo.png';
import PodiumImage from '../../podium.png';
import Bronze from '../../tier-1-reward.png';
import Silver from '../../tier-2-reward.png';
import Gold from '../../tier-3-reward.png';
import Platinum from '../../nokemon-gift-logo.png';
import ProgressTracker from './ProgressTracker';

// Define the container for the tiered system section
const TieredSystemSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 2rem;
  background-color: #312a62;
  background: linear-gradient(
    to right,
    #0f0c29,
    #302b63,
    #0f0c29,
    #0f0c29,
    #0f0c29
  );
`;

// Define the title styling
const TierTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: clamp(1.25rem, 4.5vw, 3rem); // Fluid font size
  line-height: 1.2;
  letter-spacing: -0.5px;
  margin-bottom: 3.5rem;
  color: white;
  white-space: nowrap;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 2rem; // Smaller margin for smaller screens
  }

  @media (max-width: 480px) {
    margin-bottom: 1.5rem;
    white-space: normal; // Allows text wrapping for very small screens
  }
`;

// Define the list without default list styling
const TierList = styled.ul`
  list-style: none;
  padding: 0;
`;

// Styled component for the introduction text
const TierIntroduction = styled.p`
  width: 80%;
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: clamp(0.75rem, 4vw, 1.2rem); // Fluid font size
  line-height: 1.2;
  letter-spacing: -0.5px;
  margin-bottom: 3.5rem;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 2rem; // Smaller margin for smaller screens
  }

  @media (max-width: 480px) {
    margin-bottom: 1.5rem;
    white-space: normal; // Allows text wrapping for very small screens
  }
`;

// Define the individual tier item styling with hover effect
const TierItem = styled.li`
  background-color: #e8f0fe;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 20px;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

// Style for the milestone with icon, making icon more prominent
const Milestone = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  color: #1a76d2;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    height: 30px; // Larger icon for prominence
    width: auto;
  }
`;

// Define the reward text styling
const Reward = styled.p`
  font-size: 1rem;
  color: #333;
`;

// Adjusted TierIcon with emphasis on size and border
const TierIcon = styled.img`
  width: 80px; // Larger width for prominence
  height: 80px; // Larger height for prominence
  border: 3px solid #1a76d2; // Border to make the icon stand out
  border-radius: 50%; // Circular border
  padding: 5px; // Space inside the border
  margin-right: 1rem;
`;

const TierDetails = styled.div`
  overflow: hidden;
`;

const TierPerks = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #333;
`;

const PerkItem = styled.li`
  margin-bottom: 0.5rem;
`;

// Visual indicator component (e.g., a progress bar or checkmark for completion)
const VisualIndicator = styled.div`
  // Sample progress bar styles, adjust as needed for your design
  height: 10px;
  width: 100%;
  background: #ddd;
  border-radius: 5px;
  overflow: hidden;
`;

const BenefitList = styled.ul`
  list-style: none;
  text-align: left;
  padding-left: 0;
  margin-top: 15px;
  color: #333;

  li {
    font-size: 0.95em;
    line-height: 1.7;
    position: relative;
    padding-left: 32px;
    margin-bottom: 10px;

    &:before {
      content: '★';
      position: absolute;
      left: 0;
      color: #ffc107;
    }
  }
`;

const RewardsImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

const TierPodiumImage = styled.img`
  width: 512px;

  @media (max-width: 1500px) {
    flex-direction: column;
    width: 384px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    width: 80vw;
    min-width: 200px;
  }
`;

const Section = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 1500px) {
    flex-direction: column;
  }
`;

// Updated tiersData with actual rewards
const tiersData = [
  {
    milestone: 'Reach 3 Referral Points',
    perks: ['+10 Credits', '+5 Permanent Daily Generations Increase'],
    completed: true, // Assuming this tier is completed
    icon: Bronze,
  },
  {
    milestone: 'Reach 8 Referral Points',
    perks: ['+20 Credits', '+10 Permanent Daily Generations Increase'],
    completed: false,
    icon: Silver,
  },
  {
    milestone: 'Reach 15 Referral Points',
    perks: ['+30 Credits', '+15 Permanent Daily Generations Increase'],
    completed: false,
    icon: Gold,
  },
  {
    milestone: 'Every Additional 3 Referral Points',
    perks: ['+5 Credits'],
    completed: false,
    size: 100,
    icon: Platinum,
  },
];

const RewardsContainer = styled.div`
  display: flex;
  flex-direction: column; // Stack cards vertically
  justify-content: center;
  align-items: center;
  gap: 15px; // Space between cards
  width: 50%;

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

const RewardTiersContainer = styled.div`
  display: flex;
  flex-direction: column; // Horizontal layout for reward tiers
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  gap: 20px; // Space between reward tier cards
  width: 100%; // Full width to utilize space
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RewardTierCard = styled.div`
  border-radius: 4px;
  padding: 15px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: 500px;
  border: 2px solid #506cb4;
  gap: 10px;
  // background: linear-gradient(
  //     to bottom,
  //     rgba(255, 255, 255, 0.2),
  //     rgba(255, 255, 255, 0)
  //   ),
  //   rgba(255, 255, 255, 0.1); // Glassy background with linear gradient

  // &:hover {
  //   transform: translateY(-5px);
  //   background: linear-gradient(
  //       to bottom,
  //       rgba(255, 255, 255, 0.6),
  //       rgba(255, 255, 255, 0.2)
  //     ),
  //     rgba(255, 255, 255, 0.2); // Brighter gradient on hover
  // }

  @media (max-width: 768px) {
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const RewardIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;

  @media (max-width: 768px) {
    width: 30%; // Increase width on smaller screens for better visibility
    min-width: 30px;
  }

  @media (max-width: 480px) {
    width: 30%; // Further increase on very small screens
    min-width: 30px;
  }
`;

const RewardIcon = styled.img`
  width: 75px; // Fixed width for larger screens
  height: auto; // Maintain aspect ratio

  @media (max-width: 768px) {
    width: 30px; // Slightly smaller on medium screens
  }

  @media (max-width: 480px) {
    width: 30px; // Even smaller on small screens
  }
`;

const RewardTierTitle = styled.h3`
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
  margin: 10px 0;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 1.1rem; // Slightly smaller on medium screens
  }

  @media (max-width: 480px) {
    font-size: 1rem; // Smaller on small screens for better fit
  }
`;

const RewardTierDescription = styled.p`
  font-size: 1rem;
  color: white;
  margin: 5px 0;
  width: 100%;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.9rem; // Slightly smaller on medium screens
  }

  @media (max-width: 480px) {
    font-size: 0.8rem; // Smaller on small screens for better readability
  }
`;

const RewardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: center;

  @media (max-width: 768px) {
    width: 70%; // Increase width on smaller screens
  }

  @media (max-width: 480px) {
    width: 70%; // Further increase on very small screens
  }
`;
const RewardTiers = ({ tiersData }) => (
  <RewardTiersContainer>
    {tiersData.map((tier, index) => (
      <RewardTierCard key={index}>
        <RewardIconContainer>
          <RewardIcon
            src={tier.icon}
            style={{ width: tier.size ? tier.size : '100px' }}
          />
        </RewardIconContainer>
        <RewardTextContainer>
          <RewardTierTitle>{tier.milestone}</RewardTierTitle>
          {tier.perks.map((perk, index) => (
            <RewardTierDescription key={index}>{perk}</RewardTierDescription>
          ))}
        </RewardTextContainer>
      </RewardTierCard>
    ))}
  </RewardTiersContainer>
);
const TieredSystemSection = () => {
  return (
    <TieredSystemSectionContainer>
      <TierTitle>Tiered Rewards System</TierTitle>
      <TierIntroduction>
        Each referral you make earns you points, steadily unlocking bonus
        rewards. Reach specific point milestones to receive bonuses like
        increased daily generations and Credits. The concept is simple: the more
        you refer, the more benefits you receive. Start referring today and
        enjoy the growing rewards!
      </TierIntroduction>
      <Section>
        <RewardsImageContainer>
          <TierPodiumImage src={PodiumImage} />
        </RewardsImageContainer>
        <RewardsContainer>
          <RewardTiers tiersData={tiersData} />
        </RewardsContainer>
      </Section>
    </TieredSystemSectionContainer>
  );
};

export default TieredSystemSection;
