import styled from 'styled-components';

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 10;
  border: 2px solid #506cb4;
  max-width: 800px; // Adjust for your design
  margin: 20px auto;
  width: 50%;
  min-width: 400px;

  @media (min-width: 1030px) {
    flex-direction: row;
    justify-content: space-around; // This will space out the children equally
    width: 100%;
  }

  @media (max-width: 560px) {
    min-width: 200px;
  }
`;

const StatBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  flex: 1; // This ensures that each StatBlock takes up equal space
  width: 50%;

  @media (min-width: 768px) {
    // Adjust margins if needed for spacing between blocks
  }
`;

const StatLabel = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: white;
  margin-bottom: 5px;
  height: 25px;
  white-space: nowrap;
`;

const StatNumber = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: clamp(1.2rem, 4vw, 2.5rem);
  font-weight: bold;
  height: 50%;
`;

const StatRow = styled.div`
  display: flex;
  // flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 1030px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 560px) {
    flex-direction: column;
    // padding: 20px;
    // justify-content: space-around; // This will space out the children equally
    // width: 100%;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #ccc;
  align-self: center;
  margin: 10px 0;
  display: none;

  @media (min-width: 1030px) {
    height: 60px;
    width: 1px;
    margin: 0 20px;
  }
`;
// Other styled components...

// Usage in a React component
const ReferralStats = ({ userInfo }) => {
  let referralPoints = 0;
  let dailyGenerationIncrease = 0;
  let permanentDailyGenerationIncrease = 0;
  let nokepoints = 0;

  if (userInfo && userInfo.referralRewards) {
    referralPoints = userInfo.referralRewards.referralPoints || 0;
    dailyGenerationIncrease =
      userInfo.referralRewards.totalDailyGenerations || 0;
    permanentDailyGenerationIncrease =
      userInfo.referralRewards.permanentDailyGenerationIncrease || 0;
    nokepoints = userInfo.referralRewards.totalCredits || 0;
  }

  return (
    <StatsContainer>
      <StatRow>
        <StatBlock>
          <StatLabel>Referral Points</StatLabel>
          <StatNumber>{referralPoints}</StatNumber>
        </StatBlock>
        <Divider /> {/* This is the vertical line separator */}
        <StatBlock>
          <StatLabel>Credits</StatLabel>
          <StatNumber>{nokepoints}</StatNumber>
        </StatBlock>
      </StatRow>
      <Divider /> {/* This is the vertical line separator */}
      <StatRow>
        <StatBlock>
          <StatLabel>Daily Generations</StatLabel>
          <StatNumber>{dailyGenerationIncrease}</StatNumber>
        </StatBlock>
        <Divider /> {/* This is the vertical line separator */}
        <StatBlock>
          <StatLabel>Perm. Daily Increase</StatLabel>
          <StatNumber>{permanentDailyGenerationIncrease}</StatNumber>
        </StatBlock>
      </StatRow>
    </StatsContainer>
  );
};

export default ReferralStats;
