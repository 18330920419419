import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #3c4c5a;
  color: #f2f3f4;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const Footer = () => {
  return <FooterContainer>{/* Footer Content Here */}</FooterContainer>;
};

export default Footer;
