import styled from 'styled-components';

// Styled components
export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

export const StatusMessage = styled.div`
  font-family: 'Inter', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 10px 0;
  width: 100%;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const LoadingMessage = styled(StatusMessage)`
  font-family: 'Inter', sans-serif;
  background-color: #e1f5fe;
  color: #0277bd;
`;

export const ErrorMessage = styled(StatusMessage)`
  font-family: 'Inter', sans-serif;
  background-color: #ffebee;
  color: #d32f2f;
`;
