import {
  EightGenerationsLeftModal,
  ZeroGenerationsLeftModal,
  SubscriptionPromptModal,
  UpgradeSubscriptionPromptModal,
  PurchaseCreditsModal,
  PromoCodeModal,
  FreeTrialModal,
} from '..';

const MODALS = {
  NOT_REGISTERED_EIGHT_GENERATIONS_LEFT:
    'NOT_REGISTERED_EIGHT_GENERATIONS_LEFT',
  NOT_REGISTERED_ZERO_GENERATIONS_LEFT: 'NOT_REGISTERED_ZERO_GENERATIONS_LEFT',
  REGISTERED_ZERO_GENERATIONS_LEFT: 'REGISTERED_ZERO_GENERATIONS_LEFT',
  SUBSCRIBED_UPGRADE_ZERO_GENERATIONS_LEFT:
    'SUBSCRIBED_UPGRADE_ZERO_GENERATIONS_LEFT',
  SUBSCRIBED_MAX_ZERO_GENERATIONS_LEFT: 'SUBSCRIBED_MAX_ZERO_GENERATIONS_LEFT',
  PURCHASE_CREDITS_MODAL: 'PURCHASE_CREDITS_MODAL',
  PROMO_CODE_MODAL: 'PROMO_CODE_MODAL',
  FREE_TRIAL_MODAL: 'FREE_TRIAL_MODAL',
};

const ModalManagerComponent = ({ activeModal, onClose, ...modalProps }) => {
  switch (activeModal) {
    case MODALS.NOT_REGISTERED_EIGHT_GENERATIONS_LEFT:
      return (
        <EightGenerationsLeftModal
          isVisible={true}
          onClose={onClose}
          {...modalProps}
        />
      );
    case MODALS.NOT_REGISTERED_ZERO_GENERATIONS_LEFT:
      return (
        <ZeroGenerationsLeftModal
          isVisible={true}
          onClose={onClose}
          {...modalProps}
        />
      );
    case MODALS.REGISTERED_ZERO_GENERATIONS_LEFT:
      return <SubscriptionPromptModal isVisible={true} onClose={onClose} />;
    case MODALS.SUBSCRIBED_UPGRADE_ZERO_GENERATIONS_LEFT:
      return (
        <UpgradeSubscriptionPromptModal isVisible={true} onClose={onClose} />
      );
    case MODALS.PROMO_CODE_MODAL:
      return <PromoCodeModal isVisible={true} onClose={onClose} />;

    case MODALS.PURCHASE_CREDITS_MODAL:
      return <PurchaseCreditsModal isVisible={true} onClose={onClose} />;

    case MODALS.FREE_TRIAL_MODAL:
      return <FreeTrialModal isVisible={true} onClose={onClose} />;
    default:
      return null;
  }
};

export default ModalManagerComponent;
