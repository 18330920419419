// Import necessary dependencies
import React from 'react';
import styled from 'styled-components';

import PricingComponent from './PricingComponent';

// Define styled-components for styling

// Container for the Credits purchase section
const NokepointsSectionContainer = styled.section`
  margin: 2rem 0; // Add some margin to the top and bottom
  display: flex;
  flex-direction: row;
  justify-content: center; // Center the cards horizontally
  gap: 1rem; // Space between cards
  width: 100%;

  @media (max-width: 1110px) {
    align-items: center;
    flex-direction: column; // Make the cards stack vertically on small screens
  }
`;

// Main React component for Credits Section
const NokepointsSection = ({ maxCardWidth }) => {
  const nokepointsPlans = [
    {
      title: 'Starter Pack',
      description: 'A solid beginning for your custom Fakemon creations.',
      price: '$5',
      features: [
        '20 Generation Credits',
        'Ideal for testing new ideas',
        'Create with ease',
      ],
      recommended: false,
      amount: 20,
      productIndex: 0,
    },
    {
      title: 'Explorer’s Choice',
      description: 'More credits to fuel your Fakemon creativity.',
      price: '$10',
      features: [
        '50 Generation Credits',
        'Freedom to explore concepts',
        'Create more, enjoy more',
      ],
      recommended: true,
      amount: 50,
      productIndex: 1,
    },
    {
      title: 'Master’s Vault',
      description: 'Maximum credits for the dedicated Fakemon enthusiast.',
      price: '$20',
      features: [
        '120 Generation Credits',
        'For extensive creative sessions',
        'Bring all your ideas to life',
      ],
      recommended: false,
      amount: 120,
      productIndex: 2,
    },
  ];

  return (
    <NokepointsSectionContainer>
      {nokepointsPlans.map((plan, index) => (
        <PricingComponent
          key={index}
          title={plan.title}
          description={plan.description}
          price={plan.price}
          recommended={plan.recommended}
          features={plan.features}
          amount={plan.amount}
          perMonth={false}
          productIndex={plan.productIndex}
          buttonTitle="Get Credits"
          maxCardWidth={maxCardWidth || '300px'}
        />
      ))}
    </NokepointsSectionContainer>
  );
};

export default NokepointsSection;
