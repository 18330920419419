import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const StyledTooltip = styled(ReactTooltip)`
  &.__react_component_tooltip {
    font-size: 14px !important;
    padding: 15px 15px !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5) !important;
    background-color: #181a1b !important;
    color: #ffffff !important;

    // Glowing border effect
    border: 1px solid #6464ec !important;
    box-shadow: 0 0 8px 2px #6464ec !important;

    &.place-top::after {
      border-top-color: #181a1b !important;
    }
  }
`;

const TooltipComponent = ({ id, text, place, effect, children }) => {
  return (
    <>
      {children}
      <StyledTooltip id={id} place={place || 'top'} effect={effect || 'solid'}>
        {text}
      </StyledTooltip>
    </>
  );
};

export default TooltipComponent;
