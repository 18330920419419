import React from 'react';
import styled, { keyframes } from 'styled-components';
import GoogleLoginComponent from '../GoogleLogin';
import GoogleLogoIcon from '../../google-logo.png';
import WebsiteLogo from '../../nokemon-logo.png'; // Path to your logo image
// Animation for modal entrance
const fadeInAndSlideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; // Ensuring it's above everything
  width: 100vw;
  height: 100vh;
  cursor: pointer; // Add cursor pointer to indicate clickability
`;

const ModalContainer = styled.div`
  background: #1a1a1d; // A shade that complements the rest of the site
  padding: 2em 2em 3em; // Perfectly balanced padding
  border-radius: 6px; // Modern, subtle border-radius
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
  max-width: 420px; // Ideal for various screen sizes
  animation: ${fadeInAndSlideUp} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  cursor: auto; // Prevent clicks inside the modal from closing it
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`;
const Logo = styled.img`
  width: 70px; // A size that denotes importance without overwhelming
`;

const ModalTitle = styled.h2`
  color: #eaeaea; // A bright yet subtle color for legibility
  font-family: 'Inter', sans-serif;
  font-size: 1.75rem;
  font-weight: 700; // Bold for emphasis
  margin-bottom: 1.5rem;
`;

const StyledGoogleButton = styled.button`
  background-color: #ffffff;
  color: rgb(96, 97, 235); // Accent color for the text
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.8rem 1.6rem;
  border: 1px solid rgba(96, 97, 235, 0.3); // A touch of accent color
  border-radius: 6px; // A modern, universally appealing border-radius
  margin-bottom: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;
  will-change: background-color, transform, box-shadow;

  img {
    width: 20px; // Proportionate to the text size
    margin-right: 12px;
  }

  &:hover {
    background-color: rgb(96, 97, 235); // Highlighted with accent color
    color: #ffffff;
    box-shadow: 0 4px 12px rgba(96, 97, 235, 0.5); // Elevated with a soft glow
    transform: translateY(-2px); // A subtle lift
  }

  &:active {
    background-color: rgb(86, 87, 225); // A deeper shade on click
  }
`;

const LegalLinksContainer = styled.div`
  color: #cccccc; // Muted for a non-intrusive appearance
  font-family: 'Inter', sans-serif;
  font-size: 0.85rem;
  margin-top: 1rem;
`;

const LegalLink = styled.a`
  color: rgb(96, 97, 235); // Leveraging the accent color for interactivity
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    text-decoration: underline;
    color: #ffffff; // A bright pop on hover
  }
`;

// Main Component
const LoginModal = ({
  isVisible,
  isModalCenterVisible,
  onLoginSuccess,
  onLoginFailure,
  userContext,
}) => {
  if (!isVisible) return null;

  return (
    <ModalOverlay onClick={onLoginFailure}>
      <ModalContainer onClick={e => e.stopPropagation()}>
        <Logo src={WebsiteLogo} alt="Nokemon Logo" />
        <ModalTitle>Welcome to NokémonAI</ModalTitle>
        <GoogleLoginComponent
          userContext={userContext}
          render={renderProps => (
            <StyledGoogleButton
              onClick={() => {
                renderProps.onClick();
                onLoginSuccess();
              }}
            >
              <img src={GoogleLogoIcon} alt="Google" />
              Continue with Google
            </StyledGoogleButton>
          )}
        />
        <LegalLinksContainer>
          By signing in, you're accepting our&nbsp;
          <LegalLink href="/terms-of-service" target="_blank">
            Terms of Service
          </LegalLink>
          &nbsp;and&nbsp;
          <LegalLink href="/privacy-policy" target="_blank">
            Privacy Policy
          </LegalLink>
          .
        </LegalLinksContainer>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default LoginModal;
