import { useEffect, useState } from 'react';
import './LandingPageMenu.css';
import GenericModal from './GenericModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/fontawesome-free-solid';
import ButtonWithDescription from './ButtonWithDescription';
import {
  NoviceTrainerBadge,
  EliteTrainerBadge,
  MasterTrainerBadge,
} from './SVGComponents';

const subscriptionTierToName = {
  0: 'Basic Trainer',
  1: 'Novice Trainer',
  2: 'Elite Trainer',
  3: 'Master Trainer',
};

const subscriptionTierToClass = {
  0: 'bronze',
  1: 'bronze',
  2: 'silver',
  3: 'gold',
};

function calculateTimeUntilReset() {
  const now = new Date(); // current time
  const resetTime = new Date(now); // reset time

  // Adjust reset time according to daylight saving time
  const daylightSavingStart = new Date(now.getUTCFullYear(), 2, 14, 5); // second Sunday in March, 5am
  const daylightSavingEnd = new Date(now.getUTCFullYear(), 10, 7, 4); // first Sunday in November, 4am

  const isDaylightSaving =
    now >= daylightSavingStart && now < daylightSavingEnd;

  // Set the reset time to 1am EST or 1am EDT
  resetTime.setUTCHours(isDaylightSaving ? 5 : 6, 0, 0, 0);

  // If we've passed the reset time today, set the reset time to tomorrow
  if (now > resetTime) {
    resetTime.setUTCDate(resetTime.getUTCDate() + 1);
  }

  // Calculate the difference in milliseconds
  const diffMs = resetTime - now;

  // Convert to hours and minutes
  const diffHours = Math.floor(diffMs / 1000 / 60 / 60);
  const diffMinutes = Math.floor((diffMs / 1000 / 60) % 60);

  return `Reset: ${diffHours} hours and ${diffMinutes} minutes.`;
}

export const DailyGenerationsButton = ({ value, defaultShow }) => {
  return (
    <ButtonWithDescription
      defaultShow={defaultShow}
      button={
        <button
          className="LandingPageMenu-currency-value-free"
          onClick={() => {
            // window.location.href = '/marketplace';
          }}
        >
          <FontAwesomeIcon icon={Icons.faRobot} /> {value}
        </button>
      }
      description={
        <p
          style={{
            textAlign: 'left',
            width: '300px',
          }}
        >
          Number of Daily Generations. <br />
          <br />
          {calculateTimeUntilReset()}
        </p>
      }
    />
  );
};

export const NokepointsButton = ({ value, defaultShow, zIndex }) => {
  return (
    <ButtonWithDescription
      defaultShow={defaultShow}
      button={
        <button
          className="LandingPageMenu-currency-value-premium"
          style={{}}
          onClick={() => {
            window.location.href =
              '/marketplace?productType=Nokepoints#marketplaceTabs';
          }}
        >
          <FontAwesomeIcon
            icon={Icons.faCoins}
            style={{
              color: 'gold',
            }}
          />{' '}
          {value}
        </button>
      }
      description={'Number of Credits'}
    />
  );
};

export const SVGBadgeComponent = ({ value }) => {
  let content;

  if (value == 1) {
    content = <NoviceTrainerBadge />;
  } else if (value == 2) {
    content = <EliteTrainerBadge />;
  } else if (value == 3) {
    content = <MasterTrainerBadge />;
  }

  return <div className="SVGBadgeComponent">{content}</div>;
};

export const SubscriptionTierSidebarButton = ({
  subscriptionTier,
  endSubscriptionTime,
}) => {
  let text;

  if (subscriptionTier == 1) {
    text = 'Novice Trainer';
  } else if (subscriptionTier == 2) {
    text = 'Elite Trainer';
  } else if (subscriptionTier == 3) {
    text = 'Master Trainer';
  } else {
    text = 'Basic Trainer';
  }

  const endSubscriptionTimeDate = new Date(endSubscriptionTime); // convert timestamp to Date object

  // add 1 day to get start of next day
  endSubscriptionTimeDate.setDate(endSubscriptionTimeDate.getDate() + 1);
  endSubscriptionTimeDate.setHours(0);
  endSubscriptionTimeDate.setMinutes(0);
  endSubscriptionTimeDate.setSeconds(0);
  endSubscriptionTimeDate.setMilliseconds(0);

  const numberDaysRemaininSubscription = Math.floor(
    (endSubscriptionTimeDate - Date.now()) / (24 * 60 * 60 * 1000),
  );

  const subscriptionTierClassName = subscriptionTierToClass[subscriptionTier];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <button
        className={
          'LandingPageMenu-subsciption-tier-sidebar-button ' +
          subscriptionTierClassName
        }
        style={{
          justifyContent: 'center',
          backgroundColor: 'transparent',
          ...(subscriptionTier == 0
            ? { padding: '12px 0', marginBottom: '10px' }
            : {}),
        }}
        onClick={async () => {
          if (subscriptionTier > 0) {
            const res = await fetch('/api/stripe/portalSession', {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              method: 'POST',
              body: JSON.stringify({
                cookie: localStorage.getItem('jwt'),
              }),
            });

            if (res.status != 303) {
              const text = await res.text();
              console.error(text);
              return;
            }

            const body = await res.json();
            window.location.href = body.portalSessionUrl;
          } else {
            window.location.href = '/marketplace';
          }
        }}
      >
        {subscriptionTier != 0 ? (
          <SVGBadgeComponent value={subscriptionTier} />
        ) : (
          <></>
        )}
        <span className="LandingPageMenu-subscription-tier-button">
          &nbsp; {subscriptionTierToName[subscriptionTier]}
        </span>
      </button>
      {/* {subscriptionTier != 0 ? (
        <div className={`ButtonWithDescription-sidebar-description`}>
          {`${numberDaysRemaininSubscription} days left`}
        </div>
      ) : (
        <></>
      )} */}
    </div>
  );
};

export const SubscriptionTierButton = ({
  subscriptionTier,
  endSubscriptionTime,
}) => {
  let text;

  if (subscriptionTier == 1) {
    text = 'Novice Trainer';
  } else if (subscriptionTier == 2) {
    text = 'Elite Trainer';
  } else if (subscriptionTier == 3) {
    text = 'Master Trainer';
  }

  const endSubscriptionTimeDate = new Date(endSubscriptionTime); // convert timestamp to Date object

  // add 1 day to get start of next day
  endSubscriptionTimeDate.setDate(endSubscriptionTimeDate.getDate() + 1);
  endSubscriptionTimeDate.setHours(0);
  endSubscriptionTimeDate.setMinutes(0);
  endSubscriptionTimeDate.setSeconds(0);
  endSubscriptionTimeDate.setMilliseconds(0);

  const numberDaysRemaininSubscription = Math.floor(
    (endSubscriptionTimeDate - Date.now()) / (24 * 60 * 60 * 1000),
  );

  return (
    <ButtonWithDescription
      button={
        <div
          style={{
            display: 'flex',
          }}
        >
          <button
            className={
              'LandingPageMenu-subsciption-tier-button ' +
              subscriptionTierToClass[subscriptionTier]
            }
            onClick={async () => {
              if (subscriptionTier > 0) {
                const res = await fetch('/api/stripe/portalSession', {
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  method: 'POST',
                  body: JSON.stringify({
                    cookie: localStorage.getItem('jwt'),
                  }),
                });

                if (res.status != 303) {
                  const text = await res.text();
                  console.error(text);
                  return;
                }

                const body = await res.json();
                window.location.href = body.portalSessionUrl;
              } else {
                window.location.href = '/marketplace';
              }
            }}
          >
            <SVGBadgeComponent value={subscriptionTier} />
            <span className="LandingPageMenu-subscription-tier-button-text">
              {subscriptionTierToName[subscriptionTier]}
            </span>
          </button>
        </div>
      }
      description={`Subscription: ${numberDaysRemaininSubscription} days left`}
    />
  );
};

const LoggedOutMenuRight = ({ setModalSettings }) => {
  return (
    <>
      {/* <button
        className="LandingPageMenu-try-free"
        onClick={() => {
          setModalSettings({
            modalType: 'login',
          });
        }}
      >
        Try for Free
      </button>
      <button
        className="LandingPageMenu-sign-in"
        onClick={() => {
          setModalSettings({
            modalType: 'login',
          });
        }}
      >
        Sign In
      </button> */}
    </>
  );
};

const LoggedInMenuRight = ({ isLoading, userInfo }) => {
  const [showButtons, setShowButtons] = useState(true);
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    setProfileImage(userInfo['picture']);
  }, [userInfo['picture']]);

  return (
    <>
      <div
        className={`LoggedInMenuRight-buttons ${showButtons ? 'show' : 'hide'}`}
      >
        <DailyGenerationsButton value={userInfo['currencyValueFree']} />
        <NokepointsButton value={userInfo['currencyValue']} />
        <button
          className="LandingPageMenu-subscribe-button"
          onClick={() => {
            window.location.href =
              '/marketplace?productType=Subscription&mode=1#marketplaceTabs';
          }}
        >
          Subscribe
        </button>
      </div>
      <div
        className="LoggedInMenuRight-GoogleProfile"
        onClick={() => setShowButtons(!showButtons)}
      >
        <FontAwesomeIcon
          icon={showButtons ? Icons.faCaretLeft : Icons.faCaretRight}
          style={{
            paddingRight: '10px',
          }}
        />
        <div className="LoggedInMenuRight-GoogleProfile-container">
          {!isLoading && profileImage !== null ? (
            <img
              src={profileImage}
              alt="Google Login profile logo"
              onError={() => {
                setProfileImage(null);
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

const LoggedInSubscribedMenuRight = ({ userInfo }) => {
  const [showButtons, setShowButtons] = useState(true);

  return (
    <>
      <div
        className={`LoggedInMenuRight-buttons ${showButtons ? 'show' : 'hide'}`}
      >
        <DailyGenerationsButton value={userInfo['currencyValueFree']} />
        <NokepointsButton value={userInfo['currencyValue']} />
        <SubscriptionTierButton
          subscriptionTier={userInfo['subscriptionTier']}
          endSubscriptionTime={userInfo['endSubscriptionTime']}
        />
      </div>
      <div
        className="LoggedInMenuRight-GoogleProfile"
        onClick={() => setShowButtons(!showButtons)}
      >
        <FontAwesomeIcon
          icon={showButtons ? Icons.faCaretLeft : Icons.faCaretRight}
          style={{
            paddingRight: '10px',
          }}
        />
        <div className="LoggedInMenuRight-GoogleProfile-container">
          <img src={userInfo['picture']} alt="Google Login profile logo" />
        </div>
      </div>
    </>
  );
};

const LandingPageMenu = ({
  isLoggedIn,
  isLoading,
  userInfo,
  setModalSettings,
}) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  let content;

  if (isLoggedIn && userInfo['subscriptionTier'] > 0) {
    content = <LoggedInSubscribedMenuRight userInfo={userInfo} />;
  } else if (isLoggedIn) {
    content = <LoggedInMenuRight isLoading={isLoading} userInfo={userInfo} />;
  } else {
    content = <LoggedOutMenuRight setModalSettings={setModalSettings} />;
  }

  return (
    <div className="LandingPageMenu-main">
      <div
        className="LandingPageMenu-left"
        onClick={() => (window.location.href = '/')}
      >
        <h1 className="LandingPageMenu-logo">NokémonAI</h1>
      </div>
      <div>
        {/* <h5
          className="LandingPageMenu-logo"
          style={{
            fontSize: '15px',
            color: '#D3D3D3',
            textAlign: 'center',
          }}
        >
          🚀 Introducing Our{' '}
          <a href="/referral" style={{ color: 'white' }}>
            Referral System!
          </a>{' '}
          🚀
        </h5>
        <h5
          className="LandingPageMenu-logo"
          style={{
            fontSize: '15px',
            color: '#D3D3D3',
            textAlign: 'center',
          }}
        >
          Invite Friends and Discover Shared Rewards.
        </h5> */}
      </div>
      <div className="LandingPageMenu-right">{content}</div>
    </div>
  );
};

export default LandingPageMenu;
