import React from 'react';
import PropTypes from 'prop-types';
import {
  TipSection,
  TipTitle,
  TipContent,
  ProgressBarContainer,
  LoaderWrapper,
} from '../shared/styles/SharedLoaderStyles';

import {
  LoaderHeader,
  SkeletonTextLoader,
  LoadingPlaceholder,
  PlaceholderIcon,
} from './styles/NokepointsLoader.styles.js';

import GenerationStatus from './subcomponents/GenerationStatus.js';
import GenerationTimer from './subcomponents/GenerationTimer.js';

import { useStatusStep } from './hooks/useStatusStep';
import { useFetchRandomNokemon } from './hooks/useFetchRandomNokemon';

import { TestGenerationStatus } from '../GenerationStatus/GenerationStatus.js';
import { useLastTaskStatus } from './hooks/useLastTaskStatus';

const NokepointsLoaderComponent = ({
  isVisible,
  beamStatus,
  queuePosition,
  generationSessionUUID,
}) => {
  const { image, text } = useFetchRandomNokemon(
    isVisible,
    beamStatus,
    queuePosition,
    generationSessionUUID,
  );

  const { lastTaskEndTime, lastTaskStartPendingTime, isLoading, error } =
    useLastTaskStatus(isVisible);

  return (
    <LoaderWrapper isVisible={isVisible}>
      <LoaderHeader>
        {image && generationSessionUUID ? (
          <PlaceholderIcon src={image} alt="Nokemon" />
        ) : (
          <LoadingPlaceholder />
        )}
        <GenerationStatus
          lastComplete={lastTaskEndTime}
          lastPending={lastTaskStartPendingTime}
          beamStatus={beamStatus}
          queuePosition={queuePosition}
        />
      </LoaderHeader>
      <TipSection>
        <TipTitle>
          Whilst the generation is in progress, here's a sneak peek!{' '}
        </TipTitle>
        <TipContent>
          {text ? (
            text
          ) : (
            <SkeletonTextLoader>
              <div></div> {/* Full line */}
              <div></div> {/* Shorter line */}
            </SkeletonTextLoader>
          )}
        </TipContent>
      </TipSection>
      <ProgressBarContainer>
        <GenerationTimer
          lastComplete={lastTaskEndTime}
          lastPending={lastTaskStartPendingTime}
          beamStatus={beamStatus}
          queuePosition={queuePosition}
        />
      </ProgressBarContainer>
    </LoaderWrapper>
  );
};

NokepointsLoaderComponent.propTypes = {
  isVisible: PropTypes.bool,
  beamStatus: PropTypes.string,
  queuePosition: PropTypes.number,
};

export default NokepointsLoaderComponent;
