import { useState, useEffect, useMemo, useContext } from 'react';
import { NokemonName, NokemonLabels, NokemonImage } from './Nokemon';
import { constants, sleep } from 'nokemon-common-utils';
import Select, { NonceProvider } from 'react-select';
import { Tab, Tabs, TabList } from 'react-tabs';
import { generateTextFromFileName } from 'nokemon-common-utils';
import {
  AddButton,
  DeleteButton,
  ShareButton,
  ImportButton,
} from './StorageButtons.js';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactCardFlip from 'react-card-flip';
import { ModalGoogleLoginButton } from './LoginModal.js';
import GoogleLoginComponent from './GoogleLogin.js';
import StoragePageBanner from './StoragePageBanner';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';
import NokemonContext from '../context/NokemonContext';
import ReactTooltip from 'react-tooltip';
import LoginModal from './LoginModal/LoginModal';

import { SettingsMenu } from './Buttons/SettingsButton';

// import { NokemonCard } from './NokemonCard';
import './StoragePage.css';
const typeToColor = {
  bug: '#92bc2c',
  dark: '#595761',
  dragon: '#0c69c8',
  electric: '#f2d94e',
  fire: '#fba54c',
  fairy: '#ee90e6',
  fighting: '#d3425f',
  flying: '#a1bbec',
  ghost: '#5f6dbc',
  grass: '#5fbd58',
  ground: '#da7c4d',
  ice: '#75d0c1',
  normal: '#a0a29f',
  poison: '#b763cf',
  psychic: '#fa8581',
  rock: '#c9bb8a',
  steel: '#5695a3',
  water: '#539ddf',
  legendary: '#e60073',
};

const proStatusToCapacityMap = {
  0: 6,
  1: 50,
  2: 200,
  3: 500,
};

const subscriptionToCapacityMap = {
  0: 6,
  1: 50,
  2: 200,
  3: 500,
};

function NokemonTabs(props) {
  return (
    <Tabs
      onSelect={(index, lastIndex) => {
        if (index == 0) {
          props.setTabSelection('History');
        } else if (index == 1) {
          props.setTabSelection('Favourites');
        } else if (index == 2) {
          props.setTabSelection('Premium');
        }
      }}
    >
      <TabList>
        <Tab data-cy="storageHistoryTab">History</Tab>
        <Tab data-cy="storageFavouritesTab">Favourites</Tab>
        <Tab data-cy="storagePremiumTab">
          Premium
          {/* <span
            style={{
              position: 'absolute',
              left: '80%',
              top: '10%',
              color: 'red',
              textShadow: '0px 0px 10px red, 0px 0px 10px red',
              fontWeight: '400',
            }}
          >
            new
          </span> */}
        </Tab>
      </TabList>
    </Tabs>
  );
}

export function NokemonCardFront(props) {
  const [isHover, setIsHover] = useState(props.isHover ? props.isHover : false);
  const navigate = useNavigate();
  // const { setNokemonData } = useContext(NokemonContext);

  const extractUuidFromFileName = fileName => {
    let uuid = fileName.split('uuid=')[1];
    return uuid;
  };

  const handleButtonClick = async () => {
    // Extract the UUID from the fileName

    const uuid = extractUuidFromFileName(props.fileName);

    let response;

    if (uuid) {
      response = await fetch(`/api/nokemon/uuid=${uuid}`);
    } else {
      response = await fetch(`/api/nokemon/fileName=${props.fileName}`);
    }

    // Make a request to your backend to retrieve the nokemon data
    const data = await response.json();

    // Store the data in the state
    // setNokemonData(data);
    navigate('/');
  };

  return (
    <div
      data-cy="nokemonCard"
      className={
        props.fileName.includes('shiny')
          ? 'nokemonCard border-shine'
          : 'nokemonCard'
      }
      style={{
        // borderWidth: '16px 16px 16px 16px',
        // borderStyle: 'solid',
        borderColor: props.fileName.includes('shiny')
          ? 'gold'
          : typeToColor[props.type],
      }}
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <NokemonLabels fileName={props.fileName} />
      <NokemonImage fileName={props.fileName} />
      <NokemonName fileName={props.fileName} color="black" />

      <SettingsMenu
        fileName={props.fileName}
        collection={props.collection}
        setCollection={props.setCollection}
        isAddButtonDisabled={props.isAddButtonDisabled}
        setIsAddButtonDisabled={props.setIsAddButtonDisabled}
        isDeleteButtonDisabled={props.isDeleteButtonDisabled}
        setIsDeleteButtonDisabled={props.setIsDeleteButtonDisabled}
        isAddButtonDisplayed={props.isAddButtonDisplayed}
        isDeleteButtonDisplayed={props.isDeleteButtonDisplayed}
        isShareButtonDisplayed={true}
        isImportButtonDisplayed={false}
      />

      {/* {isHover && (
        <ImportButton
          fileName={props.fileName}
          setNokemonData={setNokemonData}
          navigate={navigate}
        />
      )}
      {isHover && props.isAddButtonDisplayed ? (
        <AddButton
          fileName={props.fileName}
          isAddButtonDisabled={props.isAddButtonDisabled}
          setIsAddButtonDisabled={props.setIsAddButtonDisabled}
        />
      ) : (
        <div />
      )}
      {isHover && props.isDeleteButtonDisplayed ? (
        <DeleteButton
          fileName={props.fileName}
          collection={props.collection}
          setCollection={props.setCollection}
          isDeleteButtonDisabled={props.isDeleteButtonDisabled}
          setIsDeleteButtonDisabled={props.setIsDeleteButtonDisabled}
        />
      ) : (
        <div />
      )}
      {isHover && props.isShareButtonDisplayed ? (
        <>
          <ShareButton
            data-tip="Share this Nokemon"
            fileName={props.fileName}
          />
          <ReactTooltip />
        </>
      ) : (
        <div />
      )} */}
    </div>
  );
}

export function NokemonCardBack(props) {
  return (
    <div
      className="nokemonCard shine"
      style={{
        borderWidth: '16px 16px 16px 16px',
        borderStyle: 'solid',
        backgroundColor: typeToColor[props.type],
        cursor: 'pointer',
      }}
      onClick={() => {
        props.onClick();
      }}
    ></div>
  );
}

export function NokemonCard(props) {
  const [isFlipped, setIsFlipped] = useState(props.isFlippedToBack);

  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      flipDirection="horizontal"
      flipSpeedFrontToBack={1.5}
      flipSpeedBackToFront={1.5}
    >
      <NokemonCardFront
        fileName={props.fileName}
        type={generateTextFromFileName(props.fileName)['type1']}
        collection={props.collection}
        setCollection={props.setCollection}
        isAddButtonDisabled={props.isAddButtonDisabled}
        setIsAddButtonDisabled={props.setIsAddButtonDisabled}
        isDeleteButtonDisabled={props.isDeleteButtonDisabled}
        setIsDeleteButtonDisabled={props.setIsDeleteButtonDisabled}
        isAddButtonDisplayed={props.isAddButtonDisplayed}
        isDeleteButtonDisplayed={props.isDeleteButtonDisplayed}
        isImportButtonDisplayed={false}
        isShareButtonDisplayed={true}
        isHover={props.isHover}
      />

      <NokemonCardBack
        fileName={props.fileName}
        type={generateTextFromFileName(props.fileName)['type1']}
        onClick={() => {
          setIsFlipped(false);
          props.setHideText(true);
        }}
      />
    </ReactCardFlip>
  );
}

function getHistoryStorageLimit(proStatus) {
  switch (proStatus) {
    case 0:
      return 6;
    case 1:
      return 50;
    case 2:
      return 200;
    case 3:
      return 500;
    default:
      throw Error(
        `proStatus should be in the set [0, 1, 2, 3], got: ${proStatus}`,
      );
  }
}

function getFavouriteNokemon(offset, filters) {
  return fetch('/api/user/favourite/get', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('jwt'),
    },
    method: 'POST',
    body: JSON.stringify({
      offset: offset,
      filters: filters,
    }),
  })
    .then(res => res.json())
    .then(body => {
      return {
        favouritesSegment: body.favouritesSegment,
        hasNext: body.hasNext,
      };
    })
    .catch(e => {
      return {
        favouritesSegment: [],
        hasNext: false,
      };
    });
}

function getPremiumNokemon(offset, filters) {
  return fetch('/api/user/premiumNokemon/get', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('jwt'),
    },
    method: 'POST',
    body: JSON.stringify({
      offset: offset,
      filters: filters,
    }),
  })
    .then(res => res.json())
    .then(body => {
      return {
        premiumSegment: body.premiumSegment,
        hasNext: body.hasNext,
      };
    })
    .catch(e => {
      return {
        premiumSegment: [],
        hasNext: false,
      };
    });
}

function getHistoryNokemon(offset, loadLimit, proStatus, filters) {
  let nokemonHistory = [];

  try {
    const storedHistory = localStorage.getItem('nokemonHistory');
    if (storedHistory) {
      const parsedHistory = JSON.parse(storedHistory);
      // Check if the parsed data is an array
      if (Array.isArray(parsedHistory)) {
        nokemonHistory = parsedHistory;
      }
    }
  } catch (error) {
    console.error(
      "Error reading or parsing 'nokemonHistory' from localStorage:",
      error,
    );
    // nokemonHistory will remain the default empty array in case of error or if parsed data isn't an array
  }

  const proStatusLimit = getHistoryStorageLimit(proStatus);
  const nokemonHistoryTruncated = nokemonHistory.slice(0, proStatusLimit);

  // filter
  const nokemonHistoryFiltered = filterHistoryNokemonCards(
    nokemonHistoryTruncated,
    filters,
  );

  const historySegment = nokemonHistoryFiltered.slice(
    offset,
    Math.min(offset + loadLimit, nokemonHistoryFiltered.length),
  );

  const hasNext = nokemonHistoryFiltered.length - offset > 1;

  return {
    historySegment,
    hasNext,
  };
}

function filterHistoryNokemonCards(collection, filters) {
  let filteredFileNames = collection.filter(fileName => {
    let isFilteredOut;

    if (filters.length == 0) {
      isFilteredOut = false;
    } else {
      const isFilteredArray = filters.map(filter => {
        return fileName.includes(filter);
      });

      isFilteredOut = isFilteredArray.some(v => v === false);
    }

    if (!isFilteredOut) {
      return fileName;
    }
  });

  return filteredFileNames;
}

export function NokemonHistoryCollection(props) {
  const [collection, setCollection] = useState([]);
  const [hasNext, setHasNext] = useState(true);
  const [offset, setOffset] = useState(0);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const limit = 8;

  useMemo(() => {
    const { historySegment, hasNext } = getHistoryNokemon(
      0,
      limit,
      Math.max(props.userInfo['subscriptionTier'], props.userInfo['proStatus']),
      props.filters,
    );

    setCollection(historySegment);
    setHasNext(hasNext);
  }, []);

  useEffect(() => {
    const { historySegment, hasNext } = getHistoryNokemon(
      0,
      limit,
      Math.max(props.userInfo['subscriptionTier'], props.userInfo['proStatus']),
      props.filters,
    );
    setCollection(historySegment);
    setHasNext(hasNext);
    setOffset(0);
  }, [props.filters, props.userInfo['subscriptionTier']]);

  async function fetchMoreData() {
    const { historySegment, hasNext } = getHistoryNokemon(
      offset + limit,
      limit,
      Math.max(props.userInfo['subscriptionTier'], props.userInfo['proStatus']),
      props.filters,
    );

    sleep(1000).then(() => {
      setCollection(collection.concat(historySegment));
      setOffset(offset + limit);
      setHasNext(hasNext);
    });
  }

  return (
    <InfiniteScroll
      className="nokemonCollection"
      dataLength={collection.length}
      next={fetchMoreData}
      hasMore={hasNext}
      scrollableTarget="scrollableDiv"
      loader={<Loader />}
    >
      {collection.map((fileName, index) => {
        const types = generateTextFromFileName(fileName);
        return (
          <NokemonCard
            key={index}
            fileName={fileName}
            shiny={fileName.includes('shiny')}
            type={types['type1']}
            collection={collection}
            setCollection={setCollection}
            isAddButtonDisplayed={true}
            isFlippedToBack={false}
            isAddButtonDisabled={isAddButtonDisabled}
            setIsAddButtonDisabled={setIsAddButtonDisabled}
          />
        );
      })}
    </InfiniteScroll>
  );
}

const PremiumEmptyCollection = ({ isLoggedIn }) => {
  let text;

  if (!isLoggedIn) {
    text = 'Please log in to access your Premium collection!';
  } else {
    text =
      'Fakémon generated by using Credits are stored here! These are your very own unique collection of never-seen-before Fakémon!';
  }

  return (
    <>
      <h1 className="StoragePageBanner-subtitle">{text}</h1>
      {isLoggedIn ? (
        <button
          className="LandingPageMenu-currency-value-premium"
          onClick={() => {
            window.location.href =
              '/marketplace?productType=Credits#marketplaceTabs';
          }}
        >
          Get Credits!
        </button>
      ) : (
        <></>
      )}
    </>
  );
};

const FavouritesEmptyCollection = ({ isLoggedIn }) => {
  let text;

  if (!isLoggedIn) {
    text = 'Please log in to access your Favourites collection!';
  } else {
    text =
      'Once a Fakémon has been generated, you can easily store your favorites by clicking the star icon. Your favorites will be readily accessible for future reference.';
  }

  return <h1 className="StoragePageBanner-subtitle">{text}</h1>;
};
const HistoryEmptyCollection = ({}) => {
  let text;

  text =
    'Fakémon will be added to your history collection when you generate Fakémon.';

  return <h1 className="StoragePageBanner-subtitle">{text}</h1>;
};

const GenericEmptyCollection = ({ collectionType, isLoggedIn }) => {
  const nokemonImages = [
    // 'https://nokemon.eloie.tech//api/generation/basic/images/fakemon?fileName=type1=Dark_type2=Electric__k=1024_p=0.99_score=0.17075659334659576_count=9195.png',
    'https://nokemon.eloie.tech/api/generation/basic/images/fakemon?fileName=type1=Grass_type2=Electric_legendary_k=1024_p=0.99_score=122.08161926269531_count=703.png',
  ];
  let content;

  if (collectionType == 'History') {
    content = <HistoryEmptyCollection />;
  } else if (collectionType == 'Favourites') {
    content = <FavouritesEmptyCollection isLoggedIn={isLoggedIn} />;
  } else if (collectionType == 'Premium') {
    content = <PremiumEmptyCollection isLoggedIn={isLoggedIn} />;
  }

  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalCenterVisible, setModalCenterVisible] = useState(true);

  const handleLoginClick = () => {
    setModalVisible(true);
    setModalCenterVisible(true);
  };

  const handleLoading = () => {
    setModalCenterVisible(false);
  };

  const handleCloseModal = () => {
    setModalCenterVisible(false);
    setModalVisible(false);
  };

  return (
    <div className="GenericEmptyCollection-container">
      {content}
      {!isLoggedIn ? (
        <div className="GenericEmptyCollection-login-button">
          <ModalGoogleLoginButton renderProps={{ onClick: handleLoginClick }} />
          {isModalVisible && (
            <LoginModal
              isVisible={isModalVisible}
              isModalCenterVisible={isModalCenterVisible}
              onLoginSuccess={handleLoading}
              onLoginFailure={handleCloseModal}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export function NokemonFavouriteCollection(props) {
  const [collection, setCollection] = useState([]);
  const [hasNext, setHasNext] = useState(true);
  const [offset, setOffset] = useState(0);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);

  useMemo(() => {
    getFavouriteNokemon(0, props.filters).then(body => {
      setCollection(body.favouritesSegment);
      setHasNext(body.hasNext);
    });
  }, []);

  useEffect(() => {
    getFavouriteNokemon(0, props.filters).then(body => {
      setCollection(body.favouritesSegment);
      setHasNext(body.hasNext);
      setOffset(0);
    });
  }, [props.filters, isDeleteButtonDisabled]);

  function fetchMoreData() {
    const limit = 8;

    getFavouriteNokemon(offset + limit, props.filters).then(body => {
      sleep(1000).then(() => {
        setCollection(collection.concat(body.favouritesSegment));
        setOffset(offset + limit);
        setHasNext(body.hasNext);
      });
    });

    return;
  }

  return (
    <InfiniteScroll
      className="nokemonCollection"
      dataLength={collection.length}
      next={fetchMoreData}
      hasMore={hasNext}
      scrollableTarget="scrollableDiv"
      loader={<Loader />}
    >
      {collection.map((fileName, index) => {
        const types = generateTextFromFileName(fileName);
        return (
          <NokemonCard
            key={index}
            fileName={fileName}
            type={types['type1']}
            shiny={fileName.includes('shiny')}
            collection={collection}
            setCollection={setCollection}
            isDeleteButtonDisabled={isDeleteButtonDisabled}
            setIsDeleteButtonDisabled={setIsDeleteButtonDisabled}
            isDeleteButtonDisplayed={true}
            isFlippedToBack={false}
          />
        );
      })}
    </InfiniteScroll>
  );
}

export function NokemonPremiumCollection(props) {
  const [collection, setCollection] = useState([]);
  const [hasNext, setHasNext] = useState(true);
  const [offset, setOffset] = useState(0);

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
  const [isImportButtonDisplayed, setIsImportButtonDisplayed] = useState(false);

  useMemo(() => {
    getPremiumNokemon(0, props.filters).then(body => {
      setCollection(body.premiumSegment);
      setHasNext(body.hasNext);
    });
  }, []);

  useEffect(() => {
    getPremiumNokemon(0, props.filters).then(body => {
      setCollection(body.premiumSegment);
      setHasNext(body.hasNext);
      setOffset(0);
    });
  }, [props.filters, isDeleteButtonDisabled]);

  function fetchMoreData() {
    const limit = 8;

    getPremiumNokemon(offset + limit, props.filters).then(body => {
      sleep(1000).then(() => {
        setCollection(collection.concat(body.premiumSegment));
        setOffset(offset + limit);
        setHasNext(body.hasNext);
      });
    });

    return;
  }
  return (
    <InfiniteScroll
      className="nokemonCollection"
      dataLength={collection.length}
      next={fetchMoreData}
      hasMore={hasNext}
      scrollableTarget="scrollableDiv"
      loader={<Loader />}
    >
      {collection.map((fileName, index) => {
        const types = generateTextFromFileName(fileName);
        return (
          <NokemonCard
            key={index}
            fileName={fileName}
            type={types['type1']}
            shiny={fileName.includes('shiny')}
            collection={collection}
            setCollection={setCollection}
            isDeleteButtonDisabled={isDeleteButtonDisabled}
            setIsDeleteButtonDisabled={setIsDeleteButtonDisabled}
            isDeleteButtonDisplayed={false}
            isAddButtonDisplayed={true}
            isFlippedToBack={false}
            isAddButtonDisabled={isAddButtonDisabled}
            setIsAddButtonDisabled={setIsAddButtonDisabled}
            isImportButtonDisplayed={false}
            isShareButtonDisplayed={true}
          />
        );
      })}
    </InfiniteScroll>
  );
}

export function NokemonCollection(props) {
  if (props.tabSelection == 'History') {
    return (
      <NokemonHistoryCollection
        tabSelection={props.tabSelection}
        userInfo={props.userInfo}
        filters={props.filters}
      />
    );
  } else if (props.tabSelection == 'Favourites') {
    return (
      <NokemonFavouriteCollection
        tabSelection={props.tabSelection}
        filters={props.filters}
      />
    );
  } else if (props.tabSelection == 'Premium') {
    return (
      <NokemonPremiumCollection
        tabSelection={props.tabSelection}
        filters={props.filters}
      />
    );
  } else {
    return <div />;
  }
}

// export function NokemonCollection(props) {
//   const [collection, setCollection] = useState([]);
//   const offset = 8;

//   useEffect(() => {
//     if (
//       props.tabSelection == 'Favourites' &&
//       localStorage.getItem('jwt') != 'null'
//     ) {
//       const { favouritesSegment, hasNext } = getFavouriteNokemon(offset);
//     } else if (props.tabSelection == 'History') {
//       const nokemonHistory =
//         'nokemonHistory' in localStorage
//           ? JSON.parse(localStorage.getItem('nokemonHistory'))
//           : [];

//       getUserProStatus().then(proStatus => {
//         const historyStorageLimit = getHistoryStorageLimit(proStatus);
//         const nokemonHistoryLimited = nokemonHistory.slice(
//           0,
//           historyStorageLimit,
//         );
//         setCollection(nokemonHistoryLimited);
//       });
//     } else {
//       setCollection([]);
//     }
//   }, [props.tabSelection]);

//   let filteredCollection = collection;

//   if (filteredCollection.length > 0) {
//     for (const filter of props.filters) {
//       filteredCollection = filteredCollection.filter(card => {
//         return card.includes(filter);
//       });
//     }
//   }

//   return (
//     <InfiniteScroll
//       dataLength={items.length} //This is important field to render the next data
//       next={fetchData}
//       hasMore={true}
//       loader={<h4>Loading...</h4>}
//       endMessage={
//         <p style={{ textAlign: 'center' }}>
//           <b>Yay! You have seen it all</b>
//         </p>
//       }
//       // below props only if you need pull down functionality
//       refreshFunction={this.refresh}
//       pullDownToRefresh
//       pullDownToRefreshThreshold={50}
//       pullDownToRefreshContent={
//         <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
//       }
//       releaseToRefreshContent={
//         <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
//       }
//     >
//       {items}
//     </InfiniteScroll>
//   );

//   return (
//     <div className="nokemonCollection">
//       {filteredCollection.map((fileName, index) => {
//         const types = generateTextFromFileName(fileName);
//         return (
//           <NokemonCard
//             key={index}
//             fileName={fileName}
//             type={types['type1']}
//             collection={collection}
//             setCollection={setCollection}
//             tabSelection={props.tabSelection}
//           />
//         );
//       })}
//     </div>
//   );
// }

const CustomStyle = {
  option: (base, state) => ({
    ...base,
    textAlign: 'left',
  }),

  container: (base, state) => ({
    ...base,
    textAlign: 'left',
  }),
};

function NokemonFiltering(props) {
  const [openMenu, setOpenMenu] = useState(false);

  const handleInputChange = (query, { action }) => {
    if (action === 'input-change') {
      setOpenMenu(true);
    }
  };

  const hideMenu = () => {
    setOpenMenu(false);
  };

  return (
    <div className="collectionSelector">
      <p
        style={{
          marginRight: 'auto',
        }}
      >
        Search:
      </p>
      <Select
        data-cy="storageFilterSelectBar"
        styles={CustomStyle}
        options={props.options}
        onInputChange={handleInputChange}
        onChange={filterValues => {
          hideMenu();

          props.setFilters(
            filterValues.map(filterValue => {
              return filterValue['value'];
            }),
          );
        }}
        onBlur={hideMenu}
        menuIsOpen={openMenu}
        isMulti
        isOptionDisabled={option => option.disabled}
        placeholder="Search by type..."
      />
    </div>
  );
}

export default function StoragePage(props) {
  const [filters, setFilters] = useState([]);
  const [tabSelection, setTabSelection] = useState('History');

  const filteringOptions = constants.types;
  let content;

  if (
    localStorage.getItem('nokemonHistory') === null &&
    tabSelection === 'History'
  ) {
    content = <HistoryEmptyCollection />;
  } else if (
    (props.userInfo['numFavourites'] == 0 && tabSelection == 'Favourites') ||
    (props.userInfo['numPremiumNokemon'] == 0 && tabSelection == 'Premium')
  ) {
    content = (
      <GenericEmptyCollection
        isLoggedIn={props.isLoggedIn}
        collectionType={tabSelection}
      />
    );
  } else {
    content = (
      <>
        <NokemonFiltering options={filteringOptions} setFilters={setFilters} />
        <StorageCapacity
          tabSelection={tabSelection}
          userInfo={props.userInfo}
          proStatus={props.proStatus}
        />
        <NokemonCollection
          filters={filters}
          tabSelection={tabSelection}
          userInfo={props.userInfo}
          proStatus={props.proStatus}
        />
      </>
    );
  }

  return (
    <div
      className="historyBody"
      id="scrollableDiv"
      style={{
        height: '1000px',
        overflow: 'auto',
      }}
    >
      <StoragePageBanner />
      <NokemonTabs setTabSelection={setTabSelection} />
      {content}
    </div>
  );
}

async function getNumFavouritesStorage() {
  const res = await fetch('/api/user/numFavourites/get', {
    headers: {
      Authorization: localStorage.getItem('jwt'),
    },
  });

  if (res.status != 200) {
    return 0;
  }

  const numFavourites = await res.json().then(body => {
    return body.numFavourites;
  });

  return numFavourites;
}

async function getNumPremiumStorage() {
  const res = await fetch('/api/user/numPremiumNokemon/get', {
    headers: {
      Authorization: localStorage.getItem('jwt'),
    },
  });

  if (res.status != 200) {
    return 0;
  }

  const numPremiumNokemon = await res.json().then(body => {
    return body.numPremiumNokemon;
  });

  return numPremiumNokemon;
}

function StorageCapacity(props) {
  const [capacityText, setCapacityText] = useState('0 / 0');

  useEffect(() => {
    if (props.tabSelection == 'History') {
      let nokemonHistory = [];

      // Ensure nokemonHistory is an array
      try {
        const storedHistory = localStorage.getItem('nokemonHistory');
        if (storedHistory) {
          const parsedHistory = JSON.parse(storedHistory);
          if (Array.isArray(parsedHistory)) {
            nokemonHistory = parsedHistory;
          }
        }
      } catch (error) {
        console.error(
          "Error reading or parsing 'nokemonHistory' from localStorage:",
          error,
        );
      }

      // Assuming proStatusToCapacityMap and subscriptionToCapacityMap are defined and accessible
      const maxCapacity = Math.max(
        proStatusToCapacityMap[props.userInfo['proStatus']],
        subscriptionToCapacityMap[props.userInfo['subscriptionTier']],
      );

      const numerator = Array.isArray(nokemonHistory)
        ? String(Math.min(nokemonHistory.length, maxCapacity))
        : '0';
      const denominator = String(maxCapacity);
      setCapacityText(`${numerator} / ${denominator}`);
    } else if (props.tabSelection == 'Favourites') {
      let numerator = props.userInfo['numFavourites'];

      const denominator = String(
        Math.max(
          proStatusToCapacityMap[props.userInfo['proStatus']],
          subscriptionToCapacityMap[props.userInfo['subscriptionTier']],
        ),
      );

      setCapacityText(`${numerator} / ${denominator}`);

      getNumFavouritesStorage().then(body => {
        numerator = String(body);
        setCapacityText(`${numerator} / ${denominator}`);
      });
    } else if (props.tabSelection == 'Premium') {
      let numerator = props.userInfo['numPremiumNokemon'];
      setCapacityText(`${numerator}`);
      getNumPremiumStorage().then(body => {
        const numerator = String(body);
        setCapacityText(`${numerator}`);
      });
    }
  }, [props.tabSelection, props.userInfo]);

  return <h2 data-cy="storageCapacityText">Capacity: {capacityText}</h2>;
}
