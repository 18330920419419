import './LandingPageBanner.css';

const LandingPageBanner = () => {
  return (
    <div className="LandingPageBanner-main">
      <h1 className="LandingPageBanner-title">Generate Fakémon With AI</h1>
      <h2 className="LandingPageBanner-subtitle">
        Discover Unique AI-Generated Creatures
      </h2>
    </div>
  );
};

export default LandingPageBanner;
