import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { DefaultOutput } from '../../Output';
import { enableFeedback } from '../../FeedbackButtons';

const TYPE_OPTIONS = [
  'none',
  'normal',
  'fire',
  'water',
  'grass',
  'electric',
  'ice',
  'fighting',
  'poison',
  'ground',
  'flying',
  'psychic',
  'bug',
  'rock',
  'ghost',
  'dark',
  'dragon',
  'steel',
  'fairy',
];

function get_random(list) {
  return list[Math.floor(Math.random() * list.length)];
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// Function to setup the type parameters
const setupTypeParameters = props => {
  let type1;
  let type2;
  let legendary;

  //   if (props.generatedBy == 'type') {
  type1 = props.type1;

  if (props.type2 == 'None') {
    type2 = type1;
  } else {
    type2 = props.type2;
  }
  legendary = 'false';
  //   } else if (props.generatedBy == 'random') {
  //     type1 = get_random(TYPE_OPTIONS.filter(type => type != 'none'));
  //     type2 = get_random(TYPE_OPTIONS.filter(type => type != type1));
  //     legendary = get_random(['true', 'false']);

  //     props.setType1(type1);
  //     props.setType2(type2);
  //     props.setLegendary(legendary);
  //   }

  return { type1, type2, legendary };
};

// Function to setup the body parameters
const setupBodyParameters = (
  props,
  type1,
  type2,
  legendary,
  generateSessionUUID,
) => {
  let body;

  if (props.modelType == 'sd_v1') {
    legendary = legendary == 'true' ? 1 : 0;

    let optionalText = props.optionalText || '';
    let objectText = props.objectText || '';

    let seed;
    if (props.resetSeed) {
      seed = Math.floor(Math.random() * Math.pow(2, 31) - 1);
      props.setSeed(seed);
    } else {
      seed = parseInt(props.seed);
    }

    body = {
      modelType: props.modelType,
      type1: type1,
      type2: type2,
      legendary: legendary,
      optional_text: optionalText,
      object_kind: objectText,
      seed: seed,
      cookie: localStorage.getItem('jwt'),
      generationSessionUUID: generateSessionUUID,
    };
  } else {
    body = {
      style: props.style,
      type1: type1,
      type2: type2,
      legendary: legendary,
      body: props.body,
      color: props.color,
      share: 'false',
      cookie: localStorage.getItem('jwt'),
      generationSessionUUID: generateSessionUUID,
    };
  }

  return body;
};

const setupRequestParameters = (props, setGenerationSessionId) => {
  /* Generate a comprehensive comment for this function:
   * 1. Generate a UUID for the generation session
   * 2. Set the generation session ID
   * 3. Set the endpoint and body parameters based on the generation type
   * 4. Return the endpoint and body parameters
   */
  const generateSessionUUID = uuidv4();
  setGenerationSessionId(generateSessionUUID);

  let endpoint;
  let body;

  if (props.generatedBy == 'batch') {
    endpoint = '/api/generation/advanced/generate/sd_v1/batch';
    body = {
      modelType: props.modelType,
      batchNum: props.batchNum,
      cookie: localStorage.getItem('jwt'),
      generationSessionUUID: generateSessionUUID,
    };
  } else if (props.generatedBy == 'type') {
    const { type1, type2, legendary } = setupTypeParameters(props);

    endpoint = '/api/generation/advanced/generate/sd_v1/';

    body = setupBodyParameters(
      props,
      type1,
      type2,
      legendary,
      generateSessionUUID,
    );
  } else {
    throw new Error('Invalid generation type');
  }

  return { endpoint, body };
};

export function PremiumOutput(props) {
  const [loadingState, setLoadingState] = useState(false);
  const [outputData, setOutputData] = useState(undefined);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
  const [generationSessionId, setGenerationSessionId] = useState(undefined);
  const [beamTaskId, setBeamTaskId] = useState(undefined);

  const fetchData = async (endpoint, body) => {
    const response = await fetch(endpoint, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body),
    });

    return response;
  };

  const handleResponse = async (response, outputBox) => {
    if (response.status != 200) {
      const text = await response.text();
      toast.error(text);

      setLoadingState(false);
      outputBox.style.opacity = 1.0;
      const submitButton = document.getElementById('submitButton');

      if (submitButton != null) {
        submitButton.disabled = false;
      }
      setIsAddButtonClicked(false);
      enableFeedback();
      return null;
    }

    props.setUserInfo({
      ...props.userInfo,
      currencyValue: Math.max(
        props.userInfo['currencyValue'] - props.batchNum,
        0,
      ),
    });
  };

  const updateUIAfterGeneration = fileName => {
    setLoadingState(false);
    props.setFileName(fileName);

    setOutputData({
      selected: props.selected,
      generatedBy: props.generatedBy,
      fileName: fileName,
      session: props.session_id,
      uuid: localStorage.getItem('storageId'),
    });

    const outputBox = document.getElementById('outputBox');
    outputBox.style.opacity = 1.0;

    const submitButton = document.getElementById('submitButton');

    if (submitButton != null) {
      submitButton.disabled = false;
    }
    setIsAddButtonClicked(false);
    enableFeedback();
  };

  const checkGenerationStatus = async () => {
    if (loadingState && generationSessionId) {
      const response = await fetch(
        `/api/generation/advanced/getSessionEntity?generationSessionUUID=${generationSessionId}`,
      );
      const entity = await response.json();
      const status = entity['status'];
      const fileName = entity['fileName'];

      if (status && status.includes('complete')) {
        updateUIAfterGeneration(fileName);
      }
    }
  };

  useInterval(checkGenerationStatus, 3000);

  useEffect(() => {
    const fetchDataAndHandleResponse = async () => {
      if (!props.generationButtonClicked) {
        return;
      }
      const outputBox = document.getElementById('outputBox');

      if (outputBox) {
        outputBox.style.opacity = 0.5;
      }

      setLoadingState(true);

      const { endpoint, body } = setupRequestParameters(
        props,
        setGenerationSessionId,
      );

      const response = await fetchData(endpoint, body);
      const response_json = await response.json();

      setBeamTaskId(response_json.beamTaskId);

      handleResponse(response, outputBox);
      props.setGenerationButtonClicked(false);
    };

    if (props.generatedBy == 'batch') {
      fetchDataAndHandleResponse();
    } else if (props.generatedBy == 'type') {
      if (props.type1 && props.type2) {
        fetchDataAndHandleResponse();
      }
    }
  }, [props.generationButtonClicked]);

  if (props.generatedBy) {
    return (
      <DefaultOutput
        modelType={props.modelType}
        isLoggedIn={props.isLoggedIn}
        fileName={props.fileName}
        outputData={outputData}
        loadingState={loadingState}
        setLoadingState={setLoadingState}
        isAddButtonClicked={isAddButtonClicked}
        setFileName={props.setFileName}
        setOutputData={setOutputData}
        setIsAddButtonClicked={setIsAddButtonClicked}
        isPremium={props.isPremium}
        generateSessionUUID={generationSessionId}
        random={props.random}
        type1={props.type1}
        type2={props.type2}
        legendary={props.legendary}
        style={props.style}
        generatedBy={props.generatedBy}
        batchNum={props.batchNum}
        nokemonData={props.nokemonData}
        beamTaskId={beamTaskId}
      />
    );
  } else {
    return <div className="box"></div>; // <div className="box"></div>;
  }
}
