import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // To generate a new UUID
import { Container } from './Container';
import TextBox from './subcomponents/TextBox';
import GeneratorBody from './subcomponents/GeneratorBody';
import SuggestionsArea from './subcomponents/SuggestionsArea';
import GeneratorTopBar from './subcomponents/GeneratorTopBar';
import { toast } from 'react-toastify';
import MediumSquareAdUnit from '../AdComponents/SkyScraperAds';
import SkyscraperAdUnit from '../AdComponents/SkyScraperAds';
import SeedToggle from './subcomponents/SeedToggle';
import ModalManagerComponent from '../Modals/components/ModalManager';
import ModalManager from '../Modals/utilities/ModalManager';
import { recordUserInteraction } from '../../utils/userInteractionsLogger/userInteractionLogger';

const AdsContainerLeft = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  margin-left: 80px;

  // Adjust ad size for different breakpoints
  @media (min-width: 769px) {
    top: calc(50% - 300px);
  }
  @media (max-width: 768px) {
    top: calc(50% - 250px);
  }
  @media (max-width: 480px) {
    // Even smaller or hidden for mobile
    top: calc(50% - 150px);
  }
`;

const AdsContainerRight = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  margin-left: calc(100vw - 120px);

  // Adjust ad size for different breakpoints
  @media (min-width: 769px) {
    top: calc(50% - 300px);
  }
  @media (max-width: 768px) {
    top: calc(50% - 250px);
  }
  @media (max-width: 480px) {
    // Even smaller or hidden for mobile
    top: calc(50% - 150px);
  }
`;

const PageContainer = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #181a1b;
  align-items: center;

  // On tall screens that have more than 100vh, make the page scrollable
  @media (min-height: 100vh) {
    overflow-y: auto;
  }

  @media (max-height: 800px) {
  }
`;

const ContentContainer = styled.div`
  flex: 4; // Now takes up 4/6 of the space
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; // Add some padding around the main content
  height: 100%;
`;

const ApplicationContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
`;

// AdSidebarContainer will be a fixed container that houses AdContainer
const AdSidebarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px; // Maximum width for the skyscraper ad
  height: 100%; // Takes full height to center AdContainer vertically
  padding: 10px 0;
  box-sizing: border-box;

  @media (max-width: 900px) {
    // Optionally hide the sidebar container on smaller screens
    display: none;
  }
`;

const GeneratorBodyStyled = styled(GeneratorBody)`
  flex: none; // Changed from 'flex: 2' to 'flex: none' for explicit height control
  height: calc((100vh - 60px) * 2 / 3) !important; // 2/3 of the remaining space
  min-height: 400px; // Or any other value that suits your design
  overflow: hidden; // Optional: hides overflowing content
  position: relative; // Needed for absolute positioning inside
`;

const SuggestionsAndTextBoxContainer = styled.div`
  flex: none; // Changed from 'flex: 1' to 'flex: none' for explicit height control
  height: calc((100vh - 60px) * 1 / 3) !important; // 1/3 of the remaining space
  display: flex;
  flex-direction: column;
  justify-content: flex-end; // Keep it at the bottom
  width: 90%;
  max-width: 1100px;

  @media (max-width: 500px) {
    width: 98%;
  }
`;

const suggestionCache = {};

// Helper function to manage localStorage for generation count
const manageGenerationCount = () => {
  let generationCount = parseInt(localStorage.getItem('generationCount'), 10);
  if (isNaN(generationCount)) {
    localStorage.setItem('generationCount', 9);
    generationCount = 9;
  } else {
    generationCount = Math.max(generationCount - 1, 0); // Prevent negative values
    localStorage.setItem('generationCount', generationCount);

    if (generationCount === 0) {
      localStorage.removeItem('generationCount');
    }
  }
  return generationCount;
};

// Async function to show modal after a delay
const showModalAfterDelay = async (setActiveModal, modalType) => {
  await new Promise(resolve => setTimeout(resolve, 3000)); // 3-second delay
  setActiveModal(modalType);
};

// Function to determine which not-registered user modal to show
const notRegisteredGenerationsModal = async setActiveModal => {
  /**
   * Two types of modals can pop up here, letting non-registered users they have limited generations left
   * and if the log in they can get more. This happens at 8 generations and at 0 generations left.
   */
  const generationCount = manageGenerationCount();
  if (generationCount === 8) {
    await showModalAfterDelay(
      setActiveModal,
      'NOT_REGISTERED_EIGHT_GENERATIONS_LEFT',
    );
  } else if (generationCount === 0) {
    await showModalAfterDelay(
      setActiveModal,
      'NOT_REGISTERED_ZERO_GENERATIONS_LEFT',
    );
  }
};

// Function to handle registered user modal logic
const registeredGenerationsModal = async (userInfo, setActiveModal) => {
  /**
   * There are two types of modals that can pop up here for registered users.
   * If the user is subscribed, and they run out of generations, it'll prompt them to upgrade their subscription.
   * otherwise, if the useri s not subscribed, it'll prompt them to subscribe for more
   */
  if (userInfo.currencyValueFree === 1 || userInfo.currencyValueFree === 0) {
    const modalType = userInfo.isSubscribed
      ? 'SUBSCRIBED_UPGRADE_ZERO_GENERATIONS_LEFT'
      : 'REGISTERED_ZERO_GENERATIONS_LEFT';
    await showModalAfterDelay(setActiveModal, modalType);
  }

  if (userInfo.currencyValueFree === 5) {
    const modalType = 'PURCHASE_CREDITS_MODAL';
    await showModalAfterDelay(setActiveModal, modalType);
  }
};

// Centralized function to manage modal logic based on user status
const modalLogic = async (userInfo, endpointModifier, setActiveModal) => {
  if (endpointModifier === 'notLoggedIn') {
    await notRegisteredGenerationsModal(setActiveModal);
  } else if (endpointModifier === 'loggedIn' && userInfo) {
    await registeredGenerationsModal(userInfo, setActiveModal);
  }
};

const GeneratorContainer = ({ userContext }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [displayedPrompt, setDisplayedPrompt] = useState('');

  const [hoverUserInput, setHoverUserInput] = useState(
    'Describe your Fakémon Design...',
  );

  const [useCredits, setUseCredits] = useState(false);
  const [beamTaskId, setBeamTaskId] = useState(null);
  const [beamStatus, setBeamStatus] = useState(null);
  const [queuePosition, setQueuePosition] = useState(null);
  const [generationSessionUUID, setGenerationSessionUUID] = useState(null);

  // This is used to track generation sessions for Daily Generation Mode
  const [basicGenerationSessionUUID, setBasicGenerationSessionUUID] = useState(
    uuidv4(),
  );

  const [showSuggestions, setShowSuggestions] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);

  const [selectedType, setSelectedType] = useState('');
  const [modelVersion, setModelVersion] = useState('modelv3');

  // Define a variable that tells us that the user is allowed to generate
  // This will test whether localStorage contains a userInfo
  // or if the userContext contains a userInfo AND isLoggedIn

  const [isAllowedToGenerate, setIsAllowedToGenerate] = useState(false);

  // Seed should be a random number between 1 and 2^31 - 1
  const [seed, setSeed] = useState(null);
  const [keepSeed, setKeepSeed] = useState(false);

  const [activeModal, setActiveModal] = useState(null);

  const onClose = () => {
    setActiveModal(null); // Close any modals
  };

  function handleModelSwitchReset() {
    setBeamTaskId(null);
    setBeamStatus(null);
    setQueuePosition(null);
    setGenerationSessionUUID(null);
    setUseCredits(false);
    setSelectedType('');
    setDisplayedPrompt('');
    setHoverUserInput('Describe your Fakemon Design...');
    setKeepSeed(false);
    setSeed(null);
    setShowSuggestions(true);
    setIsGenerating(false);
    setUserInput('');
    setIsLoading(false);
  }

  useEffect(() => {
    // Attempt to retrieve userInfo from local storage
    const storedUserInfo = localStorage.getItem('userInfo');
    const userInfoFromStorage = storedUserInfo
      ? JSON.parse(storedUserInfo)
      : null;

    // Check if the user is currently logging in
    const isLoggedIn = userContext.isLoggedIn;
    const isCurrentlyLoggingIn = userContext.isLoggingIn;

    if (!isCurrentlyLoggingIn && !isLoggedIn) {
      setIsAllowedToGenerate(true);
      return;
    }

    if (isCurrentlyLoggingIn && !isLoggedIn) {
      return setIsAllowedToGenerate(false);
    }

    if (
      userInfoFromStorage &&
      (userInfoFromStorage.currencyValue > 0 ||
        userInfoFromStorage.currencyValueFree > 0)
    ) {
      setIsAllowedToGenerate(true);
    } else {
      setIsAllowedToGenerate(false);
    }

    // Include userContext in the dependency array to re-evaluate when userContext changes
  }, [userContext]);

  useEffect(() => {
    setBeamTaskId(null);
    setBeamStatus(null);
    setQueuePosition(null);
  }, [generationSessionUUID]);

  useEffect(() => {
    const cacheKey = `${basicGenerationSessionUUID}-${selectedType}`;
    const getSuggestions = async () => {
      // Check cache first
      if (suggestionCache[cacheKey]) {
        setSuggestions(suggestionCache[cacheKey]);
      } else {
        // Fetch new suggestions if not in cache
        const newSuggestions = await fetchSuggestions(
          userContext.isLoggedIn,
          selectedType,
        );

        // Update state and cache
        setSuggestions(newSuggestions);
        suggestionCache[cacheKey] = newSuggestions;
      }
    };

    getSuggestions();

    // Resetting other states can remain as is, assuming they're not related to caching logic
    setGenerationSessionUUID(null);
    setBeamTaskId(null);
    setBeamStatus(null);
    setQueuePosition(null);
  }, [
    imageUrl,
    userContext.isLoggedIn,
    selectedType,
    basicGenerationSessionUUID,
  ]); // Added `basicGenerationSessionUUID` as a dependency

  useEffect(() => {
    if (isLoading) {
      // Optional: set a timeout to hide the suggestions after they've animated out
      const timeoutId = setTimeout(() => {
        setShowSuggestions(false);
      }, suggestions.length * 100); // Adjust timing based on the animation

      return () => clearTimeout(timeoutId);
    } else {
      // Immediately show suggestions without animation
      setShowSuggestions(true);
    }
  }, [isLoading]);

  useEffect(() => {
    let intervalId;

    const checkQueueStatus = async () => {
      const queuePosition = await fetchQueuePosition(generationSessionUUID);
      setQueuePosition(queuePosition);
    };

    if (isLoading && useCredits && generationSessionUUID) {
      // Set an interval to run every five seconds
      intervalId = setInterval(checkQueueStatus, 5000);
    }

    // Clean up the interval when isLoading becomes false or component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isLoading, generationSessionUUID, useCredits]);

  useEffect(() => {
    let intervalId;

    const checkStatus = async () => {
      const beamTaskStatus = await fetchTaskStatus(beamTaskId);
      const queueTaskObject = await fetchQueueTaskObject(generationSessionUUID);

      // If beamTaskStatus is 'FAILED' or queueTaskObject.status is 'timeout' then we should terminate the interval
      if (beamTaskStatus === 'FAILED' || queueTaskObject.status === 'timeout') {
        setBeamStatus('FAILED');
        setIsLoading(false);
        clearInterval(intervalId);

        toast.error(
          "Sorry, we couldn't generate your Fakemon. Please try again later.",
        );
        return;
      }

      setBeamStatus(beamTaskStatus);

      if (
        beamTaskStatus === 'COMPLETE' &&
        queueTaskObject.status === 'complete' &&
        queueTaskObject.fileName
      ) {
        // queueTaskObject.fileName is a list of imageUrls
        const urls = queueTaskObject.fileName.map(
          fileName =>
            `/api/generation/basic/images/fakemon?fileName=${fileName}`,
        );

        setImageUrl(urls);
        setIsLoading(false);
        updateNokemonHistory(queueTaskObject.fileName);
        reduceCreditsByOne(userContext.userInfo.currencyValue - 1);

        setIsGenerating(false);

        clearInterval(intervalId); // Stop the status check interval
      }
    };

    if (beamTaskId) {
      intervalId = setInterval(checkStatus, 2000); // Check every 2 seconds
    }

    return () => clearInterval(intervalId);
  }, [beamTaskId, generationSessionUUID]);

  const updateNokemonHistory = fileName => {
    let nokemonHistory = JSON.parse(
      localStorage.getItem('nokemonHistory') || '[]',
    );

    if (Array.isArray(fileName))
      fileName.forEach(
        f => !nokemonHistory.includes(f) && nokemonHistory.unshift(f),
      );
    else !nokemonHistory.includes(fileName) && nokemonHistory.unshift(fileName);
    nokemonHistory = nokemonHistory.slice(0, 500);
    localStorage.setItem('nokemonHistory', JSON.stringify(nokemonHistory));
  };

  const handleModelVersionChange = modelVersion => {
    setModelVersion(modelVersion);
    handleModelSwitchReset();
  };

  const handleGenerate = async (input, isRandom) => {
    if (isRandom) {
      setDisplayedPrompt(`Generating a random ${input} type Fakemon...`);
    } else {
      setDisplayedPrompt(input);
    }

    setIsLoading(true);

    if (useCredits) {
      const sessionUUID = uuidv4();
      setGenerationSessionUUID(sessionUUID);

      let newSeed;
      let previousGenerationRequest;
      if (!keepSeed) {
        newSeed = Math.floor(Math.random() * 2147483647);
        setSeed(newSeed);
        previousGenerationRequest = null;
      } else {
        newSeed = seed;
        previousGenerationRequest = localStorage.getItem(
          'previousGenerationRequest',
        );
      }

      try {
        const response = await axios.post(
          '/api/generation/advanced/generate/sd_v2/',
          {
            userInput: input,
            cookie: localStorage.getItem('jwt'), // Assuming JWT is stored in localStorage
            generationSessionUUID: sessionUUID,
            seed: newSeed,
            previousGenerationRequestObject: previousGenerationRequest,
          },
        );

        if (response.status === 400 || response.status === 409) {
          setIsLoading(false);
          toast.error(response.data.message);
          return;
        }

        // Add the previousGenerationRequest to localStorage
        localStorage.setItem(
          'previousGenerationRequest',
          JSON.stringify(response.data.previousGenerationRequest),
        );

        setIsGenerating(true);
        setBeamTaskId(response.data.beamTaskId); // Assuming the response contains beamTaskId
        // setSuggestions(response.data.variantSuggestions);
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.status === 429) {
          toast.error(error.response.data.message);
          return;
        } else {
          // Handle other errors
          const errorMessage = error.response
            ? error.response.data.message
            : error.response.data.message;

          toast.error(errorMessage);
        }
      }

      // Log user interaction
      if (userContext.userInfo.emailHash) {
        recordUserInteraction(
          userContext.userInfo.emailHash,
          'Generation (Credit)',
          {
            userInput: input,
            specificAction: 'Click Generate',
            timestamp: Date.now(),
          },
        );
      }
    } else {
      try {
        let endpointModifier = userContext.isLoggedIn
          ? 'loggedIn'
          : 'notLoggedIn';

        let response;

        // Generate random Fakemon – functionality should work for all model versions.
        if (isRandom) {
          if (modelVersion === 'modelv3') {
            response = await axios.post(
              `/api/generation/basic/new/${endpointModifier}/${modelVersion}/fakemon/randomByType`,
              {
                batchNum: 1,
                cookie: localStorage.getItem('jwt'),
                isRandom: isRandom,
                selectedType: selectedType,
                modelType: 'modelv3',
              },
              {
                headers: {
                  'x-token': userContext.storageId, // Add the x-token header
                },
              },
            );
          } else if (modelVersion === 'modelv2') {
            response = await axios.post(
              `/api/generation/basic/new/${endpointModifier}/${modelVersion}/fakemon/randomByType`,
              {
                modelType: 'sd_v1',
                type1: selectedType,
                style: '3d model',
                nokemonHistory: localStorage.getItem('nokemonHistory'),
                cookie: localStorage.getItem('jwt'),
                batchNum: 1,
              },
              {
                headers: {
                  'x-token': userContext.storageId, // Add the x-token header
                },
              },
            );
          } else if (modelVersion === 'modelv1') {
            response = await axios.post(
              `/api/generation/basic/new/${endpointModifier}/${modelVersion}/fakemon/randomByType`,
              {
                modelType: 'dalle',
                type1: selectedType,
                style: '3d model',
                nokemonHistory: localStorage.getItem('nokemonHistory'),
                cookie: localStorage.getItem('jwt'),
                batchNum: 1,
              },
              {
                headers: {
                  'x-token': userContext.storageId, // Add the x-token header
                },
              },
            );
          }

          // Log user interaction
          if (
            endpointModifier === 'loggedIn' &&
            userContext.userInfo.emailHash
          ) {
            recordUserInteraction(
              userContext.userInfo.emailHash,
              'Generation (Daily Generation)',
              {
                modelVersion: modelVersion,
                selectedType: selectedType,
                specificAction: 'Click Random',
                timestamp: Date.now(),
              },
            );
          }
        } else {
          response = await axios.post(
            `/api/generation/basic/new/${endpointModifier}/modelv3/fakemon/randomByDisplayText`,
            {
              displayText: input,
              batchNum: 1,
              cookie: localStorage.getItem('jwt'),
              modelType: 'modelv3',
            },
            {
              headers: {
                'x-token': userContext.storageId, // Add the x-token header
              },
            },
          );

          // Log user interaction
          if (
            endpointModifier === 'loggedIn' &&
            userContext.userInfo.emailHash
          ) {
            recordUserInteraction(
              userContext.userInfo.emailHash,
              'Generation (Daily Generation)',
              {
                modelVersion: modelVersion,
                selectedType: selectedType,
                displayText: input,
                specificAction: 'Click Display Text',
                timestamp: Date.now(),
              },
            );
          }
        }

        const url = response['data'].fileName[0];
        setImageUrl('/api/generation/basic/images/fakemon?fileName=' + url);
        setIsLoading(false);
        updateNokemonHistory(url);

        if (modelVersion === 'modelv3') {
          reduceDailyGenerationsByOne();
        } else if (userContext.userInfo.subscriptionTier < 1) {
          reduceDailyGenerationsRetroByOne();
        }

        // Set a new basic generation session uuid.
        setBasicGenerationSessionUUID(uuidv4());

        // Perform modal logic based on daily generation of modelv3
        if (modelVersion === 'modelv3') {
          const modalManager = new ModalManager(setActiveModal);
          modalManager.handleModal(userContext.userInfo, endpointModifier);
        }
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.status === 429) {
          toast.error(error.response.data.message);
          return;
        } else {
          // Handle other errors
          const errorMessage = error.response
            ? error.response.data.message
            : error.response.data.message;

          toast.error(errorMessage);
        }
      }
    }
  };
  const reduceDailyGenerationsByOne = () => {
    if (userContext.isLoggedIn) {
      // Assuming 'currencyValueFree' is the number of daily generations left
      const updatedCurrencyFreeValue =
        userContext.userInfo.currencyValueFree - 1;

      userContext.setUserInfo({
        ...userContext.userInfo,
        currencyValueFree: updatedCurrencyFreeValue,
      });

      // Update the local storage as well
      const updatedUserInfo = {
        ...userContext.userInfo,
        currencyValueFree: updatedCurrencyFreeValue,
      };
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
    }
  };

  const reduceDailyGenerationsRetroByOne = () => {
    if (userContext.isLoggedIn) {
      // Assuming 'currencyValueFree' is the number of daily generations left
      const updatedCurrencyFreeValue =
        userContext.userInfo.currencyValueFreeRetro - 1;

      userContext.setUserInfo({
        ...userContext.userInfo,
        currencyValueFreeRetro: updatedCurrencyFreeValue,
      });

      // Update the local storage as well
      const updatedUserInfo = {
        ...userContext.userInfo,
        currencyValueFreeRetro: updatedCurrencyFreeValue,
      };
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
    }
  };

  const reduceCreditsByOne = () => {
    if (userContext.isLoggedIn) {
      // Assuming 'currencyValue' represents the nokepoints
      const updatedCurrencyValue = userContext.userInfo.currencyValue - 1;

      userContext.setUserInfo({
        ...userContext.userInfo,
        currencyValue: updatedCurrencyValue,
      });

      // Update the local storage as well
      const updatedUserInfo = {
        ...userContext.userInfo,
        currencyValue: updatedCurrencyValue,
      };
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
    }
  };

  // Handler for when a suggestion is clicked
  const handleSuggestionClick = suggestion => {
    setUserInput(suggestion);
  };

  const handleSuggestionHover = suggestion => {
    setHoverUserInput(suggestion);
  };

  const handleToggleCredits = () => {
    // Check if modelVersion is 'modelv3'
    if (modelVersion === 'modelv3') {
      // If modelVersion is 'modelv3', toggle useCredits as usual
      setUseCredits(!useCredits);
    } else {
      // If modelVersion is not 'modelv3', set useCredits to false
      setUseCredits(false);
    }
  };

  const handleSelectedType = type => {
    setSelectedType(type);
  };

  // useEffect(() => {
  //   // This function runs when the component mounts and ad loads
  //   const interval = setInterval(() => {
  //     // Select the element that AdSense is modifying
  //     const applicationContainer = document.getElementById(
  //       'application-container',
  //     );
  //     if (applicationContainer) {
  //       // Override the styles set by AdSense
  //       applicationContainer.setAttribute(
  //         'style',
  //         'height: calc(100vh - 60px) !important;',
  //       );
  //     }
  //   }, 1000); // Check every 100 milliseconds

  //   // Clear the interval when the component unmounts
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <>
      <Container userContext={userContext}>
        <ModalManagerComponent
          activeModal={activeModal}
          onClose={onClose}
          handleModelVersionChange={handleModelVersionChange}
          userContext={userContext}
        />
        <PageContainer>
          <GeneratorTopBar
            userContext={userContext}
            isLoggedIn={userContext.isLoggedIn}
            dailyGenerationsLeft={
              modelVersion === 'modelv3'
                ? userContext.userInfo.currencyValueFree
                : userContext.userInfo.subscriptionTier > 0
                ? 'Unlimited'
                : userContext.userInfo.currencyValueFreeRetro
            }
            nokePoints={userContext.userInfo.currencyValue}
            subscriptionTier={userContext.userInfo.subscriptionTier}
            useCredits={useCredits}
            handleModelVersionChange={handleModelVersionChange}
            modelVersion={modelVersion}
          />
          <ApplicationContainer id="application-container">
            {userContext.userInfo.subscriptionTier !== 1 &&
              userContext.userInfo.subscriptionTier !== 2 &&
              userContext.userInfo.subscriptionTier !== 3 && (
                <AdSidebarContainer>
                  {/* <SkyscraperAdUnit adSlot="2554593682" /> */}
                </AdSidebarContainer>
              )}
            <ContentContainer>
              <GeneratorBodyStyled
                imageUrl={imageUrl}
                isLoading={isLoading}
                userPrompt={displayedPrompt}
                userContext={userContext}
                useCredits={useCredits}
                beamTaskId={beamTaskId}
                beamStatus={beamStatus}
                queuePosition={queuePosition}
                isAllowedToGenerate={isAllowedToGenerate}
                isGenerating={isGenerating}
                generationSessionUUID={generationSessionUUID}
                modelVersion={modelVersion}
              />
              <SuggestionsAndTextBoxContainer>
                <SuggestionsArea
                  suggestions={suggestions}
                  onSelectedType={handleSelectedType}
                  onSuggestionClick={handleSuggestionClick}
                  onSuggestionHover={handleSuggestionHover}
                  onGenerate={handleGenerate}
                  isLoading={!showSuggestions}
                  isAllowedToGenerate={isAllowedToGenerate}
                  useCredits={useCredits}
                  dailyGenerationsLeft={userContext.userInfo.currencyValueFree}
                  isLoggedIn={userContext.isLoggedIn}
                  selectedType={selectedType}
                  modelVersion={modelVersion}
                />
                <SeedToggle
                  hide={!useCredits || seed === null}
                  keepSeed={keepSeed}
                  setKeepSeed={setKeepSeed}
                />
                {modelVersion === 'modelv3' ? (
                  <TextBox
                    onGenerate={handleGenerate}
                    userInput={userInput}
                    setUserInput={setUserInput}
                    hoverUserInput={hoverUserInput}
                    nokePoints={userContext.userInfo.currencyValue}
                    isLocked={!useCredits}
                    setIsLocked={handleToggleCredits}
                    isGeneratorRunning={isLoading}
                    isAllowedToGenerate={isAllowedToGenerate}
                    isLoggedIn={userContext.isLoggedIn}
                    isLoading={isLoading}
                    modelVersion={modelVersion}
                    userInfo={userContext.userInfo}
                  />
                ) : null}
              </SuggestionsAndTextBoxContainer>
            </ContentContainer>
            {userContext.userInfo.subscriptionTier !== 1 &&
              userContext.userInfo.subscriptionTier !== 2 &&
              userContext.userInfo.subscriptionTier !== 3 && (
                <AdSidebarContainer>
                  {/* <SkyscraperAdUnit adSlot="6627315034" /> */}
                </AdSidebarContainer>
              )}
          </ApplicationContainer>
        </PageContainer>
      </Container>
      <AdsContainerLeft>
        {userContext.userInfo.subscriptionTier !== 1 &&
          userContext.userInfo.subscriptionTier !== 2 &&
          userContext.userInfo.subscriptionTier !== 3 && (
            <AdSidebarContainer>
              <SkyscraperAdUnit adSlot="2554593682" />
            </AdSidebarContainer>
          )}
      </AdsContainerLeft>
      <AdsContainerRight>
        {userContext.userInfo.subscriptionTier !== 1 &&
          userContext.userInfo.subscriptionTier !== 2 &&
          userContext.userInfo.subscriptionTier !== 3 && (
            <AdSidebarContainer>
              <SkyscraperAdUnit adSlot="6627315034" />
            </AdSidebarContainer>
          )}
      </AdsContainerRight>
    </>
  );
};

async function fetchQueueTaskObject(generationSessionUUID) {
  if (!generationSessionUUID) {
    console.error('No generationSessionUUID provided');
    return null; // Return null or an appropriate default value
  }

  try {
    // Fetch the data from the API
    const response = await fetch(
      `/api/generation/advanced/getSessionEntity?generationSessionUUID=${generationSessionUUID}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      },
    );

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the JSON response
    const data = await response.json();

    // Validate the data structure
    if (data) {
      return { status: data['status'], fileName: data['fileName'] }; // Return the status
    } else {
      throw new Error('Invalid data structure received from API');
    }
  } catch (error) {
    console.error('Error fetching queue status:', error);
    return null; // Return null or an appropriate default value in case of error
  }
}

async function fetchQueuePosition(generationSessionUUID) {
  // Check if the UUID is provided
  if (!generationSessionUUID) {
    console.error('No generationSessionUUID provided');
    return null; // Return null or an appropriate default value
  }

  try {
    // Fetch the data from the API
    const response = await fetch(
      `/api/generation/advanced/getSessionIndex?generationSessionUUID=${generationSessionUUID}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      },
    );

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the JSON response
    const data = await response.json();

    // Validate the data structure
    if (data && typeof data['index'] === 'number') {
      return 1 + data['index']; // Assuming 'index' starts from 0
    } else {
      throw new Error('Invalid data structure received from API');
    }
  } catch (error) {
    console.error('Error fetching queue status:', error);
    return null; // Return null or an appropriate default value in case of error
  }
}

async function fetchTaskStatus(beamTaskId) {
  if (!beamTaskId) {
    return;
  }
  // Define the URL to fetch the task status from.
  const url = `/api/beam/status/${beamTaskId}`;

  try {
    // Perform the fetch operation.
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });

    // Check if the response was successful.
    if (!response.ok) {
      throw new Error(`Server responded with status code: ${response.status}`);
    }

    // Parse the JSON response.
    const data = await response.json();

    // Check if the expected 'status' field is present.
    if (!data || typeof data['status'] !== 'string') {
      throw new Error(
        'The "status" field is missing or invalid in the response.',
      );
    }

    // Return the status.
    return data['status'];
  } catch (error) {
    // Handle any errors that occurred during the fetch operation.
    console.error(`Error fetching task status for ID ${beamTaskId}:`, error);
    throw error; // Re-throw the error to handle it where the function is called.
  }
}

const fetchSuggestions = async (isLoggedIn, selectedType) => {
  const url = isLoggedIn
    ? '/api/generation/suggestions/displayText/random/isLoggedIn'
    : '/api/generation/suggestions/displayText/random/notLoggedIn';

  try {
    const response = await axios.post(
      url,
      {
        nokemonHistory: localStorage.getItem('nokemonHistory'),
        type: selectedType,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error(
      'Error fetching suggestions:',
      error.response ? error.response.data : error.message,
    );
  }
};

export default GeneratorContainer;
