import styled from 'styled-components';

// Styled component for the FAQ section container
const FAQSectionContainer = styled.section`
  width: 80%;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 2rem;
`;

// Styled component for the FAQ title
const FAQTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: clamp(1.25rem, 4.5vw, 3rem); // Fluid font size
  line-height: 1.2;
  letter-spacing: -0.5px;
  margin-bottom: 3.5rem;
  color: #333; // A dark color for contrast and readability
  white-space: nowrap;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 2rem; // Smaller margin for smaller screens
  }

  @media (max-width: 480px) {
    margin-bottom: 1.5rem;
    white-space: normal; // Allows text wrapping for very small screens
  }
`;

// Styled component for the list of questions and answers
const FAQList = styled.ul`
  list-style: none;
  padding: 0;
  font-family: 'Inter', sans-serif; // A modern, high-quality typeface
`;

// Styled component for individual FAQ item
const FAQItem = styled.li`
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  font-family: 'Inter', sans-serif; // A modern, high-quality typeface

  &:last-child {
    border-bottom: none;
  }
`;

const Question = styled.h3`
  font-size: 1.2rem; // Default size for larger screens
  color: #333;
  margin-bottom: 0.5rem;
  font-family: 'Inter', sans-serif;

  @media (max-width: 1024px) {
    font-size: 1.1rem; // Slightly smaller on medium screens
  }

  @media (max-width: 768px) {
    font-size: 1rem; // Further reduction for tablet screens
  }

  @media (max-width: 480px) {
    font-size: 0.9rem; // Smallest size for mobile screens
  }
`;

const Answer = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 1rem; // Default size for larger screens
  color: #666;

  @media (max-width: 1024px) {
    font-size: 0.95rem; // Slightly smaller on medium screens
  }

  @media (max-width: 768px) {
    font-size: 0.9rem; // Further reduction for tablet screens
  }

  @media (max-width: 480px) {
    font-size: 0.85rem; // Smallest size for mobile screens
  }
`;

const faqData = [
  {
    question: 'How do I refer someone to the program?',
    answer:
      'To refer someone, simply copy the referral link located at the top of the refer-a-friend page and share it with your friend. When they click on the link and sign up, it will count as a successful referral on your behalf.',
  },
  {
    question: 'When do I receive my referral rewards?',
    answer:
      'Referral rewards are typically granted as soon as your referral completes a qualifying action, such as signing up and making their first purchase.',
  },
  {
    question: 'Are there limits to how many people I can refer?',
    answer:
      'No, you can refer as many people as you like. There are no upper limits, so the more friends you invite, the more you can earn from our tiered rewards system.',
  },
  {
    question: 'Are there any restrictions on who I can refer?',
    answer:
      "You can refer anyone who hasn't already signed up for our services. There's no restriction on the number of new users you can refer, as long as they are new to our platform.",
  },
  {
    question: 'How can I track my referral progress?',
    answer:
      'You can track your referral progress at the top of the refer-a-friend page. This page will display the number of points you’ve accumulated and the rewards you’re eligible for.',
  },
  {
    question:
      'What is the number of bonus Credits awarded to both me and my referee when a referral results in a product purchase?',
    answer:
      "As a reward for your referral, you'll receive 10% of the Credits your referee purchases. For example, if your referee buys 120 Credits, you'll be awarded 12 Credits. Similarly, if they opt for a subscription containing 20 Credits, you'll receive 2 additional Credits. In case they choose a novice subscription, you will be credited with 1 Noképoint.",
  },
  {
    question:
      'Are there any special rewards for referring a certain number of people?',
    answer:
      "Yes, you will earn referral points for every successful sign-up. These points contribute to our tiered rewards system. You'll earn bonus rewards at 3, 8, 15 referral points, and additional rewards for every 3 referral points you earn thereafter. For more details on the specific rewards at each tier, please refer to our tiered reward section.",
  },
  {
    question: 'Can I earn referral points indefinitely, or is there a maximum?',
    answer:
      'You can continue to earn referral points indefinitely. There is no maximum limit, and each point brings you closer to the next reward tier.',
  },
  {
    question: 'What happens if my referee cancels their subscription?',
    answer:
      'If your referee cancels their subscription, it won’t affect your referral reward. You will be rewarded for their first purchase regardless of any subsequent cancellations.',
  },
  {
    question:
      'Is there a cap on the number of daily generations I can earn from referrals?',
    answer:
      'While there is no cap on the number of daily generations you can earn immediately after each referral, the increase in your permanent daily generations is subject to the milestones in the tiered system.',
  },
  {
    question:
      'Do the daily generations I earn for referrals add to my permanent daily generation count?',
    answer:
      'No, immediate daily generations earned from referrals are for one-time use and do not add to your permanent daily generation count. However, earning referral points can increase your permanent daily generation count as part of the tiered rewards system.',
  },
  {
    question:
      'Is there an expiration date on the daily generations for immediate use?',
    answer:
      'The daily generations you receive for immediate use from referrals do not expire. They will remain available until you use them.',
  },
];

// Define the FAQSection component
const FAQSection = () => {
  return (
    <FAQSectionContainer>
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      <FAQList>
        {faqData.map((faq, index) => (
          <FAQItem key={index}>
            <Question>{faq.question}</Question>
            <Answer>{faq.answer}</Answer>
          </FAQItem>
        ))}
      </FAQList>
    </FAQSectionContainer>
  );
};

export default FAQSection;
