import React, { useState, useEffect } from 'react';
import { IO, SubscriptionIO } from './IO.js';
// import IO from './IO/IO.js';
import Footer from './Footer.js';
import Title from './Title.js';
import Header from './Header.js';
import StoragePage from './StoragePage';
import SupportPage from './SupportPage';
import SharingPage from './SharingPage';
import ShopPage from './ShopPage';
import ReceiptPage from './ReceiptPage';
import FAQPage from './FAQPage';
import { Component } from 'react';
import { useWindowSize } from '../utils';
import { v4 as uuidv4 } from 'uuid';
import AdSense from 'react-adsense';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/fontawesome-free-solid';
import Rodal from 'rodal';
import nokemonFireImage from '../nokemon_fire.png';
import { IncludesList } from './ShopDisplay';
import { Sidebar } from './Sidebar.js';
import LandingPageBanner from './LandingPageBanner.js';
import LandingPageMenu from './LandingPageMenu.js';
import './Container.css';

import { IOProvider } from '../context/IOContext';

import { Container } from './GeneratorPage/Container';

// include styles
import 'rodal/lib/rodal.css';
import { ToastContainer } from 'react-toastify';

// export const Container = ({ children, userContext, setIsLoading }) => {
//   const [sidebarCollapse, setSidebarCollapse] = useState(true);

//   const handleSidebarCollapse = () => {
//     setSidebarCollapse(true);
//   };

//   return (
//     <div className="Container-main">
//       <div className="Container-sidebar">
//         <Sidebar
//           isLoggedIn={userContext.isLoggedIn}
//           setIsLoggedIn={userContext.setIsLoggedIn}
//           userInfo={userContext.userInfo}
//           setIsLoading={setIsLoading}
//           isCollapsed={sidebarCollapse}
//           setIsCollapsed={setSidebarCollapse}
//         />
//       </div>
//       <div
//         className="Container-children"
//         onClick={() => handleSidebarCollapse()}
//       >
//         {children}
//       </div>
//       <ToastContainer />
//     </div>
//   );
// };

export function SharingContainer(props) {
  return (
    <Container
      userContext={props.userContext}
      isLoggedIn={props.isLoggedIn}
      setIsLoggedIn={props.setIsLoggedIn}
      userInfo={props.userInfo}
      setIsLoading={props.setIsLoading}
    >
      <SharingPage />
    </Container>
  );
}

export function SupportContainer(props) {
  return (
    <Container
      userContext={props.userContext}
      isLoggedIn={props.isLoggedIn}
      setIsLoggedIn={props.setIsLoggedIn}
      userInfo={props.userInfo}
      setIsLoading={props.setIsLoading}
    >
      <SupportPage setLoggedIn={props.setIsLoggedIn} />
    </Container>
  );
}

export function ShopContainer(props) {
  return (
    <Container
      userContext={props.userContext}
      isLoggedIn={props.isLoggedIn}
      setIsLoggedIn={props.setIsLoggedIn}
      userInfo={props.userInfo}
      setIsLoading={props.setIsLoading}
    >
      <ShopPage
        setLoggedIn={props.setIsLoggedIn}
        setModalSettings={props.setModalSettings}
      />
    </Container>
  );
}

export function FAQContainer(props) {
  return (
    <Container
      userContext={props.userContext}
      isLoggedIn={props.isLoggedIn}
      setIsLoggedIn={props.setIsLoggedIn}
      userInfo={props.userInfo}
      setIsLoading={props.setIsLoading}
    >
      <FAQPage setLoggedIn={props.setIsLoggedIn} />
    </Container>
  );
}

export function StorageContainer(props) {
  return (
    <Container
      userContext={props.userContext}
      isLoggedIn={props.isLoggedIn}
      setIsLoggedIn={props.setIsLoggedIn}
      userInfo={props.userInfo}
      setIsLoading={props.setIsLoading}
    >
      {/* <LandingPageMenu
        userInfo={props.userInfo}
        isLoading={props.isLoading}
        isLoggedIn={props.isLoggedIn}
        setModalSettings={props.setModalSettings}
      /> */}
      <StoragePage isLoggedIn={props.isLoggedIn} userInfo={props.userInfo} />
    </Container>
  );
}

export function ReceiptContainer(props) {
  return <ReceiptPage setLoggedIn={props.setIsLoggedIn} />;
}

export function ClassicGeneratorContainer(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState(true);
  const [generationButtonClicked, setGenerationButtonClicked] = useState(false);

  let ioContainer;

  if (props.userContext.userInfo['isSubscribed']) {
    // ioContainer = (
    //   <IO
    //     generationButtonClicked={generationButtonClicked}
    //     setGenerationButtonClicked={setGenerationButtonClicked}
    //     setModalVisible={setModalVisible}
    //     setModalType={setModalType}
    //     setUserInfo={props.userContext.setUserInfo}
    //     setModalSettings={props.setModalSettings}
    //     userContext={props.userContext}
    //     // nokemonData={props.nokemonData}
    //   />
    // );
    ioContainer = (
      <SubscriptionIO
        sessionId={props.userContext.sessionId}
        uniqueIdentifier={props.userContext.uniqueIdentifier}
        userInfo={props.userContext.userInfo}
        isLoggedIn={props.userContext.isLoggedIn}
        generationButtonClicked={generationButtonClicked}
        setGenerationButtonClicked={setGenerationButtonClicked}
        setModalVisible={setModalVisible}
        setModalType={setModalType}
        setUserInfo={props.userContext.setUserInfo}
        setModalSettings={props.setModalSettings}
        // nokemonData={props.nokemonData}
      />
    );
  } else {
    ioContainer = (
      <IO
        sessionId={props.userContext.sessionId}
        uniqueIdentifier={props.userContext.uniqueIdentifier}
        userInfo={props.userContext.userInfo}
        isLoggedIn={props.userContext.isLoggedIn}
        generationButtonClicked={generationButtonClicked}
        setGenerationButtonClicked={setGenerationButtonClicked}
        setModalVisible={setModalVisible}
        setModalType={setModalType}
        setUserInfo={props.userContext.setUserInfo}
        setModalSettings={props.setModalSettings}
      />
    );
  }

  return (
    <Container
      userContext={props.userContext}
      setIsLoading={props.setIsLoading}
      isLoggedIn={props.isLoggedIn}
      setIsLoggedIn={props.setIsLoggedIn}
      userInfo={props.userInfo}
    >
      {/* <Header /> */}
      {/* <Title text="Generate Fakemon using AI!" /> */}
      <LandingPageMenu
        userInfo={props.userContext.userInfo}
        isLoading={props.isLoading}
        isLoggedIn={props.userContext.isLoggedIn}
        setModalSettings={props.setModalSettings}
      />
      <LandingPageBanner />
      <IOProvider>{ioContainer}</IOProvider>
      <Footer />
    </Container>
  );
}

class AdBanner extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-4838487336494546"
        data-ad-slot="7354005554"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    );
  }
}
