import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useUserContext } from '../../context/UserContext';
import { recordUserInteraction } from '../../utils/userInteractionsLogger/userInteractionLogger';

const ReferralLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 80%; // Increased for better responsiveness
  max-width: 360px;
  min-width: 150px;
  margin: 0 auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background: #2d2d2d;

  @media (max-width: 768px) {
    width: 90%; // More width on smaller screens
  }

  @media (max-width: 480px) {
    width: 95%; // Almost full width on very small screens
  }
`;
const ReferralTextBox = styled.input`
  flex-grow: 1;
  padding: 12px 16px; // Adjusted for better ergonomics
  font-size: 16px;
  font-weight: 400;
  border: none;
  color: black;
  background-color: white;
  outline: none;
  text-overflow: ellipsis;
`;

const CopyButton = styled.button`
  min-width: 80px; // Adjusted for smaller screens
  padding: 12px 16px; // Reduced padding for consistency
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  background-color: #4b6cb7;
  color: #fff;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #3a579a;
    transform: scale(1.03);
  }

  @media (max-width: 768px) {
    min-width: 70px; // Smaller width on tablet screens
  }

  @media (max-width: 480px) {
  }
`;

const ClipboardIcon = styled.i`
  /* Use a styled font icon or SVG here */
  margin-right: 8px;
`;
const ReferralLinkComponent = ({ referralCode, isLoggedIn }) => {
  const [copied, setCopied] = React.useState(false); // State for copied feedback
  const [referralText, setReferralText] = React.useState('Loading...');

  const userContext = useUserContext();

  useEffect(() => {
    if (!isLoggedIn) {
      setReferralText('Log in to retrieve your referral link');
      return;
    }
    if (referralCode === 0) {
      return;
    }
    setReferralText(`https://nokemon.eloie.tech/?referralCode=${referralCode}`);
  }, [referralCode]);

  const handleCopyClick = () => {
    if (referralText === 'Loading...') {
      return;
    }
    navigator.clipboard.writeText(
      `https://nokemon.eloie.tech/?referralCode=${referralCode}`,
    );
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds

    if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
      recordUserInteraction(
        userContext.userInfo.emailHash,
        'Click Referral Link',
        {
          specificAction: 'Copy Link',
          timestamp: new Date(),
        },
      );
    }
  };

  return (
    <ReferralLinkContainer>
      <ReferralTextBox type="text" value={referralText} readOnly />
      <CopyButton onClick={handleCopyClick}>
        {copied ? (
          'Copied!'
        ) : (
          <>
            <ClipboardIcon /> {/* Include your clipboard icon here */}
            Copy
          </>
        )}
      </CopyButton>
    </ReferralLinkContainer>
  );
};

export default ReferralLinkComponent;
