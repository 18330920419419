import { useState, useEffect, useCallback, useMemo } from 'react';
import { fetchLastTaskTimes } from '../api/fetchLastTaskTimes';

export const useLastTaskStatus = isVisible => {
  const defaultStatus = useMemo(
    () => ({
      lastTaskEndTime: null,
      lastTaskStartPendingTime: null,
      isLoading: true,
      error: null,
    }),
    [],
  );

  const [status, setStatus] = useState(defaultStatus);

  useEffect(() => {
    let isMounted = true; // Flag to track mounted state

    const updateStatus = async () => {
      if (isVisible) {
        try {
          const newStatus = await fetchLastTaskTimes();
          if (isMounted) {
            setStatus(prevStatus => ({
              ...prevStatus,
              ...newStatus,
              isLoading: false,
            }));
          }
        } catch (error) {
          if (isMounted) {
            setStatus(prevStatus => ({
              ...prevStatus,
              isLoading: false,
              error: error.message,
            }));
          }
        }
      } else {
        setStatus(defaultStatus);
      }
    };

    updateStatus();

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, [isVisible, defaultStatus]);

  return {
    lastTaskEndTime: status.lastTaskEndTime,
    lastTaskStartPendingTime: status.lastTaskStartPendingTime,
    isLoading: status.isLoading,
    error: status.error,
  };
};
