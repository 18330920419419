import React, { createContext, useState, useContext, useEffect } from 'react';
import { DefaultDict } from 'nokemon-common-utils';
import * as apiHelpers from '../api-helpers';
import { v4 as uuidv4 } from 'uuid';

async function generateIdentifier() {
  const screenSize = `${window.screen.width}x${window.screen.height}`;
  const userAgent = window.navigator.userAgent;
  const language = window.navigator.language;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const colorDepth = window.screen.colorDepth;
  const hardwareConcurrency = window.navigator.hardwareConcurrency;
  const platform = window.navigator.platform;

  const text = `${screenSize}-${userAgent}-${language}-${timezone}-${colorDepth}-${hardwareConcurrency}-${platform}`;

  return btoa(text);
}

function storeSelectedUserInfo(fetchedUserInfo, fetchedSubscriptionTier) {
  // Replace 'name' and 'email' with the actual attributes you need
  const selectedAttributes = {
    name: fetchedUserInfo.name,
    picture: fetchedUserInfo.picture,
    currencyValue: fetchedUserInfo.currencyValue,
    currencyValueFree: fetchedUserInfo.currencyValueFree,
    currencyValueFreeRetro: fetchedUserInfo.currencyValueFreeRetro,
    proStatus: fetchedUserInfo.proStatus,
    referralCode: fetchedUserInfo.referralCode,
    referralRewards: fetchedUserInfo.referralRewards,
    subscriptionTier: fetchedUserInfo.subscriptionTier,
  };

  localStorage.setItem('userInfo', JSON.stringify(selectedAttributes));
  localStorage.setItem('subscriptionTier', fetchedSubscriptionTier.toString());
}

const UserContext = createContext();

export function UserProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  // Initialize userInfo from localStorage if available, or set to default
  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    const defaultDict = new DefaultDict(Number);

    defaultDict['currencyValue'] = null;
    defaultDict['currencyValueFree'] = null;
    return storedUserInfo ? JSON.parse(storedUserInfo) : defaultDict;
  });

  // Initialize subscriptionTier from localStorage if available, or set to default
  const [subscriptionTier, setSubscriptionTier] = useState(() => {
    const storedSubscriptionTier = localStorage.getItem('subscriptionTier');
    return storedSubscriptionTier ? parseInt(storedSubscriptionTier, 10) : 0;
  });

  const [sessionId, setSessionId] = useState(undefined);
  const [uniqueIdentifier, setUniqueIdentifier] = useState(null);
  const [storageId, setStorageId] = useState('');

  function assignStorageId() {
    let storageId = localStorage.getItem('storageId');
    let startSessionTime = localStorage.getItem('startSessionTime');

    if (!storageId || !startSessionTime) {
      storageId = uuidv4();
      localStorage.setItem('storageId', storageId);
      localStorage.setItem('startSessionTime', Date.now());
    }

    setStorageId(storageId);
  }

  useEffect(() => {
    const generateUniqueIdentifier = async () => {
      const uuid = await generateIdentifier();
      setUniqueIdentifier(uuid);
    };

    // If they are a referee
    const getReferralCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const extractedReferralCode = urlParams.get('referralCode');

      if (extractedReferralCode) {
        localStorage.setItem('referralCode', extractedReferralCode);
      }
    };

    const loginUser = async () => {
      setIsLoggingIn(true);

      const isValid = await apiHelpers.checkLogin();

      setIsLoggedIn(isValid);
      setIsLoggingIn(false);

      if (isValid) {
        try {
          const fetchedUserInfo = await apiHelpers.getUserInfo();
          const fetchedSubscriptionTier =
            await apiHelpers.getSubscriptionTier();

          // Update state with the fetched info
          setUserInfo(fetchedUserInfo);
          setSubscriptionTier(fetchedSubscriptionTier);

          storeSelectedUserInfo(fetchedUserInfo, fetchedSubscriptionTier);

          const session = await apiHelpers.setSessionId();
          setSessionId(session);
        } catch (error) {
          console.error('Error during user info retrieval:', error);
        }
      }
    };

    (async () => {
      assignStorageId();
      await generateUniqueIdentifier();
      await getReferralCode();
      await loginUser();
    })();
  }, []);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        userInfo,
        setUserInfo,
        subscriptionTier,
        setSubscriptionTier,
        sessionId,
        setSessionId,
        uniqueIdentifier,
        setUniqueIdentifier,
        storageId,
        setStorageId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
