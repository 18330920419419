import './StoragePageBanner.css';

const StoragePageBanner = () => {
  return (
    <div className="StoragePageBanner-main">
      <h1 className="StoragePageBanner-title">Fakemon Collection</h1>
      <h2 className="StoragePageBanner-subtitle">
        Explore all the Fakemon designs you have generated so far, including
        Favourites and Premium Fakemon!
      </h2>
    </div>
  );
};

export default StoragePageBanner;
