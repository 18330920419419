// ReactWizardX's Modern Text and Interactive Component
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import FakemonGrassExample from '../../../fakemon-example-grass-1.png';
import FakemonIceExample from '../../../fakemon-example-ice-1.png';
import FakemonFlyingExample from '../../../fakemon-example-flying-electric-1.png';
import FakemonFairyExample from '../../../fakemon-example-fairy-1.png';

const responsiveFontSizes = ({ small, medium, large }) => {
  return css`
    @media (max-width: 500px) {
      font-size: ${small};
    }

    @media (min-width: 501px) and (max-width: 768px) {
      font-size: ${medium};
    }

    @media (min-width: 769px) {
      font-size: ${large};
    }
  `;
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  padding: 20px;
  background-color: #1e2124;
  border: 1px solid #444c56aa;
  border-radius: 6px;
  position: absolute; // Absolutely position GridContainer to break out of the flow
  bottom: -5rem; // Negative value to move it outside the SuggestionsContainer
  right: -5rem; // Negative value to move it outside the SuggestionsContainer on the right
  width: calc(100% - 4rem); // Adjust the width as necessary
  max-width: 960px; // Max width to bound the size
  z-index: 10; // Ensure it stacks on top
  // Other styles...

  @media (max-width: 768px) {
    margin: 0 auto; // Center the container
    bottom: -5rem;
    right: auto;
    width: calc(100% - 20px);
  }

  @media (max-width: 420px) {
    width: calc(100% - 20px);
  }
`;

const Suggestion = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 98.5%;
  height: 75px;
  background-color: transparent;
  border: 1px solid ${props => (props.isSelected ? '#6464ec' : '#444c56aa')};
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 13.5px;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #6464ec;
  }

  ${responsiveFontSizes({ small: '12px', medium: '13px', large: '13.5px' })}
`;

const SuggestionImageDescription = styled.p`
  font-family: 'Inter', sans-serif;
  color: #c2c2c2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  text-align: left;
  width: 80%;
  padding: 0 20px;
  align-self: flex-start;

  @media (max-width: 480px) {
    width: 100%;
    padding-bottom: 10px;
  }

  ${responsiveFontSizes({ small: '0.8em', medium: '0.9em', large: '1em' })}
`;
const SuggestedImageAndDescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e2124;
  border: 1px solid #6464ec;
  border-radius: 6px;
  height: auto; // Allow the container to grow with content
  max-height: 200px; // Max height to bound the size of the image container
  width: 100%; // Take the full width of the parent container
  max-width: 500px; // Max width to bound the size
  margin: 0 auto; // Center the container
  @media (max-width: 480px) {
    flex-direction: column;
    max-height: 400px;
  }
`;

const SuggestionImage = styled.img`
  max-width: 100%; // Limit the width to the parent container
  max-height: 100%; // Limit the height to the parent container
  object-fit: contain; // Ensure the aspect ratio is maintained
  padding: 20px; // Add padding to create space around the image
  border-radius: 6px; // Match the border radius of the container
  margin: 10px; // Add margin to ensure it doesn't touch the sides

  @media (max-width: 768px) {
    max-height: 150px; // Adjust the height for smaller screens
  }
`;

// 🌐 ReactWizardX's Styled Components for Feature Layout 🌐
const FeatureContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #191a1a;
  backdrop-filter: blur(10px); // Blur effect for glassmorphism
  -webkit-backdrop-filter: blur(10px); // For Safari browser
  // border: 1px solid rgba(255, 255, 255, 0.25); // Border for light glass effect
  color: white;
  border-radius: 12px;
  margin: auto; // Center the container on the page
  width: 90%;
  padding-bottom: 10rem;

  @media (max-width: 1200px) {
    padding: 0 1rem 10rem 1rem; // Further reduce padding on small screens
  }

  @media (max-width: 768px) {
    width: 100%;
    min-width: 100px;
    padding-top: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 6rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem; // Space below the content containers

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const InteractiveContainer = styled.div`
  background-color: #282c30aa;
  border-radius: 6px;
  padding: 2rem; // Match TextContainer padding
  border: 1px solid #444c56aa; // Match border with Suggestion
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center; // Center-align the content
  justify-content: flex-start;
  margin: auto; // Center the container on the page
  height: 400px;
  width: 600px;
  min-width: 600px;

  @media (max-width: 768px) {
    width: 90%;
    min-width: 300px;
  }

  @media (max-width: 480px) {
    width: 100%;
    min-width: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const SuggestionTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // Add any additional styling you need for the container
`;

const TypeText = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 700; /* Increased font weight for better emphasis */
  font-size: 1.1em; /* Slightly larger font size */
  color: white;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  text-align: left;

  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1em; // Adjusted to em unit
  color: white;
  ${responsiveFontSizes({ small: '0.9em', medium: '1em', large: '1.1em' })}
`;

const DescriptionText = styled.span`
  text-align: left;
  font-family: 'Inter', sans-serif;
  color: #c2c2c2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.lineClamp};

  overflow: hidden;
  text-overflow: ellipsis;

  text-align: left;
  font-family: 'Inter', sans-serif;
  color: #c2c2c2;
  font-size: 0.85em; // Adjusted to em unit

  ${responsiveFontSizes({ small: '0.75em', medium: '0.8em', large: '0.85em' })}
`;

const SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; // This is needed to position GridContainer absolutely within
  width: 100%;
  overflow: visible; // Make sure the overflow is visible to show the layered effect
  padding-bottom: 10rem;
`;

// Dummy data for suggestions and images, replace with real data as needed
const dummySuggestions = [
  {
    id: 1,
    text: 'A young dragon with green scales and leaf-shaped appendage at the tail.',
    type: 'Grass / Dragon',
    imgUrl: FakemonGrassExample,
  },
  {
    id: 2,
    text: 'A baby Macaque with blue fur and icy crystalline mane, Ice type.',
    type: 'Ice',
    imgUrl: FakemonIceExample,
  },
  {
    id: 3,
    text: 'A cyberpunk hawk with matte silver metallic feathers, glowing cyan circuitry.',
    type: 'Flying / Eletric',
    imgUrl: FakemonFlyingExample,
  },
  {
    id: 4,
    text: 'A baby noctural mythical fox, with deep violet fur, glowing crimson highlights.',
    type: 'Fairy / Ghost',
    imgUrl: FakemonFairyExample,
  },
];

// Modify the SuggestionsGrid component
const SuggestionsGrid = () => {
  const [currentSuggestion, setCurrentSuggestion] = useState(
    dummySuggestions[0],
  );
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    let intervalId;
    if (!isHovering) {
      intervalId = setInterval(() => {
        const currentIndex = dummySuggestions.indexOf(currentSuggestion);
        const nextIndex = (currentIndex + 1) % dummySuggestions.length;
        setCurrentSuggestion(dummySuggestions[nextIndex]);
      }, 2000);
    }

    return () => clearInterval(intervalId);
  }, [currentSuggestion, isHovering]);

  const handleSuggestionClick = suggestion => {
    setCurrentSuggestion(suggestion);
  };

  return (
    <SuggestionsContainer
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <SuggestedImageAndDescriptionContainer>
        <SuggestionImage src={currentSuggestion.imgUrl} alt="Fakémon Image" />
        <SuggestionImageDescription>
          <TypeText>{currentSuggestion.type}</TypeText>
          <DescriptionText lineClamp={3}>
            {currentSuggestion.text}
          </DescriptionText>
        </SuggestionImageDescription>
      </SuggestedImageAndDescriptionContainer>
      <GridContainer>
        {dummySuggestions.map(suggestion => (
          <Suggestion
            key={suggestion.id}
            isSelected={suggestion === currentSuggestion}
            onClick={() => handleSuggestionClick(suggestion)}
          >
            <SuggestionTextContainer>
              <TypeText>{suggestion.type}</TypeText>
              <DescriptionText lineClamp={2}>{suggestion.text}</DescriptionText>
            </SuggestionTextContainer>
          </Suggestion>
        ))}
      </GridContainer>
    </SuggestionsContainer>
  );
};

const TextContainer = styled.div`
  background: #191a1add; // Adjust color to match the image
  color: white; // Text color
  padding: 0rem 2rem; // Adjust padding to match the image
  margin: 0rem 0; // Space around the container
  width: 50%;

  @media (max-width: 1200px) {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem; // Adjust font-size to match the image
  color: white; // Title color
  margin-bottom: 1rem; // Space below the title
  margin-top: 0;
  padding-top: 0;
  font-family: Inter;
  ${responsiveFontSizes({ small: '2rem', medium: '2.25rem', large: '2.5rem' })}
`;

const Subtitle = styled.h2`
  display: inline-block; // Make the tag a block-level element that flows with the text
  background-color: transparent; // Adjust background color to match the tag color in the image
  border: 2px solid #6061eb; // Adjust the color to match the line on the image
  color: white; // Text color for contrast
  padding: 0.2rem 0.7rem; // Vertical and horizontal padding
  border-radius: 6px; // Fully rounded corners for pill shape
  font-size: 0.8rem; // Adjust font-size to match the image
  margin-bottom: 1rem; // Space below the subtitle
  font-weight: bold; // Bold font weight
  ${responsiveFontSizes({
    small: '0.7rem',
    medium: '0.75rem',
    large: '0.8rem',
  })}
`;

const BulletPoints = styled.ul`
  list-style: none; // Remove default list styling
  padding: 0; // Remove default padding
  margin: 0; // Remove default margin
  border-left: 5px solid #6061eb; // Adjust the color to match the line on the image
  width: 100%;
`;

const BulletPoint = styled.div`
  background-color: #2a2d3444; // A dark background that fits the theme
  padding: 1rem; // Spacing inside the bullet points
  margin-bottom: 0.5rem; // Space between bullet points
  color: white; // Text color for readability
  transition: background-color 0.3s ease; // Smooth transition for hover effect
  width: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #373b44; // Slightly lighter background on hover
  }
`;

const BulletPointTitle = styled.h3`
  font-size: 1.1rem; // Larger size for titles
  color: #ffffff; // Bright color for the title
  margin: 0 0 0.3rem 0; // Spacing between title and description
  font-weight: 600; // Semi-bold for distinction
  ${responsiveFontSizes({ small: '1rem', medium: '1.05rem', large: '1.1rem' })}
`;

const BulletPointDescription = styled.p`
  font-size: 0.9rem; // Smaller than the title
  color: #bbbbbb; // A lighter shade for descriptions
  margin: 0; // No additional spacing needed
  ${responsiveFontSizes({
    small: '0.8rem',
    medium: '0.85rem',
    large: '0.9rem',
  })}
`;

// Component structure
const DiscoveryTextContainer = () => (
  <TextContainer>
    <Subtitle>Simple & Fun</Subtitle>
    <Title>Daily Discovery Mode</Title>
    <BulletPoints>
      <BulletPoint>
        <BulletPointTitle>Choose a Fakémon Description</BulletPointTitle>
        <BulletPointDescription>
          Discover new, randomly generated Fakémon each day to expand your
          collection.
        </BulletPointDescription>
      </BulletPoint>
      <BulletPoint>
        <BulletPointTitle>Instant Generation</BulletPointTitle>
        <BulletPointDescription>
          With just one click, the Fakémon will instantly come to life. Fun and
          effortless!
        </BulletPointDescription>
      </BulletPoint>
      <BulletPoint>
        <BulletPointTitle>Daily Generations</BulletPointTitle>
        <BulletPointDescription>
          Enjoy 10 free Fakémon generations each day.
        </BulletPointDescription>
      </BulletPoint>
      <BulletPoint
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          window.location.href = '/marketplace';
        }}
      >
        <BulletPointTitle>Subscribe For More</BulletPointTitle>

        <BulletPointDescription>
          Consider subscribing for additional daily generations every day.
        </BulletPointDescription>
        <Subtitle
          style={{
            margin: 0,
            marginTop: '10px',
            width: '120px',
            marginLeft: 'auto',
            cursor: 'pointer',
            // Move component to most right handside
          }}
        >
          Subscribe Now!
        </Subtitle>
      </BulletPoint>
    </BulletPoints>
  </TextContainer>
);

// The FeatureComponent with left-aligned text and right-aligned interactive placeholder
const DailyDiscoverySection = () => {
  return (
    <FeatureContainer>
      <ContentContainer>
        <DiscoveryTextContainer />
        <InteractiveContainer>
          <SuggestionsGrid />
        </InteractiveContainer>
      </ContentContainer>
    </FeatureContainer>
  );
};

export default DailyDiscoverySection;
