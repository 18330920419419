import { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NokemonCard } from './StoragePage';
import { generateTextFromFileName } from 'nokemon-common-utils';
import { toast } from 'react-toastify';

import { ShareButton } from './StorageButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import * as RegularIcons from '@fortawesome/free-regular-svg-icons';

import ReactCardFlip from 'react-card-flip';
import { TwitterShareButton, TwitterIcon } from 'react-share';

export default function SharingPage(props) {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [hideText, setHideText] = useState(false);

  const [nokemon, setNokemon] = useState(undefined);
  const query = useQuery();
  const nokemonUUID = query.get('nokemon');

  useEffect(() => {
    const getNokemonFromDatastoreByUUID = async function (uuid) {
      return await fetch('/api/nokemon/share/uuid', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          nokemonUUID: uuid,
        }),
      });
    };

    getNokemonFromDatastoreByUUID(nokemonUUID).then(async res => {
      if (res.status == 200) {
        const nokemon = await res.json();
        setNokemon(nokemon);
      } else {
        const text = await res.text();
        toast.error(text);
      }
    });
  }, []);

  {
    return nokemon ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '13px',
          height: '100vh',
        }}
      >
        {hideText ? (
          <h1>A wild Fakémon appeared! </h1>
        ) : (
          <h1>Click to reveal...</h1>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: '5px',
          }}
        >
          {/* <SharingFeedbackButtons votes={nokemon.upvotes - nokemon.downvotes} /> */}

          <NokemonCard
            fileName={nokemon.file_name}
            type={generateTextFromFileName(nokemon.file_name)['type1']}
            isAddButtonDisabled={isAddButtonDisabled}
            setIsAddButtonDisabled={setIsAddButtonDisabled}
            isAddButtonDisplayed={true}
            isDeleteButtonDisplayed={false}
            isSharingButtonDisplayed={true}
            isFlippedToBack={true}
            setHideText={setHideText}
            isHover={true}
          />
        </div>
        {/* <br />
        <br />
        <TwitterShareButton
          url={`nokemon.eloie.tech/sharing?nokemon=${nokemon.uuid}`}
          title={'Check out this AI generated Nokémon!'}
          style={{
            position: 'relative',
            marginRight: '300px',
          }}
        >
          <TwitterIcon size={43} round />
        </TwitterShareButton> */}
        {/* <ViewCount views={nokemon.views} /> */}
      </div>
    ) : (
      <div></div>
    );
  }
}

function ViewCount(props) {
  return (
    <h2
      style={{
        textAlign: 'right',
        width: '90%',
      }}
    >
      <FontAwesomeIcon icon={RegularIcons.faEye} />
      &nbsp;
      {props.views}
    </h2>
  );
}

function SharingFeedbackButtons(props) {
  return (
    <div className="sharingFeedbackButtons">
      <UpvoteButton />
      <Votes votes={props.votes} />
      <DownvoteButton />
    </div>
  );
}

function Votes(props) {
  return (
    <h1
      style={{
        margin: '0px',
      }}
    >
      {props.votes}
    </h1>
  );
}

function UpvoteButton(props) {
  return (
    <button className="upvoteButton">
      <FontAwesomeIcon icon={SolidIcons.faSortUp} />
    </button>
  );
}

function DownvoteButton(props) {
  return (
    <button className="downvoteButton">
      <FontAwesomeIcon icon={SolidIcons.faSortDown} />
    </button>
  );
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
