import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Container } from '../GeneratorPage/Container';
import DailyDiscoverySection from './subcomponents/DailyDiscoverySection';
import CreateYourOwnSection from './subcomponents/CreateYourOwnSection';
import FAQSection from './subcomponents/FAQSection';
import SubscriptionContainer from './subcomponents/SubscriptionSection';

import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

import { useUserContext } from '../../context/UserContext';

import { recordUserInteraction } from '../../utils/userInteractionsLogger/userInteractionLogger';

// Global style for consistent base styling
const GlobalStyle = createGlobalStyle`
 body {
   margin: 0;
   padding: 0;
   background-color: #121212;
   font-family: 'Roboto', sans-serif;
   color: #ffffff;
 }

 *, *::before, *::after {
   box-sizing: border-box;
 }
`;

// Responsive design utility function
const responsiveFontSizes = ({ small, medium, large }) => {
  return css`
    @media (max-width: 500px) {
      font-size: ${small};
    }

    @media (min-width: 501px) and (max-width: 768px) {
      font-size: ${medium};
    }

    @media (min-width: 769px) {
      font-size: ${large};
    }
  `;
};

// TitleContainer with responsive padding
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 8rem 0 4rem 0;
  color: #ffffff;
  background: radial-gradient(ellipse at bottom, #3c3cff -100%, #181a1b 80%);

  @media (max-width: 768px) {
    padding: 5rem 0 2rem 0;
  }

  @media (max-width: 500px) {
    padding: 3rem 1rem;
  }
`;

// GetStartedButton with responsive font size and padding
const GetStartedButton = styled.button`
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #6464ec;
  border-radius: 8px;
  padding: 0.8rem 1.6rem;
  margin-top: 1.5rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  ${responsiveFontSizes({ small: '0.8rem', medium: '0.85rem', large: '1rem' })}

  &:hover {
    background-color: #8080ff;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 500px) {
    padding: 0.5rem 1rem;
    border-radius: 6px;
  }
`;

// Title components with responsive font sizes
const Title = styled.h1`
  font-weight: bold;
  margin: 0;
  background-image: linear-gradient(45deg, #ffffff, #6464ec);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  ${responsiveFontSizes({ small: '2.2rem', medium: '2.5rem', large: '3.5rem' })}
`;

const TopTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: bold;
  margin: 0;
  background-image: linear-gradient(45deg, #ffffff, #6464ec);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 500px) {
    font-size: 2.2rem; // Adjust font size for better fitting
    line-height: 1.2; // Adjust line height to prevent awkward spacing
    text-align: center; // Ensure text is centered
  }
  ${responsiveFontSizes({ small: '2.2rem', medium: '2.5rem', large: '3.5rem' })}
`;

const BottomTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: bold;
  margin: 0;
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 500px) {
    font-size: 2.2rem; // Same adjustments as TopTitle
    line-height: 1.2;
    text-align: center;
  }
  ${responsiveFontSizes({ small: '2.2rem', medium: '2.5rem', large: '3.5rem' })}
`;

const TitleAccent = styled.span`
  color: #ffffff;
  font-weight: 600;
  ${responsiveFontSizes({ small: '500', medium: '600', large: '600' })}
`;

const Subtitle = styled.h2`
  color: #ffffff;
  font-weight: 300;
  margin-top: 1.5rem;
  ${responsiveFontSizes({ small: '0.8rem', medium: '0.9rem', large: '1rem' })}
  text-align: center;
`;

const HowItWorksSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  background-color: #191a1a;
  color: #ffffff;
  width: 100%;

  @media (max-width: 768px) {
    padding: 3rem 0;
  }

  @media (max-width: 500px) {
    padding: 2rem 0;
  }
`;

// FadeInSection component
const FadeInSection = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Synchronize with TitleContainer animation
  const animationStyles = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
    config: { duration: 400 },
    delay: inView ? 400 : 0, // Delay if initially in view, to sync with TitleContainer
    width: '100%',
  });

  return (
    <animated.div style={animationStyles} ref={ref}>
      {children}
    </animated.div>
  );
};

// Animate styled components directly
const AnimatedTopTitle = animated(TopTitle);
const AnimatedBottomTitle = animated(BottomTitle);
const AnimatedSubtitle = animated(Subtitle);
const AnimatedGetStartedButton = animated(GetStartedButton);

const InfoPage = () => {
  // Animation for TitleContainer
  const titleContainerAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(30px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 700 }, // Customize duration
  });

  // Staggered animations for child components
  const topTitleAnimation = useSpring({
    ...titleContainerAnimation,
    delay: 100,
  });
  const bottomTitleAnimation = useSpring({
    ...titleContainerAnimation,
    delay: 200,
  });
  const subtitleAnimation = useSpring({
    ...titleContainerAnimation,
    delay: 300,
  });
  const buttonAnimation = useSpring({ ...titleContainerAnimation, delay: 400 });

  const userContext = useUserContext();

  return (
    <Container>
      <GlobalStyle />
      <TitleContainer>
        <AnimatedTopTitle style={topTitleAnimation}>
          Design Fakémon <TitleAccent>Using</TitleAccent>
        </AnimatedTopTitle>
        <AnimatedBottomTitle style={bottomTitleAnimation}>
          Generative <TitleAccent>AI</TitleAccent>
        </AnimatedBottomTitle>
        <AnimatedSubtitle style={subtitleAnimation}>
          Design. Discover. Generate. - Where AI Brings Your Fakémon to Life
        </AnimatedSubtitle>
        <AnimatedGetStartedButton
          style={buttonAnimation}
          onClick={() => {
            if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
              recordUserInteraction(
                userContext.userInfo.emailHash,
                'Click Discover Now Button',
                {
                  timestamp: new Date(),
                  specificAction: 'Click Discover Now',
                },
              );
            }
            window.location.href = '/';
          }}
        >
          Discover Now!
        </AnimatedGetStartedButton>
      </TitleContainer>

      <HowItWorksSection>
        <FadeInSection>
          <DailyDiscoverySection />
        </FadeInSection>
        <FadeInSection>
          <CreateYourOwnSection />
        </FadeInSection>
        <FadeInSection>
          <SubscriptionContainer />
        </FadeInSection>
        <FadeInSection>
          <FAQSection />
        </FadeInSection>
      </HowItWorksSection>
    </Container>
  );
};

export default InfoPage;
