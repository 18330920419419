import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import ButtonWithDescription from '../ButtonWithDescription';

// Define your styled component
const NokepointsButtonStyled = styled.button`
  height: 40px;
  font-size: 16px;
  padding: 10px 20px;
  background-color: purple;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid gold;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  animation: shine 3s ease-in-out infinite;
  display: flex;
  align-items: center;
  gap: 10px;
`;

// Then, in your component, you can use this new styled component like so:
const NokepointsButton = ({ value, defaultShow, zIndex }) => {
  return (
    <ButtonWithDescription
      defaultShow={defaultShow}
      button={
        <NokepointsButtonStyled
          onClick={() => {
            window.location.href =
              '/marketplace?productType=Nokepoints#marketplaceTabs';
          }}
        >
          <FontAwesomeIcon
            icon={faCoins}
            style={{
              color: 'gold',
            }}
          />{' '}
          {value}
        </NokepointsButtonStyled>
      }
      description={'Number of Credits'}
    />
  );
};

export default NokepointsButton;
