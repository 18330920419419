import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// AdContainer will be responsive and adjust the ad size based on the viewport
const AdContainer = styled.div`
  width: 100%; // Responsive width based on the parent container
  height: auto; // Height is auto to maintain aspect ratio
  display: flex;
  justify-content: center;
  align-items: center;

  // Adjust ad size for different breakpoints
  @media (min-width: 769px) {
    width: 120px; // Full width for desktop
    height: 600px; // Full height for desktop
  }
  @media (max-width: 768px) {
    width: 100px; // Reduced width for tablet
    height: 500px; // Reduced height for tablet
  }
  @media (max-width: 480px) {
    // Even smaller or hidden for mobile
    height: 300px; // Reduced height for mobile
  }
`;
// Placeholder styling for development mode
const AdPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ccc; // A light gray to mimic an ad background
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-family: Arial, sans-serif;
  text-align: center;

  // Adjust ad size for different breakpoints
  @media (min-width: 769px) {
    width: 120px; // Full width for desktop
    height: 600px; // Full height for desktop
  }
  @media (max-width: 768px) {
    width: 100px; // Reduced width for tablet
    height: 500px; // Reduced height for tablet
  }
  @media (max-width: 480px) {
    // Even smaller or hidden for mobile
    height: 300px; // Reduced height for mobile
  }
`;

const SkyscraperAdUnit = ({ adSlot }) => {
  const adContainerRef = useRef(null);
  const [isAdVisible, setIsAdVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      // Check if the ad container is in the DOM and its width
      if (adContainerRef.current && adContainerRef.current.offsetWidth < 120) {
        // If the width is less than the minimum, don't show the ad
        setIsAdVisible(false);
      } else {
        // Otherwise, make sure it's visible
        setIsAdVisible(true);
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Initialize AdSense ads
    if (process.env.NODE_ENV !== 'development') {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (error) {
        console.error('AdSense ad push failed', error);
      }
    }

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AdContainer ref={adContainerRef}>
      {process.env.NODE_ENV === 'development' ? (
        <AdPlaceholder>Ad - {adSlot} (Development Placeholder)</AdPlaceholder>
      ) : isAdVisible ? (
        <ins
          className="adsbygoogle"
          style={{ display: 'block', width: '100%', height: '100%' }}
          data-ad-client="ca-pub-4838487336494546"
          data-ad-slot={adSlot}
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      ) : null}
    </AdContainer>
  );
};

export default SkyscraperAdUnit;
