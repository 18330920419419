import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from './subcomponents/Sidebar';
import { useUserContext } from '../../context/UserContext';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainContainer = styled.div`
  display: flex;
  height: 100vh;

  // Not scrollable horizontally
  overflow-x: hidden;

  position: relative; // Relative position for absolute children
`;

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast-container {
    @media (max-width: 768px) {
      width: 100%; // On smaller screens, you might want to make it full width
      padding: 0; // Remove padding if you want it edge to edge
    }
  }

  .Toastify__toast {
    width: 400px; // Set your desired width
    border-radius: 8px; // If you want rounded corners

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .Toastify__toast-body {
    width: auto;
    text-align: center;
    font-size: 1rem; // Adjust font size as needed
  }
`;

const SidebarContainer = styled.div`
  transition: transform 0.3s ease;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? 'translateX(-100%)' : 'translateX(0)'};
  width: 250px; // Set a fixed width for the sidebar
  position: absolute; // Absolutely position the sidebar
  height: 100%;
  z-index: 2; // Ensure the sidebar is above the content when expanded

  @media (max-width: 768px) {
    width: 250px; // Keep the sidebar at 200px on mobile or adjust as needed
    transform: ${({ isCollapsed }) =>
      isCollapsed
        ? 'translateX(-100%)'
        : 'translateX(0)'}; // Adjust transformation for mobile
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1; // Take up all available space
  margin-left: 80px; // Offset by the width of the collapsed sidebar
  transition: margin-left 0.3s ease;
  //   width: calc(100% - 80px); // Account for collapsed sidebar width
  height: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-left: 0; // Sidebar overlays content on mobile
    width: 100%; // Full width since the sidebar is overlaid
  }

  @media (max-width: 1024px) {
    // Make so we can scroll
    overflow-y: auto;
    height: calc(100vh); // Subtract the height of the top bar
  }
`;

export const Container = ({ children }) => {
  const [sidebarCollapse, setSidebarCollapse] = useState(true); // Start with the sidebar collapsed
  const userContext = useUserContext();

  const handleUserLogout = () => {
    // Remove everything from localStorage except for nokemonHistory
    const nokemonHistory = localStorage.getItem('nokemonHistory');
    localStorage.clear();
    localStorage.setItem('nokemonHistory', nokemonHistory);
    userContext.setIsLoggedIn(false);
    window.location.href = '/';
  };

  return (
    <MainContainer>
      <Sidebar userContext={userContext} handleUserLogout={handleUserLogout} />
      <ContentContainer isCollapsed={sidebarCollapse}>
        {children}
      </ContentContainer>
      <StyledToastContainer position="top-center" theme="dark" />
    </MainContainer>
  );
};
