import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
// Define the styled-components
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #f5f5f5;
`;

const Input = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  margin: 2px;
  padding: 5px;
  font-size: 16px;
  border-radius: 2px;
`;

const Keyword = styled.div`
  margin: 5px;
  padding: 5px 10px;
  // background-color: #007bff;
  background-color: purple;
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  min-width: 50px;
  // font-family: 'Open Sans', sans-serif;
`;

const KeywordText = styled.span`
  margin-right: 10px;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px; /* smaller font size */
  // font-family: 'Open Sans', sans-serif;
`;

// The main component
const KeywordInputBar = ({
  onKeywordsChange,
  defaultValue,
  maxKeywords = 6,
  maxKeywordLength = 12,
}) => {
  const [input, setInput] = useState('');
  let defaultKeywords;

  if (defaultValue !== '') {
    // Convert defaultValue string to an array of keyword objects
    defaultKeywords = defaultValue
      .split(', ')
      .map(keyword => ({ id: Date.now(), value: keyword }));
  } else {
    defaultKeywords = [];
  }

  const [keywords, setKeywords] = useState(defaultKeywords || '');
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, [keywords]);

  useEffect(() => {
    setKeywords(defaultKeywords);
  }, [defaultValue]);

  const handleInputChange = e => {
    if (/^[a-zA-Z]*$/g.test(e.target.value)) {
      setInput(e.target.value.substring(0, maxKeywordLength)); // limit the length to maxKeywordLength
    }
  };

  const handleKeyDown = e => {
    if (
      (e.key === 'Enter' || e.key === ' ') &&
      input &&
      input.length <= maxKeywordLength &&
      keywords.length < maxKeywords
    ) {
      setKeywords([...keywords, { id: Date.now(), value: input }]);
      setInput('');
      e.preventDefault(); // Prevents the space key from being entered in the input field
    } else if (e.key === 'Backspace' && !input) {
      setKeywords(keywords.slice(0, -1));
    }
  };

  const handleRemove = id => {
    setKeywords(keywords.filter(keyword => keyword.id !== id));
  };

  const handleDragEnd = result => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.index !== destination.index) {
      const newKeywords = Array.from(keywords);
      const [removed] = newKeywords.splice(source.index, 1);
      newKeywords.splice(destination.index, 0, removed);
      setKeywords(newKeywords);
    }
  };

  // Call onKeywordsChange whenever keywords change
  useEffect(() => {
    if (onKeywordsChange) {
      onKeywordsChange(keywords.map(keyword => keyword.value)); // Pass only keyword values to the parent component
    }
  }, [keywords, onKeywordsChange]);
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="keywords" direction="horizontal">
        {provided => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            {keywords.map((keyword, index) => (
              <Draggable
                key={keyword.id}
                draggableId={keyword.id.toString()}
                index={index}
              >
                {provided => (
                  <Keyword
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <KeywordText>{keyword.value}</KeywordText>
                    <RemoveButton onClick={() => handleRemove(keyword.id)}>
                      x
                    </RemoveButton>
                  </Keyword>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <Input
              ref={inputRef}
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              disabled={keywords.length === maxKeywords} // Disable the input when there are maxKeywords keywords
            />
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { KeywordInputBar };
