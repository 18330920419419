import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from '../../../nokemon-logo.png';

const ChatContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  top: 10px;
  position: relative;
  margin-bottom: 30px;
  width: 100%;

  @media (max-width: 930px) {
    margin-top: 15px;
    margin-bottom: 20px;
  }
`;

const ProfileImage = styled.img`
  align-self: flex-start;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  margin-top: -6px;

  // If not logged in, then border-radius 0 (isLoggedIn)

  ${({ isLoggedIn }) =>
    isLoggedIn ? 'border-radius: 50%;' : 'border-radius: 0;'}
`;

const TypingText = styled.div`
  align-self: flex-start;
  font-family: 'Inter', sans-serif;
  min-height: 20px;
  width: 100%;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const TypingEffect = ({ text, onComplete, trigger }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    if (trigger) {
      setDisplayedText('');

      let index = 0;
      const timer = setInterval(() => {
        if (index < text.length) {
          const nextChar =
            index === 0 ? text.charAt(index) : text.charAt(index).toLowerCase();
          setDisplayedText(prev => prev + nextChar);
          index++;
        } else {
          clearInterval(timer);
          if (onComplete) {
            onComplete(); // Call onComplete when typing effect finishes
          }
        }
      }, 10); // Adjust typing speed here

      return () => clearInterval(timer);
    }
  }, [text, trigger]);

  return <TypingText>{displayedText}</TypingText>;
};

const ChatMessage = ({
  imageUrl,
  userPrompt,
  isLoading,
  toggleMessageCompletion,
  isLoggedIn,
}) => {
  if (userPrompt === '') {
    return <></>;
  }

  return (
    <ChatContainer>
      <ProfileImage
        src={imageUrl || Logo}
        alt="Profile"
        isLoggedIn={isLoggedIn}
      />
      <TypingEffect
        text={userPrompt}
        trigger={isLoading}
        onComplete={() => toggleMessageCompletion(true)}
      />
    </ChatContainer>
  );
};

export default ChatMessage;
