import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    background: #121212;
    color: #E0E0E0;
    line-height: 1.7;
    font-size: 16px;
  }
`;

const PrivacyPolicyWrapper = styled.div`
  max-width: 800px;
  margin: 3rem auto;
  padding: 2.5rem;
  background: #1f1f1f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
  border-radius: 15px;
`;

const Title = styled.h1`
  font-size: 2.8rem;
  color: rgb(96, 97, 235);
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
`;

const SectionTitle = styled.h2`
  font-size: 2.1rem;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  color: #e0e0e0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 0.6rem;
  transition: all 0.3s ease;
  &:hover {
    color: rgb(96, 97, 235);
    border-color: rgb(96, 97, 235);
  }
`;

const Paragraph = styled.p`
  font-size: 1.05rem;
  margin-bottom: 1.2rem;
  line-height: 1.8;
`;

const SubSectionTitle = styled.h3`
  font-size: 1.6rem;
  margin-top: 1.7rem;
  margin-bottom: 1rem;
  color: #e0e0e0;
  font-weight: 500;
`;

const List = styled.ul`
  list-style-position: inside;
  padding-left: 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.6rem;
  font-size: 1.05rem;
  line-height: 1.7;
`;

const Hyperlink = styled.a`
  color: rgb(96, 97, 235);
  text-decoration: none;
  transition: all 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }
`;

const LastRevised = styled.div`
  font-size: 0.95rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #b0b0b0; // Adjust color as needed to fit your design
`;

const PrivacyPolicyPage = () => {
  return (
    <PrivacyPolicyWrapper>
      <LastRevised>Last Revised: January 23, 2024</LastRevised>
      <Title>Privacy Policy</Title>

      <Paragraph>
        This is the Privacy Policy for NokémonAI (
        <Hyperlink href="https://nokemon.eloie.tech">
          https://nokemon.eloie.tech
        </Hyperlink>
        ), operated by Artigen Limited. This document explains our policies for
        the collection, use, and disclosure of personal information on
        NokémonAI. This privacy policy pertains to personally identifiable
        information (referred to as "data" below) that may be collected by our
        site.
      </Paragraph>

      <SectionTitle>COLLECTION OF DATA</SectionTitle>
      <Paragraph>
        As with many websites, NokémonAI may automatically receive general
        information contained in server log files, such as your cookie
        information. Additionally, we collect email addresses for communication
        purposes.
      </Paragraph>

      <SectionTitle>USE OF DATA</SectionTitle>
      <Paragraph>
        Data may be used to customize and improve your user experience on this
        site. We will make efforts to prevent your data from being made
        available to third parties unless:
      </Paragraph>
      <List>
        <ListItem>provided for otherwise in this Privacy Policy;</ListItem>
        <ListItem>
          your consent is obtained, such as when you opt-in or opt-out for the
          sharing of data;
        </ListItem>
        <ListItem>
          a service provided on our site requires interaction with or is
          provided by a third party, like an application service provider;
        </ListItem>
        <ListItem>pursuant to legal action or law enforcement;</ListItem>
        <ListItem>
          it is found that your use of this site violates our policy, terms of
          service, or other usage guidelines, or if it is deemed necessary by us
          to protect our legal rights and/or property;
        </ListItem>
        <ListItem>
          this site is purchased by a third party, in which case that third
          party will be able to use the data in the same manner as set forth in
          this policy.
        </ListItem>
      </List>

      <SectionTitle>THIRD-PARTY SERVICES</SectionTitle>
      <SubSectionTitle>Google Analytics and Google AdSense</SubSectionTitle>
      <Paragraph>
        We use Google Analytics for traffic analysis and Google AdSense for
        advertising. These services collect data as per their respective privacy
        policies.
      </Paragraph>
      <SubSectionTitle>Stripe for Payment Processing</SubSectionTitle>
      <Paragraph>
        For secure online payment processing, we use Stripe, a globally
        recognized payment gateway. Stripe handles all payment card processing
        in compliance with their stringent privacy and security standards. To
        understand how Stripe processes and protects your data, refer to the{' '}
        <a href="https://stripe.com/privacy">Stripe Privacy Policy</a>.
      </Paragraph>

      <SubSectionTitle>Third-Party Emailing Service</SubSectionTitle>
      <Paragraph>
        We utilize a third-party service for email communication. The collection
        and use of data by this service are governed by their privacy policy.
      </Paragraph>

      <SectionTitle>COOKIES</SectionTitle>
      <Paragraph>
        NokémonAI sets and uses cookies to enhance your user experience, such as
        remembering your personal settings. Advertisements, served through
        Google AdSense, may set and access cookies on your computer; these
        cookies are subject to Google's privacy policy. These advertising
        parties usually use non-personally-identifiable or anonymous codes to
        obtain information about your visits to this site.
      </Paragraph>

      <SectionTitle>ADVERTISING</SectionTitle>
      <Paragraph>
        Our site displays advertisements served by Google AdSense. Google may
        collect and use certain data for advertising purposes
      </Paragraph>
      <Paragraph>
        Please note that turning off advertising cookies won’t mean that you are
        not served any advertising, merely that it will not be tailored to your
        interests.
      </Paragraph>

      <SectionTitle>YOUR RIGHTS</SectionTitle>
      <Paragraph>
        You have certain rights regarding your personal data, including the
        right to access, correct, or delete your data. To exercise these rights,
        please contact us at{' '}
        <Hyperlink href="#">nokemon.ai@gmail.com</Hyperlink>.
      </Paragraph>
    </PrivacyPolicyWrapper>
  );
};

export default PrivacyPolicyPage;
