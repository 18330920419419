// GenerationStatus.js
import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusTitle,
  StatusCard,
  StatusText,
  StatusIndicator,
  StatusAreaContainer,
  StatusTop,
  QueueStatusIndicator,
} from '../styles/NokepointsLoader.styles.js';
import useGeneratorQueueStatus from '../hooks/useGeneratorQueueStatus';

const GenerationStatus = ({
  lastComplete,
  lastPending,
  beamStatus,
  queuePosition,
}) => {
  const { generatorStatus, queueStatus } = useGeneratorQueueStatus(
    lastComplete,
    lastPending,
    beamStatus,
    queuePosition,
  );

  return (
    <StatusAreaContainer>
      <StatusCard>
        <StatusTop>
          <StatusIndicator status={generatorStatus} />
          <StatusTitle>Generator Status</StatusTitle>
        </StatusTop>
        <StatusText>{generatorStatus}</StatusText>
      </StatusCard>
      <StatusCard>
        <StatusTop>
          <QueueStatusIndicator position={queuePosition} />
          <StatusTitle>Queue Status</StatusTitle>
        </StatusTop>
        <StatusText>
          {queuePosition !== null && queuePosition !== 0
            ? `Position: ${queuePosition}`
            : 'Determining Queue Position...'}
        </StatusText>
      </StatusCard>
    </StatusAreaContainer>
  );
};

GenerationStatus.propTypes = {
  lastComplete: PropTypes.string,
  lastPending: PropTypes.string,
  beamStatus: PropTypes.string,
  queuePosition: PropTypes.number,
};

export default GenerationStatus;
