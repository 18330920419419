// useFetchNokemon.js
import { useState, useEffect, useCallback } from 'react';
import { fetchRandomNokemon } from '../api/fetchRandomNokemon';

export const useFetchRandomNokemon = (
  isVisible,
  beamStatus,
  queuePosition,
  generationSessionUUID,
) => {
  const [nokemon, setNokemon] = useState({ image: null, text: '' });

  const fetchData = useCallback(async () => {
    if (!queuePosition) return;
    if (!generationSessionUUID) return;

    try {
      const nokemonData = await fetchRandomNokemon(generationSessionUUID);
      setNokemon(nokemonData);
    } catch (error) {
      console.error('Error fetching Nokemon data:', error);
    }
  }, [queuePosition, generationSessionUUID]);

  useEffect(() => {
    let interval;
    if (isVisible && beamStatus !== 'COMPLETE') {
      fetchData(); // Fetch immediately on becoming visible and not complete
      interval = setInterval(fetchData, 10000); // Fetch every 10 seconds
    }

    return () => {
      if (interval) clearInterval(interval); // Clear interval on unmount or condition change
    };
  }, [isVisible, beamStatus, fetchData]);

  return nokemon;
};
