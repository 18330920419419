import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useUserContext } from '../../../context/UserContext';
import { recordUserInteraction } from '../../../utils/userInteractionsLogger/userInteractionLogger';

// Helper function to get the JWT from localStorage
const getJwtFromLocalStorage = () => localStorage.getItem('jwt');

// Function to call the Stripe checkout session creation endpoint
export const createStripeCheckoutSession = async (
  productIndex,
  hasFreeTrialCoupon = false,
) => {
  const jwt = getJwtFromLocalStorage();
  if (!jwt) {
    toast.error('You must be logged in to purchase products.', {
      type: 'error',
    });
    throw new Error('Authorization token not found.');
  }

  // Toast popup to say validating details

  toast(
    <div>
      <FontAwesomeIcon icon={faSpinner} spin /> &nbsp;Validating details
    </div>,
  );

  const response = await fetch('/api/stripe/create-checkout-session', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${jwt}`,
    },
    body: JSON.stringify({
      index: productIndex,
      cookie: jwt,
      hasFreeTrialCoupon,
    }),
    credentials: 'include',
  });

  if (!response.ok) {
    if (response.status === 400) {
      await response.json().then(async data => {
        if (data.reason === 'redirectToSubscriptionManagement') {
          toast(
            <div>
              <FontAwesomeIcon icon={faSpinner} spin /> &nbsp;{data.message}
            </div>,
          );

          const res = await fetch('/api/stripe/portalSession', {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
              cookie: localStorage.getItem('jwt'),
            }),
          });

          if (res.status !== 303) {
            toast.error('Failed to redirect, try again later!');
            return;
          }

          const body = await res.json();
          window.location.href = body.portalSessionUrl;

          return;
        }
      });
    }
  }

  return response.json();
};

const colors = {
  brightPurple: '#6060eb', // Bright purple for highlights
  darkBackground: '#101926', // Background color for the card
  white: '#ffffff', // White color for text
  lightGrey: '#abb2bf', // Light grey for feature list
  darkGrey: '#282c34', // Dark grey for the card content background
};

const BuyButton = styled.button`
  background-color: ${colors.brightPurple};
  color: ${colors.white};
  font-size: calc(0.6rem + 0.3vw); // Responsive font size
  border: none;
  border-radius: 5px;
  padding: 1rem 2rem;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, transform 0.3s ease;
  width: 100%;
  margin-bottom: 1rem;
  font-weight: 600;

  @media (max-width: 1215px) {
    font-size: calc(0.6rem + 0.25vw); // Smaller increment below 1215px
  }
  @media (max-width: 1110px) {
    font-size: 0.9rem;
  }
`;

// Your plan component
export const ProductButton = ({ title, productIndex }) => {
  const userContext = useUserContext();

  const handleBuyPlanClick = async () => {
    try {
      if (userContext.isLoggedIn && userContext.userInfo.emailHash) {
        recordUserInteraction(userContext.userInfo.emailHash, 'Click Product', {
          specificAction: title,
          timestamp: new Date(),
          productIndex: productIndex,
        });
      }

      const { url } = await createStripeCheckoutSession(productIndex);

      // Redirect to Stripe checkout using the URL provided by your backend
      window.location.href = url;
    } catch (error) {
      console.error('Error during Stripe checkout redirect:', error);
    }
  };

  return <BuyButton onClick={handleBuyPlanClick}>{title}</BuyButton>;
};
