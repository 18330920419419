// SuggestionsArea.js
import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faDice,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Dropdown from './Dropdown.js';

const SuggestionsContainer = styled.div`
  display: grid;
  justify-content: center; // Center the items in the container
  position: relative;

  margin: 0 auto;
  padding-bottom: 20px;
  gap: 5px;
  width: 100%;

  // Base layout for small devices
  grid-template-columns: 1fr; // Single column layout for small devices

  // Responsive adjustments for wider screens
  @media (min-width: 768px) {
    grid-template-columns: repeat(
      2,
      minmax(0, 560px)
    ); // Two columns with a max width
    justify-content: space-evenly; // Evenly space the columns
  }
  @media (min-width: 1200px) {
    padding: 0; // Remove padding for very large screens
    padding-bottom: 20px;
  }

  @media (max-height: 900px) {
    padding-bottom: 8px;
  }
`;

const UploadButton = styled.button`
  position: absolute;
  right: 0; // Align to the right side of the Suggestion component
  top: 50%; // Optionally center vertically inside the Suggestion
  transform: translateY(-50%); // Adjust for vertical centering

  background-color: #111;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
  width: 25px;
  height: 25px;
  margin-right: 15px;

  font-size: 12px;

  opacity: 0;
  visibility: hidden;

  cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
`;

const popIn = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  70% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Suggestion = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // Add padding to the right if the UploadButton is inside the Suggestion
  padding: 0px 20px 0px 20px;
  width: 98.5%;

  height: 75px;
  background-color: transparent;
  border: 2px solid #444c56;
  padding: 0px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 13.5px;

  ${({ shouldLoad, index }) =>
    shouldLoad &&
    css`
      animation: ${popIn} 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
      animation-delay: ${index * 0.2}s;
    `};

  opacity: ${({ shouldLoad }) => (shouldLoad ? 1 : 0)};
  transition: opacity 0.2s
    ${({ shouldLoad }) => (shouldLoad ? 'ease-in' : 'ease-out')};

  transition-delay: ${({ index, shouldLoad }) =>
    shouldLoad ? `${index * 0.2}s` : `${index * 0.2}s`};

  cursor: ${({ shouldLoad }) => (shouldLoad ? 'pointer' : 'default')};
  pointer-events: ${({ shouldLoad }) => (shouldLoad ? 'auto' : 'none')};

  // display: ${({ shouldLoad }) => (shouldLoad ? 'flex' : 'none')};

  &:hover {
    background-color: ${({ isLoading }) =>
      isLoading ? 'transparent' : 'rgba(255, 255, 255, 0.1)'};

    border-color: #6061eb;
    ${UploadButton} {
      opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
      visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
    }
  }

  // Add any additional styling you need for the Suggestion
  @media (max-width: 768px) {
    height: 60px;
    padding: 0px 20px;
    margin: 5px;
    border-radius: 5px;
    font-size: 12px;
  }
`;

const SuggestionText = styled.p`
  font-family: 'Inter', sans-serif;
  color: white;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SuggestionTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // Add any additional styling you need for the container
`;

const TypeText = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 700; /* Increased font weight for better emphasis */
  font-size: 1.1em; /* Slightly larger font size */
  color: white;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  text-align: left;
`;

const DescriptionText = styled.span`
  text-align: left;
  font-family: 'Inter', sans-serif;
  color: #c2c2c2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.h1`
  // Subtle and modern style adjustments
  width: 100%;
  font-family: 'Inter', sans-serif; // 'Inter' is a good choice for modern UI
  font-weight: 500; // Reduced weight for subtlety
  font-size: 1.1em; // Smaller size for less dominance
  color: #d1d4d8; // Soft white for less contrast against a dark background
  margin-bottom: 12px; // Reduced space to bring content closer together
  letter-spacing: 0.02em; // Slight spacing increase for readability
  opacity: 0.9; // Slightly transparent to blend with the background
  text-transform: uppercase; // Optional: capitalize to maintain a design presence
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); // Soft shadow for depth
  text-align: ${({ modelVersion }) =>
    modelVersion === 'modelv3' ? 'left' : 'center'};

  transition: opacity 0.2s;

  @media (max-width: 768px) {
    text-align: ${({ modelVersion }) =>
      modelVersion === 'modelv3' ? 'left' : 'center'};
  }

  @media (max-height: 900px) {
    font-size: 1em;
    margin-bottom: 6px;
  }
  // @media (max-height: 750px) {
  //   display: none;
  // }
`;

// Skeleton Placeholder Styling
const Skeleton = styled.div`
  cursor: ${({ shouldLoad, modelVersion }) =>
    shouldLoad && modelVersion === 'modelv3' ? 'pointer' : 'default'};
  width: 98.5%;
  height: 75px;
  background-color: #2c2f33;
  border-radius: 5px;
  margin: 5px;
  display: flex;

  @media (max-width: 768px) {
    height: 50px;
    background-color: transparent;
  }

  ${({ shouldLoad, index }) =>
    shouldLoad &&
    css`
      animation: ${popIn} 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
      animation-delay: ${index * 0.2}s;
    `};

  opacity: ${({ shouldLoad }) => (shouldLoad ? 1 : 0)};
  transition: opacity 0.2s
    ${({ shouldLoad }) => (shouldLoad ? 'ease-in' : 'ease-out')};

  transition-delay: ${({ index, shouldLoad }) =>
    shouldLoad ? `${index * 0.2}s` : `${index * 0.2}s`};

  pointer-events: ${({ shouldLoad }) => (shouldLoad ? 'auto' : 'none')};

  &:hover {
    background-color: ${({ isLoading }) =>
      isLoading ? 'transparent' : 'rgba(255, 255, 255, 0.1)'};

    border-color: #6061eb;
    ${UploadButton} {
      opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
      visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
    }
  }
`;

function SuggestionTextDisplay({ suggestion }) {
  // Function to transform the text
  const transformText = text => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  function extractTypeAndDescription(suggestion) {
    // Regular expression to match the pattern "word/word type" or "word type"
    const typeRegex = /(\w+(\/\w+)? type)/;

    // Extract the type
    const typeMatch = suggestion.match(typeRegex);

    // If type is found, extract and clean it up
    let type = typeMatch ? typeMatch[0].replace(' type', '') : 'Unknown Type';
    type = type[0].toUpperCase() + type.slice(1);

    let description = suggestion.replace(typeMatch[0] + ', ', ''); // Assuming suggestion contains '{word1}/{word2}' or 'word1'
    description = transformText(description);

    // Split the description by '/'
    let words = type.split('/');
    let Word1 = words[0].charAt(0).toUpperCase() + words[0].slice(1).trim();
    let Word2 =
      words.length > 1
        ? words[1].charAt(0).toUpperCase() + words[1].slice(1).trim()
        : '';

    // Create the new formatted string
    type = words.length > 1 ? `${Word1} / ${Word2}` : Word1;

    return { type, description };
  }
  const { type, description } = extractTypeAndDescription(suggestion);

  return (
    <SuggestionTextContainer>
      <TypeText>{type}</TypeText>
      <DescriptionText>{description}</DescriptionText>
    </SuggestionTextContainer>
  );
}

const typeColors = {
  bug: '#92bc2c',
  dark: '#595761',
  dragon: '#0c69c8',
  electric: '#f2d94e',
  fire: '#fba54c',
  fairy: '#ee90e6',
  fighting: '#d3425f',
  flying: '#a1bbec',
  ghost: '#5f6dbc',
  grass: '#5fbd58',
  ground: '#da7c4d',
  ice: '#75d0c1',
  normal: '#a0a29f',
  poison: '#b763cf',
  psychic: '#fa8581',
  rock: '#c9bb8a',
  steel: '#5695a3',
  water: '#539ddf',
  legendary: '#e60073',
  shiny: 'gold',
};

// RoundButtonContainer is used to create an overlay effect
const RoundButtonContainer = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: ${({ shouldLoad }) => (!shouldLoad ? 0 : 0.9)};
  display: ${({ shouldLoad }) => (shouldLoad ? 'flex' : 'none')};
`;

const RoundButton = styled.button`
  background: #181b1a; // Modern gradient
  color: #ffffff; // White text for contrast and visibility
  border: 2px solid ${({ borderColor }) => borderColor || '#9677ff'};
  border-radius: ${({ modelVersion }) =>
    modelVersion === 'modelv3' ? '50%' : '8px'};
  width: ${({ modelVersion }) =>
    modelVersion === 'modelv3' ? '55px' : '220px'};
  height: ${({ modelVersion }) =>
    modelVersion === 'modelv3' ? '55px' : '50px'};
  font-size: ${({ modelVersion }) =>
    modelVersion === 'modelv3' ? '2rem' : '1.2rem'};
  font-weight: bold;
  cursor: ${({ modelVersion }) =>
    modelVersion === 'modelv3' ? 'pointer' : 'not-allowed'};
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4),
    // Deep shadow for a 3D effect
    inset 0 2px 4px rgba(255, 255, 255, 0.2); // Subtle inner glow for depth
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  @media (max-width: 768px) {
    // height: 50px;
    font-size: 1.5rem;
  }

  &:hover {
    background: ${({ borderColor }) => borderColor || '#9677ff'};
    box-shadow: 0 10px 25px
      ${({ borderColor }) => borderColor || 'rgba(128, 76, 252, 0.5)'};
    transform: translate(-50%, -50%) scale(1.05);
  }

  &:active {
    background: ${({ borderColor }) => borderColor || '#9677ff'};
    box-shadow: 0 5px 15px
      ${({ borderColor }) => borderColor || 'rgba(128, 76, 252, 0.7)'};
    transform: translate(-50%, -50%) scale(0.95);
  }

  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;

  // Accessibility improvements
  aria-label: 'Learn more'; // Provides context for screen readers
`;

const TitleContainer = styled.div`
  // opacity: ${({ shouldLoad }) => (!shouldLoad ? 0 : 0.9)};
  display: ${({ shouldLoad }) => (shouldLoad ? 'flex' : 'none')};
  flex-direction: ${({ modelVersion }) =>
    modelVersion === 'modelv3' ? 'row' : 'column'};

  align-items: ${({ modelVersion }) =>
    modelVersion === 'modelv3' ? '' : 'center'};

  margin: ${({ modelVersion }) =>
    modelVersion === 'modelv3' ? '0px' : '10px 0'};
`;

const types = [
  { value: '', label: 'All Types', color: '#9677ff' },
  { value: 'normal', label: 'Normal', color: '#A8A77A' },
  { value: 'fire', label: 'Fire', color: '#EE8130' },
  { value: 'water', label: 'Water', color: '#6390F0' },
  { value: 'electric', label: 'Electric', color: '#F7D02C' },
  { value: 'grass', label: 'Grass', color: '#7AC74C' },
  { value: 'ice', label: 'Ice', color: '#96D9D6' },
  { value: 'fighting', label: 'Fighting', color: '#C22E28' },
  { value: 'poison', label: 'Poison', color: '#A33EA1' },
  { value: 'ground', label: 'Ground', color: '#E2BF65' },
  { value: 'flying', label: 'Flying', color: '#A98FF3' },
  { value: 'psychic', label: 'Psychic', color: '#F95587' },
  { value: 'bug', label: 'Bug', color: '#A6B91A' },
  { value: 'rock', label: 'Rock', color: '#B6A136' },
  { value: 'ghost', label: 'Ghost', color: '#735797' },
  { value: 'dragon', label: 'Dragon', color: '#6F35FC' },
  { value: 'dark', label: 'Dark', color: '#705746' },
  { value: 'steel', label: 'Steel', color: '#B7B7CE' },
  { value: 'fairy', label: 'Fairy', color: '#D685AD' },
];

const SuggestionsArea = ({
  suggestions,
  onSuggestionClick,
  onSuggestionHover,
  onSelectedType,
  onGenerate,
  isLoading,
  isAllowedToGenerate,
  useCredits,
  dailyGenerationsLeft,
  isLoggedIn,
  selectedType,
  modelVersion,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [suggestionsToShow, setSuggestionsToShow] = useState([]);
  const [numberOfSuggestionsToShow, setNumberOfSuggestionsToShow] = useState(4);
  const [clickable, setClickable] = useState(true);

  useEffect(() => {
    // Set a timeout to make the component visible after 3 seconds
    const initialTimeout = setTimeout(() => {
      setIsVisible(true);
    }, 2100);

    return () => clearTimeout(initialTimeout);
  }, []);

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        if (suggestions) {
          setSuggestionsToShow(suggestions.slice(0, 2)); // Limit to 3 suggestions
        }

        setNumberOfSuggestionsToShow(2);
      } else {
        setSuggestionsToShow(suggestions);
        setNumberOfSuggestionsToShow(4);
      }
    }

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Initial setup
    handleResize();

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [suggestions]);

  function handleSuggestionClick(suggestion) {
    // Check for logged in user
    if (isLoggedIn) {
      const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));

      if (userInfoFromStorage.currencyValue <= 0 && useCredits) {
        toast.error(
          "Oops, you're out of Credits! Grab more to continue your journey.",
        );
        return;
      }
      if (userInfoFromStorage.currencyValueFree <= 0 && !useCredits) {
        toast.error(
          "Reached today's limit. Subscribe for additional daily generations!",
        );
        return;
      }

      // If dailyGenerationsLeft is a number, proceed with the check
      if (typeof dailyGenerationsLeft !== 'number') {
        return;
      }
    }

    // The rest of the function for both logged-in and non-logged-in users
    if (!isLoading && clickable) {
      onSuggestionClick(suggestion);
      onSuggestionHover('Describe your Fakémon design...');

      if (!useCredits) {
        onGenerate(suggestion);

        setClickable(false);
        // Disable the clickable state for a certain time after a click
        setTimeout(() => setClickable(true), 3000); // Re-enable after 3 seconds
      } else {
      }
    }
  }

  useEffect(() => {
    setSelectedOption(types.find(type => type.value === selectedType));
  }, [selectedType]);

  const [selectedOption, setSelectedOption] = useState(types[0]);

  const handleTypeChange = selectedOption => {
    setSelectedOption(selectedOption);
    onSelectedType(selectedOption.value);
  };

  return (
    <>
      <TitleContainer
        shouldLoad={isVisible && !isLoading}
        modelVersion={modelVersion}
      >
        {modelVersion === 'modelv3' ? (
          <Title modelVersion={modelVersion}>AI Quick Picks</Title>
        ) : (
          <Title modelVersion={modelVersion}>Choose a type</Title>
        )}
        <Dropdown
          options={types}
          selectedOption={selectedOption}
          onChange={handleTypeChange}
          width={modelVersion === 'modelv3' ? 'auto' : '150px'}
        />
      </TitleContainer>
      <SuggestionsContainer>
        {
          suggestionsToShow && modelVersion === 'modelv3'
            ? // Display Suggestions
              suggestionsToShow.map((suggestion, index) => (
                <Suggestion
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  onMouseOver={() =>
                    !isLoading && onSuggestionHover(suggestion)
                  }
                  onMouseOut={() =>
                    !isLoading &&
                    onSuggestionHover('Describe your Fakémon design...')
                  }
                  shouldLoad={isVisible && !isLoading}
                  index={index}
                  isLoading={isLoading || !clickable} // Pass the combined loading state and clickable state
                >
                  <SuggestionTextDisplay suggestion={suggestion} />
                  <UploadButton isLoading={isLoading || !clickable}>
                    <FontAwesomeIcon icon={faArrowUp} className="fa-icon" />
                  </UploadButton>
                </Suggestion>
              ))
            : isVisible
            ? // Display Skeletons
              new Array(numberOfSuggestionsToShow)
                .fill()
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    index={index}
                    shouldLoad={!isLoading && isVisible}
                    isLoading={isLoading || !clickable}
                    modelVersion={modelVersion}
                  />
                ))
            : // Optionally, display nothing or a placeholder message
              null // or <PlaceholderMessage />
        }
        <RoundButtonComponent
          isVisible={isVisible}
          isLoading={isLoading}
          useCredits={useCredits}
          selectedOption={selectedOption}
          selectedType={selectedType}
          modelVersion={modelVersion}
          onGenerate={onGenerate}
        />
      </SuggestionsContainer>
    </>
  );
};

const GenerateButtonTitle = styled.div`
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  color: white;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: center;
`;
const RoundButtonComponent = ({
  isVisible,
  isLoading,
  useCredits,
  selectedType,
  selectedOption,
  modelVersion,
  onGenerate,
}) => {
  const [clickable, setClickable] = useState(true);

  return (
    <RoundButtonContainer shouldLoad={isVisible && !isLoading && !useCredits}>
      <RoundButton
        onClick={() => {
          if (clickable) {
            // Check if the button is currently clickable
            onGenerate(selectedType, 'random');
            setClickable(false); // Disable the button immediately after click
            setTimeout(() => {
              setClickable(true); // Re-enable the button after 5 seconds
            }, 5000); // 5000 milliseconds = 5 seconds
          }
        }}
        borderColor={selectedOption.color}
        modelVersion={modelVersion}
        disabled={!clickable} // Optionally use the disabled attribute for visual feedback
        style={{
          opacity: clickable ? 1 : 0.5, // Change opacity when not clickable for visual feedback
          cursor: clickable ? 'pointer' : 'not-allowed', // Change cursor style
        }}
      >
        {modelVersion === 'modelv3' ? (
          <FontAwesomeIcon icon={faQuestion} className="fa-icon" />
        ) : (
          <GenerateButtonTitle>Generate!</GenerateButtonTitle>
        )}
      </RoundButton>
    </RoundButtonContainer>
  );
};
export default SuggestionsArea;
