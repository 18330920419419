import React, { useState } from 'react';
import styled from 'styled-components';
import { FaSignInAlt } from 'react-icons/fa';
import { GoogleLoginIcon } from '../../GoogleLogin';
import LoginModal from '../../LoginModal/LoginModal';

// Styled components
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const Button = styled.button`
  background: #333645;
  border: 1px solid #474a5d;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  color: #ffffff;
  font-size: 0.9em;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #3d404f;
    color: #f0f0f0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.35);
    border-color: #6305dc;
  }

  &:focus {
    outline: none;
    border-color: #5c5a7d;
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  }
`;

const SignInIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignInLabel = styled.span`
  display: inline-block;
  transition: transform 0.2s ease;
  font-weight: 600;
`;

// SignInButton component
const SignInButton = ({ userContext }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalCenterVisible, setModalCenterVisible] = useState(true);

  const handleLoginClick = () => {
    setModalVisible(true);
    setModalCenterVisible(true);
  };

  const handleLoading = () => {
    setModalCenterVisible(false);
  };

  const handleCloseModal = () => {
    setModalCenterVisible(false);
    setModalVisible(false);
  };

  return (
    <>
      <ButtonWrapper onClick={handleLoginClick}>
        <Button>
          <SignInIconWrapper>
            <FaSignInAlt size="1em" />
          </SignInIconWrapper>
          <SignInLabel>Sign In</SignInLabel>
        </Button>
      </ButtonWrapper>
      {isModalVisible && (
        <LoginModal
          isVisible={isModalVisible}
          isModalCenterVisible={isModalCenterVisible}
          onLoginSuccess={handleLoading}
          onLoginFailure={handleCloseModal}
          userContext={userContext}
        />
      )}
    </>
  );
};

export default SignInButton;
