import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    background: #121212;
    color: #E0E0E0;
    line-height: 1.7;
    font-size: 16px;
  }
`;

const ToSWrapper = styled.div`
  max-width: 800px;
  margin: 3rem auto;
  padding: 2.5rem;
  background: #1f1f1f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
  border-radius: 15px;
`;

const Title = styled.h1`
  font-size: 2.8rem;
  color: rgb(96, 97, 235);
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
`;

const SectionTitle = styled.h2`
  font-size: 2.1rem;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  color: #e0e0e0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 0.6rem;
  transition: all 0.3s ease;
  &:hover {
    color: rgb(96, 97, 235);
    border-color: rgb(96, 97, 235);
  }
`;

const Paragraph = styled.p`
  font-size: 1.05rem;
  margin-bottom: 1.2rem;
  line-height: 1.8;
`;

const SubSectionTitle = styled.h3`
  font-size: 1.6rem;
  margin-top: 1.7rem;
  margin-bottom: 1rem;
  color: #e0e0e0;
  font-weight: 500;
`;

const List = styled.ul`
  list-style-position: inside;
  padding-left: 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.6rem;
  font-size: 1.05rem;
  line-height: 1.7;
`;

const Hyperlink = styled.a`
  color: rgb(96, 97, 235);
  text-decoration: none;
  transition: all 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }
`;

const LastRevised = styled.div`
  font-size: 0.95rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #b0b0b0; // Adjust color as needed to fit your design
`;

const TermsOfServicePage = () => {
  return (
    <>
      <GlobalStyle />
      <ToSWrapper>
        <LastRevised>Last Revised: January 23, 2024</LastRevised>
        <Title>Terms of Service Agreement</Title>
        <Paragraph>
          This Terms of Service Agreement ("Agreement"), made and entered into
          by and between the user ("you," "your," "User," or "Customer") and
          Artigen Limited ("Artigen," "we," "us," "our"), governs your use of
          the NokémonAI website{' '}
          <Hyperlink href="https://nokemon.eloie.tech/">
            https://nokemon.eloie.tech/
          </Hyperlink>{' '}
          ("Site") and any related products or services (collectively,
          "Services"). This Agreement becomes effective upon your use of the
          Site or your electronic acceptance of these terms.
        </Paragraph>
        <Paragraph>
          By accessing, browsing, or using the Services, you acknowledge,
          understand, and agree to be legally bound by this Agreement, including
          the Privacy Policy{' '}
          <Hyperlink href="https://nokemon.eloie.tech/privacy-policy">
            https://nokemon.eloie.tech/privacy-policy
          </Hyperlink>
          . Artigen Limited reserves the right to unilaterally amend or modify
          this Agreement and any incorporated policies at any time. Such changes
          will be effective immediately upon posting on the Site, and your
          continued use of the Site or Services following any changes
          constitutes acceptance of the revised Agreement.
        </Paragraph>
        <SectionTitle>Service</SectionTitle>
        <SubSectionTitle>NokémonAI</SubSectionTitle>
        <Paragraph>
          NokémonAI, provided by Artigen Limited, offers a Software as a Service
          (SaaS) platform for AI-driven digital image generation. The service
          allows users to create digital images utilizing AI algorithms,
          supported by our computing resources and infrastructure, thereby
          obviating the need for complex software installations or hardware
          configurations.
        </Paragraph>
        <SubSectionTitle>Access to the Service</SubSectionTitle>
        <List>
          <ListItem>
            Web Interface: The service is accessible through a user-friendly web
            interface, governed by this Agreement.
          </ListItem>
        </List>
        <SubSectionTitle>
          User Acknowledgment and Responsibilities
        </SubSectionTitle>
        <List>
          <ListItem>
            We endeavor to ensure accuracy and quality in our AI-generated
            outputs, but NokémonAI does not guarantee the accuracy or
            completeness of these results. Users bear the responsibility to
            validate and act upon these results based on individual assessments
            and needs.
          </ListItem>
        </List>
        <SubSectionTitle>Subscription and Billing</SubSectionTitle>
        <List>
          <ListItem>
            Subscription Tiers: NokémonAI offers various subscription tiers with
            distinct levels of access and functionalities. The specifics of each
            tier, including services and pricing, are detailed during the
            subscription process.
          </ListItem>
          <ListItem>
            Credit-Based Billing: Our service operates on a credit-based model.
            Users purchase credits corresponding to computational resources used
            for image generation, with transparent communication regarding
            credit usage and consumption rates.
          </ListItem>
          <ListItem>
            Billing and Cancellation Policy: Subscription fees are based on the
            selected plan. Users can cancel their subscription in line with the
            cancellation terms in their account settings. We reserve the right
            to modify subscription fees or billing terms, providing users with a
            minimum notice period of 30 days.
          </ListItem>
        </List>
        <SectionTitle>Eligibility and User Agreement</SectionTitle>
        <SubSectionTitle>Access to NokémonAI Services</SubSectionTitle>
        <List>
          <ListItem>
            NokémonAI's website and services are accessible only to those
            capable of forming legally binding contracts under UK law and other
            applicable jurisdictions. By using the Site or Services, you
            represent and warrant that you meet the following criteria:
          </ListItem>
          <ListItem>
            Age Requirement: You are at least eighteen (18) years old, in
            compliance with UK legal age requirements for binding agreements.
          </ListItem>
          <ListItem>
            Legal Capacity: You have the legal capacity to form binding
            contracts.
          </ListItem>
          <ListItem>
            Compliance with Laws: Your use of the Services is not prohibited
            under UK law or any other applicable jurisdiction.
          </ListItem>
        </List>

        <SubSectionTitle>Corporate Use and Representation</SubSectionTitle>
        <List>
          <ListItem>
            If accessing NokémonAI on behalf of a corporate entity, you affirm
            you are authorized to bind the entity to this Agreement. In the
            event it is later found that you lacked such authority, you shall be
            personally accountable for the obligations in this Agreement.
          </ListItem>
        </List>

        <SectionTitle>Legal Compliance and Export Control</SectionTitle>
        <Paragraph>
          Users are responsible for complying with all local, national, and
          international laws and regulations applicable to their use of the
          Service, including export control laws and regulations.
        </Paragraph>

        <SectionTitle>Rules of User Conduct</SectionTitle>
        <SubSectionTitle>Compliance and Responsibility</SubSectionTitle>
        <Paragraph>
          Your use of NokémonAI must comply with this Agreement and all
          applicable laws.
        </Paragraph>

        <SubSectionTitle>Prohibited Activities</SubSectionTitle>
        <List>
          <ListItem>
            You shall not engage in illegal activities, exploitation, violence
            and terrorism, spam and malicious conduct, intellectual property
            infringement, privacy and confidentiality violations, interference
            with the operation of the platform, or introduce harmful code.
          </ListItem>
        </List>

        <SubSectionTitle>Restrictions on Use</SubSectionTitle>
        <Paragraph>
          You shall not copy, modify, or access any content or user data through
          unauthorized means.
        </Paragraph>
        <Paragraph>
          Violation of these rules may result in suspension or termination of
          your access, along with legal action to protect the rights and safety
          of others.
        </Paragraph>

        <SectionTitle>Intellectual Property</SectionTitle>
        <SubSectionTitle>Ownership and Rights</SubSectionTitle>
        <List>
          <ListItem>
            NokémonAI Content: The content on NokémonAI ("Companies Content") is
            owned or licensed to Artigen Limited and protected under
            intellectual property laws including without limitation the text,
            software, scripts, source code, API, graphics, photos, sounds,
            music, videos and interactive features and the trademarks, service
            marks and logos contained therein ("Companies Content"), are owned
            by or licensed to Artigen Limited in perpetuity, and are subject to
            copyright, trademark, and/or patent protection.
          </ListItem>
          <ListItem>
            Usage Restrictions: NokémonAI Content is for your personal use and
            information. Unauthorized exploitation of any NokémonAI Content is
            strictly prohibited.
          </ListItem>
          <ListItem>
            No Implied Licenses: This Agreement does not grant you any
            intellectual property rights over NokémonAI Content or any Artigen
            Limited property.
          </ListItem>
        </List>

        <SectionTitle>Use of Cookies and Analytics</SectionTitle>
        <Paragraph>
          Cookies Utilization: NokémonAI uses cookies to enhance user
          experience, maintain user preferences, and ensure efficient
          functioning of the service. By using NokémonAI, you consent to the use
          of cookies in accordance with our Cookie Policy.
        </Paragraph>
        <Paragraph>
          Google Analytics: We employ Google Analytics for gathering anonymized
          data regarding site usage and user interaction to improve our
          services. This data collection is subject to Google’s privacy policy.
        </Paragraph>
        <Paragraph>
          Advertising Cookies: Cookies may also be used in relation to
          advertising services, including but not limited to, targeting and
          optimizing ad relevance for users. Users can opt out of personalized
          advertising through their browser settings.
        </Paragraph>

        <SectionTitle>Data Privacy and Protection</SectionTitle>
        <Paragraph>
          Our Service collects and uses personal data in accordance with our
          Privacy Policy. We take appropriate measures to protect your data
          against unauthorized access, alteration, disclosure, or destruction.
        </Paragraph>

        <SectionTitle>
          Use of Third-Party Services for Email Communication
        </SectionTitle>
        <Paragraph>
          Engagement of Third-Party Services: NokémonAI utilizes third-party
          service providers for various functionalities, including but not
          limited to, the facilitation of email communications.
        </Paragraph>
        <Paragraph>
          These services are employed to manage and send emails to our users,
          such as newsletters, service updates, promotional materials, and other
          communications related to NokémonAI.
        </Paragraph>
        <Paragraph>
          User Consent: By using NokémonAI, you acknowledge and consent to the
          transfer and processing of your data by these third-party service
          providers for the purposes outlined above.
        </Paragraph>

        <SectionTitle>
          Disclaimer of Representations and Warranties
        </SectionTitle>
        <SubSectionTitle>
          Acknowledgement of Risks and Service Nature
        </SubSectionTitle>
        <List>
          <ListItem>
            Use at Own Risk: You acknowledge that your use of NokémonAI and its
            services is entirely at your own risk. The service is provided "as
            is," "as available," and with all faults, without warranties of any
            kind.
          </ListItem>
          <ListItem>
            No Implied Warranties: Artigen Limited expressly disclaims all
            warranties, both statutory and implied, including but not limited to
            warranties of title, merchantability, fitness for a particular
            purpose, and non-infringement.
          </ListItem>
          <ListItem>
            No Warranties for Third-Party Links: Artigen Limited does not
            warrant the safety, reliability, accuracy, or legality of any
            third-party websites or advertisements accessed through links on
            NokémonAI. Users assume all risks associated with accessing
            third-party content, including, but not limited to, viruses, worms,
            and malicious software.
          </ListItem>
        </List>
        <SectionTitle>Limitation of Liability</SectionTitle>
        <SubSectionTitle>Scope of Liability Limitation</SubSectionTitle>
        <List>
          <ListItem>
            Exclusion of Certain Damages: Artigen Limited is not liable for any
            direct, indirect, incidental, special, punitive, or consequential
            damages.
          </ListItem>
          <ListItem>
            Specific Exclusions: This includes, but is not limited to, damages
            related to content accuracy, personal injury, third-party conduct,
            service interruption, harmful elements, objectionable content, and
            losses incurred using NokémonAI.
          </ListItem>
          <ListItem>
            Exclusion of Damages from External Content: In no event shall
            Artigen Limited be liable for any damages resulting from content,
            advertisements, or services provided by third parties, even if
            accessed through NokémonAI.
          </ListItem>
          <ListItem>
            Limitation on Liability for Malicious Code: Artigen Limited shall
            not be held liable for any damages or losses resulting from viruses,
            worms, Trojan horses, or any other malicious code that may be
            transmitted through the use of NokémonAI, irrespective of the source
            of such code.
          </ListItem>
          <ListItem>
            Statutory Limits: The limitations of liability are subject to all
            applicable laws and do not affect any statutory rights that cannot
            be excluded or limited under applicable law.
          </ListItem>
        </List>

        <SubSectionTitle>Time Limitation for Claims</SubSectionTitle>
        <List>
          <ListItem>
            Claims Deadline: Any action related to NokémonAI must begin within
            one (1) year after the cause of action accrues, or it is permanently
            barred.
          </ListItem>
        </List>

        <SectionTitle>Indemnity</SectionTitle>
        <SubSectionTitle>Obligation to Indemnify</SubSectionTitle>
        <List>
          <ListItem>
            Scope of Indemnity: You agree to defend, indemnify, and hold
            harmless Artigen Limited from claims, costs, and damages arising
            from your use of NokémonAI, Agreement violations, or infringement of
            third-party rights.
          </ListItem>
        </List>

        <SubSectionTitle>Survival of Obligations</SubSectionTitle>
        <Paragraph>
          The indemnity obligations survive the termination of this Agreement.
        </Paragraph>

        <SectionTitle>Data Transfer</SectionTitle>
        <SubSectionTitle>Cross-Border Data Transmission</SubSectionTitle>
        <List>
          <ListItem>
            International Data Transfers: Accessing NokémonAI from outside our
            server locations involves international data transfers.
          </ListItem>
          <ListItem>
            Consent to Data Transfer: By using NokémonAI, you consent to such
            data transfers.
          </ListItem>
        </List>

        <SectionTitle>Availability of Website</SectionTitle>
        <SubSectionTitle>Service Availability</SubSectionTitle>
        <List>
          <ListItem>
            Effort for Continuous Access: Artigen Limited strives to maintain
            NokémonAI's availability, subject to this Agreement.
          </ListItem>
          <ListItem>
            Potential Downtime: Service may be affected by maintenance, external
            factors, or technical failures, with limited liability for such
            unavailability.
          </ListItem>
        </List>

        <SectionTitle>Discontinued Services</SectionTitle>
        <SubSectionTitle>Service Discontinuation Rights</SubSectionTitle>
        <List>
          <ListItem>
            Right to Discontinue: Artigen Limited may cease providing any
            Services at its discretion.
          </ListItem>
          <ListItem>
            Service Sustainability Efforts: We aim to maintain service
            availability but may discontinue services as needed.
          </ListItem>
          <ListItem>
            Options in Case of Discontinuation: Alternatives or compensations
            may be provided in case of service discontinuation.
          </ListItem>
        </List>

        <SubSectionTitle>Modification and Termination Process</SubSectionTitle>
        <List>
          <ListItem>
            Notice of Changes: We will provide reasonable notice of any
            significant changes to the Service or Terms, including
            discontinuation. Notice will be given through the Service interface
            or via email to registered users.
          </ListItem>
        </List>

        <SubSectionTitle>
          Limitation of Liability for Discontinued Services
        </SubSectionTitle>
        <Paragraph>
          Artigen Limited is not liable for any service modification,
          suspension, or discontinuation.
        </Paragraph>

        <SectionTitle>Fees and Payments</SectionTitle>
        <SubSectionTitle>Payment and Pricing Policy</SubSectionTitle>
        <List>
          <ListItem>
            Payment Processing Partner: All service payments are processed
            through Stripe, a third-party payment processing service. Artigen
            Limited does not directly handle or store your payment card
            information.
          </ListItem>
          <ListItem>
            Price Modification Rights: We reserve the right to modify prices and
            fees, with immediate effect upon posting on NokémonAI.
          </ListItem>
          <ListItem>
            Refund and Cancellation Policy: Charges for services are generally
            non-refundable and non-cancellable.
          </ListItem>
        </List>

        <SubSectionTitle>Account and Billing</SubSectionTitle>
        <List>
          <ListItem>
            Prepaid Account System: Billing is based on a prepaid account
            system.
          </ListItem>
          <ListItem>
            Non-Expiry of Credits: Prepaid credits in user accounts do not
            expire.
          </ListItem>
          <ListItem>
            Service Costs and Account Deductions: Service costs are deducted
            from the user's account balance.
          </ListItem>
          <ListItem>
            Company's Rights on Payment Terms: Artigen Limited reserves rights
            to modify payment terms and policies.
          </ListItem>
          <ListItem>
            User Responsibilities: Users must maintain accurate account
            information and secure their accounts.
          </ListItem>
          <ListItem>
            Account Balance Maintenance: Users must maintain a positive account
            balance and promptly address negative balances.
          </ListItem>
          <ListItem>
            Payment Disputes: Disputed amounts will be deducted from the user's
            account, along with a dispute resolution fee.
          </ListItem>
        </List>

        <SectionTitle>No Third-Party Beneficiaries</SectionTitle>
        <Paragraph>
          This Agreement benefits only the parties involved and confers no
          rights on third parties.
        </Paragraph>

        <SectionTitle>Compliance with Local Laws</SectionTitle>
        <Paragraph>
          Users must comply with local laws applicable in their jurisdiction.
        </Paragraph>

        <SectionTitle>Governing Law</SectionTitle>
        <Paragraph>
          This Agreement is governed by the laws of the United Kingdom.
        </Paragraph>

        <SectionTitle>Dispute Resolution</SectionTitle>
        <Paragraph>
          Disputes related to these Terms are subject to binding arbitration in
          the United Kingdom.
        </Paragraph>
        <SectionTitle>International Dispute Resolution</SectionTitle>
        <Paragraph>
          For international users, any disputes will be resolved in accordance
          with the laws of the United Kingdom, and you consent to the
          jurisdiction of its courts.
        </Paragraph>

        <SectionTitle>Titles and Headings</SectionTitle>
        <Paragraph>
          Titles and headings are for convenience and do not affect the
          interpretation of this Agreement.
        </Paragraph>

        <SectionTitle>Severability</SectionTitle>
        <Paragraph>
          Each clause of this Agreement is independent, and if any part is
          invalid, the remainder stays valid and enforceable.
        </Paragraph>

        <SectionTitle>Updates to Terms of Service</SectionTitle>
        <Paragraph>
          We may update these Terms from time to time. All changes will be
          communicated to our users through the Service interface or via email.
          New terms will take effect immediately for new users and following a
          notice period of 30 days for existing users.
        </Paragraph>

        <SectionTitle>Legal Information</SectionTitle>
        <Paragraph>
          Artigen Limited is a private limited company registered in England and
          Wales, number 15372967, located at Unit 13 Freeland Park, Wareham
          Road, Lytchett Matravers, Poole, England, BH16 6FA.
        </Paragraph>

        <SectionTitle>Contact Information</SectionTitle>
        <Paragraph>For inquiries or support:</Paragraph>
        <List>
          <ListItem>Email: artigen.official@gmail.com</ListItem>
          <ListItem>
            Address: Unit 13 Freeland Park, Wareham Road, Lytchett Matravers,
            Poole, England, BH16 6FA
          </ListItem>
        </List>
      </ToSWrapper>
    </>
  );
};

export default TermsOfServicePage;
