// Import necessary dependencies
import React from 'react';
import styled from 'styled-components';

import PricingComponent from './PricingComponent';

// Define styled-components for styling

// Container for the subscription tiers section
const SubscriptionTiersContainer = styled.section`
  margin: 2rem 0; // Add some margin to the top and bottom
  display: flex;
  flex-direction: row;
  justify-content: center; // Center the cards horizontally
  gap: 1rem; // Space between cards
  width: 100%;

  @media (max-width: 1110px) {
    align-items: center;
    flex-direction: column; // Make the cards stack vertically on small screens
  }
`;

// Main React component for Subscription Tiers

const SubscriptionSection = ({ maxCardWidth }) => {
  const plans = [
    {
      title: 'Novice Trainer',
      description: 'Start with more daily generations for beginners.',
      price: '$2',
      features: [
        '30 Daily Generations (Ultimate Model)',
        'Unlimited Generations (Classic Models)',
        '50-slot storage capacity',
        'Ad-free experience',
      ],
      recommended: false,
      productIndex: 3,
    },
    {
      title: 'Elite Trainer',
      description: 'Increased daily generations for serious creators.',
      price: '$5',
      features: [
        '100 Daily Generations (Ultimate Model)',
        'Unlimited Generations (Classic Models)',
        '20 credits monthly',
        '200-slot storage capacity',
        'Ad-free experience',
      ],
      recommended: true,
      productIndex: 4,
    },
    {
      title: 'Master Trainer',
      description: 'Max daily generations for Fakemon enthusiasts.',
      price: '$10',
      features: [
        '200 Daily Generations (Ultimate Model)',
        'Unlimited Generations (Classic Models)',
        '50 credits monthly',
        '500-slot storage capacity',
        'Ad-free experience',
      ],
      recommended: false,
      productIndex: 5,
    },
  ];

  return (
    <SubscriptionTiersContainer>
      {plans.map((plan, index) => (
        <PricingComponent
          key={index}
          title={plan.title}
          description={plan.description}
          price={plan.price}
          features={plan.features}
          recommended={plan.recommended}
          perMonth={true}
          productIndex={plan.productIndex}
          buttonTitle="Subscribe Now"
          maxCardWidth={maxCardWidth || '300px'}
        />
      ))}
    </SubscriptionTiersContainer>
  );
};

export default SubscriptionSection;
