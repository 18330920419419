import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import GoogleLoginComponent from './GoogleLogin';
import NokemonSDMascot from '../nokemon_sdv1_mascot.png';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 100vh;
  background: #191a1a;
  font-family: 'Helvetica', sans-serif;
`;

const Title = styled.h1`
  color: #ffffff;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const Message = styled.div`
  font-size: 1.2rem;
  color: #e9ecef;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

const Button = styled.button`
  background: #17a2b8;
  color: #fff;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #138496;
  }

  @media (max-width: 600px) {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 1rem;
  }
`;

const ExpiryMessage = styled(Message)`
  color: #f8d7da;
`;

const DisabledButton = styled(Button)`
  background: #5a6268;
  cursor: not-allowed;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
`;

const Loader = styled.div`
  color: #e9ecef;
  font-size: 2rem;
`;

const SuccessMessage = styled.div`
  color: #e9ecef;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const LoginMessage = styled.div`
  color: #ffc107;
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
  border: 2px solid #ffc107;
  border-radius: 0.25rem;
  margin-top: 1rem;
`;

const GoogleButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background: #ffffff;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: background 0.3s ease;
  width: 100%;
  max-width: 300px;

  &:hover {
    background: #f7f7f7;
  }

  &:disabled {
    background: #e9ecef;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const GoogleLogo = styled.div`
  background: url\(
      'https://www.freepnglogos.com/images/google-logo-9808.html' \
    )center/contain no-repeat;
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const GoogleButtonText = styled.p`
  font-size: 1.1rem;
  color: #191a1a;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const CookiesInfo = styled.p`
  font-size: 0.8rem;
  color: #e9ecef;
  text-align: center;
  margin-top: 0.5rem;

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

const RedirectButton = styled(Button)`
  margin-top: 1rem;
`;

const MascotImage = styled.img`
  width: 250px;
  margin-left: 70px;
  padding-bottom: 20px;
  @media (max-width: 480px) {
    width: 150px;
    margin-left: 40px;
  }
`;

export const ModalGoogleLoginButton = ({ renderProps }) => {
  return (
    <GoogleButtonContainer>
      <GoogleButton
        onClick={renderProps.onClick}
        disabled={renderProps.disabled}
      >
        <GoogleLogo />
        <GoogleButtonText>Continue with Google</GoogleButtonText>
      </GoogleButton>
      <CookiesInfo>
        Cookies are used to remember you across sessions
      </CookiesInfo>
    </GoogleButtonContainer>
  );
};

const AwardCreditsPage = ({ isLoggedIn, userInfo, setUserInfo }) => {
  const [isCompetitionExpired, setIsCompetitionExpired] = useState(false);
  const [alreadyParticipated, setAlreadyParticipated] = useState(false);
  const [nokepointsAwarded, setCreditsAwarded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAwarding, setIsAwarding] = useState(false);
  const [expiryDate, setExpiryDate] = useState('12th Nov 2023 at 23:59:59 EST');

  const navigate = useNavigate();

  useEffect(() => {
    checkCompetitionStatusAndParticipation();
  }, []);

  const checkCompetitionStatusAndParticipation = async () => {
    try {
      const response = await fetch('/api/redeem/status', {
        body: JSON.stringify({
          cookie: localStorage.getItem('jwt'),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      if (response.ok) {
        const { competitionExpired, alreadyParticipated } =
          await response.json();

        setIsCompetitionExpired(competitionExpired);
        setAlreadyParticipated(alreadyParticipated);
      } else {
        // Handle the response error
      }
    } catch (error) {
      console.error('Error checking competition status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAwardCredits = async () => {
    // Set isAwarding to true right before the fetch call
    setIsAwarding(true);

    try {
      const response = await fetch('/api/redeem/9a23eb80', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cookie: localStorage.getItem('jwt'),
        }),
      });

      if (response.ok) {
        // Credits awarded successfully
        // You can update the UI or show a success message
        setCreditsAwarded(true);
      } else {
        // Handle the response error
      }
    } catch (error) {
      console.error('Error awarding nokepoints:', error);
    } finally {
      // Set isAwarding back to false after the fetch call
      setIsAwarding(false);
    }
  };

  if (!isLoggedIn) {
    return (
      <PageContainer>
        <Title>You must be logged in to claim nokepoints!</Title>
        <GoogleLoginComponent
          redirectUrl="/redeem"
          render={renderProps => (
            <ModalGoogleLoginButton renderProps={renderProps} />
          )}
        />
      </PageContainer>
    );
  }

  if (isLoading) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    );
  }

  if (isCompetitionExpired) {
    return (
      <InfoContainer>
        <Title>Sorry...</Title>
        <MascotImage src={NokemonSDMascot} />
        <Message>
          The Credits have expired. Stay tuned for upcoming events!
        </Message>
      </InfoContainer>
    );
  }

  if (alreadyParticipated) {
    return (
      <InfoContainer>
        <Title>Thank You!</Title>
        <MascotImage src={NokemonSDMascot} />
        <Message>You have already claimed your Credits.</Message>
      </InfoContainer>
    );
  }

  return (
    <PageContainer>
      <Title>Claim Free Credits: 10</Title>
      <MascotImage src={NokemonSDMascot} />
      {!nokepointsAwarded && expiryDate && (
        <ExpiryMessage>
          Please note: These Credits will expire on {expiryDate}.
        </ExpiryMessage>
      )}
      {nokepointsAwarded ? (
        <>
          <SuccessMessage>
            You have successfully been awarded the Credits!
          </SuccessMessage>
          <RedirectButton
            onClick={() => {
              setUserInfo({
                ...userInfo,
                currencyValue: userInfo['currencyValue'] + 10,
              });
              navigate('/');
            }}
          >
            Start Generating!
          </RedirectButton>
        </>
      ) : isAwarding ? (
        <DisabledButton>Processing...</DisabledButton>
      ) : (
        <Button onClick={handleAwardCredits}>Claim Now!</Button>
      )}
    </PageContainer>
  );
};

export default AwardCreditsPage;
