import { useState, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarReg } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { sleep } from 'nokemon-common-utils';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';
import NokemonContext from '../context/NokemonContext';

import { faUpload } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

// Styled components
const StyledButton = styled.button`
  background-color: transparent !important;
  border: none;
  cursor: pointer;
`;

const AddButtonStyled = styled(StyledButton)`
  color: rgb(255, 153, 0);
  font-size: 31px;
  padding-left: 2px;

  &:hover {
    background-color: rgba(249, 250, 251);
    color: gold;
  }
`;

const DeleteButtonStyled = styled(StyledButton)`
  color: red;
  font-size: 28px;

  &:hover {
    color: rgb(168, 2, 2);
    transform: scale(1.05);
  }
`;

const ShareButtonStyled = styled(StyledButton)`
  color: #4582ec;
  font-size: 28px;

  &:hover {
    color: #0157eb;
    transform: scale(1.05);
  }
`;

const ImportButtonStyled = styled(StyledButton)`
  color: #800080;
  font-size: 26px;

  &:hover {
    color: #0157eb;
    transform: scale(1.05);
  }

  &:disabled {
    color: #aaa;
    cursor: not-allowed;
  }
`;

export function ImportButton({ fileName }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const { setNokemonData } = useContext(NokemonContext);

  const extractUuidFromFileName = fileName => {
    let uuid = fileName.split('uuid=')[1];
    return uuid;
  };

  const handleButtonClick = async () => {
    setIsDisabled(true);
    const uuid = extractUuidFromFileName(fileName);
    let response;
    if (uuid) {
      response = await fetch(`/api/nokemon/uuid=${uuid}`);
    } else {
      response = await fetch(`/api/nokemon/fileName=${fileName}`);
    }
    const data = await response.json();
    setNokemonData(data);
    navigate('/');
    setIsDisabled(false);
  };

  return (
    <ImportButtonStyled disabled={isDisabled} onClick={handleButtonClick}>
      <FontAwesomeIcon icon={faUpload} />
    </ImportButtonStyled>
  );
}

const getNokemonFromDatastoreByFileName = async function (fileName) {
  return await fetch('/api/nokemon/share/fileName', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      fileName: fileName,
    }),
  });
};

function addToFavourites(fileName) {
  const res = fetch('/api/user/favourite/add', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('jwt'),
    },
    method: 'POST',
    body: JSON.stringify({
      fileName: fileName,
    }),
  });

  return res;
}

function removeFromFavourites(fileName) {
  const res = fetch('/api/user/favourite/delete', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('jwt'),
    },
    method: 'POST',
    body: JSON.stringify({
      fileName: fileName,
    }),
  });

  return res;
}

export function AddButton({
  fileName,
  isAddButtonDisabled,
  setIsAddButtonDisabled,
}) {
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
  const [isAddButtonHover, setIsAddButtonHover] = useState(false);

  const handleClick = async () => {
    setIsAddButtonDisabled(true);
    let res;
    if (isAddButtonClicked) {
      res = await removeFromFavourites(fileName);
    } else {
      res = await addToFavourites(fileName);
    }
    const text = await res.text();
    if (res.status !== 200) {
      toast.error(text);
    } else {
      toast.success(text);
      setIsAddButtonClicked(!isAddButtonClicked);
    }
    await sleep(1000);
    setIsAddButtonDisabled(false);
  };

  return (
    <AddButtonStyled
      disabled={isAddButtonDisabled}
      onClick={handleClick}
      onMouseOver={() => setIsAddButtonHover(true)}
      onMouseLeave={() => setIsAddButtonHover(false)}
    >
      <FontAwesomeIcon icon={isAddButtonClicked ? faStarSolid : faStarReg} />
      <br />
    </AddButtonStyled>
  );
}

export function DeleteButton({
  fileName,
  collection,
  setCollection,
  isDeleteButtonDisabled,
  setIsDeleteButtonDisabled,
}) {
  const handleClick = async () => {
    setIsDeleteButtonDisabled(true);
    const res = await removeFromFavourites(fileName);
    const text = await res.text();
    if (res.status !== 200) {
      toast.error(text);
    } else {
      const updatedCollection = collection.filter(item => item !== fileName);
      setCollection(updatedCollection);
      toast.success(text);
    }
    await sleep(1000);
    setIsDeleteButtonDisabled(false);
  };

  return (
    <DeleteButtonStyled disabled={isDeleteButtonDisabled} onClick={handleClick}>
      <FontAwesomeIcon icon={faTrashCan} />
    </DeleteButtonStyled>
  );
}

export function ShareButton({ fileName }) {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = async () => {
    setIsDisabled(true);
    const res = await getNokemonFromDatastoreByFileName(fileName);
    if (res.status !== 200) {
      toast.error('Unable to share at this moment, please try again later.');
      return;
    }
    const body = await res.json();
    window.navigator.clipboard.writeText(
      `https://nokemon.eloie.tech/sharing?nokemon=${body.uuid}`,
    );
    toast.success('Nokémon URL copied to clipboard!');
    await sleep(3000);
    setIsDisabled(false);
  };

  return (
    <ShareButtonStyled disabled={isDisabled} onClick={handleClick}>
      <FontAwesomeIcon icon={faShare} />
    </ShareButtonStyled>
  );
}
