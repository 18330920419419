import styled from 'styled-components';
// Assuming logos are imported at the top of your file
import ReferralLogo from '../../refer-friend-logo.png';
import SignUpLogo from '../../sign-up-logo.png';
import RewardLogo from '../../reward-logo.png';

const contentArray = [
  {
    title: 'Invite Your Friends!',
    text: `
      When your friend generates their first Nokémon using your referral:
      - Receive +5 daily generations to use instantaneously.
    `,
    image: ReferralLogo,
  },
  {
    title: 'Friends Join, You Gain!',
    text: `
      When your friend signs up for the first time:
      - Boost your daily generation limit by 3, indefinitely.
      - Receive +10 daily generations to use instantaneously.
      - Receive +1 referral point.
    `,
    image: SignUpLogo,
  },
  {
    title: 'Cash In On Their First Buy',
    text: `
      When your friend generates their first Nokémon using your referral:
      - Earn 10% from your referrals purchase amount.
      - Receive +30 daily generations.
      - Receive +3 referral point.
    `,
    image: RewardLogo,
  },
];

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  padding: 40px 0;
  width: 80%;
  max-width: 2000px;
  margin: auto;
  border-bottom: 2px solid #d9e8ff; // Subtle blue border for a gentle distinction
`;

const Column = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (max-width: 768px) {
    width: 50%;
  }
`;

const TextColumn = styled(Column)`
  align-items: flex-start; // Default alignment for desktop
  width: 600px;
  padding-left: 100px;

  @media (max-width: 768px) {
    align-items: center; // Centering for mobile
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Image = styled.img`
  width: 300px;
  height: auto;
  border-radius: 15px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 200px;
  }
`;

const ContentRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Text = styled.p`
  font-size: 16px;
  color: #333;
  line-height: 1.8;
  font-family: 'Helvetica Neue', Arial, sans-serif;

  @media (max-width: 768px) {
    font-size: 15px; // A slight reduction for mobile legibility
    line-height: 1.6; // Tweak line height for mobile
  }
`;

const Title = styled.h3`
  font-size: 28px;
  color: #1a1a1a;
  font-weight: 700;
  margin-bottom: 15px;
  font-family: 'Helvetica Neue', Arial, sans-serif;

  @media (max-width: 768px) {
    font-size: 24px; // Making the title a bit smaller for mobile
    margin-bottom: 10px;
    display: none;
  }
`;

const MobileTitle = styled(Title)`
  display: none; // Hidden by default

  @media (max-width: 768px) {
    display: block; // Displayed only on mobile
    text-align: center; // Centered text for mobile
    margin-bottom: 20px; // Spacing adjustment
  }
`;

const BenefitsList = styled.ul`
  padding: 0;
  list-style: none;
  margin-top: 15px;

  li {
    font-size: 18px;
    line-height: 1.7;
    position: relative;
    padding-left: 32px;
    margin-bottom: 10px;

    &:before {
      content: '★';
      position: absolute;
      left: 0;
      color: #ffc107;
      font-size: 24px;
      line-height: 1;
    }
  }

  @media (max-width: 768px) {
    li {
      font-size: 16px; // Smaller text for bullets on mobile
      padding-left: 28px; // Adjusting for the smaller bullet icon
      margin-bottom: 8px;

      &:before {
        font-size: 20px; // Making the bullet icon smaller for mobile
      }
    }
  }
`;

const InformationDisplay = ({ contents }) => {
  return (
    <PageContainer>
      {contentArray.map((content, index) => (
        <>
          <MobileTitle>{content.title}</MobileTitle> {/* Mobile only title */}
          <ContentRow key={index}>
            <Column>
              {index % 2 === 0 ? <Image src={content.image} alt="" /> : <div />}
            </Column>
            <TextColumn>
              <Title>{content.title}</Title> {/* This remains for desktop */}
              <Text>
                {content.text.split('\n').map((line, idx) => {
                  if (line.trim().startsWith('-')) {
                    return (
                      <BenefitsList key={idx}>
                        <li>{line.slice(7, 100)}</li>
                      </BenefitsList>
                    );
                  }
                  return <span key={idx}>{line}</span>;
                })}
              </Text>
            </TextColumn>
            <Column>
              {index % 2 !== 0 ? <Image src={content.image} alt="" /> : <div />}
            </Column>
          </ContentRow>
        </>
      ))}
    </PageContainer>
  );
};
export default InformationDisplay;
