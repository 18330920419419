import styled from 'styled-components';
import ReferralTracker from './ProgressTracker';
import HowItWorks from './ReferralInstructions';
import RewardsDescription from './RewardsDescription';
import ReferralInfoComponent from './ReferralInfoComponent';
import HandLogo from '../../nokemon-gift-logo.png';
import TieredSystemSection from './TieredSystemSection';
import FAQSection from './FAQSection';

const MainContentAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f9ff;
  width: 100%;
`;

const ProgressTitle = styled.h2`
  font-family: 'Roboto', Arial, sans-serif; /* Roboto is a font that Google uses often */
  font-size: 36px; /* Slightly bigger font size for better visibility */
  font-weight: 500; /* Medium font weight, not too bold, not too thin */
  text-align: center;
  margin-bottom: 0px; /* A bit more margin at the bottom for better spacing */
  padding: 15px 30px; /* Increased padding for a better visual effect */
  color: #202124; /* A dark gray color, very close to black for better contrast */
  border-radius: 8px; /* Slightly reduced border radius for a more rectangular effect */
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1); /* Use cubic-bezier for a more dynamic transition effect */
`;

const Subtitle = styled.p`
  font-size: 32px;
  color: #191a1a;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 75px;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 50px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StepNumber = styled.div`
  background-color: #191a1a;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 20px;
`;

const StepDescription = styled.p`
  font-size: 18px;
  color: #191a1a;
`;

const ReferButton = styled.button`
  padding: 15px 30px;
  font-size: 18px;
  color: #fff;
  background-color: #191a1a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #1a2330;
  }
`;

const MainContentArea = () => {
  return (
    <MainContentAreaContainer>
      <HowItWorks />
      <TieredSystemSection />
      <FAQSection />
      {/* <TieredSystemHowItWorks /> */}
    </MainContentAreaContainer>
  );
};

export default MainContentArea;
