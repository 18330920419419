import styled from 'styled-components';
import { useState } from 'react';

// Styled component for the FAQ section container
const FAQSectionContainer = styled.section`
  width: 80%;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 2rem;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Styled component for the FAQ title
const FAQTitle = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: clamp(1.25rem, 4.5vw, 3rem); // Fluid font size
  line-height: 1.2;
  letter-spacing: -0.5px;
  margin-bottom: 3.5rem;
  color: #ffffff; // Changed to white for dark theme
  text-align: center;
  // background-image: linear-gradient(45deg, #ffffff, #6464ec);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // color: transparent; // Gradient effect as per webpage style
`;

// Styled component for the list of questions and answers
const FAQList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const CollapsibleAnswer = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: ${({ expanded }) => (expanded ? '500px' : '0')};
`;

const FAQItem = styled.li`
  padding: 1rem;
  border-bottom: 1px solid #333;
  cursor: pointer; // Indicates the item is clickable
  &:hover {
    background-color: #292929;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const Question = styled.h3`
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

const Answer = styled.p`
  font-size: 1rem;
  color: #aaa; // Lighter grey for readability
`;

const faqData = [
  {
    question:
      'What types of Fakémon can I create using the Daily Generation Mode and Create Your Own Mode?',
    answer: `In our Daily Generation Mode, you have quick access to a wide array of pre-defined Fakémon descriptions. This mode covers every type of Fakémon, from Fire to Dragon and more, inspired by a diverse range of creatures. It's perfect for those looking for a quick and easy way to expand their collection with a variety of Fakémon types. 
      
      On the other hand, our Create Your Own Mode offers you complete creative freedom. In this mode, you can type in your own detailed description of a Fakémon. There are no limitations to what you can create, as long as you can imagine it. This mode is ideal for those who want to bring their unique visions to life, offering a fully personalized Fakémon creation experience.`,
  },
  {
    question: 'What are daily generations used for?',
    answer:
      'Daily generations are used to interact with the daily generation mode, allowing you to interact with the AI quick picks. A single daily generation will be used to generate a single Fakémon from our pre-determined cache. This process is very quick and easy.',
  },
  {
    question: 'What are credits used for?',
    answer:
      'Credits are used to interact directly with the AI in our "Create Your Own" mode. This allows you to have full control over your generation by typing directly to the AI. One credit generates four variations of your description.',
  },
  {
    question: 'How many daily generations do I get a day?',
    answer:
      'You get 10 daily generations a day. If you would like more, consider subscribing to a tier of your choice to get more daily generations a day.',
  },
  {
    question: 'What is the difference between daily generations and credits?',
    answer:
      'Daily generations are for quick interactions with our AI to generate Fakémon, ideal for casual exploration. Credits offer a more personalized experience, allowing you to create specific Fakémon based on your own descriptions.',
  },
  {
    question: 'What is Generative AI and how does it help me design Fakémon?',
    answer:
      'Generative AI is a technology that specializes in creating content based on your inputs. In designing Fakémon, it takes your ideas and turns them into unique visual representations, making it an invaluable tool for bringing your creative visions to life.',
  },
  {
    question: 'How does the process of "Create Your Own" mode work?',
    answer: (
      <>
        <p>
          Discover the joy of creating your very own Fakémon with our "Create
          Your Own" mode. Follow this simple guide to bring your imaginative
          Fakémon ideas to life:
        </p>
        <ol>
          <li>
            <strong>Describe Your Fakémon:</strong> Begin by entering a detailed
            description of your Fakémon, focusing on aspects like the type of
            creature, general features, and colors.
          </li>
          <li>
            <strong>Generation:</strong> Our AI swiftly gets to work, typically
            taking about 30 seconds to bring your Fakémon concept to life. On
            occasions, like if the AI is waking up or during peak hours, there
            might be a slight delay.
          </li>
          <li>
            <strong>Display:</strong> Your custom Fakémon appears on the screen,
            showcasing the creativity and detail of your design.
          </li>
          <li>
            <strong>Refining Your Creation:</strong> Perfect your Fakémon by
            using the 'Use Previous Generation' option. This lets you maintain
            the overall look of your previous creation while you fine-tune
            aspects like color or specific features. It's an ideal way to make
            precise adjustments, ensuring your Fakémon matches your vision.
          </li>
        </ol>
      </>
    ),
  },
  {
    question:
      "How detailed can my customization be in the 'Create Your Own' mode for generating Fakémon?",
    answer: `In the 'Create Your Own' mode, your ability to customize Fakémon is expansive, with the only limitation being the character count of the input text box. You have the freedom to specify a variety of features including types of creatures, mixtures of creatures, body colors, highlight colors, body types, and much more. While a good amount of complexity is encouraged to bring your unique Fakémon to life, it's important to avoid cramming too much detail into one description. Overly complex inputs might confuse the model. Ideally, your description should be detailed yet concise, enabling the AI to accurately interpret and visualize your creative vision.`,
  },
  {
    question:
      'How unique are the Fakémon generated using the Daily Generation Mode and Create Your Own Mode?',
    answer: `The uniqueness of your Fakémon varies depending on the mode you choose. In the Daily Generation Mode, the Fakémon are not entirely unique, as they are generated from a pre-determined set of descriptions. Consequently, other users may generate the same Fakémon using this mode.

However, in the Create Your Own Mode, the Fakémon are completely unique. In this mode, the AI generates the Fakémon directly based on your specific input, ensuring that each creation is one-of-a-kind.`,
  },
  {
    question:
      "How long does it take to generate a Fakémon in the 'Create Your Own' mode, and what factors can affect this time?",
    answer: `The time it takes to generate a Fakémon in the 'Create Your Own' mode can vary based on several factors. Firstly, if the AI has been inactive for a while (approximately three minutes), there might be a warm-up period of about one to two minutes for the AI to activate. Once activated, the AI remains awake for all subsequent generations, unless it goes unused for another three-minute period.

The actual generation process itself takes around 30 seconds. Additionally, if the service is experiencing high traffic, you may need to wait in a queue before your generation begins. The queue time can vary depending on the number of users accessing the service simultaneously. Once it's your turn, the generation will proceed as mentioned, taking approximately 30 seconds.`,
  },
];

const FAQSection = () => {
  // State to keep track of which FAQ item is expanded
  const [expandedFAQ, setExpandedFAQ] = useState(null);

  const toggleFAQ = index => {
    // Toggle between expanded and collapsed state
    setExpandedFAQ(expandedFAQ === index ? null : index);
  };

  return (
    <FAQSectionContainer>
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      <FAQList>
        {faqData.map((faq, index) => (
          <FAQItem key={index} onClick={() => toggleFAQ(index)}>
            <Question>{faq.question}</Question>
            <CollapsibleAnswer expanded={expandedFAQ === index}>
              <Answer>{faq.answer}</Answer>
            </CollapsibleAnswer>
          </FAQItem>
        ))}
      </FAQList>
    </FAQSectionContainer>
  );
};

export default FAQSection;
