import styled from 'styled-components';
import Header from './Header.js';
import MainContentArea from './MainContentArea.js';
import Footer from './Footer.js';

const PageLayout = styled.div`
  top: 0;
  left: 0;
  background: transparent;
  overflow-x: hidden;
`;

const Layout = ({ userInfo, isLoggedIn }) => {
  return (
    <PageLayout>
      <Header isLoggedIn={isLoggedIn} userInfo={userInfo} />
      <MainContentArea />
      <Footer />
    </PageLayout>
  );
};

export default Layout;
